const mutations = {
    SET_ALL_PIPELINES (state, pipelines) {
        state.allPipelines = pipelines;
    },

    SET_PIPELINE_LOADER_STATUS (state, value) {
        state.pipelineLoader = value;
    },

    SET_PIPELINE_STAGES (state, stages) {
        state.pipelineStages = stages;
    },
};

export default mutations;