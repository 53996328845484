const state = {
    contentCsvLoader: false,
    locationLoader: false,
    importHistoryLoader: false,
    contentImportHistories: [],
    contentImportBatches: [],
    locations: [],
    verifiedFileData: {},
    contentCSVUploadStatus: 0,
};

export default state;