const optin = [
    {
        name: 'OptinIndex',
        component: () => import(/* webpackChunkName: "js/optin" */ '@/pages/optin/Index'),
        path: '/opt-ins',
        meta: {
            title: 'Opt-ins',
            requiresAuth: true,
            accountActive: true,
            group: 'Marketing',
        },
    },
];

export default optin;