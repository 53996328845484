<template>
    <div class="modal secondary dns_prompt" v-if="modelValue">
        <div class="modal_container">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            <img src="@/assets/images/alert.svg" alt="" class="alert_img">
            <!-- <h2>EMAIL NOT VERIFIED!</h2> -->
            <!-- <h5>Please use chat support or contact <a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a> to activate your email sending capabilities. There was a problem encountered while creating your account. </h5> -->
            <!-- <h5>Welcome to Thrive Coach! Your email sending capabilities are slightly delayed. The reason this happens is related to your sending DNS propagation taking a little longer than expected. This process should be quick but can take up to 48-hours in some instances. Rest assured we are working hard to get your email sending activated. If you have any questions feel free to reach out to chat <a href="mailto:support@thrivecoach.io">support</a>.</h5> -->
            <h5>
                <b>ALERT - </b>
                There was a problem verifying your email sending DNS and you will be unable to send emails via Thrive Coach to any of your contacts until this is fixed. Please contact support using the support chat widget or email <a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a>.
            </h5>
            <h5><b>Reasons this could have happened:</b></h5>
            <h5>
                <ol>
                    <li>You created an account using your mobile device and your connection timed out.</li>
                    <li>You created your account using a poor internet connection and the DNS verification timed out.</li>
                    <li>You created an account and closed the success page before it could verify your DNS with the ESP. </li>
                </ol>
            </h5>
            <h5>Don't worry you will be up and sending mail in no time if you contact us at support.</h5>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DNSModal',

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        }
    }
</script>

<style scoped>
    .modal.dns_prompt .close_btn {
        position: absolute;
        right: -7px;
        left: auto;
        top: -7px;
        border: 1px solid #c7c7c7;
        background: rgba(50, 55, 59, 0.7);
        color: #fff;
        border-radius: 50%;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 1;
    }

    .modal.dns_prompt h5 a {
        text-decoration: none;
        color: #2f7eed;
    }

    .dns_prompt.modal.secondary h5 {
        text-align: left;
        width: 100%;
    }

    .dns_prompt ol {
        padding: 0 20px;
    }

    .dns_prompt ol li {
        list-style-type: auto;
    }
</style>