import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    updateConfirmOptin (store, params) {
        return new Promise( (resolve, reject) => {
            store.commit('SET_CONFIRMED_OPTIN_LOADER_STATUS', true);

            axios.patch('admin-setting/update-confirm-optin', params).then((resp) => {
                store.commit('SET_CONFIRMED_OPTIN_LOADER_STATUS', false);
                store.commit('authModule/SET_AUTH', resp.data.user, { root: true });

                Toastr.success('Confirmed Opt In has been updated!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONFIRMED_OPTIN_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sendConfOptinAccessKey (store, params) {
        return new Promise( (resolve, reject) => {
            store.commit('SET_ACCESS_KEY_LOADER_STATUS', true);

            axios.post('admin-setting/send-access-key', params).then((resp) => {
                store.commit('SET_ACCESS_KEY_LOADER_STATUS', false);
                Toastr.success('Your Access Key was succefully sent. Please check your account email.');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_ACCESS_KEY_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateAccountSetting (store, params) {
        return new Promise( (resolve, reject) => {
            store.commit('SET_SETUP_WIZARD_LOADER_STATUS', true);

            axios.patch(`admin-setting/${params.id}/update-account-setting`, params).then((resp) => {
                store.commit('SET_SETUP_WIZARD_LOADER_STATUS', false);
                Toastr.success('Settings has been updated successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_SETUP_WIZARD_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updatePortalSetting (store, params) {
        return new Promise( (resolve, reject) => {
            store.commit('SET_SETUP_WIZARD_LOADER_STATUS', true);

            axios.patch(`admin-setting/${params.id}/update-portal-setting`, params).then((resp) => {
                store.commit('SET_SETUP_WIZARD_LOADER_STATUS', false);
                Toastr.success('Settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SETUP_WIZARD_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    }
};

export default actions;
