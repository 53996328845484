const state = {
    scheduledMessageLoader: false,
    scheduledMessage: {},
    allScheduledMessage: {},
    stats: [],
    sendMessageLoader: false,
    receiveChatLogWatcher: 0,
    receiveChatLog: [],
    latestPortalChat: {},
};

export default state;
