
const adminSettings = [
    {
        name: 'AdminSettings',
        component: () => import(/* webpackChunkName: "js/admin-setting/admin-setting" */ '@/pages/admin-setting/AdminSetting'),
        path: '/admin-settings',
        meta: {
            title: 'Admin Settings',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'AccountBilling',
        component: () => import(/* webpackChunkName: "js/admin-setting/account-billing" */ '@/pages/admin-setting/components/account/AccountBilling'),
        path: '/account-billing',
        meta: {
            title: 'Account Billing',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'AccountDowngrade',
        component: () => import(/* webpackChunkName: "js/admin-setting/downgrade" */ '@/pages/admin-setting/components/account/billing/Downgrade'),
        path: '/account/:subscription/downgrade',
        meta: {
            title: 'Account Downgrade',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default adminSettings;