<template>
    <div class="modal cropper-model" v-if="modelValue" :class="modelValue ? `active ${classlist}`:`${classlist}`">
        <div class="modal_container">
            <div class="modal_header">
                <div class="modal_info">
                    <h1 class="m-0">Cropper</h1>
                    <div class="action_wpr m-0 p-0">
                        <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button type="button" class="btn save_btn" @click="cropImage()" :disabled="loader || !isCropperReady">
                            <i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Cropping' : 'Crop' }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-for="(item, i) in imageSizeValidation" :key="i">
                <span class="text-danger" >{{item}}</span>
            </div>
            <div class="modal_body p-0 mt-5">
                <div v-if="imageBlobLoader" class="cropper-container">
                    <quote-loader />
                </div>
                <div class="crop_wpr" v-else>
                    <vue-cropper
                        :ref='cropperRef'
                        :guides="true"
                        :view-mode="1"
                        drag-mode="move"
                        :auto-crop-area="0.75"
                        :zoomable="false"
                        :movable="false"
                        :restore="true"
                        :crop-box-movable="loader ? false : true"
                        :background="true"
                        :src="imageBlob"
                        alt="Source Image"
                        :img-style="{ 'width': '100%', 'height': '320px' }"
                        :ready="onCropperReady"
                    ></vue-cropper>
                    <div class="crop_info">
                        <a href="Javascript:void(0)" @click="flipVertical" title="Flip Horizontal (H)">
                            <i class="fas fa-arrows-alt-v"></i>
                        </a>
                        <a href="Javascript:void(0)" @click="flipHorizontal"  title="Flip Vertical (V)">
                            <i class="fas fa-arrows-alt-h"></i>
                        </a>
                        <span v-if="size != 'all'">MINIMUM IMAGE SIZE IS  {{size}}</span>
                        <div v-if="(size == '180x60' || size == '180x180') && (cropType != 'author-image')" class="action_wpr flex-center">
                            <button type="button" class="ratio_btn" :class="logoSize == '180x60' ? 'save_btn' : 'cancel_btn'" @click="logoSize = '180x60'">180x60</button>
                            <button type="button" class="ratio_btn" :class="logoSize == '180x180' ? 'save_btn' : 'cancel_btn'" @click="logoSize = '180x180'">180x180</button>
                        </div>

                        <!-- <div v-if="size == '1280x300' || size == '2560x600'" class="action_wpr flex-center">
                            <button type="button" class="btn cancel_btn" @click="logoSize = '1280x300'">1280x300</button>
                            <button type="button" class="btn cancel_btn" @click="logoSize = '2560x600'">2560x600</button>
                        </div> -->
                        <!-- <div v-if="size == '768x300' || size == '1480x960'" class="action_wpr flex-center">
                            <button type="button" class="btn cancel_btn" @click="logoSize = '768x300'">768x300</button>
                            <button type="button" class="btn cancel_btn" @click="logoSize = '1480x960'">1480x960</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'
    import Toastr from '@/utils/Toastr'
    import { uuid } from 'vue-uuid'

    import { mapState, mapGetters, mapActions } from 'vuex'

    export default {
        name: 'Image Cropper',

        data () {
            return {
                loader: false,
                type: null,
                size: null,
                cropData: [],
                logoSize: '180x60',
                cropperRef: 'cropper-'+uuid.v4(),
                isCropperReady: false,
                imageBlob: null,
                imageSizeValidation: false,
            }
        },

        props: {
            modelValue: Boolean,
            handleCrop: Function,
            cropType: String,
            image: String,
            classlist: String,
            originalImage: Object,
        },

        emits: ['update:modelValue', 'update:fileUrl'],

        components: {
            VueCropper,
        },

        watch: {
            logoSize: function(n, o) {
                const vm = this;

                if (vm.logoSize == '180x60') {
                    vm.size = '180x60';
                    vm.$refs[vm.cropperRef].setAspectRatio(180/60);
                } else if (vm.logoSize == '180x180') {
                    vm.size = '180x180';
                    vm.$refs[vm.cropperRef].setAspectRatio(180/180);
                }
            },

            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.initAspectRatio(vm.cropType);
                    vm.isCropperReady = false;
                }
            },

            image (image) {
                const vm = this;

                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator


                if (pattern.test(image)) {
                    vm.getImageBlobFromUrl({ image }).then((blob) => {
                        if (blob) {
                            vm.imageBlob = blob;
                        }
                    });
                } else {
                    vm.imageBlob = image;
                }
            },
        },

        computed: {
              ...mapState({
                  user: state => state.authModule.user,
                  imageBlobLoader: state => state.imageLibraryModule.imageBlobLoader,
              }),
              ...mapGetters({
                  getAspectRatio: 'imageLibraryModule/GET_CROPPER_ASPECT_RATIO_BY_TYPE',
              }),
        },

        methods: {
            ...mapActions({
                uploadImage: 'imageLibraryModule/uploadImage',
                getImageBlobFromUrl: 'imageLibraryModule/getImageBlobFromUrl',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
                vm.imageSizeValidation = false;
            },

            flipVertical () {
                const vm = this;

                vm.$refs[vm.cropperRef].scaleY(-vm.$refs[vm.cropperRef].getData().scaleY || -1);
            },

            flipHorizontal () {
                const vm = this;

                vm.$refs[vm.cropperRef].scaleX(-vm.$refs[vm.cropperRef].getData().scaleX || -1);
            },

            cropImage () {
                const vm = this;

                vm.loader = true;
                vm.cropData = vm.$refs[vm.cropperRef].getImageData();

                vm.$refs[vm.cropperRef].getCroppedCanvas().toBlob((blob) => {
                    vm.loader = true;
                    let location = `uploads/media/${vm.user.id}/${vm.size}`;

                    const form = new FormData();
                    form.append('image', blob);
                    form.append('size', vm.size);
                    form.append('type', vm.size);
                    form.append('location', location);

                    if (vm.originalImage && vm.originalImage.id) {
                        form.append('original_id', vm.originalImage.id);
                    }

                    vm.uploadImage(form).then((resp) => {
                        vm.loader = false;
                        if (resp.data.status == 1) {
                            const file = resp.data.response

                            vm.handleCrop(file);
                            Toastr.success('Image successfully uploaded!');
                            vm.closeModal();
                        }
                    }).catch((err) => {
                        vm.imageSizeValidation = err.response.data.errors.image
                        vm.loader = false;
                        Toastr.error('Unable to upload image, Please try to reduce the cropped image and upload again!');
                    });
                });
            },

            cancelCrop () {
                const vm = this;

                vm.closeModal();
                vm.$refs[vm.cropperRef].replace('');
            },

            initAspectRatio (type) {
                const vm = this;

                const aspectRatio = vm.getAspectRatio(type);
                vm.size = aspectRatio.size;

                const startTime = Date.now();

                const checkCropper = setInterval(() => {
                    const cropperRef = vm.$refs[vm.cropperRef];

                    if (cropperRef) {
                        vm.$refs[vm.cropperRef].setAspectRatio(aspectRatio.ratio);
                        clearInterval(checkCropper);
                    } else if (Date.now() - startTime > 3000) {
                        clearInterval(checkCropper);
                    }
                }, 100);

                vm.type = type;
            },

            onCropperReady (e) {
                const vm = this;

                setTimeout(function () {
                    vm.isCropperReady = true;
                }, 500);
            },
        },
    }
</script>

<style scoped>
    .modal.cropper-model.right_out {
        background: rgba(52, 64, 84, 0.8) !important;
    }

    .crop_wpr {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
        overflow: hidden;
    }

    .crop_info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }

    .crop_info a {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #fff;
        background: #2f7eed;
        margin: 3px;
    }

    .crop_info span {
        text-transform: capitalize;
        font-size: 14px;
        line-height: 20px;
        margin-left: 10px;
    }

    .client_card .card_body .modal .modal_header h1{
        margin: 25px 0;
        font-size: 21px;
        line-height: 28px;
        color: #121525;
        font-weight: 500;
        text-transform: capitalize;
        text-align: left;
        text-shadow: none;
    }

    .cropper-container .quote_wpr {
        min-height: 300px;
        justify-content: center;
    }

    .modal .modal_header {
        background: transparent !important;
    }

    :deep(.action_wpr .btn.cancel_btn) {
        background: transparent;
        color: #121525;
        font-weight: 400;
        border: 1px solid #dbdde1;
        min-width: 80px;
        height: 36px;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        padding: 0 15px;
        box-sizing: border-box;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        position: static;
    }
</style>
