const state = {
    plans: [],
    subscription: {},
    subscriptionLoader: false,
    billingProfile: {},
    billingProfileLoader: false,
    billingProfileSaveLoader: false,
    twoFACodeLoader: false,
    plansLoader: false,
    twoFACode: {},
    oldSubscriptionLoader: false,
    oldSubscription: {},
    allAssetsLoader: false,
    allAssets: {},
};

export default state;