import axios from '@/services/axios'
import router from '@/routes'
import Toastr from '@/utils/Toastr'
import { Promise } from 'core-js';


const actions = {
    login (store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.post('/auth/login', params).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
            store.commit('SET_IS_2FA_ENABLED_STATUS', resp.data.is_2fa_enabled);
            store.commit('SET_IS_2FA_VERIFIED_STATUS', false);
            store.commit('SET_AUTH_TOKEN', resp.data);

            axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
            axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
            axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

            localStorage.removeItem('contacts_params');

            if (resp.data.is_2fa_enabled) {
                router.push({ name: 'TwoFactorAuthentication' });
            } else if (resp.data.hibernated) {
                store.dispatch('refreshAuth');
            } else if (resp.data.is_account_active) {
                const routeName = store.getters.GET_ROUTE_NAME_BY_MODULE(resp.data.module);

                router.push({ name: routeName });
            } else {
                router.push({ name: 'PaymentActionRequired' });
            }
        }).catch((err) => {
            Toastr.handleServerError(err, params.setFieldError);
            store.commit('SET_FORM_LOADER_STATUS', false);
        });
    },

    autoLogin (store, token) {
        return new Promise((resolve) => {
            store.commit('SET_AUTO_LOGIN_LOADER_STATUS', true);

            axios.post('/auth/auto-login', { token }).then((resp) => {
                store.commit('SET_AUTO_LOGIN_LOADER_STATUS', false);
                store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
                store.commit('SET_IS_2FA_ENABLED_STATUS', resp.data.is_2fa_enabled);
                store.commit('SET_IS_2FA_VERIFIED_STATUS', false);
                store.commit('SET_AUTH_TOKEN', resp.data);

                axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
                axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
                axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

                store.dispatch('refreshAuth');
                localStorage.removeItem('contacts_params');
                localStorage.removeItem('contacts_widget_toggled');
                localStorage.removeItem('contacts_widget_filters');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_AUTO_LOGIN_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    logout (store) {
        return new Promise(function(resolve, reject) {
            axios.post('/auth/logout').then((resp) => {
                if (resp && resp.data.status) {
                    store.commit('LOGOUT_USER');
                    store.commit('SET_LOGGED_OUT_AT');
                    store.commit('SET_COMPANY_LOCATIONS', []);
                    store.commit('SET_ORGANIZATION_LOCATIONS', []);
                    store.commit('RESET_STATE', false, { root: true });
                    store.commit('checkoutModule/RESET_CHECKOUT_AUTH_TOKEN', false, { root: true });

                    const gist = window.gist;

                    if (gist) {
                        gist.chat('shutdown');
                    }

                    localStorage.removeItem('contacts_widget_toggled');
                    localStorage.removeItem('contacts_widget_filters');
                }

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    forgot (store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.post('/auth/forgot-password', params).then((resp) => {
            if (resp.data.status == 1) {
                store.commit('FORGOT_PASSWORD_MESSAGE', resp.data.message);

                Toastr.success(resp.data.message);
                params.resetForm();
            }

            store.commit('SET_FORM_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('FORGOT_PASSWORD_MESSAGE', '');
            store.commit('SET_FORM_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    reset (store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.post('/auth/reset-password', params).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            Toastr.success(resp.data.message);
            router.push({ name: 'Login' });
        }).catch((err) => {
            store.commit('FORGOT_PASSWORD_MESSAGE', '');
            store.commit('SET_FORM_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    refreshAuth (store) {
        return new Promise((resolve) => {
            axios.post('/auth/refresh').then((resp) => {
                store.commit('SET_AUTH', resp.data.user);
                store.commit('SET_USER_MEMBERSHIP', resp.data.membership);
                store.commit('SET_COMPANY_USER_PERMISSIONS', resp.data.permissions);
                store.commit('SET_COMPANY_LOCATIONS', resp.data.locations);
                store.commit('SET_IS_ACCESSED_ACCOUNT', resp.data.accessed);
                store.commit('SET_FILE_STORAGE', resp.data.file_storage);
                store.commit('SET_USER_INTEGRATIONS', resp.data.integrations);
                store.commit('SET_CONTACT_PROFILE_SETTING', resp.data.contact_profile_setting);
                store.commit('SET_WHITE_LABEL', resp.data.user && resp.data.user.white_label ? resp.data.user.white_label : {});
                store.commit('SET_IS_ACCOUNT_ACTIVE_STATUS', resp.data.user.is_account_active);
                store.commit('SET_HAS_TRIAL_PERIOD_STATUS', resp.data.user.has_trial_period);
                store.commit('SET_IS_ACCOUNT_SUSPENDED_STATUS', resp.data.user.is_suspended ? true : false);
                store.commit('commonModule/SET_CURRENCIES', resp.data.currencies, { root: true });
                store.commit('broadcastModule/SET_BROADCAST_EMAIL_SENT_COUNT', resp.data.broadcast_emails_sent_count, { root: true });
                store.commit('commonModule/SET_RESERVED_WORDS', resp.data.reserved_words, { root: true });
                store.commit('FORGOT_PASSWORD_MESSAGE', '');
                store.commit('contactModule/SET_CONTACT_TABLE_PREFRENCES', resp.data.user.contact_preferences, { root: true });
                store.dispatch('commonModule/getDefinedLinks', {}, { root: true });

                if (store.rootState.commonModule.emailButtons.lenght == 0) {
                    store.dispatch('commonModule/getEmailButtons', {}, { root: true });
                }

                if (store.rootState.commonModule.favoriteColors.lenght == 0) {
                    store.dispatch('commonModule/getFavoriteColors', {}, { root: true });
                }

                if (router.currentRoute && router.currentRoute.value && router.currentRoute.value.name == 'PaymentActionRequired' && resp.data.user.is_account_active && resp.data.user.hibernated == 0) {
                    router.push({ name: 'ContactIndex' });
                }

                if (resp.data.user.is_account_active == 0 && router.currentRoute && router.currentRoute.value && router.currentRoute.value.name != 'PaymentActionRequired') {
                    router.push({ name: 'PaymentActionRequired' });
                }

                if (resp.data.user.hibernated == 1 && router.currentRoute && router.currentRoute.value && router.currentRoute.value.name != 'ActivateAccount') {
                    router.push({ name: 'ActivateAccount' });
                }

                if (resp.data.user.hibernated == 0 && router.currentRoute && router.currentRoute.value && router.currentRoute.value.name == 'ActivateAccount') {
                    router.push({ name: 'ContactIndex' });
                }

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(true);
            });
        });
    },

    accessAccount (store, id) {
        store.dispatch('logoutCheckout');

        axios.post(`/account/${id}/access`).then((resp) => {
            store.commit('RESET_STATE', false, { root: true });
            store.commit('SET_AUTH_TOKEN', resp.data);
            store.commit('checkoutModule/RESET_CHECKOUT_AUTH_TOKEN', false, { root: true });

            axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
            axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
            axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

            localStorage.removeItem('contacts_params');

            const routeName = store.getters.GET_ROUTE_NAME_BY_MODULE(resp.data.module);

            router.push({ name: routeName });

            setTimeout(function () {
                window.location.reload();
            }, 100);
        }).catch((err) => {
            store.commit('checkoutModule/RESET_CHECKOUT_AUTH_TOKEN', false, { root: true });
            Toastr.handleServerError(err);
        });
    },

    switchLocation (store, id) {
        axios.post(`/location/${id}/access`).then((resp) => {
            store.commit('RESET_STATE', false, { root: true });
            store.commit('SET_AUTH_TOKEN', resp.data);

            axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;

            localStorage.removeItem('contacts_params');

            router.push({ name: 'ContactIndex', force: true });

            setTimeout(function () {
                window.location.reload();
            }, 100);
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    getOrganizationLocations (store) {
        store.commit('SET_ORGANIZATION_LOCATION_LOADER_STATUS', true);

        axios.get(`/auth/locations`).then((resp) => {
            store.commit('SET_ORGANIZATION_LOCATIONS', resp.data);
            store.commit('SET_ORGANIZATION_LOCATION_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ORGANIZATION_LOCATION_LOADER_STATUS', false);
        });
    },

    getLocationTabs (store, location) {
        axios.get(`/auth/location/${location}/tabs`).then((resp) => {
            store.commit('SET_LOCATION_TABS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getOrganizationUsers (store, params) {
        store.commit('SET_ORGANIZATION_USER_LOADER_STATUS', true);

        axios.get(`/organization/users`, { params }).then((resp) => {
            store.commit('SET_ORGANIZATION_USERS', resp.data);
            store.commit('SET_ORGANIZATION_USER_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ORGANIZATION_USER_LOADER_STATUS', false);
        });
    },

    getOrganizationSuperUsers (store, userId) {
        if (userId) {
            axios.get(`/organization/${userId}/super-users`).then((resp) => {
                store.commit('SET_ORGANIZATION_SUPER_USERS', resp.data);
            }).catch((err) => {
                console.log(err);
            });
        }
    },

    updateUser (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_USER_LOADER_STATUS', true);

            axios.patch(`/user/update`, params).then((resp) => {
                store.commit('SET_USER_LOADER_STATUS', false);
                Toastr.success('Primary location has been updated successfully!');

                store.dispatch('refreshAuth');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_USER_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    saveOrganizationLocation (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ORGANIZATION_LOCATION_SAVE_LOADER_STATUS', true);

            let route   = `/organization/location/create`;
            let method  = 'post';

            if (params.id) {
                method  = 'patch';
                route   = `/organization/location/${params.id}/update`
            }

            axios[method](route, params).then((resp) => {
                store.commit('SET_ORGANIZATION_LOCATION_SAVE_LOADER_STATUS', false);
                Toastr.success('Secondary location has been saved successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_ORGANIZATION_LOCATION_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteLocation (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/organization/location/${params.location_id}/delete`, { params }).then((resp) => {
                Toastr.success('Secondary location has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    suspendLocation (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/organization/location/${params.location_id}/suspend`, params).then((resp) => {
                Toastr.success(`Secondary location has been ${params.status ? 'suspended' : 'activated'} successfully!`);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    addUserToLocation (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/organization/location/${params.location_id}/add-users`, params).then((resp) => {
                Toastr.success(`Users has been added successfully!`);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateWhiteLable (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_WHITE_LABEL_SAVE_LOADER_STATUS', true);

            axios.patch(`/user/update-white-label`, params).then((resp) => {
                store.commit('SET_WHITE_LABEL_SAVE_LOADER_STATUS', false);
                Toastr.success(`White label has been updated successfully!`);

                store.dispatch('refreshAuth');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_WHITE_LABEL_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getPermissions (store) {
        axios.get('/organization/permissions').then((resp) => {
            store.commit('SET_PERMISSIONS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getUserPermissions (store, userId) {
        return axios.get(`/organization/user/${userId}/permissions`);
    },

    saveUser (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_USER_SAVE_LOADER_STATUS', true);

            axios.post('/organization/user/save', params).then((resp) => {
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                console.log(params.setFieldError);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateKillStatus (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/user/update-kill-status', params).then((resp) => {
                Toastr.success(`The ${params.type} sending has been ${ params.status ? 'enabled' : 'disabled' } successfully!`);
                // store.dispatch('refreshAuth');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteUser (store, userId) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/organization/user/${userId}/delete`).then((resp) => {
                Toastr.success('The user has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    resendLoginCredentials (store, userId) {
        return new Promise(function(resolve, reject) {
            axios.post(`/organization/user/${userId}/resend-credentials`).then((resp) => {
                if (resp.data.status == 1) {
                    Toastr.success('The login credentials has been send successfully!');
                    resolve(true);
                } else {
                    Toastr.error('We are unable to process your request, Please try again!');
                    resolve(false);
                }
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    storeTwilioCredentials (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/integration/save-twilio-credentials', params).then((resp) => {
                Toastr.success('Twilio credentials has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    updateTwilioConnection (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/integration/update-twilio-connection', params).then((resp) => {
                if (resp.data.status) {
                    Toastr.success('Twilio connection has been updated successfully!');
                    resolve(true);
                } else {
                    Toastr.error(resp.data.error_message);
                    resolve(false);
                }
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    getFitFunnelWebhooks (store, params) {
        axios.get('/integration/fit-funnel-webhooks', params).then((resp) => {
            store.commit('SET_FIT_FUNNEL_WEBHOOK', resp.data)
        }).catch((err) => {
            console.log(err);
        });
    },

    saveFitFunnelWebhook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/integration/save-fit-funnel-webhook', params).then((resp) => {
                store.dispatch('getFitFunnelWebhooks');
                Toastr.success('Fit funnel webhook has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    deleteFitFunnelWebhook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/integration/delete-fit-funnel-webhook', { params }).then((resp) => {
                store.dispatch('getFitFunnelWebhooks');
                Toastr.success('Fit funnel webhook has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    generateZapierAccessToken (store, params) {
        return axios.post('/integration/generate-zapier-token', params);
    },

    getEmailDomains (store, params) {
        store.commit('SET_CUSTOM_EMAIL_DOMAIN_LOADER_STATUS', true);

        axios.get('/email-domain/list', { params }).then((resp) => {
            store.commit('SET_CUSTOM_EMAIL_DOMAINS', resp.data);
            store.commit('SET_CUSTOM_EMAIL_DOMAIN_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CUSTOM_EMAIL_DOMAIN_LOADER_STATUS', false);
        });
    },

    getEmailDomainSenders (store, params) {
        store.commit('SET_CUSTOM_EMAIL_DOMAIN_SENDER_LOADER_STATUS', true);

        axios.get(`/email-domain/${params.domain_id}/sender-list`, { params }).then((resp) => {
            store.commit('SET_CUSTOM_EMAIL_DOMAIN_SENDERS', resp.data);
            store.commit('SET_CUSTOM_EMAIL_DOMAIN_SENDER_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CUSTOM_EMAIL_DOMAIN_SENDER_LOADER_STATUS', false);
        });
    },

    saveEmailDomain (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_DNS_LOADER_STATUS', true);

            axios.post('/email-domain/save', params).then((resp) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                store.commit('SET_DOMAIN_DNS_DETAILS', resp.data.dns_details);
                resolve(resp.data.domain);
            }).catch((err) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                console.log(err);
                resolve(false);
            });
        });
    },

    saveEmailDomainSender (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_DNS_LOADER_STATUS', true);

            axios.post('/email-domain/save-sender', params).then((resp) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                console.log(err);
                resolve(false);
            });
        });
    },

    activateEmailDomain (store, id) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_DNS_LOADER_STATUS', true);

            axios.patch(`/email-domain/${id}/activate`).then((resp) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.success('Email custom domain has been activated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    activateEmailDomainSender (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/email-domain/${id}/activate-sender`).then((resp) => {
                Toastr.success('Email custom domain sender has been activated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    enableDisableEmailDomain (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_DNS_LOADER_STATUS', true);

            axios.patch('/email-domain/enable-disable', params).then((resp) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.success(`Email custom domain has been ${ params.status ? 'enabled' : 'disabled' } successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    emailDomainDNSDetails (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_DNS_LOADER_STATUS', true);

            axios.get('/email-domain/dns-details', { params }).then((resp) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                store.commit('SET_DOMAIN_DNS_DETAILS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOMAIN_DNS_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    verifyEmailDomain (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/email-domain/${id}/verify`).then((resp) => {
                if (Object.values(resp.data.errors).length) {
                    Toastr.error(Object.values(resp.data.errors).join(', '));
                    resolve(false);
                } else if (resp.data.is_verified == 0) {
                    resolve(0);
                } else {
                    Toastr.success(`Email custom domain has been verified successfully!`);
                    resolve(true);
                }
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(0);
            });
        });
    },

    deleteEmailDomain (store, ids) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/email-domain/delete`, { params: { ids } }).then((resp) => {
                Toastr.success(`Email custom domain has been deleted successfully!`);
                resolve(true);
            }).catch((err) => {
              console.log(err);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    deleteEmailDomainSender (store, ids) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/email-domain/delete-sender`, { params: { ids } }).then((resp) => {
                Toastr.success(`Email custom domain sender has been deleted successfully!`);
                resolve(true);
            }).catch((err) => {
              console.log(err);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    activateEmailDomainForLocation (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/email-domain/${id}/activate-domain-for-locations`).then((resp) => {
                Toastr.success(`Email custom domain has been activated for all locations successfully!`);
                resolve(true);
            }).catch((err) => {
              console.log(err);
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    activateEmailDomainSenderForLocation (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/email-domain/${id}/activate-sender-for-locations`).then((resp) => {
                Toastr.success(`Email custom domain sender has been activated for all locations successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    updateProfile (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/user/update-profile', params).then((resp) => {
                Toastr.success(`Profile has been updated successfully!`);
                store.commit('SET_AUTH', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                console.log(err);
                resolve(false);
            });
        });
    },

    updateContactProfileSetting (store, params) {
        axios.patch(`/user/update-contact-profile-setting`, params).then((resp) => {
            store.commit('SET_CONTACT_PROFILE_SETTING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    verifyTwoFactorAuthentication (store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.post('/auth/verify-twofa', params).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_IS_2FA_VERIFIED_STATUS', true);

            if (resp.data.hibernated) {
                store.commit('SET_IS_HIBERNATED_STATUS', true);
                router.push({ name: 'ActivateAccount' });
            } else {
                router.push({ name: 'ContactIndex' });
            }
        }).catch((err) => {
            Toastr.handleServerError(err, params.setFieldError);
            store.commit('SET_FORM_LOADER_STATUS', false);
        });
    },

    changePassword (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/user/change-password', params).then((resp) => {
                Toastr.success(`Password has been changed successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    generateLandingPageToken (store) {
        return new Promise(function(resolve, reject) {
            axios.post('/user/generate-landing-token').then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    developerLogin (store, params) {
        store.commit('SET_DEVELOPER_LOGIN_LOADER_STATUS', true);

        axios.post(`/developer/${params.user}/${params.token}/login`).then((resp) => {
            store.commit('LOGOUT_USER');
            store.commit('SET_LOGGED_OUT_AT');
            store.commit('RESET_STATE', false, { root: true });
            store.commit('SET_DEVELOPER_LOGIN_LOADER_STATUS', false);

            const gist = window.gist;

            if (gist) {
                gist.chat('shutdown');
            }

            store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
            store.commit('SET_IS_2FA_ENABLED_STATUS', false);
            store.commit('SET_IS_2FA_VERIFIED_STATUS', false);
            store.commit('SET_AUTH_TOKEN', resp.data);

            axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
            axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
            axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

            localStorage.removeItem('contacts_params');

            if (params.redirect) {
                router.push({ name: params.redirect, query: { tab: params.params.tab, contact: params.params.contact } });
            } else {
                window.location = '/contacts';
            }
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_DEVELOPER_LOGIN_LOADER_STATUS', false);
            router.push({ name: 'Login', force: true });
        });
    },

    getOrganizationTabs (store, location) {
        axios.get(`/auth/organization/${location}/tabs`).then((resp) => {
            store.commit('SET_LOCATION_TABS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    logoutCheckout (store) {
        axios.post(`${process.env.VUE_APP_CHECKOUT_API_URL}/api/auth/logout`, {}, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.rootState.checkoutModule.checkoutAuthToken}`,
            }
        });
    },

    updateLatestVersion (store) {
        return new Promise((resolve, reject) => {
            axios.patch(`/app/latest-version`).then((resp) => {
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        })
    },

    loginWithPlaybookToken (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/auth/login-with-playbook-token`, params).then((resp) => {
                store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
                store.commit('SET_IS_2FA_ENABLED_STATUS', false);
                store.commit('SET_IS_2FA_VERIFIED_STATUS', false);
                store.commit('SET_AUTH_TOKEN', resp.data);

                axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
                axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
                axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

                localStorage.removeItem('contacts_params');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    updateContactPreferences (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/user/update-contact-preferences', params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    refreshContactPreferences (store) {
        return new Promise(function(resolve, reject) {
            axios.get('/user/contact-preferences', {}).then((resp) => {
                store.commit('contactModule/SET_CONTACT_TABLE_PREFRENCES', resp.data, { root: true });

                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },
};

export default actions;
