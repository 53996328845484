const mutations = {
    SET_ALL_SEQUENCES (state, sequences) {
        state.allSequences = sequences;
    },

    SET_SEQUENCE_LOADER_STATUS (state, status) {
        state.sequenceLoader = status;
    },

    SET_SEQUENCES (state, sequences) {
        state.sequences = sequences;
    },

    SET_SEQUENCE_EMAIL_LOADER_STATUS (state, status) {
        state.sequenceEmailLoader = status;
    },

    SET_SEQUENCE_EMAILS (state, emails) {
        state.sequenceEmails = emails;
    },

    SET_SEQUENCE_ANALYTIC_LOADER_STATUS (state, status) {
      state.analyticsLoader = status;
    },

    SET_SEQUENCE_EMAIL_ANALYTICS (state, emails) {
        state.emailAnalytics = emails;
    },

    SET_SEQUENCE_SMS_ANALYTICS (state, emails) {
        state.smsAnalytics = emails;
    },

    SET_SEQUENCE_CREATE_LOADER_STATUS (state, status) {
        state.sequenceCreateLoader = status;
    },

    SET_SELECTED_SEQUENCE (state, sequence) {
        state.selectedSequence = sequence;
    },

    SET_SEQUENCE_UPDATE_LOADER_STATUS (state, status) {
        state.sequenceUpdateLoader = status;
    },

    SET_SEQUENCE_COMPONENT_LOADER_STATUS (state, status) {
        state.sequenceComponentLoader = status;
    },

    SET_SEQUENCE_USERS (state, sequenceUsers) {
        state.sequenceUsers = sequenceUsers;
    },

    SET_SEQUENCE_MESSAGE_UPDATE_LOADER_STATUS (state, status) {
        state.sequenceMessageUpdateLoader = status;
    },

    SET_SEQUENCE_SCHEDULE (state, schedules) {
        state.schedules = schedules;
    },

    SET_SEQUENCE_SCHEDULE_LOADER_STATUS (state, status) {
        state.scheduleLoader = status;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'ordering',
                            order: 'desc',
                            folder_id: null,
                        };
    },

    SET_SEQUENCE_DETAIL_ANALYTICS_LOADER_STATUS (state, status) {
        state.detailAnalyticsLoader = status;
    },

    SET_SEQUENCE_ANALYTICS_CONTACTS_LOADER_STATUS (state, status) {
        state.analyticsContactsLoader = status;
    },

    SET_MESSAGES_ANALYTICS (state, analytics) {
        state.messagesAnalytics = analytics;
    },

    SET_MESSAGES_SUBSCRIBERS (state, subscribers) {
        state.messagesSubscribers = subscribers;
    },
};

export default mutations;
