import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getReward (store) {
        store.commit('SET_NOTIFICATION_REWARD_LOADER_STATUS', true);

        axios.get('/reward/get-user-reward').then((resp) => {
            store.commit('SET_NOTIFICATION_REWARD_LOADER_STATUS', false);
            store.commit('SET_REWARD', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_NOTIFICATION_REWARD_LOADER_STATUS', false);
        });
    },

    updatePointName (store, params) {
        store.commit('SET_REWARD_LOADER_STATUS', true);

        axios.patch('/reward/update-point-name', params).then((resp) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            store.dispatch('getReward');
            params.closeModel();
            Toastr.success('Reward points name successfully updated');
        }).catch((err) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    updatePointCurrency (store, params) {
        store.commit('SET_REWARD_LOADER_STATUS', true);

        axios.patch('/reward/update-point-currency', params).then((resp) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            store.dispatch('getReward');
            params.closeModel();
            Toastr.success('Reward currency successfully updated');
        }).catch((err) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    updateRewardNotification (store, params) {
        store.commit('SET_REWARD_LOADER_STATUS', true);

        axios.patch('/reward/update-reward-notification', params).then((resp) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            store.dispatch('getReward');
            params.closeModel();
            Toastr.success('Reward notification successfully updated');
        }).catch((err) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            console.log(err);
        });
    }
};

export default actions;
