const playbook = [
    {
        name: 'PlaybookIndex',
        component: () => import(/* webpackChunkName: "js/playbook/index" */ '@/pages/playbook/Index'),
        path: '/playbooks',
        meta: {
            title: 'Playbooks',
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
        },
    },
    {
        name: 'PlaybookEdit',
        component: () => import(/* webpackChunkName: "js/playbook/edit" */ '@/pages/playbook/PlaybookEdit'),
        path: '/playbook/:playbook/edit',
        meta: {
            title: 'Playbook Edit',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            group: 'Coaching',
            parent: {
                title: 'Playbooks',
                name: 'PlaybookIndex',
            }
        },
    },
    {
        name: 'PlaybookAnswerQuestion',
        path: '/playbook/:playbook/auto-login',
    },
];

export default playbook;