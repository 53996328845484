const state = {
    workoutParams: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'order',
        order: 'desc',
        folder_id: null,
    },
    planParams: {
        per_page: 6,
        page: 1,
        search: '',
        filter: 'date_created',
        order_by: 'order',
        order: 'desc',
        folder_id: null,
    },
    workouts: {data: []},
    workoutLoader: false,
    selectedPlan: {},
    workoutAttributeTypes: {},
    workoutCustomAttributeTypes: {},
    workoutAttributeTypesLoader: false,
    workoutExercises: {},
    workoutExercisesLoader: false,
    workoutUpdateLoader: false,
    createBlockLoader: false,
    updateBlockLoader: false,
    workoutBlocks: [],
    workoutBlockLoader: false,
    workoutBlockExerciseLoader: false,
    workoutBlockDuplicateLoader: false,
    selectedWorkout: {},
    workoutSmartBlocks: [],
    workoutSmartBlockLoader: false,
    workoutPlanLoader: false,
    workoutPlans: {},
    workoutPlanUpdateLoader: false,
    selectedWorkoutPlan: {},
    exerciseAttributes: [],
    workoutPlanWeekLoader: false,
    allWorkouts: [],
    allWorkoutLoader: false,
    workoutPlanComponentLoader: false,
    activeContacts: {},
    deletedContacts: {},
    optinContacts: {},
    completedContacts: {},
    workoutPlanWeeks: {},
    updateWorkoutPlanLoader: false,
    workoutPlanSmartWeekLoader: false,
    savePlanWeekLoader: false,
    planWeekDuplicateLoader: false,
    planSmartWeeks: [],
    planSmartWeekLoader: false,
    assignedWorkoutPlans: [],
    createPlanWeekLoader: false,
    workoutplanDay: {},
    defaultWorkoutForm: {
        public_settings: {
            display_explainer_video: 0,
            explainer_video_type: 0,
            explainer_video: '',
            explainer_embed: '',
            display_company_branding: 1,
            company_branding: '',
            display_cover: 1,
            cover_type: 2,
            headline: 'Time To Submit Your Workouts',
            subheadline: '',
            display_logo: 0,
            logo: '',
            display_author: 0,
            author: '',
            display_header: 1,
            display_footer: 0,
            header_bgcolor: '#FFF',
            header_textcolor: '#000',
            submit_btn_text: 'Submit',
            element_color: '#2f7eed',
            element_text_color: '#2C3E50',
            block_bg_color: '#eff5ff',
            timer_color: '#f2a31d',
            submit_btn_textcolor: '#fff',
            submit_btn_bgcolor: '#2c3e50',
            footer_bgcolor: '#D4D4D4',
            footer_textcolor: '#2C3E50',
            cover_color: '#1976d2',
            cover_textcolor: '#2C3E50',
            cover: '',
            small_cover: '',
            seo_title: 'Join our Workout Challenge',
            seo_keyword: 'Workouts',
            seo_desc: 'Whatever can be measured can be improved.',
            seo_index: 0,
            seo_share_title: 'Join our workout challenge',
            seo_share_desc: 'Whatever can be measured can be improved',
            seo_thumb: '',
            seo_fav: '',
            footer_has_facebook: 1,
            footer_has_twitter: 1,
            footer_has_instagram: 1,
            thankyou_bgcolor: '#edebeb',
            thankyou_textcolor: '#2c3e50',
            overlay_color: '#2c3e50',
            overlay_opacity: 50,
        },
    },
    defaultPlanForm: {
        public_settings: {
            display_explainer_video: 0,
            explainer_video_type: 0,
            explainer_video: '',
            explainer_embed: '',
            display_company_branding: 1,
            company_branding: '',
            display_cover: 1,
            cover_type: 2,
            headline: 'Time To Submit Your Workouts Plan',
            subheadline: '',
            display_logo: 0,
            logo: '',
            display_author: 0,
            author: '',
            display_header: 1,
            display_footer: 0,
            header_bgcolor: '#FFF',
            header_textcolor: '#000',
            submit_btn_text: 'Submit',
            element_color: '#2f7eed',
            element_text_color: '#2C3E50',
            block_bg_color: '#eff5ff',
            timer_color: '#f2a31d',
            submit_btn_textcolor: '#fff',
            submit_btn_bgcolor: '#2c3e50',
            footer_bgcolor: '#D4D4D4',
            footer_textcolor: '#2C3E50',
            cover_color: '#1976d2',
            cover_textcolor: '#2C3E50',
            cover: '',
            small_cover: '',
            seo_title: 'Join our Workout Challenge',
            seo_keyword: 'Workouts',
            seo_desc: 'Whatever can be measured can be improved.',
            seo_index: 0,
            seo_share_title: 'Join our workout challenge',
            seo_share_desc: 'Whatever can be measured can be improved',
            seo_thumb: '',
            seo_fav: '',
            footer_has_facebook: 1,
            footer_has_twitter: 1,
            footer_has_instagram: 1,
            thankyou_bgcolor: '#edebeb',
            thankyou_textcolor: '#2c3e50',
            overlay_color: '#2c3e50',
            overlay_opacity: 50,
        },
    },
    planAnalytics: {},
    planAnalyticsLoader: false,
    contactPlanActivity: {},
    contactPlanActivityLoader: false,
    workoutAnalytics: {},
    workoutAnalyticsLoader: false,
    contactWorkoutActivity: {},
    contactWorkoutActivityLoader: false,
    allWorkoutPlans: [],
    allWorkoutPlanLoader: false,
    workoutNotes: {},
    workoutNotesLoader: false,
    contactWorkouts: {},
    contactWorkoutPlans: {},
    contactWorkoutLoader: false,
    contactWorkoutPlanLoader: false,
    workoutPlanReportLoader: false,
    workoutReportLoader: false,
    historyChartLoader: false,
    workoutCategoryLoader: false,
    workoutCategories: { data: [] },
    categorySaveLoader: false,
};

export default state;
