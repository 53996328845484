import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getLink (store) {
        axios.get('/referral/link').then((resp) => {
            store.commit('SET_REFERRAL_LINK', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getHistories (store, params) {
        store.commit('SET_REFERRAL_HISTORY_LOADER_STATUS', true);

        axios.get('/referral/histories', { params }).then((resp) => {
            store.commit('SET_REFERRAL_HISTORIES', resp.data);
            store.commit('SET_REFERRAL_HISTORY_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_REFERRAL_HISTORY_LOADER_STATUS', false);
        });
    },

    getFaqs (store) {
        axios.get('/referral/faqs').then((resp) => {
            store.commit('SET_REFERRAL_FAQS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getLeaderboards (store, params) {
        store.commit('SET_REFERRAL_LEADERBOARD_LOADER_STATUS', true);

        axios.get('/referral/leaderboards', { params }).then((resp) => {
            store.commit('SET_REFERRAL_LEADERBOARDS', resp.data);
            store.commit('SET_REFERRAL_LEADERBOARD_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_REFERRAL_LEADERBOARD_LOADER_STATUS', false);
        });
    },

    updateReferralsStats (store, token) {
        axios.post(`/referral/${token}/clicked`, { token });
    },
};

export default actions;