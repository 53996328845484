<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="$emit('update:modelValue', false)"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li @click="$emit('update:modelValue', false)">
                    <router-link :to="{ name: 'SetupGuideIndex' }">Setup Guide</router-link>
                </li>
                <li :class="tab === 'help' ? 'active' : ''" @click="tab = 'help'">Help</li>
                <!-- <li :class="tab === 'apps' ? 'active' : ''" @click="tab = 'apps'">Apps</li> -->
                <!-- <li :class="tab === 'tools' ? 'active' : ''" @click="tab = 'tools'">Tools</li> -->
                <li :class="tab === 'importer' ? 'active' : ''" @click="tab = 'importer'">Importer</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <div class="content_area">
                    <div class="section_content w-100" v-if="tab === 'guide'">
                        <div class="section_header">
                            <h2>Get Set Up In 3 Steps</h2>
                        </div>
                        <div class="app_card">
                            <ul>
                                <li>
                                    <label>Step 1</label>
                                    <card title="Account Setup" img="forms2" :btn-trigger="showUpcomingMessage" link="https://pages.onboardme.io" />
                                    <h6>Estimated Time: 15 Minutes</h6>
                                </li>
                                <li>
                                    <label>Step 2</label>
                                    <card title="Thrive Score" img="forms" :btn-trigger="showUpcomingMessage" link="https://app.omcheckout.com/login" btn-title="Start" />
                                    <h6>Estimated Time: 8 Minutes</h6>
                                </li>
                                <li>
                                    <label>Step 3</label>
                                    <card title="Thrive Call" img="sorry" :btn-trigger="showUpcomingMessage" btn-title="Book"/>
                                    <h6>Estimated Time: 30 Minutes</h6>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'help'">
                        <div class="section_header mt-4">
                            <h2>24/7 On Demand Help</h2>
                        </div>
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Help Center" img="Admin-Settings" :is-link="true" link="https://help.thrivecoach.io" target="_blank" />
                                </li>
                                <li>
                                    <card title="Thrive Academy" img="om-scheduling" :btn-trigger="showUpcomingMessage" btn-title="Login" :is-link="true" link="https://academy.thrivecoach.io" target="_blank" />
                                </li>
                                <!-- <li>
                                    <card title="Live Chat" img="playbooks"/>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'apps'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Thrive Funnels" img="fit-funnels" :btn-trigger="handleThriveFunnelRedirect" btn-title="Launch" isPin />
                                </li>
                                <li>
                                    <card title="Thrive Checkouts" img="om-checkout" :btn-trigger="showUpcomingMessage" btn-title="2023" isPin />
                                </li>
                                <li>
                                    <card title="Thrive Scheduling" img="om-scheduling" :btn-trigger="showUpcomingMessage" btn-title="2023" isPin />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'tools'">
                        <div class="section_header mt-4">
                            <h2>Admin Tools</h2>
                        </div>
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Admin Settings" img="Admin-Settings" :is-link="true" link="/admin-settings"/>
                                </li>
                                <li>
                                    <card title="Message Center" img="message" :is-link="true" link="/sms-center" />
                                </li>
                                <li>
                                    <card title="Tags" img="tags" :is-link="true" link="/tags" isPin />
                                </li>
                                <li>
                                    <card title="Groups" img="Groups" :is-link="true" link="/groups" isPin />
                                </li>
                                <li>
                                    <card title="Files" img="files" :is-link="true" link="/files" isPin />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'tools'">
                        <div class="section_header mt-4">
                            <h2>Marketing Tools</h2>
                        </div>
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Broadcasts" img="broadcasts" :is-link="true" link="/broadcasts" isPin />
                                </li>
                                <li>
                                    <card title="Sequences" img="sequences" :btn-trigger="showUpcomingMessage" link="/pipelines" isPin />
                                </li>
                                <li>
                                    <card title="Pipelines" img="pipelines" :btn-trigger="showUpcomingMessage" link="/pipelines" isPin />
                                </li>
                                <li>
                                    <card title="Templates" img="templates" :is-link="true" link="/template" isPin />
                                </li>
                                <li>
                                    <card title="Journeys" img="journeys" :is-link="true" link="/journeys" isPin />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'tools'">
                        <div class="section_header mt-4">
                            <h2>Coaching Tools</h2>
                        </div>
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Client Portal" img="client-portal" :is-link="true" link="/client-portal" isPin />
                                </li>
                                <li>
                                    <card title="Playbooks" img="playbooks" :is-link="true" link="/playbooks" isPin />
                                </li>
                                <li>
                                    <card title="Pages" img="pages" :is-link="true" link="/pages" isPin />
                                </li>
                                <li>
                                    <card title="Forms" img="forms" :is-link="true"  link="/forms" isPin />
                                </li>
                                <li>
                                    <card title="Habits" img="habits" :is-link="true"  link="/habit-tracking" isPin />
                                </li>
                                <li>
                                    <card title="Progress" img="progress" :is-link="true"  link="/progress-tracking" isPin />
                                </li>
                                <li>
                                    <card title="Workouts" img="forms2" :btn-trigger="showUpcomingMessage" btn-title="2023" isPin />
                                </li>
                                <li>
                                    <card title="Tasks" img="tasks" :btn-trigger="showUpcomingMessage" link="/tasks" isPin />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'importer'">
                        <div class="app_card">
                            <ul>
                                <li>
                                    <card title="Content Importer" img="forms2" isOption :btn-trigger="openContentImportWizard" :option-trigger="openOptinModal"/>
                                </li>
                                <li>
                                    <card title="Content Club" content-type="content-club" img="content-club" :btn-trigger="openContentClub"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app_card">
            <content-club v-model="contentClubModal" />
            <content-import-history v-model="contentImportHistory" />
            <content-import-wizard v-model="contentImportWizard" :toggle-history="openOptinModal" />
        </div>
    </div>
</template>

<script>
    import Card from '@/pages/apps/components/Card'
    import ContentClub from '@/pages/apps/components/ContentClub'
    import ContentImportHistory from '@/pages/apps/components/ContentImportHistory'
    import ContentImportWizard from '@/pages/apps/components/ContentImportWizard'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Tools',

        data () {
            return {
                tab: 'help',
                omSchedulingModal: false,
                contentClubModal: false,
                contentImportWizard: false,
                contentImportHistory: false,
            };
        },

        components: {
            Card,
            ContentClub,
            ContentImportHistory,
            ContentImportWizard,
        },

        props:{
            modelValue: Boolean,
        },

        watch:{
            modelValue (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            subscription: state => state.billingModule.subscription,
        }),

        mounted () {
            const vm = this;

            if (!vm.subscription.id) {
                vm.getSubscription();
            }
        },

        methods: {
            ...mapActions({
                getSubscription: 'billingModule/getSubscription',
                generateLandingPageToken: 'authModule/generateLandingPageToken',
            }),

            openOptinModal (type) {
                const vm = this;

                vm[type] = true;
            },

            openContentImportWizard () {
                const vm = this;

                vm.contentImportWizard = true;
            },

            openContentClub() {
                const vm = this;

                vm.contentClubModal = true;
            },

            showUpcomingMessage () {
                Swal.fire(Helper.swalWarningOptions('Coming in early 2023!'))
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            handleThriveFunnelRedirect () {
                const vm = this;

                if (vm.subscription.has_funnels) {
                    if (vm.user.landing_account_id) {
                        vm.generateLandingPageToken().then((params) => {
                            if (params) {
                                window.open(`https://app.landingi.com/api/auth/sign-in/thrivecoach?token=${params.token}&timestamp=${params.timestamp}`, '_blank');
                            } else {
                                window.open('https://pages.onboardme.io', '_blank');
                            }
                        });
                    } else {
                        window.open('https://pages.onboardme.io', '_blank');
                    }
                } else {
                    const options = Helper.swalConfirmOptions('', `Please upgrade your subscription to get Unlimited Thrive Funnels (in <a href="/admin-settings?module=billing&tab=plans">billing</a> under subscriptions)`, 'Upgrade');
                    options.preConfirm = function () {
                                              window.location = '/admin-settings?module=billing&tab=plans';

                                              return true;
                                          }

                    Swal.fire(options);
                }
            },
        },
    }
</script>

<style scoped>
    @media(max-width: 991px){
        .preview_area{
            z-index: 13;
        }
    }
</style>
