const error = [
    {
        name: 'Error-404',
        component: () => import(/* webpackChunkName: "js/error/404" */ '@/pages/errors/404'),
        path: '/404',
        meta: {
            title: '404',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'Error-403',
        component: () => import(/* webpackChunkName: "js/error/403" */ '@/pages/errors/403'),
        path: '/403',
        meta: {
            title: '403',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'AccessDenied',
        component: () => import(/* webpackChunkName: "js/error/access-denied" */ '@/pages/errors/AccessDenied'),
        path: '/access-denied',
        meta: {
            title: 'Access Denied',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default error;