const broadcast = [
    {
        name: 'BroadcastIndex',
        component: () => import(/* webpackChunkName: "js/broadcast" */ '@/pages/broadcast/Index'),
        path: '/broadcasts',
        meta: {
            title: 'Broadcasts',
            requiresAuth: true,
            accountActive: true,
            group: 'Marketing',
        },
    },
];

export default broadcast;
