const Mustache = require('mustache');
import store from '@/store'
import moment from 'moment'

const getters = {
    REPLACE_VARIABLES: (state, rootGetters) => (content, buttonBackground = '#2F7FED', buttonColor = '#FFF', buttonText = 'View', hasButton = true) => {
        const user  = store.state.authModule.user;
        const reservedWords = JSON.parse(JSON.stringify(state.reservedWords));
        let clientPortal  = store.state.clientPortalModule.clientPortal;

        if (!clientPortal.id) {
            // store.dispatch('clientPortalModule/getClientPortal', {}, { root: true }).then((portal) => {
            //     clientPortal = portal;
            // });
        }

        if (store.state.checkoutModule.companySettings && store.state.checkoutModule.companySettings.id) {
            reservedWords.support_email = store.state.checkoutModule.companySettings.support_address;
        }

        // const autoLoginLink = `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.auto_login_link}">${clientPortal.btn_text ? clientPortal.btn_text : 'Sign In'}</a><br /><br />`;
        // const formUrlButton = `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.form_url}">View Form</a>`;
        // const emailButton   = hasButton ? `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.auto_login_link}">${buttonText}</a><br /><br />` : reservedWords.auto_login_link;

        // Dummy links
        reservedWords.dummy_link = 'https://superfitcoaching.com';

        const autoLoginLink = `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.dummy_link}">${clientPortal.btn_text ? clientPortal.btn_text : 'Sign In'}</a><br /><br />`;
        const formUrlButton = `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.dummy_link}">View Form</a>`;
        const emailButton   = hasButton ? `<br /><a style="border-radius:3px; text-decoration:none; padding:12px 35px; background: ${buttonBackground}; color: ${buttonColor};" href="${reservedWords.dummy_link}">${buttonText}</a><br /><br />` : 'https://superfitcoaching.com';

        let emailContent    = content ? content.replaceAll('{{{', '{{') : '';
            emailContent    = emailContent.replaceAll('}}}', '}}');
            emailContent    = emailContent.replaceAll(' }}', '}}');
            emailContent    = emailContent.replaceAll('{{ ', '{{');
            emailContent    = emailContent.replace('{{ auto_login_link }}', autoLoginLink);
            emailContent    = emailContent.replace('{{auto_login_link}}', autoLoginLink);
            emailContent    = emailContent.replace('{{ form_url_button }}', formUrlButton);
            emailContent    = emailContent.replace('{{form_url_button}}', formUrlButton);
            // emailContent    = emailContent.replace('{{ form_url_sms }}', reservedWords.form_url);
            // emailContent    = emailContent.replace('{{form_url_sms}}', reservedWords.form_url);
            // emailContent    = emailContent.replace('{{ auto_login_sms_link }}', reservedWords.auto_login_link);
            // emailContent    = emailContent.replace('{{auto_login_sms_link}}', reservedWords.auto_login_link);
            emailContent    = emailContent.replace('{{ habit_tracking_goal_button }}', emailButton);
            emailContent    = emailContent.replace('{{habit_tracking_goal_button}}', emailButton);
            emailContent    = emailContent.replace('{{ habit_tracking_button }}', emailButton);
            emailContent    = emailContent.replace('{{habit_tracking_button}}', emailButton);
            emailContent    = emailContent.replace('{{ page_url_button }}', emailButton);
            emailContent    = emailContent.replace('{{page_url_button}}', emailButton);
            emailContent    = emailContent.replace('{{ progress_tracking_goal_button }}', emailButton);
            emailContent    = emailContent.replace('{{progress_tracking_goal_button}}', emailButton);
            emailContent    = emailContent.replace('{{ progress_tracking_button }}', emailButton);
            emailContent    = emailContent.replace('{{progress_tracking_button}}', emailButton);
            emailContent    = emailContent.replace('{{ progress_tracking_response_link }}', emailButton);
            emailContent    = emailContent.replace('{{progress_tracking_response_link}}', emailButton);
            emailContent    = emailContent.replace('{{ current_year }}', new Date().getFullYear());
            emailContent    = emailContent.replace('{{current_year}}', new Date().getFullYear());
            emailContent    = emailContent.replace('{{ sender_domain }}', user.mailgun_email);
            emailContent    = emailContent.replace('{{XX}}', store.state.checkoutModule.companyCurrencySymbol+Math.floor(Math.random() * 999));
            emailContent    = emailContent.replace('{{order_id}}', Math.floor(Math.random() * 99));
            emailContent    = emailContent.replace('{{subscription_id}}', 'sub_1MnfHfE0CvqmRmW8itF'+Math.random().toString(36).substring(2,7));
            emailContent    = emailContent.replace('{{product_name}}', 'Your-product-'+Math.floor(Math.random() * 9));
            emailContent    = emailContent.replace('{{date_of_Purchase}}', moment().format('YYYY-MM-DD'));
            emailContent    = emailContent.replace('{{workout_plan_button}}', emailButton);
            emailContent    = emailContent.replace('{{workout_button}}', emailButton);
            emailContent    = emailContent.replace('{{workout_response_button}}', emailButton);
            emailContent    = emailContent.replace('{{workout_plan_response_button}}', emailButton);
            emailContent    = emailContent.replace('{{plan_analytics_button}}', emailButton);

            // Dummy links
            emailContent    = emailContent.replace('{{ form_url_sms }}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{form_url_sms}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{ auto_login_sms_link }}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{auto_login_sms_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{habit_tracking_goal_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{habit_tracking_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{habit_tracking_response_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{progress_tracking_goal_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{progress_tracking_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{page_url_sms}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{form_url}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{workout_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{workout_response_link}}', reservedWords.dummy_link);
            emailContent    = emailContent.replace('{{plan_analytics_link}}', reservedWords.dummy_link);


        try {
            return rootGetters.DECODE_HTML_ENTITIES(Mustache.render(emailContent, reservedWords));
        } catch (error) {
            console.log(error);

            return rootGetters.DECODE_HTML_ENTITIES(emailContent);
        }
    },

    GENERATE_SIGNATURE: (state) => (displaySignature, signatureId) => {
        const signature = store.state.signatureModule.signatures.filter(signature => signature.id == signatureId)[0];
        let html = '';

        if (displaySignature && signature) {
            html = '<div class="email-signature" style="margin: '+signature.top_margin+'px '+signature.right_margin+'px '+signature.bottom_margin+'px '+signature.left_margin+'px; border: 1px solid #eaeaea; border-radius: 12px; padding: 10px;">';
            html += '    <div style="padding: '+signature.top_padding+'px '+signature.right_padding+'px '+signature.bottom_padding+'px '+signature.left_padding+'px; border-radius: '+signature.border_radius+'px; background-color: '+signature.bar_color+'; display: flex; align-items: center; justify-content: space-between;">';
            html += '        <div style="width: 100%;">';
            html += '            <h3 style="color: '+signature.bar_text_color+'; font-size: 19px; line-height: 24px;  font-weight: 500; align-items: flex-end; margin-bottom: 0px;">'+(signature.full_name ? signature.full_name : '');
            html += '                <span style="color: '+signature.bar_text_color+';">'+(signature.credentials ? ' - '+signature.credentials : '')+'</span>';
            html += '            </h3>';
            html += '            <h6 style="color: '+signature.bar_text_color+' !important; font-size: 13px; line-height: 18px; font-weight: 500; margin-top: 3px; display: block; margin-bottom: 0px; text-transform: capitalize;">'+(signature.company_name ? signature.company_name : '-')+'</h6>';
            html += '        </div>';

            html += '    </div>';
            html += '    <div style="padding: 25px 20px;">';

            if (signature.slogan) {
                html += '        <p style="color: #121525; width: 100%; font-weight: 400; font-size: 14px; margin-bottom: 15px;">'+signature.slogan+'</p>';
            }

            html += '        <div style="display: flex; align-items: center;">';
            html += '            <img src="'+(signature.author_image ? signature.author_image : '')+'" alt="" style="width: 90px; height: 90px; border-radius: 50%; -o-object-fit: cover; object-fit: cover; margin-top: 5px;">';
            html += '            <ul style="margin-left: 15px; list-style: none;">';

            if (signature.phone && signature.has_phone) {
                html += '                <li style="color: '+signature.link_color+'; font-size: 14px; line-height: 18px; font-weight: 400; padding: 4px 0 4px 0; position: relative; margin-left: 0px;">';

                if (signature.is_abbreviate) {
                    // html += '                   <i class="fas fa-phone-alt" style="color: '+signature.icon_color+'; margin-right: 5px;"></i>';
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">P:</label>';
                } else {
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">Phone:</label>';
                }

                html += '                        <a style="text-decoration: none; color: '+signature.link_color+';" :href="tel:'+signature.phone+'">'+(signature.phone ? signature.phone : '')+'</a>';
                html += '                </li>';
            }

            if (signature.email && signature.has_email) {
                html += '                <li style="color: '+signature.link_color+'; font-size: 14px; line-height: 18px; font-weight: 400; padding: 4px 0 4px 0; position: relative; margin-left: 0px;">';

                if (signature.is_abbreviate) {
                    // html += '                   <i class="fas fa-envelope" style="color: '+signature.icon_color+'; margin-right: 5px;"></i>';
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">E:</label>';
                } else {
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">Email:</label>';
                }

                html += '                        <a style="text-decoration: none; color: '+signature.link_color+';" :href="mailto:'+signature.email+'">'+(signature.email ? signature.email : '')+'</a>';
                html += '                </li>';
            }

            if (signature.website && signature.has_website) {
                html += '                <li style="color: '+signature.link_color+'; font-size: 14px; line-height: 18px; font-weight: 400; padding: 4px 0 4px 0; position: relative; margin-left: 0px;">';

                if (signature.is_abbreviate) {
                    // html += '                   <i class="fas fa-link" style="color: '+signature.icon_color+'; margin-right: 5px;"></i>';
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">W:</label>';
                } else {
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">Website:</label>';
                }

                html += '                        <a style="text-decoration: none; color: '+signature.link_color+';" :href="'+signature.website+'">'+(signature.website ? signature.website : '')+'</a>';
                html += '                </li>';
            }

            if (signature.address && signature.has_address) {
                html += '                <li style="color: '+signature.link_color+'; font-size: 14px; line-height: 18px; font-weight: 400; padding: 4px 0 4px 0; position: relative; margin-left: 0px;">';

                if (signature.is_abbreviate) {
                    // html += '                   <i class="fas fa-location-arrow" style="color: '+signature.icon_color+'; margin-right: 5px;"></i>';
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">A:</label>';
                } else {
                    html += '                    <label style="color: '+signature.icon_color+'; margin-right: 5px;">Address:</label>';
                }

                html += '                        <a style="text-decoration: none; color: '+signature.link_color+';">'+(signature.address ? signature.address : '')+'</a>';
                html += '                </li>';
            }

            html += '            </ul>';
            html += '        </div>';
            html += '    </div>';
            html += '    <div style="align-items: center; padding: 10px 0;">';
            html += '        <div style="height: '+signature.linebar_height+'px; width: '+signature.linebar_width+'%; background-color: '+signature.linebar_color+'; border-radius: 3px; margin-bottom: 10px;"></div>';

            // if (signature.social_icon_position == 'under') {
                html += '        <div class="signatureFooter" style="padding:15px 0 0 0; text-align:center; justify-content: center;">';
                html += '            <ul style="list-style: none;">';

                if (signature.is_facebook) {
                    html += '                <li style="display:inline-block; margin-right: 10px;">';
                    html += '                    <a :href="'+signature.facebook_url+'" target="_blank" style="color: '+signature.social_icon_color+'; width: 25px; height: 25px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 14px; text-decoration: none;">';

                    if (signature.is_facebook_icon) {
                        html += '                        <img src="'+(require('@/assets/images/facebook.svg'))+'" />';
                    }

                    html += '                    </a>';
                    html += '                </li>';
                }

                if (signature.is_ig) {
                    html += '                <li style="display:inline-block; margin-right: 10px;">';
                    html += '                   <a :href="'+signature.ig_url+'" target="_blank" style="color: '+signature.social_icon_color+'; width: 25px; height: 25px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 14px; text-decoration: none;">';

                    if (signature.is_ig_icon) {
                        html += '                        <img src="'+(require('@/assets/images/instagram.svg'))+'" />';
                    }

                    html += '                    </a>';
                    html += '                </li>';
                }

                if (signature.is_twitter) {
                    html += '                <li style="display:inline-block; margin-right: 10px;">';
                    html += '                    <a :href="'+signature.twitter_url+'" target="_blank" style="color: '+signature.social_icon_color+'; width: 25px; height: 25px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 14px; text-decoration: none;">';

                    if (signature.is_twitter_icon) {
                        html += '                        <img src="'+(require('@/assets/images/twitter.svg'))+'" />';
                    }

                    html += '                    </a>';
                    html += '                </li>';
                }

                html += '        </ul>';
            // }

            html += '        </div>';
            html += '    </div>';
            html += '</div>';
        }

        return html;
    },

    DECODE_HTML_ENTITIES: (state) => (string) => {
        const entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];

        let content = string;

        for (let i = 0, max = entities.length; i < max; ++i) {
            content = content.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
        }

        return content;
    }
};

export default getters;
