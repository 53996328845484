<template>
    <div class="card">
        <button type="button" class="pin_btn" v-if="isPin"><i class="fas fa-thumbtack"></i></button>
        <img :src="require(`@/assets/images/${img}.svg`)">
        <div class="card_title">
            {{title}}
        </div>
        <a v-if="isLink" class="launch_btn pointer" :href="isLink ? link : ''" :target="isLink ? target : ''" @click="handleClick">{{btnTitle ? btnTitle : 'Launch'}}</a>
        <a v-else class="launch_btn pointer" @click="handleClick">{{btnTitle ? btnTitle : 'Launch'}}</a>
        <div v-if="isOption" class="dropdown" @click="otherOption = !otherOption">
            <i class="fas fa-ellipsis-v"></i>
            <div class="drp_wrapper" v-if="otherOption" :class="{ active : otherOption }">
                <ul class="m-0">
                    <!-- <li @click="optionTrigger('contentClubModal')">Join Content Club</li> -->
                    <li @click="optionTrigger('contentImportHistory')">Import History</li>
                </ul>
            </div>
        </div>
        <div class="modal secondary" v-if="contentMachineModal" :class="{ active : contentMachineModal }" @click.self="contentMachineModal = false">
            <div class="modal_container">
                <img src="@/assets/images/fit-funnels.svg" alt="" class="alert_img">
                <h2>Oops!</h2>
                <h5>It looks like you don't have access to the Content Machine™. Please contact support to learn how to get access.</h5>
                <div class="button-group">
                    <button type="button" class="ok_btn mr-2" @click="contentMachineModal = false">Cancel</button>
                    <button type="button" class="ok_btn" @click="contactSupport()">Contact Support</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Apps Card',

        data () {
            return {
                otherOption: false,
                contentMachineModal: false,
            };
        },

        props: {
            title: String,
            img: String,
            icon: String,
            link: String,
            isOption: Boolean,
            btnTrigger: Function,
            optionTrigger: Function,
            contentType: String,
            btnTitle: String,
            isLink: {
                type: Boolean,
                default: false,
            },
            target: {
                type: String,
                default: '_self',
            },
            isPin: Boolean
        },

        computed: mapState({
            integrations: state => state.authModule.integrations,
        }),

        methods: {
            handleClick () {
                const vm = this;

                if (vm.isLink) {
                    window.open(vm.link, vm.target);
                } else {
                    if (vm.contentType == 'fit-funnel' && vm.integrations.fit_funnels == 0) {
                        vm.contentMachineModal = true;
                    } else {
                        if (vm.contentType == 'fit-funnel' || vm.contentType == 'om-checkout') {
                            window.open(vm.link, '_blank');
                        } else {
                            if (vm.btnTrigger) {
                                vm.btnTrigger();
                            }
                        }
                    }
                }
            },

            contactSupport () {
                const vm = this;
                vm.contentMachineModal = false;

                window.open('mailto:support@onboardme.io?subject=Upgrade Plan', '_blank');
            },
        },
    }
</script>
