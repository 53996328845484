const state = {
    checkoutAuthToken: localStorage.hasOwnProperty('checkoutAuthToken') && isJson(localStorage.getItem('checkoutAuthToken')) ? JSON.parse(localStorage.getItem('checkoutAuthToken')) : null,
    productComponentLoader: false,
    productLoader: false,
    productUpdateLoader: false,
    products: {},
    clientLoader: false,
    clients: {},
    locations: [],
    tagsGroupByLocation: [],
    companySettings: {},
    companySettingLoader: false,
    orderBumps: [],
    coupons: [],
    defaultTemplates: [],
    processors: [],
    updateCompanySettingLoader: false,
    emailSettingLoader: false,
    abandonedEmailSettings: {},
    transactionEmailSettings: {},
    dunningEmailSettings: {},
    updateEmailSettingLoader: false,
    couponLoader: false,
    processorLoader: false,
    bumpUpdateLoader: false,
    updateClientProfileLoader: false,
    clientWatcher: 0,
    singlePurchaseProductsLoader: false,
    singlePurchaseProducts: {},
    orderTransactionsLoader: false,
    orderTransactions: {},
    couponListLoader: false,
    clientAnalytics: {},
    clientAnalyticsLoader: false,
    clientOrderSubscriptions: {},
    clientOrderSubscriptionsLoader: false,
    trackingIds: [],
    allSubscriptionsLoader: false,
    allSubscriptions: {},
    subscriptionProducts: [],
    checkoutLogs: {},
    checkoutLogsLoader: false,
    OrdersAnalytics: {},
    OrdersAnalyticsLoader: false,
    isSidebarMounted: 0,
    sendInvoiceLoader: false,
    refundPaymentLoader: false,
    failedSubscriptionLogs: {},
    failedSubscriptionLogsLoader: false,
    trialSubscriptionLogs: {},
    trialSubscriptionLogsLoader: false,
    paidSubscriptionLogs: {},
    paidSubscriptionLogsLoader: {},
    params: {
        per_page: 6,
        page: 1,
        search: '',
        filter: 'date_created',
        archived: 0,
        is_archived: 0,
        folder_id: null,
    },
    defaultProductSettings: {
        proof_headline: 'Happy Clients',
        bump_headline: 'Want us to hold you accountable?',
        bump_description: "Get this amazing product upgrade for just ${{bump_amount}}. With this you will be able to leap tall buildings ins a single bound! I mean doesn't everyone need this? Doesn't everyone want to have super powers?",
        countdown_timer: {
            headline: 'This offer expires soon!',
            text_color: '#FFFFFF',
            bg_color: '#FF1363',
            timer_box_color: '#2c3e50',
            timer_box_text_color: '#FFFFFF',
        },
        support_email: {
            color: '#FFFFFF',
            bg_color: '#012198',
        },
        headline: {
            text: 'Join Our 28-Day Body Transformation Program',
            color: '#FFFFFF',
        },
        sub_headline: {
            text: 'Spaces are limited so sign up today!',
            color: '#FFFFFF',
        },
        payment_details: {
            bg_color: '#EEF7FF',
            headline: 'Security is our top priority!',
            text: 'This website utilizes some of the most advanced techniques to protect your information and personnel data including technical, administrative, and even physical safeguards against unauthorized access, misuse, and improper disclosure',
            icon_color: '#FF98CF'
        },
        payment_button: {
            text: 'Place Order',
            text_color: '#FFFFFF',
            bg_color: '#E20179'
        },
        cover: {
            bg_color: '#012198',
        },
        order_bump: {
            acceptance: "Yes, I want this!",
            brand_color: '#E20179',
        },
        sidebar: {
            bg_color: '#FFFFFF',
            title_text_color: '#121525',
            accent_color: '#ff98cf',
        },
        sidebar_product: {
            headline: "What's Included",
            shadow_color: '#888888',
            feature_icon_color: '#FF98CF',
            proof_bg_color: '#FFFFFF',
            proof_text_color: '#5a5a5a',
            testimonial: "I could not believe it. When i hopped on the scale I was completely blown away with the weight I had dropped in such a short time.",
            person_name: 'Emily Ewing',
            title_location: 'Deigner at Evolve',
            star_color: '#ff98cf',
        },
        sidebar_security: {
            headline: 'Security You Can Trust',
            seal_color: '#ff98cf',
            seal_text_color: '#2c3e50',
        },
        sidebar_guarantee: {
            headline: 'You Are Protected',
            top_text: 'Our 100% Ironclad Guarantee',
            body_text: "If for any reason you are not happy with your purchase and you decide it's not what you wanted, just ask us for a full refund and we will gladly issue it to you with a smile. If you are not happy we don't want your money. This is a 'zero risk' transaction.",
            text_color: '#5a5a5a',
            bg_color: '#FFFFFF',
            icon_color: '#ff98cf',
            icon_bg_color: '#FFFFFF',
            shadow_color: '#888888'
        },
        terms: {
            text_color: '#2c3e50',
        }
    },
    assignedTagList: [],
    recentActivities: [],
    recentActivitiesLoader: false,
    chartData: {
        axis: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [0, 0, 0, 0, 0, 0, 0],
    },
    chartStats: {},
    chartLoader: false,
    grossSale: '0.00',
    companyCurrencySymbol: '$',
    clientAbandonedOrdersLoader: false,
    clientAbandonedOrders: {},
    allProductLoader: false,
    allProducts: [],
    allOrderTransactions: {},
    subscriptionStats: [],
    addCardLoader: false,
    setupIntentLoader: false,
    cardUpdateEmailLoader: false,
    productTemplateLoader: false,
    downloadInvoiceLoader: 0,
    allSubscriptionLogs: [],
    allSubscriptionLogsLoader: false,
    liveProducts: [],
    liveProductLoader: false,
};

function isJson (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export default state;
