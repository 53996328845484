const mutations = {
    SET_GROUPS (state, groups) {
        state.groups = groups;
    },

    SET_ALL_GROUP_LOADER_STATUS (state, status) {
        state.allGroupLoader = status;
    },

    SET_GROUP_LOADER_STATUS (state, status) {
        state.groupLoader = status;
    },

    SET_CONTACTS (state, contacts) {
        state.contacts = contacts;
    },

    SET_USER_GROUPS (state, groups) {
        state.userGroups = groups;
    },

    SET_GROUP_COMPONENT_LOADER_STATUS (state, status) {
        state.groupComponentLoader = status;
    },
};

export default mutations;
