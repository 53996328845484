import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    scheduleSMS (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SCHEDULE_LOADER_STATUS', true);

            axios.post('/sms-center/schedule', params).then(() => {
                store.commit('SET_SCHEDULE_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', false, { root: true });
                Toastr.success('SMS has been successfully scheduled!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SCHEDULE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getLatestSMS (store) {
        axios.get('/sms-center/inbound-sms').then((resp) => {
            store.commit('SET_LATEST_SMS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
        });
    },

    getContacts (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/sms-center/contacts', { params }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    getStats (store) {
        axios.get('/sms-center/stats').then((resp) => {
            store.commit('SET_STATS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getChatLogs (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/sms-center/chat-logs', {params}).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    updateMarkAsReadUnread (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/sms-center/mark-read-unread', params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    getScheduledSMS (store, params) {
        store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', true);

        axios.get('/sms-center/scheduled-list', { params }).then((resp) => {
            store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', false);
            store.commit('SET_SCHEDULED_SMS', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', false);
        });
    },

    deleteScheduledSms (store, ids) {
        return new Promise(function(resolve, reject) {
            const schedule_ids = ids;

            store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', true);

            axios.delete('/sms-center/delete-scheduled-sms', {data : { schedule_ids }} ).then((resp) => {
                store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', false);
                resolve(true);
                Toastr.success('Selected scheduled has been successfully deleted!');
            }).catch((err) => {
                store.commit('SET_SCHEDULED_SMS_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    },

    archiveUnarchiveChat (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/sms-center/archive-unarchive', params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    resendSMS (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/sms-center/resend-sms', params).then((resp) => {
                if (resp.data.status == 1) {
                    Toastr.success('The SMS has been resent successfully!');
                    resolve(resp.data);
                } else {
                    Toastr.error(resp.data.response);
                    resolve(false);
                }
            }).catch((err) => {
                resolve(false);
            });
        });
    },
};

export default actions;