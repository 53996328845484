import initialState from './state';

const mutations = {
    SET_REWARD (state, data) {
        state.reward = data;
    },

    SET_REWARD_LOADER_STATUS (state, value) {
        state.rewardLoader = value;
    },

    SET_NOTIFICATION_REWARD_LOADER_STATUS (state, value) {
        state.notificationLoader = value;
    },

};

export default mutations;
