const mutations = {
    SET_REFERRAL_LINK (state, link) {
        state.link = link;
    },

    SET_REFERRAL_HISTORIES (state, histories) {
        state.histories = histories;
    },

    SET_REFERRAL_FAQS (state, faqs) {
        state.faqs = faqs;
    },

    SET_REFERRAL_HISTORY_LOADER_STATUS (state, status) {
        state.historyLoader = status;
    },

    SET_REFERRAL_LEADERBOARDS (state, leaderboards) {
        state.leaderboards = leaderboards;
    },

    SET_REFERRAL_LEADERBOARD_LOADER_STATUS (state, status) {
        state.leaderboardLoader = status;
    },
};

export default mutations;