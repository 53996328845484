import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getScheduleSMS (store, params) {
        store.commit('SET_SCHEDULE_LOADER_STATUS', true);

        return axios.get('/scheduled/get-schedule-sms', { params } ).then((resp) => {
            store.commit('SET_SCHEDULE_LOADER_STATUS', false);
            store.commit('SET_SCHEDULED_SMS', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SCHEDULE_LOADER_STATUS', false);
        });
    },

    deleteScheduledSMS (store, params) {
        const schedule_ids = params.schedule_ids;
        delete params.schedule_ids;

        axios.post('/scheduled/delete-sms', { schedule_ids }).then((resp) => {
            store.dispatch('getScheduleSMS', params);
            Toastr.success('Scheduled SMS successfully deleted');
        }).catch((err) => {
            console.log(err);
        });
    },

    getSchedule (store, params) {
        store.commit('SET_SCHEDULE_LOADER_STATUS', true);

        axios.get('/scheduled/get-schedule', { params }).then((resp) => {
            store.commit('SET_SCHEDULE_LOADER_STATUS', false);
            store.commit('SET_SCHEDULES', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SCHEDULE_LOADER_STATUS', false);
        });
    },

    deleteSchedule (store, params) {
        const schedule_ids = params.schedule_ids;
        delete params.schedule_ids;

        axios.post('/scheduled/delete-schedule', { schedule_ids }).then((resp) => {
            store.dispatch('getSchedule', params);
            Toastr.success('Scheduled successfully deleted');
        }).catch((err) => {
            console.log(err);
        });
    },
};

export default actions;
