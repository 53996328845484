const setupGuide = [
    {
        name: 'SetupGuideIndex',
        component: () => import(/* webpackChunkName: "js/setup-guide" */ '@/pages/setup-guide/Index'),
        path: '/setup-guide',
        meta: {
            title: 'Setup Guide',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'HomeIndex',
        component: () => import(/* webpackChunkName: "js/setup-guide" */ '@/pages/home/Index'),
        path: '/home',
        meta: {
            title: 'Home',
            requiresAuth: true,
            accountActive: true,
        },
    },

    {
        name: 'AccountSetup',
        component: () => import(/* webpackChunkName: "js/apps" */ '@/pages/account-setup/Index'),
        path: '/account-setup',
        meta: {
            title: 'Account Setup',
            requiresAuth: false,
            accountActive: true,
        },
    },
];

export default setupGuide;