import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllHabitTrackings (store, params) {
        store.commit('SET_HABIT_TRACKING_LOADER_STATUS', true);

        axios.get('/habit-tracking/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_HABIT_TRACKINGS', resp.data);
            store.commit('SET_HABIT_TRACKING_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_HABIT_TRACKING_LOADER_STATUS', false);
        });
    },

    startHabit (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/habit-tracking/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Habit tracking has been started successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    stopHabit (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/habit-tracking/remove-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Habit tracking has been stopped successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getContactHabitTracking (store, params) {
        store.commit('SET_CONTACT_HABIT_TRACKING_LOADER_STATUS', true);

        axios.get(`/habit-tracking/${params.contact_id}/contact-habit-tracking`, { params }).then((resp) => {
            store.commit('SET_CONTACT_HABIT_TRACKING_LOADER_STATUS', false);
            store.commit('SET_CONTACT_HABIT_TRACKINGS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_HABIT_TRACKING_LOADER_STATUS', false);
            // Toastr.handleServerError(err);
        });
    },

    getContactCurrentProgress (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', true);

            axios.get(`/habit-tracking/${params.habit_tracking_id}/current-progress`, { params }).then((resp) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', false);
                store.commit('SET_CONTACT_CURRENT_PROGRESS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', false);
                resolve(false)
            });
        });
    },

    getHabitTrackings (store, params) {
        store.commit('SET_HABIT_TRACKING_LOADER_STATUS', true);

        axios.get('habit-tracking/list', { params }).then((resp) => {
            store.commit('SET_HABIT_TRACKINGS', resp.data);
            store.commit('SET_HABIT_TRACKING_LOADER_STATUS', false);
        }).catch((err) => {
            Toastr.handleServerError(err)
            store.commit('SET_HABIT_TRACKING_LOADER_STATUS', false);
        });
    },

    renameHabitTracking (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.patch('habit-tracking/rename', params).then((resp) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Habit Scenario name has been successfully updated.');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    duplicateHabitTracking (store, params) {
        return new Promise((resolve, reject) => {
            axios.post('habit-tracking/duplicate', params ).then((resp) => {
                Toastr.success('Habit scenario has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteHabitTracking (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('habit-tracking/delete', { params } ).then((resp) => {
                store.dispatch('getAllHabitTrackings');
                Toastr.success('Habit scenario has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    assignContacts (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.post('/habit-tracking/add-contact', params).then((resp) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Habit scenario has been assigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    unassignContacts (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.post('/habit-tracking/remove-contact', params).then((resp) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Habit scenario has been unassigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAssignContacts (store, params) {
        store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', true);

        axios.get(`/habit-tracking/${params.habit_tracking_id}/contacts`, { params }).then((resp) => {
            store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);

            if (params.status == 'active') {
                store.commit('SET_ACTIVE_CONTACTS', resp.data);
            } else if (params.status == 'deleted') {
                store.commit('SET_DELETED_CONTACTS', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    enableDisableReminder (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch('/habit-tracking/enable-disable-reminder', params).then((resp) => {
                const status = params.has_reminder == 1 ? 'enabled' : 'disabled';
                Toastr.success(`Reminder has been ${status} successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    resendReminder (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/habit-tracking/${params.id}/resend-reminder`, params).then((resp) => {
                Toastr.success(`${ params.type == 1 ? 'Email' : 'SMS' } reminder has been sent successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveHabitTracking (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_UPDATE_LOADER_STATUS', true);

            if (params.id) {
                axios.patch(`/habit-tracking/${params.id}/update`, params).then((resp) => {
                    Toastr.success('Habit tracking has been updated successfully!');
                    store.commit('SET_HABIT_TRACKING_UPDATE_LOADER_STATUS', false);
                    store.commit('SET_SELECTED_HABIT_TRACKING', resp.data);
                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err, params.setFieldError);
                    store.commit('SET_HABIT_TRACKING_UPDATE_LOADER_STATUS', false);
                    resolve(false);
                });
            } else {
                axios.post(`/habit-tracking/store`, params).then((resp) => {
                    store.dispatch('getAllHabitTrackings');
                    Toastr.success('Habit tracking has been saved successfully!');
                    store.commit('SET_HABIT_TRACKING_UPDATE_LOADER_STATUS', false);
                    store.commit('SET_SELECTED_HABIT_TRACKING', resp.data);
                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err, params.setFieldError);
                    store.commit('SET_HABIT_TRACKING_UPDATE_LOADER_STATUS', false);
                    resolve(false);
                });
            }
        });
    },

    saveHabitCategory(store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/habit-tracking/${params.habit_tracking_id}/save-category`, params).then((resp) => {
                Toastr.success('Habit category has been saved successfully!');
                resolve(resp.data);
            }).catch((err) => {
                if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
                    if (params.setFieldError) {
                        const errors = err.response.data.errors;

                        Object.keys(errors).map(field => {
                            let errorString = errors[field].join(', ');

                            if (field == 'name') {
                                params.setFieldError(`category_${params.error_index}`, errorString);
                            } else {
                                params.setFieldError(field, errorString);
                            }
                        });
                    }
                }

                resolve(false);
            });
        });
    },

    deleteCategory (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/habit-tracking/category/${id}/delete`).then((resp) => {
                Toastr.success('Habit category has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortCategory (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/habit-tracking/sort-category`, params).then((resp) => {
                Toastr.success('Habit category has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortCategoryHabits (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/habit-tracking/sort-category-habits`, params).then((resp) => {
                Toastr.success('Category habits has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    createHabit (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/habit-tracking/${params.habit_tracking_id}/create-habit`, params).then((resp) => {
                Toastr.success('Category habits has been created successfully!');
                resolve(resp.data.habit);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getHabitCategories (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`/habit-tracking/${params.habit_tracking_id}/habit-categories`, params).then((resp) => {
                store.commit('SET_HABIT_TRACKING_CATEGORIES', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveHabitTrackingHabits (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/habit-tracking/${params.id}/update-habits`, params).then((resp) => {
                Toastr.success('Category habits has been updated successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteHabit (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/habit-tracking/${params.id}/delete-habit`, { params }).then((resp) => {
                Toastr.success('Category habits has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getCurrentHabitProof (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`/habit-tracking/${params.habit_tracking_id}/contact-habit-proofs`, { params }).then((resp) => {
                store.commit('SET_HABIT_SUBMITTED_PROOF', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getHabitLeaderboard (store, params) {
        store.commit('SET_HABIT_TRACKING_LEADERBOARD_LOADER_STATUS', true);

        axios.get(`/habit-tracking/${params.id}/leaderboard`).then((resp) => {
            store.commit('SET_HABIT_TRACKING_LEADERBOARD_LOADER_STATUS', false);
            store.commit('SET_LEADERBOARD', resp.data);
        }).catch((err) => {
            store.commit('SET_HABIT_TRACKING_LEADERBOARD_LOADER_STATUS', false);
            console.log(err);
        });
    },

    refreshHabitTracking (store, id) {
        axios.get(`/habit-tracking/${id}/refresh`).then((resp) => {
            store.commit('SET_SELECTED_HABIT_TRACKING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    refreshHabitScores (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', true);

            axios.get(`/habit-tracking/${params.habit}/refresh-habit-scores`, { params }).then((resp) => {
                store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    refreshContactHabitsScore (store, contact) {
        return new Promise((resolve, reject) => {
            store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', true);

            axios.get(`/habit-tracking/${contact}/refresh-contact-scores`).then((resp) => {
                store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    createHabitTracking (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/habit-tracking/create', params).then((resp) => {
                store.dispatch('getAllHabitTrackings');
                Toastr.success(`${params.name} habit tracking has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            })
        });
    },

    restartReminder (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/habit-tracking/restart-reminder', params).then((resp) => {
                Toastr.success(`Habit tracking reminder has been restarted successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    },
};

export default actions;
