const getters = {
    COMPANY_USER_CAN: (state) => (type, section) => {
        if (state.user.company_id && state.user.is_company_location == 0) {
            const permissions = state.companyUserPermissions;

            return permissions[section] && permissions[section][type] ? true : false;
        } else if (type == 'assign' && section == 'contacts-center' ) {
            if (state.user.company_id && state.user.is_company_location) {
                return false;
            } else if (!state.user.company_id && !state.user.is_company_location) {
                return true;
            }

            return false;
        } else if (section == 'contacts-center' && type == 'create' && state.user.company_id && state.user.is_company_location && state.user.location_id) {
            return true;
        } else if (section == 'contacts-center' && type == 'create' && state.user.company_id) {
            return false;
        }

        return true;
    },

    IS_SUPER_USER: (state) => {
        if ((state.user.company_id == 0 || state.user.company_id == null) && (state.user.location_id == 0 || state.user.location_id == null) && (state.user.is_company_location == 0 || state.user.is_company_location == null)) {
            return true;
        } else {
            return false;
        }
    },

    GET_ROUTE_NAME_BY_MODULE: (state) => (module) => {
        const routes = {
            'admin-settings':       'AdminSettings',
            'broadcasts':           'BroadcastIndex',
            'checkouts':            'CheckoutIndex',
            'client-portal':        'ClientPortalIndex',
            'contacts':             'ContactIndex',
            'sms-center':           'SmsCenterIndex',
            'files':                'FileIndex',
            'forms':                'FormIndex',
            'groups':               'GroupIndex',
            'habit-tracking':       'HabitTrackingIndex',
            'journeys':             'JourneyIndex',
            'opt-ins':              'OptinIndex',
            'pages':                'PageIndex',
            'playbooks':            'PlaybookIndex',
            'progress-tracking':    'ProgressTrackingIndex',
            'sequences':            'SequenceIndex',
            'tags':                 'TagIndex',
            'templates':            'TemplatesIndex',
            'workouts':             'WorkoutIndex',
        };

        return  routes[module] ? routes[module] : 'AppsIndex';
    },
};

export default getters;
