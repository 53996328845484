const state = {
    allFiles: [],
    contactFiles: [],
    fileLoader: false,
    fileRefreshLoader: false,
    contactFileLoader: false,
    files: {},
    fileUsers: {},
};

export default state;