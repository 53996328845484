const auth = [
    {
        name: 'Index',
        component: () => import(/* webpackChunkName: "js/auth/index" */ '@/pages/auth/Login'),
        path: '/',
        meta: {
            title: 'Login',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'Login',
        component: () => import(/* webpackChunkName: "js/auth/login" */ '@/pages/auth/Login'),
        path: '/login',
        meta: {
            title: 'Login',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'TwoFactorAuthentication',
        component: () => import(/* webpackChunkName: "js/auth/two-factor-authentication" */ '@/pages/auth/TwoFactorAuthentication'),
        path: '/two-factor-authentication',
        meta: {
            title: 'Two Factor Authentication',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'ActivateAccount',
        component: () => import(/* webpackChunkName: "js/auth/activate-account" */ '@/pages/auth/ActivateAccount'),
        path: '/activate-account',
        meta: {
            title: 'Activate Account',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "js/auth/forgot" */ '@/pages/auth/Forgot'),
        path: '/forgot',
        meta: {
            title: 'Forgot Password',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'Reset',
        component: () => import(/* webpackChunkName: "js/auth/reset" */ '@/pages/auth/Reset'),
        path: '/password/:token/reset',
        meta: {
            title: 'Reset Password',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'Register',
        component: () => import(/* webpackChunkName: "js/auth/register" */ '@/pages/auth/Register'),
        path: '/create-account',
        meta: {
            title: 'Create Account',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'VerifyAccount',
        component: () => import(/* webpackChunkName: "js/auth/verify-account" */ '@/pages/auth/VerifyAccount'),
        path: '/account/:token/verify-email',
        meta: {
            title: 'Verify Account',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'VerifyPhone',
        component: () => import(/* webpackChunkName: "js/auth/verify-phone" */ '@/pages/auth/VerifyPhone'),
        path: '/account/:token/verify-phone',
        meta: {
            title: 'Verify Phone',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'RegisterNewEmail',
        component: () => import(/* webpackChunkName: "js/auth/register-new-email" */ '@/pages/auth/RegisterNewEmail'),
        path: '/account/:token/change-email',
        meta: {
            title: 'Create Account',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'RegisterNewPhone',
        component: () => import(/* webpackChunkName: "js/auth/register-new-phone" */ '@/pages/auth/RegisterNewPhone'),
        path: '/account/:token/change-phone',
        meta: {
            title: 'Create Account',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'VerifyBusiness',
        component: () => import(/* webpackChunkName: "js/auth/verify-business" */ '@/pages/auth/VerifyBusiness'),
        path: '/account/:token/verify-business',
        meta: {
            title: 'Verify Business',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'SelectPlan',
        component: () => import(/* webpackChunkName: "js/auth/select-plan" */ '@/pages/auth/SelectPlan'),
        path: '/account/:token/plans',
        meta: {
            title: 'Select Plan',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'RegistrationFinish',
        component: () => import(/* webpackChunkName: "js/auth/registration-finish" */ '@/pages/auth/RegistrationFinish'),
        path: '/account/:token/finish',
        meta: {
            title: 'Activate Account',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'GetStarted',
        component: () => import(/* webpackChunkName: "js/auth/get-started" */ '@/pages/auth/RegistrationFinish'),
        path: '/get-started',
        meta: {
            title: 'Get Started',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'PaymentActionRequired',
        component: () => import(/* webpackChunkName: "js/auth/payment-action/required" */ '@/pages/auth/PaymentActionRequired'),
        path: '/payment-action-required',
        meta: {
            title: 'Payment Action Required',
            requiresAuth: true,
            accountActive: false,
        },
    },
    {
        name: 'PaymentConfirmation',
        component: () => import(/* webpackChunkName: "js/auth/payment-confirmation" */ '@/pages/auth/PaymentConfirmation'),
        path: '/stripe/payment/:id',
        meta: {
            title: 'Payment Confirmation',
            requiresAuth: false,
            accountActive: false,
        },
    },
    {
        name: 'Referral',
        path: '/referral/:token',
    },
    {
        name: 'DeveloperLogin',
        path: '/developer/:user/:token/login',
    },
    {
        name: 'AutoLogin',
        path: '/user/:token/auto-login',
    },
];

export default auth;