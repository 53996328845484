const mutations = {
    SET_SETUP_GUIDE_VIDEOS (state, videos) {
        state.videos = videos;
    },

    SET_SETUP_GUIDE_QUESTIONS (state, questions) {
        state.questions = questions;
    },

    SET_SETUP_GUIDE_PROGRESS (state, progress) {
        state.progress = progress;
    },

    SET_SETUP_GUIDE_CURRENT_PROGRESS (state, currentProgress) {
        state.currentProgress = currentProgress;
    },

    SET_SETUP_GUIDE_HISTORIES (state, histories) {
        state.histories = histories;
    },

    SET_SETUP_GUIDE_PROGRESS_LOADER_STATUS (state, status) {
        state.progressLoader = status;
    },
};

export default mutations;
