const mutations = {
    SET_DEFINED_LINKS (state, links) {
        state.definedLinks = links;
    },

    SET_EMAIL_BUTTONS (state, buttons) {
        state.emailButtons = buttons;
    },

    SET_TIMEZONES (state, timezones) {
        state.timezones = timezones;
    },

    SET_COUNTRIES (state, countries) {
        state.countries = countries;
    },

    SET_TIMEZONES_BY_COUNTRY (state, timezones) {
        state.timezonesByCountry = timezones;
    },

    SET_TEST_EMAIL_CONTENT (state, content) {
        state.testEmailContent = content;
    },

    SET_TEST_SMS_CONTENT (state, content) {
        state.testSMSContent = content;
    },

    SET_TEST_EMAIL_SMS_LOADER_STATUS (state, status) {
        state.testEmailSMSLoader = status;
    },

    SET_CURRENCIES (state, currencies) {
        state.currencies = currencies;
    },

    SET_BIRTHDAY_SETTING (state, setting) {
        state.birthdaySetting = setting;
    },

    SET_LOADER_STATUS (state, value) {
        state.loader = value;
    },

    SET_BIRTHDAY_LIST (state, data) {
        state.birthdayList = data;
    },

    SET_RECOVERY_PAGE (state, data) {
        state.recoveryPage = data;
    },

    SET_PUBLIC_RECOVERY_PAGE (state, data) {
        state.recoveryPagePublic = data;
    },

    SET_RECOVERY_PAGE_LOADER_STATUS (state, status) {
        state.recoveryPageLoader = status;
    },

    SET_SAVED_STYLE_CONTENT (state, content) {
        state.savedStyle = content;
    },

    SET_FAVORITE_COLORS (state, colors) {
        state.favoriteColors = colors;

        if (colors.length) {
            state.favoriteColorList = colors.map((obj) =>  {
                return obj.color;
            });
        } else {
            state.favoriteColorList = [];
        }
    },

    SET_LIMITED_SHARE_HISTORYS (state, data) {
        state.limitedShareHistory = data;
    },

    SET_UNLIMITED_SHARE_HISTORYS (state, data) {
        state.unlimitedShareHistory = data;
    },

    SET_SORTED_ASSETS (state, assets) {
        state.sortedAssets = assets;
    },

    SET_SORT_LOADER_STATUS (state, value) {
        state.sortLoader = value;
    },

    SET_SORT_DEFAULT_LOADER_STATUS (state, value) {
        state.sortDefaultLoader = value;
    },

    SET_SHORTEN_URL (state, url) {
        state.shortenUrl = url;
    },

    SET_OPTIN_SHORTEN_URL (state, url) {
        state.optinShortenUrl = url;
    },

    SET_RESERVED_WORDS (state, reservedWords) {
        state.reservedWords = reservedWords;
    },

    ADD_VARIABLE_TO_RESERVED_WORDS (state, variable) {
        state.reservedWords[variable.name] = variable.value;
    },

    SET_LAST_TOOL_ROUTE_NAME (state, name) {
        state.lastToolRouteName = name;
    },

    SET_LAST_ROUTE_NAME (state, name) {
        localStorage.setItem('lastRouteName', name);

        state.lastRouteName = name;
    },

    SET_FAVORITE_COLORS_LOADER_STATUS (state, value) {
        state.favoriteColorsLoader = value;
    },

    SET_TIPS_STATUS (state, value) {
        state.tipsStatus = value;
    },

    SET_DEFAULT_CONTENT_BY_TYPE (state, content) {
        if (content && content.content_type == 1) {
            state.playbookDefaultContent = content;
        } else if (content && content.content_type == 2) {
            state.pageDefaultContent = content;
        } else if (content && content.content_type == 3) {
            state.formDefaultContent = content;
        } else if (content && content.content_type == 4) {
            state.progressTrackingDefaultContent = content;
        } else if (content && content.content_type == 5) {
            state.habitTrackingDefaultContent = content;
        }
    }
};

export default mutations;
