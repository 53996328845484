const state = {
    definedLinks: [],
    emailButtons: [],
    timezones: [],
    countries: [],
    timezonesByCountry: [],
    testEmailContent: {},
    testSMSContent: {},
    currencies: [],
    birthdaySetting: {},
    loader: false,
    birthdayList: {},
    recoveryPage: {},
    recoveryPagePublic: {},
    recoveryPageLoader: false,
    savedStyle: {},
    favoriteColors: [],
    limitedShareHistory: {},
    unlimitedShareHistory: {},
    sortedAssets: {},
    sortLoader: false,
    sortDefaultLoader: false,
    selectedPlaybook: {},
    shortenUrl: '',
    optinShortenUrl: '',
    fontFamily: {
        mode: 'single',
        value: ['Inter'],
        options: [
            { value: 'Inter', label: 'Inter' },
            { value: 'Roboto', label: 'Roboto' },
            { value: 'Poppins', label: 'Poppins' }
        ],
        searchable: true,
    },
    fontWeight: {
        mode: 'single',
        value: [300],
        options: [
            { value: 100, label: '100' },
            { value: 200, label: '200' },
            { value: 300, label: '300' },
            { value: 400, label: '400' },
            { value: 500, label: '500' },
            { value: 600, label: '600' },
            { value: 700, label: '700' },
            { value: 800, label: '800' },
            { value: 900, label: '900' },
        ],
        searchable: true,
    },

    reservedWords: {},
    lastToolRouteName: '',
    favoriteColorList: [],
    lastRouteName: localStorage.hasOwnProperty('lastRouteName') ? localStorage.getItem('lastRouteName') : '',
    favoriteColorsLoader: false,
    tipsStatus: false,
    playbookDefaultContent: {},
    pageDefaultContent: {},
    formDefaultContent: {},
    progressTrackingDefaultContent: {},
    habitTrackingDefaultContent: {},
};

export default state;
