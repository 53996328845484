const group = [
    {
        name: 'GroupIndex',
        component: () => import(/* webpackChunkName: "js/group" */ '@/pages/group/Index'),
        path: '/groups',
        meta: {
            title: 'Groups',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
];

export default group;