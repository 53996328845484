import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'
import Helper from '@/utils/Helper'
import Swal from 'sweetalert2'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

const actions = {
    getAllFiles (store, params) {
        axios.get('/file/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_FILES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getFiles (store, params) {

        if (!params.silent) {
            store.commit('SET_FILE_REFRESH_LOADER_STATUS', true);
        }

        axios.get('/file/lists', { params }).then((resp) => {
            store.commit('SET_FILE_REFRESH_LOADER_STATUS', false);
            store.commit('SET_FILES', resp.data);
        }).catch((err) => {
            store.commit('SET_FILE_REFRESH_LOADER_STATUS', false);
            console.log(err);
        });
    },

    sendFile (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FILE_LOADER_STATUS', true);

            axios.post('/file/send-to-contact', params).then((resp) => {
                store.commit('SET_FILE_LOADER_STATUS', false);
                Toastr.success('File has been successfully added!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FILE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    uploadFile (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FILE_LOADER_STATUS', true);

            axios.post('/file/upload', params).then((resp) => {
                store.commit('SET_FILE_LOADER_STATUS', false);
                Toastr.success('File has been successfully added!');
                resolve(resp.data.file);
            }).catch((err) => {
                store.commit('SET_FILE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteFiles (store, ids) {
        return new Promise(function(resolve, reject) {
            const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ ids.length == 1 ? 'this file' : 'these files' }`);
                  options.input = 'text';
                  options.inputAttributes = { placeholder: 'Enter DELETE' };
                  options.inputValidator  = (input) => {
                                                if (/^[A-Z]/.test(input) == false) {
                                                    return 'Please enter all capital letters';
                                                }

                                                if (input !== 'DELETE') {
                                                    return `Please type DELETE to delete ${ ids.length == 1 ? 'file' : 'files' }`;
                                                }
                                            }
                  options.preConfirm = () => {
                                            return axios.delete('file/delete', { params: { ids } }).then((resp) => {
                                                Toastr.success('File has been successfully deleted!');
                                                resolve(true);
                                            }).catch((err) => {
                                                Toastr.error('Unable to delete file, please try again!');
                                                resolve(false);
                                            });
                                        }
            Swal.fire(options);
        });
    },

    getContactFiles (store, params) {
        store.commit('SET_CONTACT_FILE_LOADER_STATUS', true);

        axios.get(`/file/${params.contact_id}/list`, { params }).then((resp) => {
            store.commit('SET_CONTACT_FILE_LOADER_STATUS', false);
            store.commit('SET_CONTACT_FILES', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            store.commit('SET_CONTACT_FILE_LOADER_STATUS', false);
        });
    },

    validateFileName (store, params) {
        return axios.put('/file/validate-name', params);
    },

    deleteContactFiles (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/file/remove-from-contact', { params }).then((resp) => {
                Toastr.success('File has been successfully deleted!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updatePermission (store, params) {
        axios.patch(`/file/update-permission`, params).then((resp) => {
            Toastr.success('File permission has been successfully updated!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    updateStats (store, params) {
        return axios.patch('/file/update-stats', params);
    },

    downloadFile (store, file) {
        return new Promise(function(resolve, reject) {
            axios.get(`/file/${file.id}/download`, { responseType: 'blob' }).then((resp) => {
                store.dispatch('updateStats', { id: file.id, stats: 'downloads' });
                const url   = window.URL.createObjectURL(new Blob([resp.data]));
                const link  = document.createElement('a');
                let name    = file.name.replace(`.${file.ext}`) + '.' + file.ext;

                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameFile (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/file/${params.id}/rename`, params).then((resp) => {
                Toastr.success('File has been rename successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    archiveFiles (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/file/archive-unarchive`, params).then((resp) => {
                Toastr.success(`${params.ids.length > 1 ? 'Files' : 'File'} has been ${params.status ? 'disabled' : 'enabled'} successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    downloadFileV2 (store, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/file/${id}/download`, { responseType: "blob" }).then(data => {
                store.dispatch('updateStats', { id, stats: 'downloads' });
                resolve(data.data);
            }).catch(error => {
                reject(error.toString());
            });
        });
    },

    downloadZip (store, params) {
        const zip       = new JSZip();
        const cache     = {};
        const promises  = [];

        params.files.forEach((item) => {
            const promise = store.dispatch('downloadFileV2', item.id).then(data => {
                                const file = item.name.replace(`.${item.ext}`) + '.' + item.ext;
                                zip.file(file, data, { binary: true });
                                cache[file] = data;
                            });

            promises.push(promise);
        });

        Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" }).then(content => {
                FileSaver.saveAs(content, "files.zip");
                swal.close();
                params.action();
            });
        });
    },

    getFileUsers (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`file/${params.file_id}/users`, { params }).then((resp) => {
                store.commit('SET_FILE_USERS', resp.data);
                resolve(true);
            }).catch((err) => {
                resolve(false)
            });
        });
    },

    changePermission (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`file/${params.id}/change-permission`, params).then((resp) => {
                Toastr.success(resp.data.message);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;