import store from '@/store'

const mutations = {
    SET_ALL_PLAYBOOKS (state, playbooks) {
        state.allPlaybooks = playbooks;
    },

    SET_PLAYBOOKS (state, playbooks) {
        state.playbooks = playbooks;
    },

    SET_PLAYBOOK_USERS (state, users) {
        state.playbookUsers = users;
    },

    SET_PLAYBOOK_LOADER_STATUS (state, value) {
        state.playbookLoader = value;
    },

    SET_PLAYBOOK_USER_LOADER_STATUS (state, value) {
        state.playbookUserLoader = value;
    },

    SET_ANALYTICS (state, analyics) {
        state.analyics = analyics;
    },

    SET_PLAYBOOK_QUESTIONS (state, questions) {
        state.playbookQuestion = questions;
    },

    SET_PLAYBOOK_QUESTION_LOADER_STATUS (state, value) {
        state.playbookQuestionLoader = value;
    },

    SET_SELECTED_PLAYBOOK (state, playbook) {
        state.selectedPlaybook = playbook;

        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'playbook_name', value: playbook.title }, { root: true });
    },

    SET_PLAYBOOK_STEPS (state, steps) {
        state.playbookSteps = steps;
    },

    SET_PLAYBOOK_DETAIL_LOADER (state, status) {
        state.playbookDetailLoader = status;
    },

    SET_PLAYBOOK_CONFIGURATION (state, configuration) {
        state.playbookConfiguration = configuration;
    },

    SET_CUSTOM_BUTTONS (state, buttons) {
        state.customButtons = buttons;
    },

    SET_UPDATE_LOADER_STATUS (state, status) {
        state.playbookUpdateLoader = status;
    },

    SET_CATEGORY_SAVE_LOADER_STATUS (state, status) {
        state.categorySaveLoader = status;
    },

    SET_PLAYBOOK_CATEGORIES (state, categorys) {
        state.playbookCategories = categorys;
    },

    SET_PLAYBOOK_STEP_SAVE_TAB_LOADER (state, status) {
        state.playbookStepTabSaveLoader = status;
    },

    SET_PLAYBOOK_STEP_SAVE_LOADER (state, status) {
        state.playbookStepSaveLoader = status;
    },

    SET_PLAYBOOK_STEP_LOADER (state, status) {
        state.playbookStepLoader = status;
    },

    SET_PLAYBOOK_STEP_DOWNLOADS (state, downloads) {
        state.stepDownloads = downloads;
    },

    SET_PLAYBOOK_SMART_STEPS (state, steps) {
        state.smartSteps = steps;
    },

    SET_PLAYBOOK_SEND_LOADER_STATUS (state, status) {
        state.playbookSendLoader = status;
    },

    SET_PLAYBOOK_STEP_DUPLICATE_LOADER_STATUS (state, status) {
        state.playbookStepDuplicateLoader = status;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'ordering',
                            order: 'desc',
                            folder_id: null,
                        };
    },

    SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS (state, status) {
        state.canvaZipFileLoaderStatus = status;
    },

    SET_PLAYBOOK_CANVA_ZIP_FILE_UPLOAD_LOADER_STATUS (state, status) {
        state.canvaZipFileUploadLoaderStatus = status;
    },

    SET_PLAYBOOK_CANVA_ZIP_FILES (state, files) {
        state.canvaZipFiles = files;
    },

    SET_PLAYBOOK_CANVA_BUILD_LOADER_STATUS (state, status) {
        state.canvaBuildLoaderStatus = status;
    },

    SET_PLAYBOOK_CANVA_ARCHIVED_STEPS (state, steps) {
        state.canvaArchivedSteps = steps;
    },

    SET_PLAYBOOK_CANVA_ARCHIVED_STEPS_LOADER_STATUS (state, status) {
        state.canvaArchivedStepsLoaderStatus = status;
    },
};

export default mutations;
