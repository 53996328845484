import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getPortalContacts (store, params) {
        return new Promise((resolve, reject) => {
            axios.get('/portal-chat/contacts', { params }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    sendMessage (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SEND_MESSAGE_LOADER_STATUS', true);

            axios.post('/portal-chat/send-message', params).then((resp) => {
                store.commit('SET_SEND_MESSAGE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_SEND_MESSAGE_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    },

    getPortalChatLogs (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/portal-chat/logs', {params}).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    archiveUnarchivePortalChat (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch('/portal-chat/archive-unarchive', params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    scheduleMessage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', true);

            axios.post('/portal-chat/schedule', params).then(() => {
                store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', false);
                Toastr.success('SMS has been successfully scheduled!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getScheduledMessage (store, params) {
        store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', true);

        axios.get('/portal-chat/scheduled-message', { params }).then((resp) => {
            store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
            store.commit('SET_SCHEDULED_MESSAGE', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
        });
    },

    deleteScheduledMessage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', true);

            axios.delete('/portal-chat/delete-scheduled-message', { params }).then((resp) => {
                store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
                resolve(true);
                Toastr.success(`Selected scheduled ${params.ids && params.ids.length > 1 ? 'messages' : 'message'} has been successfully deleted!`);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getAllScheduledMessage (store, params) {
        store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', true);

        axios.get('/portal-chat/all-scheduled-message', { params }).then((resp) => {
            store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
            store.commit('SET_ALL_SCHEDULED_MESSAGE', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_SCHEDULED_MESSAGE_LOADER_STATUS', false);
        });
    },

    getStats (store, params) {
        axios.get('/portal-chat/stats').then((resp) => {
            store.commit('SET_STATS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    updateMarkAsReadUnread (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/portal-chat/mark-read-unread', params).then((resp) => {
                resolve(resp.data.status);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    latestChat (store, params) {
        axios.get('/portal-chat/latest-message', params).then((resp) => {
            store.commit('SET_LATEST_CHAT', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    }
};

export default actions;
