import store from '@/store'

const mutations = {
    SET_BROADCASTS (state, broadcasts) {
        state.broadcasts = broadcasts;
    },

    SET_BROADCAST_LOADER_STATUS (state, status) {
        state.broadcastLoader = status;
    },

    SET_BROADCAST_COMPONENT_LOADER_STATUS (state, status) {
        state.broadcastComponentLoader = status;
    },

    SET_BROADCAST_SAVE_LOADER_STATUS (state, status) {
        state.broadcastSaveLoader = status;
    },

    SET_BROADCAST_RECIPIENT (state, recipient) {
        state.recipient = recipient;
    },

    SET_BROADCAST_LOGS (state, contacts) {
        state.broadcastLogs = contacts;
    },

    SET_BROADCAST_LOGS_LOADER_STATUS (state, value) {
        state.broadcastLogsLoader = value;
    },

    SET_SUPPRESSION_LOADER_STATUS (state, value) {
        state.suppressionLoader = value;
    },

    SET_BOUNCES (state, bounces) {
        state.bounces = bounces;
    },

    SET_COMPLAINTS (state, complaints) {
        state.complaints = complaints;
    },

    SET_UNSUBSCRIBES (state, unsubscribes) {
        state.unsubscribes = unsubscribes;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'id',
                            order: 'desc',
                            type: 'all',
                        };
    },

    SET_BROADCAST_DETAIL_LOADER_STATUS (state, value) {
        state.broadcastDetailLoader = value;
    },

    SET_BROADCAST_DETAIL (state, broadcast) {
        state.broadcastDetail = broadcast;
    },

    SET_BROADCAST_EMAIL_SENT_COUNT (state, count) {
        state.totalBroadcastEmailsSent = count;
    },
};

export default mutations;
