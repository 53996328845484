const mutations = {
    SET_SCHEDULE_MESSAGE_LOADER_STATUS (state, value) {
        state.scheduleMessageLoader = value;
    },

    SET_SCHEDULED_MESSAGE (state, data) {
        state.scheduledMessage = data;
    },

    SET_SCHEDULED_MESSAGE_LOADER_STATUS (state, value) {
        state.scheduledMessageLoader = value;
    },

    SET_ALL_SCHEDULED_MESSAGE (state, data) {
        state.allScheduledMessage = data;
    },

    SET_STATS (state, data) {
        state.stats = data;
    },

    SET_SEND_MESSAGE_LOADER_STATUS (state, value) {
        state.sendMessageLoader = value;
    },

    SET_RECEIVE_CHAT_LOG_WATCHER (state, message) {
        state.receiveChatLogWatcher++;
        state.receiveChatLog = message;
    },

    SET_LATEST_CHAT(state, latestPortalChat) {
        state.latestPortalChat = latestPortalChat;
    }
};

export default mutations;
