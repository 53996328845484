import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getDefinedLinks (store, params) {
        axios.get('/defined-links', params).then((resp) => {
            store.commit('SET_DEFINED_LINKS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getEmailButtons (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/email-buttons', params).then((resp) => {
                store.commit('SET_EMAIL_BUTTONS', resp.data);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                reject();
            });
        });
    },

    getTimezones (store, params) {
        axios.get('/timezones', params).then((resp) => {
            store.commit('SET_TIMEZONES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    fileUpload (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/file-upload', params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                reject();
            });
        });
    },

    getCountries (store) {
        axios.get('/countries').then((resp) => {
            store.commit('SET_COUNTRIES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getTimezonesByCountry (store, params) {
        axios.get('/timezones-by-country', { params }).then((resp) => {
            store.commit('SET_TIMEZONES_BY_COUNTRY', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    previewTestEmail (store, params) {
        store.commit('SET_TEST_EMAIL_CONTENT', {});
        store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', true);

        axios.post('/test-email/preview', params).then((resp) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            store.commit('SET_TEST_EMAIL_CONTENT', resp.data);
        }).catch((err) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    sendTestEmail (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEST_EMAIL_CONTENT', {});
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', true);

            axios.post('/test-email/send', params).then((resp) => {
                store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
                Toastr.success('Test email has been sent successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    previewTestSMS (store, params) {
        store.commit('SET_TEST_SMS_CONTENT', {});
        store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', true);

        axios.post('/test-sms/preview', params).then((resp) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            store.commit('SET_TEST_SMS_CONTENT', resp.data);
        }).catch((err) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    sendTestSMS (store, params) {
        store.commit('SET_TEST_SMS_CONTENT', {});
        store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', true);

        axios.post('/test-sms/send', params).then((resp) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            Toastr.success('Test SMS has been sent successfully!');
        }).catch((err) => {
            store.commit('SET_TEST_EMAIL_SMS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getBirthdaySetting (store) {
        axios.get('/birthday/get-birthday-setting').then((resp) => {
            store.commit('SET_BIRTHDAY_SETTING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    updateBirthdaySetting (store, params) {
        store.commit('SET_LOADER_STATUS', true);

        axios.patch('/birthday/update-birthday-setting', params).then((resp) => {
            store.commit('SET_LOADER_STATUS', false);
            store.dispatch('getBirthdaySetting');
            Toastr.success('Birthday settings has been updated successfully!');
        }).catch((err) => {
            console.log(err);
        });
    },

    getUpcomingBirthdays (store, params) {
        store.commit('SET_LOADER_STATUS', true);

        axios.get('/birthday/get-upcoming-birthdays', { params }).then((resp) => {
            store.commit('SET_BIRTHDAY_LIST', resp.data);
            store.commit('SET_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_LOADER_STATUS', false);
        });
    },

    updateCelebrateBirthday (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/birthday/update-celebrate-birthday', params).then((resp) => {
                const message = params.status == 1 ? 'Birthday messaging has been activated successfully!' : 'Birthday messaging has been terminated successfully!';
                Toastr.success(message);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        })
    },

    getRecoveryPage (store) {
        store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', true);

        axios.get('/recovery-page').then((resp) => {
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
            store.commit('SET_RECOVERY_PAGE', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
        });
    },

    saveRecoveryPage (store, params) {
        store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', true);

        axios.post('/recovery-page/save', params).then((resp) => {
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
            store.commit('SET_RECOVERY_PAGE', resp.data);
            Toastr.success('Recovery page has been saved successfully!');
        }).catch((err) => {
            console.log(err);
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    getRecoveryPagePublic (store, params) {
        store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', true);

        axios.get(`/recovery-page/${params.id}/public-setting`, { params }).then((resp) => {
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
            store.commit('SET_PUBLIC_RECOVERY_PAGE', resp.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_RECOVERY_PAGE_LOADER_STATUS', false);
        });
    },

    resubscribeEmails (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/recovery-page/${params.id}/resubscribe`, params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getDefaultContent (store) {
        return new Promise(function(resolve, reject) {
            axios.get('/saved-style/list').then((resp) => {
                store.commit('SET_SAVED_STYLE_CONTENT', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    saveDefaultContent (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/saved-style/update', params).then((resp) => {
                Toastr.success('Saved style has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getFavoriteColors (store) {
        store.commit('SET_FAVORITE_COLORS_LOADER_STATUS', true);

        axios.get('/favorite-color/list').then((resp) => {
            store.commit('SET_FAVORITE_COLORS_LOADER_STATUS', false);
            store.commit('SET_FAVORITE_COLORS', resp.data);
        }).catch((err) => {
            store.commit('SET_FAVORITE_COLORS_LOADER_STATUS', false);
            console.log(err);
        });
    },

    storeFavoriteColor (store, params) {
        axios.post('/favorite-color/store', params).then((resp) => {
            store.dispatch('getFavoriteColors');
        }).catch((err) => {
            console.log(err);
        });
    },

    deleteFavoriteColor (store, id) {
        axios.delete(`/favorite-color/${id}/delete`).then((resp) => {
            store.dispatch('getFavoriteColors');
        }).catch((err) => {
            console.log(err);
        });
    },

    generateShareLink (store, params) {
        return new Promise((resolve, reject) => {
            axios.get('/asset/generate-share-link', {params}).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    getImportLogs (store, params) {
        store.commit('SET_LOADER_STATUS', true);

        axios.get('/asset/get-import-logs', {params}).then((resp) => {
            store.commit('SET_LOADER_STATUS', false);

            if (params.share_count == 1) {
                store.commit('SET_LIMITED_SHARE_HISTORYS', resp.data);
            } else {
                store.commit('SET_UNLIMITED_SHARE_HISTORYS', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_LOADER_STATUS', false);
            console.log(err);
        });
    },

    updateSharePermission (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/asset/update-permission', params).then((resp) => {
                Toastr.success('Selected asset permission has been successfully updated!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        })
    },

    deleteAssets (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/asset/delete-assets', params).then((resp) => {
                Toastr.success('Selected assets has been successfully deleted!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        })
    },

    shareToLocation (store, params) {
        store.commit('SET_LOADER_STATUS', true);

        axios.post('/organization/share-to-location', params).then((resp) => {
            store.commit('SET_LOADER_STATUS', false);
            Toastr.success(resp.data.message);
            params.closeModal();
        }).catch((err) => {
            store.commit('SET_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    importShareLink (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_LOADER_STATUS', true);
            const title = params.title;
            delete params.title;

            axios.post('/asset/import-share-link', params).then((resp) => {
                store.commit('SET_LOADER_STATUS', false);

                if (title != 'Newsletter') {
                    store.dispatch('formModule/getAllForms', {}, { root: true });
                    store.dispatch('habitTrackingModule/getAllHabitTrackings', {}, { root: true });
                    store.dispatch('journeyModule/getAllJourneys', {}, { root: true });
                    store.dispatch('pageModule/getAllPages', {}, { root: true });
                    store.dispatch('pipelineModule/getAllPipelines', {}, { root: true });
                    store.dispatch('playbookModule/getAllPlaybooks', {}, { root: true });
                    store.dispatch('progressTrackingModule/getAllProgressTrackings', {}, { root: true });
                    store.dispatch('sequenceModule/getAllSequences', {}, { root: true });
                    store.dispatch('taskModule/getAllTasks', {}, { root: true });
                    store.dispatch('templateModule/getAllTemplates', {}, { root: true });
                }

                Toastr.success('Asset has been successfully imported');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getSortedAssets (store, params) {
        store.commit('SET_LOADER_STATUS', true);

        axios.get('/asset/get-sorted-assets', { params }).then((resp) => {
            store.commit('SET_LOADER_STATUS', false);
            store.commit('SET_SORTED_ASSETS', resp.data);
        }).catch((err) => {
            store.commit('SET_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    sortAsset (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SORT_LOADER_STATUS', true);

            axios.post('/asset/sort-assets', params ).then((resp) => {
                store.commit('SET_SORT_LOADER_STATUS', false);
                Toastr.success('Assets has been successfully sorted');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SORT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    sortDefaultAsset (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SORT_DEFAULT_LOADER_STATUS', true);

            axios.post('/asset/sort-default-assets', params ).then((resp) => {
                store.commit('SET_SORT_DEFAULT_LOADER_STATUS', false);
                Toastr.success('Default assets has been successfully sorted');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SORT_DEFAULT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    updateAssetThumbnail (store, params) {
        return new Promise(function(resolve, reject) {
            const type = params.type;
            delete params.type;

            store.commit('SET_SORT_DEFAULT_LOADER_STATUS', true);

            axios.post(`/${type}/update-thumbnail`, params).then((resp) => {
                store.commit('SET_SORT_DEFAULT_LOADER_STATUS', false);
                Toastr.success(`${type.charAt(0).toUpperCase() + type.replaceAll('-', ' ').slice(1)} thumbnail successfully updated`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SORT_DEFAULT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getShortUrl (store, params) {
        store.commit('SET_SHORTEN_URL', 'Fetching...');

        axios.get('/asset/get-short-url', { params }).then((resp) => {
            store.commit('SET_SHORTEN_URL', resp.data.url);
        }).catch((err) => {
            console.log(err);
        });
    },

    getOptinShortUrl (store, params) {
        store.commit('SET_OPTIN_SHORTEN_URL', 'Fetching...');

        axios.get('/asset/get-short-url', { params }).then((resp) => {
            store.commit('SET_OPTIN_SHORTEN_URL', resp.data.url);
        }).catch((err) => {
            console.log(err);
        });
    },

    getDefaultContentByType (store, type) {
        return new Promise(function(resolve, reject) {
            axios.get(`/saved-style/${type}/content`).then((resp) => {
                store.commit('SET_DEFAULT_CONTENT_BY_TYPE', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },
};

export default actions;
