const journey = [
    {
        name: 'JourneyIndex',
        component: () => import(/* webpackChunkName: "js/journey" */ '@/pages/journey/Index'),
        path: '/journeys',
        meta: {
            title: 'Journeys',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
];

export default journey;
