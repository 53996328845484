import axios from '@/services/axios'
import router from '@/routes'
import Toastr from '@/utils/Toastr'


const actions = {
    registerUser (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post('/account/create', params).then((resp) => {
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);

                if (params.google_auth) {
                    if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
                        const errors      = err.response.data.errors;
                        const errorsArray = [];

                        Object.keys(errors).map(field => {
                            errorsArray.push(errors[field].join(', '));
                        });

                        Toastr.error(errorsArray.join(', '));
                    } else {
                        if (!err.response && (err.response && err.response.status != 403)) {
                            Toastr.error('Looks like something went wrong, please try after some time.');
                        }
                    }
                } else {
                    Toastr.handleServerError(err, params.setFieldError);
                }
                resolve(false);
            });
        });
    },

    getAccountDetail (store, token) {
        return new Promise(function(resolve, reject) {
              if (typeof token == 'object') {
                  axios.get(`/account/${token.token}/details`, { params: token }).then((resp) => {
                      store.commit('SET_ACCOUNT_DETAILS', resp.data);
                      store.commit('SET_ACCOUNT_ACTIVATED', false);
                      resolve(true);
                  }).catch((err) => {
                      resolve(false);
                  });
              } else {
                  axios.get(`/account/${token}/details`).then((resp) => {
                      store.commit('SET_ACCOUNT_DETAILS', resp.data);
                      store.commit('SET_ACCOUNT_ACTIVATED', false);
                      resolve(true);
                  }).catch((err) => {
                      resolve(false);
                  });
              }
        });
    },

    resendVerificationCode (store, token) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', true);

            axios.post(`/account/${token}/resend-verification-code`).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', false);
                Toastr.success('Email verification code sent successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    verifyAccountEmail (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/verify-email`, params).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.success('Email verified successfully!');
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    resendPhoneVerificationCode (store, token) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', true);

            axios.post(`/account/${token}/resend-phone-verification-code`).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', false);
                Toastr.success('Mobile phone verification code sent successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_RESEND_CODE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    verifyAccountPhone (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/verify-phone`, params).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.success('Mobile phone verified successfully!');
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    changeAccountEmail (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/change-email`, params).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.success('Email has been updated successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);

                if (params.google_auth) {
                    if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
                        const errors      = err.response.data.errors;
                        const errorsArray = [];

                        Object.keys(errors).map(field => {
                            errorsArray.push(errors[field].join(', '));
                        });

                        Toastr.error(errorsArray.join(', '));
                    } else {
                        if (!err.response && (err.response && err.response.status != 403)) {
                            Toastr.error('Looks like something went wrong, please try after some time.');
                        }
                    }
                } else {
                    Toastr.handleServerError(err, params.setFieldError);
                }

                resolve(false);
            });
        });
    },

    changeAccountPhone (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/change-phone`, params).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.success('Mobile phone has been updated successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);

                if (params.google_auth) {
                    if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
                        const errors      = err.response.data.errors;
                        const errorsArray = [];

                        Object.keys(errors).map(field => {
                            errorsArray.push(errors[field].join(', '));
                        });

                        Toastr.error(errorsArray.join(', '));
                    } else {
                        Toastr.error('Looks like something went wrong, please try after some time.');
                    }
                } else {
                    Toastr.handleServerError(err, params.setFieldError);
                }

                resolve(false);
            });
        });
    },

    verifyAccountBusiness (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/verify-business`, params).then((resp) => {
                store.commit('SET_ACCOUNT_DETAILS', resp.data);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.success('Business verified successfully!');
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getSubscriptionPlans (store) {
        return new Promise(function(resolve, reject) {
            axios.get(`/account/subscription-plans`).then((resp) => {
                store.commit('SET_SUBSCRIPTION_PLANS', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    activateAccount (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', true);

            axios.post(`/account/${params.token}/activate`, params).then((resp) => {
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);

                if (!resp.status) {
                    Toastr.success('Account activated successfully!');
                }

                store.commit('SET_ACCOUNT_ACTIVATED', true);
                store.commit('authModule/SET_IS_USER_LOGGED_IN_STATUS', true, { root: true });
                store.commit('authModule/SET_AUTH_TOKEN', resp.data, { root: true });

                axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.access_token;
                axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
                axios.defaults.headers.common['company-admin-id'] = resp.data.company_admin_id;

                // router.push({ name: 'ContactIndex' });

                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ACCOUNT_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
