import store from '@/store'

const mutations = {
    SET_ALL_HABIT_TRACKINGS (state, habitTrackings) {
        state.allHabitTrackings = habitTrackings;
    },

    SET_HABIT_TRACKING_LOADER_STATUS (state, value) {
        state.habitTrackingLoader = value;
    },

    SET_CONTACT_HABIT_TRACKING_LOADER_STATUS (state, value) {
        state.contactHabitTrackingLoader = value;
    },

    SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS (state, value) {
        state.contactCurrentProgressLoader = value;
    },

    SET_CONTACT_HABIT_TRACKINGS (state, progresses) {
        state.contactHabitTrackings = progresses;
    },

    SET_CONTACT_CURRENT_PROGRESS (state, progress) {
        state.contactCurrentProgress = progress;
    },

    SET_HABIT_TRACKINGS (state, habitTrackings) {
        state.habitTrackings = habitTrackings;
    },

    SET_HABIT_TRACKING_CATEGORIES (state, categories) {
        state.habitCategories = categories;
    },

    SET_HABIT_TRACKING_COMPONENT_LOADER_STATUS (state, value) {
        state.habitTrackingComponentLoader = value;
    },

    SET_ACTIVE_CONTACTS (state, activeContacts) {
        state.activeContacts = activeContacts;
    },

    SET_DELETED_CONTACTS (state, deletedContacts) {
        state.deletedContacts = deletedContacts;
    },

    SET_HABIT_TRACKING_UPDATE_LOADER_STATUS (state, status) {
        state.habitTrackingUpdateLoader = status;
    },

    SET_SELECTED_HABIT_TRACKING (state, habitTracking) {
        state.selectedHabitTracking = habitTracking;
        state.habitCategories = habitTracking.categories;

        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'habit_tracking_name', value: habitTracking.name }, { root: true });
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'habit_tracking_response_link', value: habitTracking.url }, { root: true });
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'habit_tracking_link', value: habitTracking.url }, { root: true });
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'habit_tracking_goal_link', value: habitTracking.url }, { root: true });
    },

    SET_HABIT_SUBMITTED_PROOF (state, submittedProofs) {
        state.submittedProofs = submittedProofs;
    },

    SET_LEADERBOARD (state, leaderboard) {
        state.leaderboard = leaderboard;
    },

    SET_HABIT_TRACKING_LEADERBOARD_LOADER_STATUS (state, status) {
        state.habitTrackingLeaderboardLoader = status;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'order',
                            order: 'desc',
                        };
    },

    SET_HABIT_TRACKING_REFRESH_SCORE_LOADER_STATUS (state, status) {
        state.refreshScoreLoader = status;
    },
};

export default mutations;
