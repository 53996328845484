import toastr from 'toastr'
import Swal from 'sweetalert2'
import Helper from '@/utils/Helper'

class Toastr {
    constructor () {
        if (!Toastr.instance) {
            Toastr.instance = this;
        }

        toastr.options.closeButton    = true;
        toastr.options.progressBar    = true;
        toastr.options.timeOut        = 1500;
        toastr.options.positionClass  = 'toast-bottom-left';

        return Toastr.instance;
    }

    success (message, title = '') {
        // toastr.success(message, title);

        let el = document.getElementById("toastr_element");

        el.className  = 'toastr';
        el.innerHTML  = `<i class="fas fa-check-circle"></i>${message}`;
        el.classList.add('show');

        setTimeout(function () {
            el.classList.remove('show');
        }, 2000);
    }

    error (message, title = '') {
        // toastr.error(message, title);

        let el = document.getElementById("toastr_element");

        el.className  = 'toastr';
        el.innerHTML  = `<i class="fas fa-shield-alt"></i>${message}`;

        el.classList.add('show');
        el.classList.add('error');

        setTimeout(function () {
            el.classList.remove('show');
        }, 2000);
    }

    info (message, title = '') {
        // toastr.info(message, title);

        let el = document.getElementById("toastr_element");

        el.className  = 'toastr';
        el.innerHTML  = `<i class="fas fa-info-circle"></i>${message}`;

        el.classList.add('show');
        el.classList.add('info');

        setTimeout(function () {
            el.classList.remove('show');
        }, 2000);
    }

    warning (message, title = '') {
        // toastr.warning(message, title);

        let el = document.getElementById("toastr_element");

        el.className  = 'toastr';
        el.innerHTML  = `<i class="fas fa-exclamation-triangle"></i>${message}`;

        el.classList.add('show');
        el.classList.add('warning');

        setTimeout(function () {
            el.classList.remove('show');
        }, 2000);
    }

    handleServerError (err, setFieldError = '', toastr = false) {
        if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
            if (setFieldError) {
                const errors = err.response.data.errors;
                const vm     = this;

                Object.keys(errors).map(field => {
                    let errorString = errors[field].join(', ');
                    setFieldError(field, errorString.replace('businessname', 'business name'));
                    toastr && vm.error(errorString);
                });

                setTimeout(function () {
                    const elements = document.getElementsByClassName('has-error');

                    if (elements.length) {
                        elements[0].scrollIntoView(true);
                    }
                }, 100);
            }

            instance.error('Unable to submit form, Please correct all the errors!');
        } else if (err.response && err.response.status == 422 && err.response.data && err.response.data.error && err.response.data.error.message) {
            instance.error(err.response.data.error.message);
        } else if (err.response && err.response.status == 422 && err.response.data && err.response.data.limit_exceed) {
            const options = Helper.swalConfirmOptions('Limit exceeded', err.response.data.message, 'Change Plan', false);

            Swal.fire(options).then((result) => {
                if (result.isConfirmed) {
                    window.location = '/account-billing?tab=plans';
                    // window.location = 'mailto:support@onboardme.io?subject=Upgrade Plan';
                }
            });
        } else if (err.response && err.response.status == 422 && err.response.data && err.response.data.message) {
            instance.error(err.response.data.message);
        } else if (err.response && err.response.status == 500 && err.response.data && err.response.data.message && err.response.data.message.includes('subscription')) {
            instance.error(err.response.data.message);
        } else {
            if (err.response && err.response.status != 403) {
                instance.error('We are unable to process your request, Please try again!');
            }
        }
    }

    handleOptinServerError (err, setFieldError = '', toastr = false, field) {

        if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
            if (setFieldError) {
                const errors = err.response.data.errors;
                const vm     = this;

                Object.keys(errors).map(f => {
                    let errorString = errors[f].join(', ');
                    setFieldError(field, errorString.replace('businessname', 'business name'));
                    toastr && vm.error(errorString);
                });

                setTimeout(function () {
                    const elements = document.getElementsByClassName('has-error');

                    if (elements.length) {
                        elements[0].scrollIntoView(true);
                    }
                }, 100);
            }

            instance.error('Unable to submit form, Please correct all the errors!');
        } else {
            if (err.response && err.response.status != 403) {
                instance.error('We are unable to process your request, Please try again!');
            }
        }
    }
}

const instance = new Toastr();
Object.freeze(instance);

export default instance;
