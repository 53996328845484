import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllPages (store, params) {
        axios.get('/page/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_PAGES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    sendPage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PAGE_LOADER_STATUS', true);

            axios.post('/page/send-to-contact', params).then((resp) => {
                store.commit('SET_PAGE_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Pages', { root: true });
                Toastr.success('Page has been queued successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    restrictPage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PAGE_LOADER_STATUS', true);

            axios.post('/page/restrict-from-contact', params).then((resp) => {
                store.commit('SET_PAGE_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Pages', { root: true });
                Toastr.success('Page has been restricted successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getPages (store, params) {
        store.commit('SET_PAGE_LOADER_STATUS', true);

        axios.get('/page/list', { params }).then((resp) => {
            store.commit('SET_PAGES_STATUS', resp.data);
            store.commit('SET_PAGE_LOADER_STATUS', false);
        }).catch((err) => {
            Toastr.handleServerError(err)
            store.commit('SET_PAGE_LOADER_STATUS', false);
        });
    },

    getPageDetails (store, pageId) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PAGE_DETAIL_LOADER_STATUS', true);

            axios.get(`page/${pageId}/details`).then((resp) => {
                store.commit('SET_SELECTED_PAGE', resp.data);
                store.commit('SET_PAGE_DETAIL_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_DETAIL_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getPageDownloads (store, pageId) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PAGE_DOWNLOAD_LOADER_STATUS', true);

            axios.get(`page/${pageId}/downloads`).then((resp) => {
                store.commit('SET_PAGE_DOWNLOADS', resp.data);
                store.commit('SET_PAGE_DOWNLOAD_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_DOWNLOAD_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    duplicatePage (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/page/duplicate', params).then((resp) => {
                store.dispatch('getAllPages');
                Toastr.success('Page has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deletePage (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/page/delete', { params }).then((resp) => {
                store.dispatch('getAllPages');
                Toastr.success('Page has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updatePage (store, params) {
        return new Promise(function(resolve, reject) {
            if (!params.page_id) {
                resolve(false);
            }

            store.commit('SET_PAGE_UPDATE_LOADER_STATUS', true);

            axios.patch(`/page/${params.page_id}/update`, params).then((resp) => {
                store.commit('SET_SELECTED_PAGE', resp.data);
                store.commit('SET_PAGE_UPDATE_LOADER_STATUS', false);
                Toastr.success('Page has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAssignedMembers (store, params) {
        store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', true);

        axios.get(`page/${params.id}/assigned-members`, { params }).then((resp) => {
            store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
            store.commit('SET_ASSIGNED_MEMBERS_STATUS', resp.data);
        }).catch((err) => {
            store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
            console.log(err);
        });
    },

    deleteAssignedMember (store, params) {
        return new Promise((resolve, reject) => {
            const pageId = params.page_id;
            delete params.page_id;

            axios.delete(`page/${pageId}/delete-assigned-members`, { params }).then((resp) => {
                Toastr.success(`${params.ids.length > 1 ? 'Members' : 'Member'} has been deleted successfully!`);
                resolve(true)
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false)
            });
        }) ;
    },

    setEnableDisable (store, params) {
        return new Promise ((resolve, reject) => {
            axios.patch('page/enable-disable', params).then((resp) => {
                Toastr.success(`Page has been ${params.enabled == 1 ? 'enabled' : 'disabled'} successfully!`);
                resolve(true)
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false)
            });
        }) ;
    },

    updatePageDisable (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', true);

            axios.patch('page/page-disable', params).then((resp) => {
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Page has been disabled successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                resolve(false);

                try {
                    const errors = err.response.data.errors;

                    Object.keys(errors).map(field => {
                        let errorString = errors[field].join(', ').replace('disable content.', ' ');
                        let errorField  = field.replace('disable_content.', ' ').trim();

                        const options   = ["disable_content.page.id", "disable_content.page.asset_type", "disable_content.page.title", "disable_content.page.public_url"];
                        let newText     = null;

                        options.some(text => newText = field.includes(text) ? text : newText)

                        if (newText) {
                            errorField  = 'page';
                            errorString = newText ? errors[field].join(', ').replace(newText.trim(), 'page') : newText;
                        }

                        params.setFieldError(errorField, errorString);
                    });
                } catch (e) {
                    if (e) {
                        Toastr.error('We are unable to process your request, Please try again!')
                    }
                }
            });
        });
    },

    createPage (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', true);

            axios.post('/page/create', params).then((resp) => {
                store.dispatch('getAllPages');
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                Toastr.success(`${params.title} page has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError( err, params.setFieldError);
                resolve(false);
            })
        });
    },

    renamePage (store, params) {
        return new Promise ((resolve, rejeact) => {
            store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', true);

            axios.patch('page/rename', params).then((resp) => {
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Page name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PAGE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            })
        })
    },

    deleteDownload (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/page/delete-download`, { params }).then((resp) => {
                store.dispatch('getPageDownloads', params.page_id);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameDownloadFile (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/page/download/${params.id}/rename`, params).then((resp) => {
                Toastr.success('Page download file has been renamed successfully!');
                store.dispatch('getPageDownloads', params.asset_id);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    enableDisablePage (store, params) {
        return new Promise(function (resolve, reject) {
            axios.patch('/contact/enable-disable-page', params).then((resp) => {
                Toastr.success(`Page has been ${ params.client_portal == 1 ? 'enabled' : 'disabled' } in portal successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
