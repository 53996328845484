const file = [
    {
        name: 'FileIndex',
        component: () => import(/* webpackChunkName: "js/file" */ '@/pages/file/Index'),
        path: '/files',
        meta: {
            title: 'Files',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
];

export default file;
