const mutations = {
    SET_CONTENT_BUTTONS (state, buttons) {
        state.contentButtons = buttons;
    },

    SET_CONTENT_SETTING_LOADER_STATUS (state, value) {
        state.contentSettingLoader = value;
    },
};

export default mutations;