<template>
    <ul class="tab_btn" :class="{'hidden' : isFreeImages}" v-if="showLibrary">
        <li @click="tab = 1" :class="{ active: tab === 1 }">Upload</li>
        <li @click="tab = 2" :class="{ active: tab === 2 }">My Library</li>
        <li @click="tab = 3" :class="{ active: tab === 3 }">Free Images</li>
        <li @click="tab = 4" :class="{ active: tab === 4 }">Bulk Upload</li>
    </ul>
    <!-- <div class="listing_loader" v-show="folderLoader || imageUploadLoader || ( imageLibraryLoader && tab != 3 )"><quote-loader /></div>
    <div class="tab_content" :class="{ 'button-uploader': isButton }" v-show="(!folderLoader && !imageUploadLoader && !imageLibraryLoader) || ( imageLibraryLoader && tab == 3 )"> -->
    <div class="listing_loader" v-show="folderLoader || imageUploadLoader"><quote-loader /></div>
    <div class="tab_content" :class="[ isButton ? 'button-uploader' : '', imageType, isFreeImages ? 'max-w-1170 mx-auto' : '' ]" v-show="(!folderLoader && !imageUploadLoader) ">
        <div class="upload_wpr mb-3" v-if="tab == 1">
            <label :for="uniqueId" :class="{ upload_area: !isButton, upload_btn: isButton }" v-bind="getRootProps()" v-if="initializedDragAndDrop">
                <input v-bind="getInputProps({ ref: `file-chooser-single-${uniqueId}`, multiple: false })" :id="uniqueId" :ref="`file-chooser-single-${uniqueId}`" name="image" :status="status" hidden :accept="gifOnly ? 'image/gif' : 'image/*'">
                <template  v-if="modelValue && modelValue != 'null' && !isButton">
                    <img :src="modelValue" alt="" class="uploaded_img">
                    <span><img src="@/assets/images/image.svg" alt=""></span>
                </template>
                <template v-else>
                    <div class="select_img" v-if="isAvatar && !isButton">
                        <img src="@/assets/images/avatar-mask.png">
                    </div>
                    <div class="select_img" v-else-if="isButton && isIcon">
                        <i :class="icon"></i>
                    </div>
                    <div class="select_img" v-else-if="isButton">
                        <img src="@/assets/images/image.svg">
                    </div>
                    <img v-else src="@/assets/images/upload.svg">
                    <h4 v-if="!isButton">Upload {{ uploadText }}</h4>
                    <!-- <h4 v-if="!isButton">Click to Upload {{ uploadText }}</h4> -->
                    <!-- <p v-if="!isButton">or drag and drop SVG, PNG, JPG or GIF</p> -->
                </template>
            </label>
            <button type="button" class="image-remove-button" v-if="modelValue && validLink(modelValue) && !isButton" @click="removeImage">Remove</button>
            <p class="info mt-3" v-if="size != '0x0' && !isButton && !['Portrait', 'Landscape'].includes(imageType)">Recommended size is {{ size }}</p>
        </div>
        <div class="library" v-if="tab == 2">
            <div class="top_bar">
                <ul class="size_tabs" v-if="imageType == 'all'">
                    <li @click="filterLibraryImages('all')" :class="{ active: selectedSize == 'all' }">
                        <a href="javascript:void(0);">All</a>
                    </li>
                    <template v-for="(type, t) of imageTypes" :key="t">
                        <li @click="filterLibraryImages(t)" :class="{ active: selectedSize == t }">
                            <a href="javascript:void(0);">{{ type }}</a>
                        </li>
                    </template>
                </ul>
                <ul v-else-if="['2560x600', '1280x300', '768x300', '746X480', '1200x800', '1200x630'].includes(selectedSize)" class="size_tabs">
                    <li @click="filterLibraryImages(selectedSize)" :class="{ active: !coverFilter }">
                        <a href="javascript:void(0);">All</a>
                    </li>
                    <li @click="filterLibraryImages('2560x600', 1)" v-if="selectedSize == '2560x600' || selectedSize == '1280x300'" :class="{ active: selectedSize == '2560x600' && coverFilter }">
                        <a href="javascript:void(0);">2560x600</a>
                    </li>
                    <li @click="filterLibraryImages('1280x300', 1)" v-if="selectedSize == '2560x600' || selectedSize == '1280x300'" :class="{ active: selectedSize == '1280x300' && coverFilter }">
                        <a href="javascript:void(0);">1280x300</a>
                    </li>
                    <li @click="filterLibraryImages('768x300', 1)" v-if="selectedSize == '768x300' || selectedSize == '746X480'" :class="{ active: selectedSize == '768x300' && coverFilter }">
                        <a href="javascript:void(0);">768x300</a>
                    </li>
                    <li @click="filterLibraryImages('746X480', 1)" v-if="selectedSize == '768x300' || selectedSize == '746X480'" :class="{ active: selectedSize == '746X480' && coverFilter }">
                        <a href="javascript:void(0);">746X480</a>
                    </li>
                    <li @click="filterLibraryImages('1200x800', 1)" v-if="selectedSize == '1200x800' || selectedSize == '746X480'" :class="{ active: selectedSize == '1200x800' && coverFilter }">
                        <a href="javascript:void(0);">1200x800</a>
                    </li>
                    <li @click="filterLibraryImages('1200x630', 1)" v-if="selectedSize == '1200x630' || selectedSize == '746X480'" :class="{ active: selectedSize == '1200x630' && coverFilter }">
                        <a href="javascript:void(0);">1200x630</a>
                    </li>
                </ul>
                <div class="library_tabs mb-3" v-if="folderBreadcrumb.length == 0">
                    <button type="button" class="folder_toggle" @click="folderHide()">{{ folder ? 'Hide' : 'Show' }} &nbsp;<i class="fas fa-angle-down"></i></button>
                    <ul class="tabs" v-if="folder">
                        <li :class="folder === 'main' ? 'active' : ''" @click="toggleFolder('main')">Main Folders</li>
                        <li :class="folder === 'archived' ? 'active' : ''" @click="toggleFolder('archived')">Archived Folders</li>
                    </ul>
                </div>
                <nav aria-label="breadcrumb" v-if="folderBreadcrumb.length && folder">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" @click="toggleFolder(folder)">Main Folder</li>
                        <li class="breadcrumb-item" v-for="(breadcrumb, b) of folderBreadcrumb" :key="b" :class="{ active: breadcrumb.id == selectedFolder.id }" @click="clickBreadcrumb(breadcrumb, b)">
                            {{ breadcrumb.title }}
                        </li>
                    </ol>
                </nav>
                <swiper :slidesPerView="'auto'" :spaceBetween="10" :navigation='true' class="folder_listing" v-if="folder">
                    <swiper-slide v-if="folder === 'main'">
                        <div class="card_item" @click="addNewFolder({ asset_type: 'images', parent_id: selectedFolder ? selectedFolder.id : 0 })">
                            <img src="@/assets/images/add.svg" alt="">
                            Add New Folder
                        </div>
                    </swiper-slide>
                    <swiper-slide v-if="folder === 'main' && folderBreadcrumb.length">
                        <div class="card_item" @click="imageMove = true">
                            <img src="@/assets/images/add.svg" alt="">
                            Add Files
                        </div>
                    </swiper-slide>
                    <swiper-slide v-for="(folder, f) of libraryFolders" :key="f">
                        <div class="card_item" @click.self="selectFolder(folder, f)">
                            <img src="@/assets/images/folder3.svg" alt="" @click.self="selectFolder(folder, f)">
                            <span @click.self="selectFolder(folder, f)"
                                :contenteditable="folder.contenteditable"
                                :class="{'div-ellipsis' : folder.contenteditable == false }"
                                :id="`folder-id-${folder.id}`"
                                @keydown="handleUpdateFolder($event, folder, 1,)"
                            >
                                {{ folder.title }}
                            </span>
                            <a href="javascript:void(0);" v-if="folder.contenteditable" @click="handleUpdateFolder(`folder-id-${folder.id}`, folder)"><i class="fa fa-check"></i></a>
                            <a href="javascript:void(0);" v-if="folder.contenteditable" @click="cancelFolderUpdate(folder, f)" class="text-danger"><i class="fa fa-times"></i></a>
                            <div class="dropdown" :class="{ active: selectedFolderDropdown == folder.id }" @click="toggleFolderDropdown(folder.id)">
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="drp_wrapper">
                                    <ul v-if="folder.archived == 0">
                                        <li @click="editFolder(folder)">Rename</li>
                                        <!-- <li>Add Images</li> -->
                                        <li @click="handleArchiveFolder(folder)">Archive</li>
                                    </ul>
                                    <ul v-else>
                                        <li @click="handleDeleteFolder(folder)">Delete</li>
                                        <li @click="handleUnarchiveFolder(folder)">Restore</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="listing_loader" v-show="imageLibraryLoader"><quote-loader /></div>
            <div v-show="!imageLibraryLoader">
                <ul class="image_list" :class="imageType">
                    <li v-for="(image, i) of libraryImages.data" :key="i">
                        <img :src="image.img_src" alt="">
                        <div class="image_option">
                            <button type="button" @click="selectImage(image.img_src, !image.is_cropped, image)" title="Upload"><i class="fas fa-check"></i></button>
                            <button type="button" @click="imageMoveToFolder = true; selectedImage = image.id" title="Move to Folder"><i class="fas fa-folder"></i></button>
                            <button type="button" class="danger" @click="deleteLibraryImage(image, i)" title="Delete"><i class="fas fa-trash-alt"></i></button>
                        </div>
                        <span class="img_info">Free Text Goes Here..</span>
                    </li>
                </ul>
                <div class="pagination" v-show="libraryImages.total">
                    <pagination v-model="libraryParams.page" :pages="libraryImages.last_page" @update:modelValue="handleLibraryPagination" />
                </div>
            </div>
        </div>
        <div class="free_images" v-if="tab == 3">
            <h4 class="sub_heading2 mb-2">Search Image</h4>
            <form @submit.prevent="handlePixelsImage()">
                <div class="search_box">
                    <div class="form-field">
                        <input type="text" v-model="pixelsParams.search" name="search" placeholder="Fitness">
                    </div>
                    <button type="button" class="search-btn" @click="handlePixelsImage()">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </form>
            <p class="info">ex. Gym Exercise</p>
            <div class="listing_loader" v-show="imageLibraryLoader"><quote-loader /></div>
            <div v-show="!imageLibraryLoader">
                <ul class="image_list">
                    <li v-for="(image, i) of pexelsImages.photos" :key="i" @click="selectImage(image.src && image.src.large2x ? image.src.large2x : '', crop)">
                        <img :src="image.src && image.src.large ? image.src.large : ''" alt="">
                    </li>
                </ul>
                <div class="pagination" v-show="pexelsImages.total_results">
                    <pagination v-model="pixelsParams.page" :pages="pexelsImages.last_page" @update:modelValue="handlePixelsPagination" />
                </div>
            </div>
        </div>
        <div class="upload_wpr bulk-upload mb-3" v-if="tab == 4">
            <div class="top_bar">
                <div class="library_tabs mb-3" v-if="folderBreadcrumb.length == 0">
                    <button type="button" class="folder_toggle" @click="folderHide()">{{ folder ? 'Hide' : 'Show' }} &nbsp;<i class="fas fa-angle-down"></i></button>
                    <ul class="tabs" v-if="folder">
                        <li :class="folder === 'main' ? 'active' : ''" @click="toggleFolder('main')">Main Folders</li>
                        <li :class="folder === 'archived' ? 'active' : ''" @click="toggleFolder('archived')">Archived Folders</li>
                    </ul>
                </div>
                <nav aria-label="breadcrumb" v-if="folderBreadcrumb.length && folder">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item" @click="toggleFolder(folder)">Main Folder</li>
                        <li class="breadcrumb-item" v-for="(breadcrumb, b) of folderBreadcrumb" :key="b" :class="{ active: breadcrumb.id == selectedFolder.id }" @click="clickBreadcrumb(breadcrumb, b)">
                            {{ breadcrumb.title }}
                        </li>
                    </ol>
                </nav>
                <swiper :slidesPerView="'auto'" :spaceBetween="10" :navigation='true' class="folder_listing" v-if="folder">
                    <swiper-slide v-if="folder === 'main'">
                        <div class="card_item" @click="addNewFolder({ asset_type: 'images', parent_id: selectedFolder ? selectedFolder.id : 0 })">
                            <img src="@/assets/images/add.svg" alt="">
                            Add New Folder
                        </div>
                    </swiper-slide>
                    <!-- <swiper-slide v-if="folder === 'main' && folderBreadcrumb.length">
                        <div class="card_item" @click="imageMove = true">
                            <img src="@/assets/images/add.svg" alt="">
                            Add Files
                        </div>
                    </swiper-slide> -->
                    <swiper-slide v-for="(folder, f) of libraryFolders" :key="f">
                        <div class="card_item" @click.self="selectFolder(folder, f)">
                            <img src="@/assets/images/folder3.svg" alt="" @click.self="selectFolder(folder, f)">
                            <span @click.self="selectFolder(folder, f)"
                                :contenteditable="folder.contenteditable"
                                :class="{'div-ellipsis' : folder.contenteditable == false }"
                                :id="`folder-id-${folder.id}`"
                                @keydown="handleUpdateFolder($event, folder, 1,)"
                            >
                                {{ folder.title }}
                            </span>
                            <a href="javascript:void(0);" v-if="folder.contenteditable" @click="handleUpdateFolder(`folder-id-${folder.id}`, folder)"><i class="fa fa-check"></i></a>
                            <a href="javascript:void(0);" v-if="folder.contenteditable" @click="cancelFolderUpdate(folder, f)" class="text-danger"><i class="fa fa-times"></i></a>
                            <div class="dropdown" :class="{ active: selectedFolderDropdown == folder.id }" @click="toggleFolderDropdown(folder.id)">
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="drp_wrapper">
                                    <ul v-if="folder.archived == 0">
                                        <li @click="editFolder(folder)">Rename</li>
                                        <!-- <li>Add Images</li> -->
                                        <li @click="handleArchiveFolder(folder)">Archive</li>
                                    </ul>
                                    <ul v-else>
                                        <li @click="handleDeleteFolder(folder)">Delete</li>
                                        <li @click="handleUnarchiveFolder(folder)">Restore</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div :id="`${uniqueId}-bulk-upload-dropzone`" class="dropzone">
                <div class="dz-message" data-dz-message>
                    <div>
                        <img src="@/assets/images/image2.png">
                        <h4>Click to Upload {{ uploadText }}</h4>
                        <!-- <p>or drag and drop SVG, PNG, JPG or GIF</p> -->
                    </div>
                </div>
            </div>
            <p class="info mt-3" v-if="size != '0x0'">Recommended size is {{ size }}</p>
        </div>
    </div>
    <cropper-component v-model="cropper" :handle-crop="handleCrop" :crop-type="imageType" :image="cropImage" :classlist="classlist" :original-image="originalImage" />
    <image-move v-model="imageMove" :type="size" :folder="selectedFolder" v-if="imageMove" />
    <image-move-to-folder v-model="imageMoveToFolder" :selected-image="selectedImage" v-if="imageMoveToFolder" />
</template>

<script>
    import CropperComponent from '@/components/image-library/Cropper'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import ImageMove from '@/components/image-library/ImageMove'
    import ImageMoveToFolder from '@/components/image-library/ImageMoveToFolder'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation])
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'

    import { useDropzone } from 'vue3-dropzone'
    import { uuid } from 'vue-uuid'
    import { mapGetters, mapState, mapMutations, mapActions  } from 'vuex'

    export default {
        name: 'Image Upload',

        data () {
            return {
                uniqueId: 'upload-'+uuid.v4(),
                size: '',
                cropper: false,
                cropImage: '',
                status: 0,
                tab: 1,
                folder: 'main',
                libraryFolders: [],
                selectedFolderDropdown: 0,
                libraryParams: {
                    page: 1,
                    per_page: 12,
                    folder_id: null,
                    type: null,
                },
                pixelsParams: {
                    page: 1,
                    search: '',
                    per_page: 12,
                },
                getRootProps: null,
                getInputProps: null,
                folderBreadcrumb: [],
                selectedFolder: {},
                selectedIndex: 0,
                imageMove: false,
                imageMoveToFolder: false,
                initializedDragAndDrop: false,
                selectedSize: 'all',
                selectedImage: 0,
                loader: false,
                coverFilter: 0,
                originalImage: {},
            };
        },

        props: {
            modelValue: String,
            imageType: String,
            isAvatar: {
                type: Boolean,
                default: false,
            },
            isButton: {
                type: Boolean,
                default: false,
            },
            isIcon: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: 'fas fa-pencil-alt',
            },
            crop: {
                type: Boolean,
                default: true,
            },
            handleImage: Function,
            showLibrary: {
                type: Boolean,
                default: false,
            },
            gifOnly: {
                type: Boolean,
                default: false,
            },
            classlist: String,
            uploadText: {
                type: String,
                default: '',
            },
            isFreeImages:{
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue', 'update:hasLogo'],

        watch: {
            imageType (type) {
                const vm = this;

                vm.initAspectRatio(type);
            },

            tab (tab) {
                const vm = this;

                setTimeout(function () {
                    if (tab == 2) {
                        vm.libraryParams.folder_id = null;

                        if (vm.imageType == 'all') {
                            vm.libraryParams.type = 'all';
                            vm.selectedSize = 'all';
                        } else {
                            vm.libraryParams.type = vm.size ? (vm.size == '180x60' ? '180x60,180x180' : vm.size) : 'all';
                            vm.selectedSize = vm.size ? vm.size : 'all';
                        }

                        vm.selectedFolder = {};
                        vm.folderBreadcrumb = [];
                        vm.getFolder({ asset_type: 'images', parent_id:  0, archived: 0 });
                        vm.getLibraryImages(vm.libraryParams);
                    } else if (tab == 4) {
                        vm.selectedFolder = {};
                        vm.folderBreadcrumb = [];
                        vm.getFolder({ asset_type: 'images', parent_id:  0, archived: 0 });
                        vm.initBulkDropzone();
                    }
                }, 100);

                if (vm.$parent.showAction !== undefined) {
                    vm.$parent.showAction = tab == 1 ? true : false;
                }

                vm.resetImageLibrary();
            },

            folders (folders) {
                const vm = this;

                vm.libraryFolders = JSON.parse(JSON.stringify(folders));
            },

            modelValue (value) {
                if (value) {
                    const vm =  this;

                    vm.getImageTypes();
                    vm.coverFilter = 0;
                }
            },
        },

        components: {
            CropperComponent,
            ImageMove,
            ImageMoveToFolder,
            Swiper,
            SwiperSlide,
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                authToken: state => state.authModule.authToken,
                folders: state => state.folderModule.folders,
                folderLoader: state => state.folderModule.folderLoader,
                libraryImages: state => state.imageLibraryModule.libraryImages,
                pexelsImages: state => state.imageLibraryModule.pexelsImages,
                imageLibraryLoader: state => state.imageLibraryModule.imageLibraryLoader,
                imageUploadLoader: state => state.imageLibraryModule.imageUploadLoader,
                imageTypes: state => state.imageLibraryModule.imageTypes,
            }),

            ...mapGetters({
                getAspectRatio: 'imageLibraryModule/GET_CROPPER_ASPECT_RATIO_BY_TYPE',
            }),
        },

        mounted () {
            const vm = this;

            vm.initAspectRatio(vm.imageType);
            vm.getImageTypes();

            const { getRootProps, getInputProps  } = useDropzone({ maxSize: 15728640, onDrop: vm.dropzoneDrop });

            vm.getRootProps = getRootProps;
            vm.getInputProps = getInputProps;

            vm.initializedDragAndDrop = false;

            setTimeout(function () {
                vm.initializedDragAndDrop = true;
            }, 500);

            if (vm.isFreeImages) {
                vm.tab = 3;
            }

        },

        methods: {
            ...mapActions({
                uploadImage: 'imageLibraryModule/uploadImage',
                getFolder: 'folderModule/getFolder',
                createFolder: 'folderModule/createFolder',
                updateFolder: 'folderModule/updateFolder',
                archiveFolder: 'folderModule/archiveFolder',
                deleteFolder: 'folderModule/deleteFolder',
                getLibraryImages: 'imageLibraryModule/getLibraryImages',
                getPixelsImages: 'imageLibraryModule/getPixelsImages',
                bulkImageUpload: 'imageLibraryModule/bulkImageUpload',
                getImageTypes: 'imageLibraryModule/getImageTypes',
                deleteImage: 'imageLibraryModule/deleteImage',
            }),

            ...mapMutations({
                resetImageLibrary: 'imageLibraryModule/RESET_IMAGE_LIBRARY',
            }),

            initBulkDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#${vm.uniqueId}-bulk-upload-dropzone`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/image-library/bulk-upload',
                    uploadMultiple: true,
                    paramName: 'images',
                    createImageThumbnails: true,
                    headers: {
                        'Authorization': 'Bearer '+vm.authToken.access_token,
                        'accessed-location-id': vm.authToken.accessed_location_id,
                        'company-admin-id': vm.authToken.company_admin_id,
                    },
                    acceptedFiles: vm.gifOnly ? 'image/gif' : 'image/*',
                    sending: function (files, xhr, formData) {
                        formData.append('type', vm.size);
                        formData.append('size', vm.size);
                        formData.append('folder_id', vm.selectedFolder.id);
                        formData.append('location', `uploads/media/${vm.user.id}/${vm.size}`);
                    },
                    error: function (file, message) {

                        if (file.previewElement) {
                            file.previewElement.classList.add("dz-error");

                            if (typeof message !== "string" && message.error) {
                                message = message.error;
                            }

                            file.previewElement.querySelectorAll("[data-dz-errormessage]").forEach((node, index) => {
                                node.textContent = message.errors[`images.${index}`][0].replace('images image', 'image');
                            });
                        }
                    },
                });
            },

            dropzoneDrop (acceptFiles, errors) {
                const vm = this;

                if (errors[0] && errors[0].errors && errors[0].errors.length && errors[0].errors[0].code == 'file-too-large') {
                    Toastr.error('The image may not be greater than 15MB.');
                } else {
                    if (vm.tab == 4) {
                        vm.handleBulkImageUpload('images[]', acceptFiles);
                    } else {
                        vm.handleFileUpload('image', acceptFiles);
                    }
                }
            },

            handleFileUpload(fieldName, fileList) {
                const vm = this;

                if (vm.crop) {
                    let file = fileList[0];

                    if (!file.type.includes('image/')) {
                        Toastr.error('Please select an image file');

                        return;
                    } else {
                        if (typeof FileReader === 'function') {
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                const ext = event.target.result.split(';')[0].split('/')[1];

                                if (ext == 'gif') {
                                    const options = Helper.fileCropWarning();

                                    options.preConfirm = function (confirm) {
                                        if (confirm) {
                                            vm.initImageCropper(event.target.result);
                                        } else {
                                            let location = `uploads/media/${vm.user.id}/${vm.size}`;

                                            const form = new FormData();
                                            form.append('image', Helper.base64toBlob(event.target.result, ext));
                                            form.append('size', vm.size);
                                            form.append('type', vm.size);
                                            form.append('location', location);

                                            vm.loader = true;

                                            if (vm.$parent.loader !== undefined) {
                                                vm.$parent.loader = true;
                                            }

                                            vm.uploadImage(form).then((resp) => {
                                                if (resp.data.status == 1) {
                                                    const file = resp.data.response;
                                                    vm.status = 1;
                                                    vm.$emit('update:modelValue', file);
                                                    Toastr.success('Image successfully uploaded!');
                                                    document.getElementById(vm.uniqueId).value = '';

                                                    if (vm.handleImage) {
                                                        vm.handleImage()
                                                    }
                                                }

                                                vm.loader = false;

                                                if (vm.$parent.loader !== undefined) {
                                                    vm.$parent.loader = false;
                                                }
                                            }).catch((error) => {
                                                vm.loader = false;

                                                if (vm.$parent.loader !== undefined) {
                                                    vm.$parent.loader = false;
                                                }

                                                Toastr.error('Unable to upload image, Please try to reduce the cropped image and upload again!');
                                            });
                                        }
                                    }

                                    Swal.fire(options).then((result) => {
                                        if (result.isDismissed) {
                                            let location = `uploads/media/${vm.user.id}/${vm.size}`;

                                            const form = new FormData();
                                            form.append('image', Helper.base64toBlob(event.target.result, ext));
                                            form.append('size', vm.size);
                                            form.append('type', vm.size);
                                            form.append('location', location);

                                            vm.loader = true;

                                            if (vm.$parent.loader !== undefined) {
                                                vm.$parent.loader = true;
                                            }

                                            vm.uploadImage(form).then((resp) => {
                                                if (resp.data.status == 1) {
                                                    const file = resp.data.response;
                                                    vm.status = 1;
                                                    vm.$emit('update:modelValue', file);
                                                    Toastr.success('Image successfully uploaded!');
                                                    document.getElementById(vm.uniqueId).value = '';

                                                    if (vm.handleImage) {
                                                        vm.handleImage()
                                                    }
                                                }

                                                vm.loader = false;

                                                if (vm.$parent.loader !== undefined) {
                                                    vm.$parent.loader = false;
                                                }
                                            }).catch((error) => {
                                                vm.loader = false;

                                                if (vm.$parent.loader !== undefined) {
                                                    vm.$parent.loader = false;
                                                }

                                                Toastr.error('Unable to upload image, Please try to reduce the cropped image and upload again!');
                                            });
                                        }
                                    });
                                } else {
                                    vm.initImageCropper(event.target.result);
                                }
                            };

                            reader.readAsDataURL(file);
                        }
                    }
                } else {
                    const formData = new FormData();
                    let location = `uploads/media/${vm.user.id}/${vm.size}`;

                    if (!fileList.length) return;

                    Array.from(Array(fileList.length).keys()).map( x => {
                        formData.append(fieldName, fileList[x], fileList[x].name);
                    });
                    formData.append('type', vm.size);
                    formData.append('size', vm.size);
                    formData.append('location', location);

                    if (vm.user.id) {
                        vm.loader = true;

                        if (vm.$parent.loader !== undefined) {
                            vm.$parent.loader = true;
                        }

                        vm.uploadImage(formData).then((resp) => {
                            vm.status = 1;
                            vm.$emit('update:modelValue', resp.data.response);
                            Toastr.success('Success', 'Image successfully uploaded!');
                            document.getElementById(vm.uniqueId).value = '';

                            if (vm.handleImage) {
                                vm.handleImage()
                            }

                            vm.loader = false;

                            if (vm.$parent.loader !== undefined) {
                                vm.$parent.loader = false;
                            }
                        }).catch((error) => {
                            vm.loader = false;

                            if (vm.$parent.loader !== undefined) {
                                vm.$parent.loader = false;
                            }
                        });
                    }
                }
            },

            initAspectRatio (type) {
                const vm = this;
                const ratio = vm.getAspectRatio(type);

                vm.size = ratio.size;
            },

            initImageCropper (file) {
                const vm = this;

                vm.cropImage = file;
                vm.cropper = true;
            },

            handleCrop (file) {
                const vm = this;
                vm.status = 1;
                vm.$emit('update:modelValue', file);
                vm.tab = 1;

                if (document.getElementById(vm.uniqueId)) {
                    document.getElementById(vm.uniqueId).value = '';
                }

                if (vm.handleImage) {
                    vm.handleImage()
                }
            },

            removeImage () {
                const vm = this;
                vm.status = 0;
                vm.$emit('update:modelValue', '');

                document.getElementById(vm.uniqueId).value = '';
            },

            folderHide () {
                const vm = this;

                if (vm.folder) {
                    vm.folder = '';
                } else {
                    vm.folder = 'main';
                }
            },

            toggleFolderDropdown (folderId) {
                const vm = this;

                if (vm.selectedFolderDropdown == folderId) {
                    vm.selectedFolderDropdown = 0;
                } else {
                    vm.selectedFolderDropdown = folderId;
                }
            },

            selectFolder (folder, index) {
                const vm = this;

                if (folder.contenteditable == false) {
                    vm.getFolder({ asset_type: 'images', parent_id: folder.id, archived: vm.folder == 'main' ? 0 : 1 });

                    vm.selectedFolder = folder;

                    if (vm.tab == 2) {
                        vm.libraryParams.page      = 1;
                        vm.libraryParams.folder_id = folder.id;
                        vm.getLibraryImages(vm.libraryParams);
                    }

                    vm.folderBreadcrumb.push(folder);
                    vm.folderBreadcrumb = vm.getUnique(vm.folderBreadcrumb, 'id');
                }
            },

            editFolder (folder) {
                const vm = this;
                folder.contenteditable = true;

                let range = document.createRange();
                range.selectNode(document.getElementById(`folder-id-` + folder.id));
                const selection = window.getSelection();

                selection.removeAllRanges();
                selection.addRange(range);

                setTimeout(function() {
                    document.getElementById(`folder-id-${folder.id}`).focus();
                    document.execCommand('selectAll', false, null);
                    vm.selectedFolderDropdown = 0;
                }, 100);
            },

            handleUpdateFolder (event, folder, hasEvent = false) {
                const vm      = this;
                const target  = hasEvent ? event.target : document.getElementById(event)
                const params  = { id: folder.id, title: target.innerText, asset_type: 'images' };

                if (hasEvent && event.keyCode === 13) {
                    event.preventDefault();
                } else if (hasEvent && event.keyCode !== 13) {
                    return;
                }

                if (target.innerText.length != 0 && target.innerText.length < 25) {
                    vm.updateFolder(params).then((result) => {
                        if (result == true) {
                            folder.contenteditable = false;
                        } else if (result.response.status == 422) {
                            Toastr.error('The folder name has already been taken!');
                        } else {
                            Toastr.handleServerError(result);
                        }
                    });
                }
            },

            cancelFolderUpdate (folder, index) {
                const vm = this;

                folder.title = vm.folders[index].title;
                folder.contenteditable = false;
            },

            handleArchiveFolder (folder) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'You want to archived this folder and all its sub folder.');
                      options.preConfirm = function () {
                          return vm.archiveFolder({ id: folder.id, archived: 1 }).then((result) => {
                              if (result) {
                                  vm.getFolder({ asset_type: 'images', parent_id:  folder.parent_id, archived: vm.folder == 'main' ? 0 : 1 });
                                  Toastr.success('Folder has been archived successfully!');
                              }
                          });
                      };

                Swal.fire(options);
            },

            handleUnarchiveFolder (folder) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'You want to restore this folder and all its sub folder.');
                      options.preConfirm = function () {
                          return vm.archiveFolder({ id: folder.id, archived: 0 }).then((result) => {
                              if (result) {
                                  vm.getFolder({ asset_type: 'images', parent_id:  folder.parent_id, archived: vm.folder == 'main' ? 0 : 1 });
                                  Toastr.success('Folder has been restored successfully!');
                              }
                          });
                      };

                Swal.fire(options);
            },

            handleDeleteFolder (folder) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'You want to delete this folder and all its sub folder.');
                      options.preConfirm = function () {
                          return vm.deleteFolder({ id: folder.id }).then((result) => {
                              if (result) {
                                  vm.getFolder({ asset_type: 'images', parent_id:  folder.parent_id, archived: vm.folder == 'main' ? 0 : 1 });
                                  Toastr.success('Folder has been deleted successfully!');
                              }
                          });
                      };

                Swal.fire(options);
            },

            toggleFolder (folder) {
                  const vm = this;

                  vm.folder = folder;
                  vm.folderBreadcrumb = [];
                  vm.selectedFolder = {};

                  vm.getFolder({ asset_type: 'images', parent_id: 0, archived: folder == 'main' ? 0 : 1 });

                  vm.libraryParams.page       = 1;
                  vm.libraryParams.folder_id  = null;

                  if (folder == 'archive') {
                      vm.libraryParams.folder_id = 0;
                  }

                  vm.getLibraryImages(vm.libraryParams);
            },

            selectImage (file, crop = false, image = null) {
                const vm = this;

                if (crop && vm.crop) {
                    vm.originalImage = image;
                    vm.initImageCropper(file);
                } else {
                    vm.status = 1;
                    vm.$emit('update:modelValue', file);
                    vm.tab = 1;
                }

                if (vm.handleImage && !crop) {
                    vm.handleImage();
                }
            },

            handleBulkImageUpload (fieldName, files) {
                const vm = this;
                const formData = new FormData();
                let location = `uploads/media/${vm.user.id}/${vm.size}`;

                if (!files.length) return;

                Array.from(Array(files.length).keys()).map( x => {
                    formData.append(fieldName, files[x], files[x].name);
                });

                formData.append('type', vm.size);
                formData.append('size', vm.size);
                formData.append('location', location);

                vm.bulkImageUpload(formData).then((result) => {
                    if (result) {
                        Toastr.success('Success', 'Image successfully uploaded!');
                        document.getElementById(`${vm.uniqueId}-bulk-upload`).value = '';
                    }
                });
            },

            filterLibraryImages (size, selected = 0) {
                const vm = this;

                vm.libraryParams.page = 1;
                vm.libraryParams.type = size;
                vm.libraryParams.selected_size_only = selected;
                vm.selectedSize = size;
                vm.coverFilter = selected;
                vm.size = size;

                vm.getLibraryImages(vm.libraryParams);
            },

            addNewFolder (params) {
                const vm = this;

                vm.createFolder(params).then((result) => {
                    if (result) {
                        vm.getFolder({ asset_type: 'images', parent_id: params.parent_id, archived: vm.folder == 'main' ? 0 : 1 });

                        vm.libraryParams.folder_id = vm.selectedFolder ? vm.selectedFolder.id : null;
                        vm.libraryParams.page      = 1;

                        vm.getLibraryImages(vm.libraryParams);
                    }
                });
            },

            getUnique (arr, comp) {
                const unique = arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map(e => arr[e]);

                return unique;
            },

            clickBreadcrumb (folder, index) {
                const vm = this;

                vm.selectedIndex = index;
                vm.selectedFolder = folder;

                let length = vm.folderBreadcrumb.length;
                vm.folderBreadcrumb.splice(index + 1, length);

                if (vm.tab == 2) {
                    vm.libraryParams.page       = 1;
                    vm.libraryParams.folder_id  = folder.id;

                    vm.getLibraryImages(vm.libraryParams);
                }

                vm.getFolder({ asset_type: 'images', parent_id: folder.id, archived: vm.folder == 'main' ? 0 : 1 });
            },

            deleteLibraryImage (image, index) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this image.');
                options.preConfirm = () => {
                                                return vm.deleteImage(image.id).then((result) => {
                                                    if (result) {
                                                        vm.getLibraryImages(vm.libraryParams);
                                                    }
                                                });
                                            }

                Swal.fire(options);
            },

            validLink (str) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                return !!pattern.test(str);
            },

            handlePixelsImage () {
                const vm = this;

                if (!vm.pixelsParams.search || vm.pixelsParams.search == '') {
                    vm.pixelsParams.search = 'Fitness'
                }

                vm.getPixelsImages(vm.pixelsParams);
            },

            handlePixelsPagination (page) {
                const vm = this;

                vm.pixelsParams.page = page;
                vm.getPixelsImages(vm.pixelsParams);
            },

            handleLibraryPagination (page) {
                const vm = this;

                vm.libraryParams.page = page;
                vm.getLibraryImages(vm.libraryParams);
            },
        },
    }
</script>

<style scoped>
    :deep(.pagination ul li) {
        flex: auto;
    }
    .tab_btn li{
        flex: 1;
        text-align: center;
        background: #fafafb;
    }
    .tab_content{
        height: calc(100vh - 214px);
    }
    .upload_area {
        display: grid;
    }

    .upload_area > div {
        margin: auto;
        vertical-align: middle;
    }
    .library{
        /* padding-bottom: 15px; */
        overflow: hidden;
    }
    .library .size_tabs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 15px;
        list-style-type: none;
        max-height: 60px;
        overflow-y: scroll;
    }
    .library .size_tabs::-webkit-scrollbar{
        width: 4px;
    }
    .library .size_tabs::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #dbdbdb;
    }
    .library .size_tabs li{
        padding: 3px 5px;
    }
    .library .size_tabs li a{
        padding: 4px 7px;
        background: #eaeaea;
        border: 1px solid #d4d4d4;
        color: #121525;
        font-size: 11px;
        line-height: 13px;
        text-decoration: none;
        border-radius: 3px;
        display: block;
    }
    .library .size_tabs li.active a{
        background: #2f7eed;
        color: #f5f5f5;
    }
    /* .image_list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0 -5px;
        max-height: 234px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    } */
    /* .free_images .image_list{
        max-height: 270px;
        margin-bottom: 10px;
    } */
    .image_list::-webkit-scrollbar {
        width: 4px;
    }
    .image_list::-webkit-scrollbar-thumb {
        background-color: #b8b8b8;
        border-radius: 2px;
    }
    .image_list li{
        /* width: 25%; */
        padding: 5px;
        position: relative;
        overflow: hidden;
    }
    .image_list li img{
        width: 100%;
    }
    /* .free_images .image_list img{
        height: 230px;
    } */
    .image_list li .image_option{
        width: 50px;
        position: absolute;
        right: 5px;
        bottom: 7px;
        top: 5px;
        border-radius: 0 5px 5px 0;
        background:rgba(0,0,0,0.5);
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        backdrop-filter: blur(3px);
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .img_info{
        position: absolute;
        left: 5px;
        right: 55px;
        bottom: 7px;
        color: #fff;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 15px 0 15px;
        background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .image_list li .image_option label{
        background: transparent;
        padding: 0;
        border: 0;
    }

    .image_list li .image_option label.checkbox span i{
        color: #b8b8b8;
    }

    .image_list li .image_option button {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f7eed;
        margin-top: 7px;
        font-size: 12px;
        cursor: pointer;
    }

    .image_list li:hover .image_option, .image_list li:hover .img_info {
        opacity: 1;
    }

    /* .upload_area {
        height: 400px;
    } */

    .image_setting .pagination {
        border-radius: 0 0 12px 12px;
        position: absolute;
        right: 0;
        left: 0;
        background: #fff;
        bottom: 0;
        height: 50px;
        padding: 0 40px;
        border-top: 1px solid #eaeaea;
    }

    .upload_wpr .upload_area .uploaded_img {
        height: 100%;
        width: auto;
        /* left: 50%;
        transform: translateX(-50%); */
    }

    .dropzone {
        border: 0;
        background: #fff;
        min-height: 280px;
        display: flex;
        margin-top: 20px;
    }

    :deep(.dz-message) {
        vertical-align: middle;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 400px;
    }

    :deep(.dz-message div) {
        margin: auto;
    }

    .upload_wpr .upload_area {
        /* border: 1px dashed #95bfff; */
        background: #fff;
        text-align: center;
        /* height: 400px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .upload_area h4, .dz-message h4 {
        font-size: 16px;
        line-height: 22px;
        color: #575757;
        font-weight: 400;
        margin: 14px 0 0;
        display: block!important;
    }
    .upload_area p, .dz-message p {
        font-size: 14px;
        line-height: 20px;
        color: #787878;
        font-weight: 300;
    }
    .upload_wpr .upload_area .uploaded_img{
        object-fit: contain;
    }
    .swiper-slide{
        width: auto;
    }
    .top_bar{
        overflow: hidden;
    }
    .pagination{
        position: static !important;
        border: 0 !important;
        margin-top: 10px !important;
        padding: 0 !important;
    }
    .search_box .form-field input{
        box-shadow: 0 0 0 30px white inset !important;
        border-radius: 24px;
    }
    /* .global_setting .image_list li{
        min-height: 150px;
    } */
    /* .email_comp .tab_content, .global_setting .tab_content, .client-portal-cover-setting-form .tab_content{
        height: auto;
    } */
    /* .email_comp .upload_wpr .upload_area, .global_setting .upload_wpr .upload_area, .client-portal-cover-setting-form .upload_wpr .upload_area{
        height: 200px;
    } */

    .bulk-upload .tabs {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px 15px -15px;
        align-items: flex-start;
    }

    .bulk-upload .tabs li {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        padding: 0 15px;
        border-right: 1px solid #d1d1d1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .bulk-upload .tabs li.active {
        color: #2F7FED;
    }

    .bulk-upload .folder_toggle {
        position: relative;
        margin-left: auto;
        font-size: 13px;
        line-height: 15px;
        color: #32373B;
        font-weight: 400;
        cursor: pointer;
        top: 0;
        right: 0;
        padding: 0;
    }
    .library .tab_content .upload_wpr{
        height: calc(100vh - 195px);
        overflow-y: auto;
    }
    .library .tab_content .upload_wpr::-webkit-scrollbar{
        display: none;
    }

    :deep(.modal .modal_header) {
        background: transparent !important;
    }

    :deep(.action_wpr .btn.cancel_btn) {
        background: transparent;
        color: #121525;
        font-weight: 400;
        border: 1px solid #dbdde1;
    }

    /* @media(max-width:991px){
        .library .image_list li{
            flex: 0 0 33.333%;
            min-height: 170px;
        }
    }
    @media(max-width:767px){
        :deep(.dz-message) {
            min-height: auto;
        }
        .library .image_list li{
            flex: 0 0 50%;
            min-height: 170px;
        }
    }
    @media(max-width:499px){
        .library .image_list li{
            flex: 0 0 100%;
            min-height: 170px;
        }
    } */
    @media(max-width: 767px){
        .library .tab_content .upload_wpr{
            height: calc(100vh - 165px);
        }
    }
    @media(max-width: 450px){
        .library .tab_content .upload_wpr{
            height: calc(100vh - 195px);
        }
    }
</style>
