import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllForms (store, params) {
        axios.get('/form/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_FORMS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    sendForm (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', true);

            axios.post('/form/send-to-contact', params).then((resp) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Forms', { root: true });
                // Toastr.success('Form has been queued successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    restrictForm (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', true);

            axios.post('/form/restrict-from-contact', params).then((resp) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Forms', { root: true });
                // Toastr.success('Form has been restricted successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getContactForms (store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.get(`/contact/${params.contact_id}/forms`, { params }).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_CONTACT_FORMS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            store.commit('SET_FORM_LOADER_STATUS', false);
        });
    },

    viewFormResponse (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_FORM_LOADER_STATUS', true);

            axios.get('/form/view-form-response', { params }).then((resp) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    viewFormPublicResponse (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_FORM_LOADER_STATUS', true);

            axios.get('/form/view-form-public-response', { params }).then((resp) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    enableDisableForm (store, params) {
        return new Promise(function (resolve, reject) {
            axios.patch('/contact/enable-disable-form', params).then((resp) => {
                const status = params.client_portal == 1 ? 'enabled' : 'disabled';
                // Toastr.success(`Form has been ${status} in portal successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    downloadFormResponse (store, params) {
        // store.commit('SET_FORM_LOADER_STATUS', true);
        axios.get('/form/download-form-response', { params, responseType: 'blob' }).then((resp) => {
            // store.commit('SET_FORM_LOADER_STATUS', false);

            const url   = window.URL.createObjectURL(new Blob([resp.data]));
            const link  = document.createElement('a');
            const user  = store.rootState.authModule.user;
            const batch = Math.random().toString(16).slice(2);
            const fileName = `${batch}-${params.file_name.toLowerCase().replaceAll(' ', '-')}-submitted-form.pdf`;

            link.href = url;
            link.setAttribute('download', fileName.toLowerCase());
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            Toastr.handleServerError(err);
            // store.commit('SET_FORM_LOADER_STATUS', false);
        });
    },

    getForms(store, params) {
        store.commit('SET_FORM_LOADER_STATUS', true);

        axios.get('form/list', {params}).then((resp) => {
            store.commit('SET_FORMS', resp.data);
            store.commit('SET_FORM_LOADER_STATUS', false);
        }).catch((err) => {
            Toastr.handleServerError(err)
            store.commit('SET_FORM_LOADER_STATUS', false);
        });
    },

    duplicateForm (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/form/duplicate', params).then((resp) => {
                // Toastr.success('Form has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteForm (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/form/delete', { params }).then((resp) => {
                store.dispatch('getAllForms');
                // Toastr.success('Form has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    createForm (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_CREATE_LOADER_STATUS', true);

            axios.patch('/form/create', params).then((resp) => {
                store.dispatch('getAllForms');
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                // Toastr.success(`${params.title} form has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    renameForm (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_CREATE_LOADER_STATUS', true);

            axios.post('form/rename', params).then((resp) => {
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                // Toastr.success('Form name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getFormDetails (store, formId) {
        store.commit('SET_FORM_DETAIL_LOADER_STATUS', true);

        axios.get(`/form/${formId}/details`).then((resp) => {
            store.commit('SET_SELECTED_FORM', resp.data);
            store.commit('SET_FORM_DETAIL_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_FORM_DETAIL_LOADER_STATUS', false);
        });
    },

    getFormQuestions (store, formId) {
        store.commit('SET_FORM_QUESTION_LOADER_STATUS', true);

        axios.get(`/form/${formId}/questions`).then((resp) => {
            store.commit('SET_QUESTIONS', resp.data);
            store.commit('SET_FORM_QUESTION_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_FORM_QUESTION_LOADER_STATUS', false);
        });
    },

    sendContactForm (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_CREATE_LOADER_STATUS', true);

            axios.post('form/send-to-contact', params).then((resp) => {
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                // Toastr.success('Form has been queued successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAnalytics (store, params) {
        store.commit('SET_FORM_COMPONENT_LOADER_STATUS', true);

        axios.get('form/get-analytics', { params }).then((resp) => {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
            store.commit('SET_ANALYTICS', resp.data);
        }).catch((err) => {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
            console.log(err);
        });
    },

    downloadAnalytics (store, params) {
        store.commit('SET_DOWNLOAD_ANALYTICS_LOADER_STATUS', true);

        axios.get(`form/${params.survey_id}/download-analytics`, { responseType: 'arraybuffer' }).then((resp) => {
            store.commit('SET_DOWNLOAD_ANALYTICS_LOADER_STATUS', false);

            const blob    = new Blob([resp.data]);
            const link    = document.createElement("a");

            link.href     = window.URL.createObjectURL(blob);
            link.download = params.survey_name.replaceAll(' ','-')+Date.now() + '-form-analytics.pdf';
            link.click();
        }).catch((err) => {
            console.log(err);
            store.commit('SET_DOWNLOAD_ANALYTICS_LOADER_STATUS', false);
        });
    },

    downloadAnalyticsCsv (store, params) {
        store.commit('SET_DOWNLOAD_ANALYTICS_CSV_LOADER_STATUS', true);

        axios.get(`form/${params.survey_id}/download-analytics-csv`, { responseType: 'arraybuffer' }).then((resp) => {
            store.commit('SET_DOWNLOAD_ANALYTICS_CSV_LOADER_STATUS', false);

            const blob    = new Blob([resp.data]);
            const link    = document.createElement("a");

            link.href     = window.URL.createObjectURL(blob);
            link.download = params.survey_name.toLowerCase().replaceAll(' ', '-')+'-'+Date.now() + '-form-analytics.csv';
            link.click();
        }).catch((err) => {
            console.log(err);
            store.commit('SET_DOWNLOAD_ANALYTICS_CSV_LOADER_STATUS', false);
        });
    },

    getRespondent (store, params) {
        store.commit('SET_FORM_COMPONENT_LOADER_STATUS', true);

        axios.get('form/get-respondent', {params}).then((resp) => {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
            store.commit('SET_FORM_RESPONDENTS', resp.data);
        }).catch((err) => {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
            console.log(err);
        });
    },

    deleteSubmittedForm (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_FORM_COMPONENT_LOADER_STATUS', true);

            axios.delete('form/delete-submitted-form', {params}).then((resp) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                // Toastr.success('Form has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_COMPONENT_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    },

    updateForm (store, params) {
        return new Promise(function(resolve, reject) {
            if (!params.form_id) {
                resolve(false);
            }

            store.commit('SET_FORM_UPDATE_LOADER_STATUS', true);

            axios.post(`/form/${params.form_id}/update`, params).then((resp) => {
                store.commit('SET_SELECTED_FORM', resp.data);
                store.commit('SET_FORM_UPDATE_LOADER_STATUS', false);
                // Toastr.success('Form has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    saveQuestion (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_QUESTION_SAVE_LOADER_STATUS', true);

            axios.post(`/form/${params.form_id}/save-question`, params).then((resp) => {
                store.dispatch('getFormQuestions', params.form_id);
                store.commit('SET_FORM_QUESTION_SAVE_LOADER_STATUS', false);
                Toastr.success('Form question has been saved successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_QUESTION_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    duplicateQuestion (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FORM_QUESTION_DULPICATE_LOADER_STATUS', true);

            axios.patch(`/form/question/${params.question_id}/duplicate`).then((resp) => {
                store.dispatch('getFormQuestions', params.form_id);
                store.commit('SET_FORM_QUESTION_DULPICATE_LOADER_STATUS', false);
                // Toastr.success('Form question has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_QUESTION_DULPICATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteQuestion (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/form/question/${params.question_id}/delete`).then((resp) => {
                store.dispatch('getFormQuestions', params.form_id);
                // Toastr.success('Form question has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    setEnableDisable (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_FORM_LOADER_STATUS', true);

            axios.post('form/set-enable-disable', params).then((resp) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                // Toastr.success(`Form has been ${params.enabled == 1 ? 'enabled' : 'disabled'} successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    },

    getAllAssets (store) {
        axios.get('form/get-all-asset').then((resp) => {
            store.commit('SET_ASSET_LIST', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    updateFormDisable (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_FORM_LOADER_STATUS', true);

            axios.post('form/set-form-disable', params).then((resp) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                // Toastr.success('Form has been disabled successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(false);

                try {
                    const errors = err.response.data.errors;

                    Object.keys(errors).map(field => {
                        let errorString = errors[field].join(', ').replace('disable content.', ' ');
                        let errorField  = field.replace('disable_content.', ' ').trim();

                        const options   = ["disable_content.form.id", "disable_content.form.asset_type", "disable_content.form.title", "disable_content.form.public_url"];
                        let newText     = null;

                        options.some(text => newText = field.includes(text) ? text : newText)

                        if (newText) {
                            errorField  = 'form';
                            errorString = newText ? errors[field].join(', ').replace(newText.trim(), 'form') : newText;
                        }

                        params.setFieldError(errorField, errorString);
                    });
                } catch (e) {
                    if (e) {
                        // Toastr.error('We are unable to process your request, Please try again!')
                    }
                }
            });
        });
    },

    sortQuestions (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/form/sort-questions`, params).then((resp) => {
                // Toastr.success('Form questions has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
