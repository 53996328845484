const getters = {
    CATEGORY_HAS_HABIT: (state) => (categoryId) => {
        const categories = state.habitCategories ? state.habitCategories : [];

        const category = categories.filter((cat) => { return cat.id == categoryId });

        if (category[0]) {
            return category[0].habits.length <= 0;
        }

        return true;
    },
};

export default getters;