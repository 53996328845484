const state = {
    allSequences: [],
    sequenceLoader: false,
    sequences: {},
    sequenceEmails: [],
    sequenceEmailLoader: false,
    emailAnalytics: {},
    smsAnalytics: {},
    analyticsLoader: false,
    sequenceCreateLoader: false,
    selectedSequence: {},
    sequenceUpdateLoader: false,
    sequenceComponentLoader: false,
    sequenceUsers: {},
    sequenceMessageUpdateLoader: false,
    sendNowLoader: false,
    schedules: {},
    scheduleLoader: false,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'ordering',
        order: 'desc',
        folder_id: null,
    },
    detailAnalyticsLoader: false,
    analyticsContactsLoader: false,
    messagesAnalytics: [],
    messagesSubscribers: {},
};

export default state;
