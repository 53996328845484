import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllProgressTrackings (store, params) {
        store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', true);

        axios.get('/progress-tracking/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_PROGRESS_TRACKINGS', resp.data);
            store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', false);
        });
    },

    startProgress (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/progress-tracking/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Progress Scenarios', { root: true });
                Toastr.success('Client tracking has been started successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    stopProgress (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/progress-tracking/remove-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Progress Scenarios', { root: true });
                Toastr.success('Client tracking has been stopped successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getContactProgressTracking (store, params) {
        store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', true);

        axios.get(`/progress-tracking/${params.contact_id}/contact-progress-tracking`, { params }).then((resp) => {
            store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', false);
            store.commit('SET_CONTACT_PROGRESS_TRACKINGS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS', false);
            // Toastr.handleServerError(err);
        });
    },

    getContactCurrentProgress (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', true);

            axios.get(`/progress-tracking/${params.progress_tracking_id}/current-progress`, { params }).then((resp) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', false);
                store.commit('SET_CONTACT_CURRENT_PROGRESS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getContactCurrentProgressV2 (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS_V2', true);

            axios.get(`/progress-tracking/${params.progress_tracking_id}/current-progress`, { params }).then((resp) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS_V2', false);
                store.commit('SET_CONTACT_CURRENT_PROGRESS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS_V2', false);
                resolve(false);
            });
        });
    },

    getProgressTrackings (store, params) {
        store.commit('SET_PROGRESS_TRACKING_LOADER_STATUS', true);

        axios.get('/progress-tracking/list', { params }).then((resp) => {
            store.commit('SET_PROGRESS_TRACKING_LOADER_STATUS', false);
            store.commit('SET_PROGRESS_TRACKINGS', resp.data);
        }).catch((err) => {
            store.commit('SET_PROGRESS_TRACKING_LOADER_STATUS', false);
            Toastr.handleServerError(err)
        })
    },

    assignContacts (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.post('/progress-tracking/add-contact', params).then((resp) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Progress scenario has been assigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    unassignContacts (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.post('/progress-tracking/remove-contact', params).then((resp) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Progress scenario has been unassigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteProgressTracking (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('progress-tracking/delete', { params } ).then((resp) => {
                store.dispatch('getAllProgressTrackings');
                Toastr.success('Progress scenario has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicateProgressTracking (store, params) {
        return new Promise((resolve, reject) => {
            axios.post('progress-tracking/duplicate', params ).then((resp) => {
                Toastr.success('Progress scenario has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameProgressTracking (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', true);

            axios.patch('progress-tracking/rename', params).then((resp) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Progress Scenario name has been successfully updated.');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAssignContacts (store, params) {
        store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', true);

        axios.get(`/progress-tracking/${params.progress_tracking_id}/contacts`, { params }).then((resp) => {
            store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);

            if (params.status == 'active') {
                store.commit('SET_ACTIVE_CONTACTS', resp.data);
            } else if (params.status == 'deleted') {
                store.commit('SET_DELETED_CONTACTS', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getProgressLeaderboard (store, params) {
        store.commit('SET_PROGRESS_TRACKING_LEADERBOARD_LOADER_STATUS', true);

        axios.get(`/progress-tracking/${params.id}/leaderboard`).then((resp) => {
            store.commit('SET_PROGRESS_TRACKING_LEADERBOARD_LOADER_STATUS', false);
            store.commit('SET_LEADERBOARD', resp.data);
        }).catch((err) => {
            store.commit('SET_PROGRESS_TRACKING_LEADERBOARD_LOADER_STATUS', false);
            console.log(err);
        });
    },

    enableDisableReminder (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch('/progress-tracking/enable-disable-reminder', params).then((resp) => {
                const status = params.has_reminder == 1 ? 'enabled' : 'disabled';
                Toastr.success(`Reminder has been ${status} successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    resendReminder (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/progress-tracking/${params.id}/resend-reminder`, params).then((resp) => {
                Toastr.success(`${ params.type == 1 ? 'Email' : 'SMS' } reminder has been sent successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveProgressTracking (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS', true);

            if (params.id) {
                axios.patch(`/progress-tracking/${params.id}/update`, params).then((resp) => {
                    Toastr.success('Client tracking has been updated successfully!');
                    store.commit('SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS', false);
                    store.commit('SET_SELECTED_PROGRESS_TRACKING', resp.data);
                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err, params.setFieldError);
                    store.commit('SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS', false);
                    resolve(false);
                });
            } else {
                axios.post(`/progress-tracking/store`, params).then((resp) => {
                    store.dispatch('getAllProgressTrackings');
                    Toastr.success('Client tracking has been saved successfully!');
                    store.commit('SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS', false);
                    store.commit('SET_SELECTED_PROGRESS_TRACKING', resp.data);
                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err, params.setFieldError);
                    store.commit('SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS', false);
                    resolve(false);
                });
            }
        });
    },

    refreshProgressTracking (store, id) {
        axios.get(`/progress-tracking/${id}/refresh`).then((resp) => {
            store.commit('SET_SELECTED_PROGRESS_TRACKING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getQuestionSections (store, params) {
        store.commit('SET_PROGRESS_TRACKING_QUESTION_LOADER_STATUS', true);

        axios.get(`/progress-tracking/${params.progress_tracking_id}/question-sections`).then((resp) => {
            store.commit('SET_PROGRESS_TRACKING_QUESTION_LOADER_STATUS', false);
            store.commit('SET_QUESTION_SECTIONS', resp.data);
        }).catch((err) => {
            store.commit('SET_PROGRESS_TRACKING_QUESTION_LOADER_STATUS', false);
        });
    },

    saveQuestionSection (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`progress-tracking/${params.progress_tracking_id}/save-question-section`, params).then((resp) => {
                store.dispatch('getQuestionSections', { progress_tracking_id: params.progress_tracking_id });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteQuestionSection (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`progress-tracking/${params.section_id}/delete-question-section`).then((resp) => {
                store.dispatch('getQuestionSections', { progress_tracking_id: params.progress_tracking_id });
                Toastr.success('Custom question section has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortCustomQuestionSection (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`progress-tracking/short-question-section`, params).then((resp) => {
                Toastr.success('Custom question sections has been sorted successfully!');

                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    sortCustomQuestions (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`progress-tracking/short-custom-question`, params).then((resp) => {
                Toastr.success('Custom questions has been sorted successfully!');

                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    saveCustomQuestion (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_QUESTION_SAVE_LOADER_STATUS', true);

            axios.post(`progress-tracking/${params.progress_tracking_id}/save-custom-question`, params).then((resp) => {
                store.commit('SET_QUESTION_SAVE_LOADER_STATUS', false);
                Toastr.success(`Custom question has been ${params.id ? 'updated' : 'added'} successfully!`);
                store.dispatch('getQuestionSections', { progress_tracking_id: params.progress_tracking_id });

                resolve(true);
            }).catch((err) => {
                store.commit('SET_QUESTION_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    getCurrentProgressProof (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`progress-tracking/${params.progress_tracking_id}/contact-progress-proof`, { params }).then((resp) => {
                store.commit('SET_PROGRESS_SUBMITTED_PROOF', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteCustomQuestion (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`progress-tracking/${params.question_id}/delete-question`).then((resp) => {
                store.dispatch('getQuestionSections', { progress_tracking_id: params.progress_tracking_id });
                Toastr.success('Custom question has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    createProgressTracking (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/progress-tracking/create', params).then((resp) => {
                store.dispatch('getAllProgressTrackings');
                Toastr.success(`${params.name} Client tracking has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            })
        });
    },

    restartReminder (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/progress-tracking/restart-reminder', params).then((resp) => {
                Toastr.success(`Client tracking reminder has been restarted successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    },

    getDefaultCategories (store) {
        store.commit('SET_DEFAULT_CATEGORIES_LOADER_STATUS', true);

        axios.get('/progress-tracking/default-categories', {}).then((resp) => {
            store.commit('SET_DEFAULT_CATEGORIES', resp.data);
            store.commit('SET_DEFAULT_CATEGORIES_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_DEFAULT_CATEGORIES_LOADER_STATUS', false);
        })
    },

    createCategoryCustomProgress (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_CATEGORY_PROGRESS_SAVE_LOADER_STATUS', true);

            axios.post(`/progress-tracking/${params.progress_tracking_id}/create-category-progress`, params).then((resp) => {
                store.commit('SET_CATEGORY_PROGRESS_SAVE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CATEGORY_PROGRESS_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    },

    updateCategoryProgress (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_CATEGORY_PROGRESS_UPDATE_LOADER_STATUS', true);

            axios.post(`/progress-tracking/${params.progress_tracking_id}/update-category-progress`, params).then((resp) => {
                store.commit('SET_CATEGORY_PROGRESS_UPDATE_LOADER_STATUS', false);
                Toastr.success(`${params.title} has been update successfully!`)
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CATEGORY_PROGRESS_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    },

    updateProgressCategory (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_CATEGORY_UPDATE_LOADER_STATUS', true);

            axios.post(`/progress-tracking/${params.progress_tracking_id}/update-category`, params).then((resp) => {
                store.commit('SET_CATEGORY_UPDATE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CATEGORY_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    },

    deleteCustomTracking (store, customId) {
        return new Promise((resolve, reject) => {
            axios.delete(`progress-tracking/custom/${customId}/delete`).then((resp) => {
                Toastr.success('Custom tracking has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    refreshContactGoal (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('REFRESH_CONTACT_GOAL_LOADER_STATUS', true);

            axios.get(`progress-tracking/${params.tracking}/contact/${params.contact}/goals`).then((resp) => {
                store.commit('SET_CONTACT_GOALS', resp.data);
                store.commit('REFRESH_CONTACT_GOAL_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                store.commit('REFRESH_CONTACT_GOAL_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateContactGoal (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('UPDATE_CONTACT_GOAL_LOADER_STATUS', true);

            axios.post(`progress-tracking/${params.tracking}/contact/${params.contact}/update-goals`, params).then(() => {
                store.commit('UPDATE_CONTACT_GOAL_LOADER_STATUS', false);

                Toastr.success('Contact goal has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('UPDATE_CONTACT_GOAL_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getProgressContactRelation (store, params) {
        return new Promise((resolve, reject) => {
            axios.get(`progress-tracking/${params.tracking}/contact/${params.contact}/relation`, { params }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
