const mutations = {
    SET_ALL_PROGRESS_TRACKINGS (state, progressTrackings) {
        state.allProgressTrackings = progressTrackings;
    },

    SET_PROGRESS_TRACKING_LOADER_STATUS (state, value) {
        state.progressTrackingLoader = value;
    },

    SET_CONTACT_PROGRESS_TRACKING_LOADER_STATUS (state, value) {
        state.contactProgressTrackingLoader = value;
    },

    SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS (state, value) {
        state.contactCurrentProgressLoader = value;
    },

    SET_CONTACT_CURRENT_PROGRESS_LOADER_STATUS_V2 (state, value) {
        state.contactCurrentProgressLoaderV2 = value;
    },

    SET_CONTACT_PROGRESS_TRACKINGS (state, progresses) {
        state.contactProgressTrackings = progresses;
    },

    SET_CONTACT_CURRENT_PROGRESS (state, progress) {
        state.contactCurrentProgress = progress;
    },

    SET_PROGRESS_TRACKINGS (state, progressTrackings) {
        state.progressTrackings = progressTrackings;
    },

    SET_PROGRESS_TRACKING_COMPONENT_LOADER_STATUS (state, value) {
        state.progressTrackingComponentLoader = value;
    },

    SET_SELECTED_PROGRESS_TRACKING (state, progressTracking) {
        state.selectedProgressTracking = progressTracking;
    },

    SET_LEADERBOARD (state, leaderboard) {
        state.leaderboard = leaderboard;
    },

    SET_ACTIVE_CONTACTS (state, activeContacts) {
        state.activeContacts = activeContacts;
    },

    SET_DELETED_CONTACTS (state, deletedContacts) {
        state.deletedContacts = deletedContacts;
    },

    SET_PROGRESS_TRACKING_LEADERBOARD_LOADER_STATUS (state, status) {
        state.progressTrackingLeaderboardLoader = status;
    },

    SET_QUESTION_SECTIONS (state, status) {
        state.questionSections = status;
    },

    SET_PROGRESS_TRACKING_QUESTION_LOADER_STATUS (state, status) {
        state.progressTrackingQuestionLoader = status;
    },

    SET_PROGRESS_TRACKING_UPDATE_LOADER_STATUS (state, status) {
        state.progressTrackingUpdateLoader = status;
    },

    SET_QUESTION_SAVE_LOADER_STATUS (state, status) {
        state.questionSaveLoader = status;
    },

    SET_PROGRESS_SUBMITTED_PROOF (state, proofs) {
        state.submittedProofs = proofs;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'order',
                            order: 'desc',
                        };
    },

    SET_DEFAULT_CATEGORIES (state, categories) {
        state.defaultCategories = categories;
    },

    SET_DEFAULT_CATEGORIES_LOADER_STATUS (state, status) {
        state.defaultCategoriesLoader = status;
    },

    SET_CATEGORY_PROGRESS_SAVE_LOADER_STATUS (state, status) {
        state.categoryProgressSaveLoader = status;
    },

    SET_CATEGORY_PROGRESS_UPDATE_LOADER_STATUS (state, status) {
        state.categoryProgressUpdateLoader = status;
    },

    SET_CATEGORY_UPDATE_LOADER_STATUS (state, status) {
        state.categoryUpdateLoader = status;
    },

    REFRESH_CONTACT_GOAL_LOADER_STATUS (state, status) {
        state.refreshContactGoalLoader = status;
    },

    SET_CONTACT_GOALS (state, goals) {
        state.contactGoals = goals;
    },

    UPDATE_CONTACT_GOAL_LOADER_STATUS (state, status) {
        state.updateContactGoalLoader = status;
    },
};

export default mutations;
