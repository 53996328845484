import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getButtonList (store, params) {
        store.commit('SET_CONTENT_SETTING_LOADER_STATUS', true);

        axios.get('/content-setting/get-button-list', { params }).then((resp) => {
            store.commit('SET_CONTENT_BUTTONS', resp.data);
            store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
        });
    },

    deleteButton (store, params) {
        return new Promise ((resolve, reject) => {
            const id = params.id;
            delete params.id;

            store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);

            axios.delete(`/content-setting/${id}/delete-button`).then((resp) => {
                store.dispatch('getButtonList', params);
                Toastr.success('Email Button has been successfully deleted.');
                store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        })
    },

    fileUpload (store, params) {
        return new Promise ((resolve, reject) => {
            axios.post('/content-setting/file-upload', params).then((resp) => {
                Toastr.success('File has been successfully uploaded.');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    updateButton(store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_CONTENT_SETTING_LOADER_STATUS', true);

            axios.post('/content-setting/update-button', params).then((resp) => {
                store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
                Toastr.success(`Email Button has been successfully ${params.id ? 'updated' : 'created'}.`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTENT_SETTING_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError, true);
                resolve(false);
            });
        })
    }
};

export default actions;
