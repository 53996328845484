import axios from '@/services/axios'
import router from '@/routes'
import Toastr from '@/utils/Toastr'

const actions = {
    getStatuses (store, params) {
        store.commit('SET_STATUS_TABS_LOADER_STATUS', true);

        axios.get('/contact/tabs/list', { params }).then((resp) => {
            store.commit('SET_STATUSES', resp.data);
            store.commit('SET_STATUS_LIST', resp.data);
            store.commit('GROUP_STATUSES', resp.data);
            store.commit('SET_STATUS_TABS_LOADER_STATUS', false);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_STATUS_TABS_LOADER_STATUS', false);
        });
    },

    getContacts (store, params) {
        localStorage.setItem('contacts_params', JSON.stringify(params));

        let counter = localStorage.getItem('contacts_request_counter');
        counter     = counter ? parseInt(counter) + 1 : 0;

        localStorage.setItem('contacts_request_counter', counter);

        store.commit('SET_CONTACTS_LOADER_STATUS', true);

        axios.get('/contact/list', { params }).then((resp) => {
            store.commit('SET_CONTACTS', resp.data);

            if (counter == parseInt(localStorage.getItem('contacts_request_counter'))) {
                store.commit('SET_CONTACTS_LOADER_STATUS', false);
                localStorage.setItem('contacts_request_counter', 0);
            }
        }).catch((err) => {
            if (counter == parseInt(localStorage.getItem('contacts_request_counter'))) {
                store.commit('SET_CONTACTS_LOADER_STATUS', false);
                localStorage.setItem('contacts_request_counter', 0);
            }

            Toastr.handleServerError(err);
        });
    },

    fetchContact (store, id) {
        store.commit('SET_CONTACTS_LOADER_STATUS', true);

        axios.get(`/contact/${id}/fetch`, { }).then((resp) => {
            store.commit('SET_CONTACTS_LOADER_STATUS', false);

            if (resp.data.id) {
                store.commit('SET_SELECTED_CONTACT', resp.data);
            } else {
                router.push({ name: 'Error-404' });
            }

        }).catch((err) => {
            store.commit('SET_CONTACTS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getAssets (store, params) {
        const isRefreshList = params.isRefreshList ? true : false;

        if (!isRefreshList) {
            store.commit('SET_ASSETS_LOADER_STATUS', true);
        } else {
            delete params.isRefreshList;
        }

        return axios.get('/contact/assets', { params }).then((resp) => {
            store.commit('SET_CONTACT_ASSETS', resp.data);

            if (!isRefreshList) {
                store.commit('SET_CONTACT_ASSETS_WATCHER_STATUS');

                setTimeout(() => {
                    store.commit('SET_ASSETS_LOADER_STATUS', false);
                }, 2000);
            }
        }).catch((err) => {
            setTimeout(() => {
                if (!isRefreshList) {
                    store.commit('SET_ASSETS_LOADER_STATUS', false);
                }
            }, 2000);
            Toastr.handleServerError(err);
        });
    },

    deleteStatusTab (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('/contact/tabs/delete', { params }).then((resp) => {
                Toastr.success(`The ${params.title} tab has been deleted successfully!`);

                if (params.module == 'contact-center') {
                    store.dispatch('getStatuses');
                }

                resolve(resp.data);
            }).catch((err) => {
                resolve(false);

                Toastr.handleServerError(err, params.setFieldError);
            });
        });
    },

    updateStatusTabs (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/contact/tabs/update', params.tabs).then((resp) => {
                Toastr.success(`Contact center tabs updated successfully!`);

                if (params.module == 'contact-center') {
                    store.dispatch('getStatuses');
                }

                resolve(true);
            }).catch((err) => {
                resolve(false);

                Toastr.handleServerError(err, params.setFieldError);
            });
        });
    },

    addNote (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_NOTE_LOADER_STATUS', true);

            axios.post('/contact/add-note', params).then((resp) => {
                store.commit('SET_NOTE_LOADER_STATUS', false);
                Toastr.success('Note has been successfully added!');
                store.commit('SET_BULK_ACTION_WATCHER');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_NOTE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sendSMS (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SMS_LOADER_STATUS', true);

            axios.post('/contact/send-sms', params).then((resp) => {
                store.commit('SET_SMS_LOADER_STATUS', false);
                store.commit('SET_BULK_ACTION_WATCHER');

                if (resp.data.status == 1) {
                    Toastr.success('SMS has been queued successfully!');
                } else {
                    Toastr.error(resp.data.message);
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_SMS_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sendEmail (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_EMAIL_LOADER_STATUS', true);

            axios.post('/contact/send-email', params).then((resp) => {
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                store.commit('SET_BULK_ACTION_WATCHER');

                if (resp.data.status == 1) {
                    Toastr.success(resp.data.response);
                }else if (resp.data.status == -1) {
                    Toastr.error(resp.data.response);
                }
                resolve(true);
            }).catch((err) => {
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sendReward (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_REWARD_LOADER_STATUS', true);

            axios.post('/contact/send-reward', params).then((resp) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Reward has been queued successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    saveContact (store, params) {
        return new Promise(function(resolve, reject) {
            const route   = params.id ? `/contact/${params.id}/update` : `/contact/save`;
            const method  = params.id ? `patch` : `post`;

            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

            axios[method](route, params).then((resp) => {
                resolve(resp.data);

                setTimeout(function () {
                    store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                    store.commit('SET_BULK_ACTION_WATCHER');
                    Toastr.success('Contact has been saved successfully!');

                    if (params.handleCancel) {
                        params.handleCancel();
                    }
                }, 100);

            }).catch((err) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    resendMemberId (store, contactId) {
        axios.put(`/contact/${contactId}/resend-member-id`).then((resp) => {
            Toastr.success('Member ID has been sent successfully!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    markAsValidEmail (store, contactId) {
        store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

        axios.put(`/contact/${contactId}/mark-email-valid`).then((resp) => {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
            Toastr.success('Email has been marked as valid successfully!');
        }).catch((err) => {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    updateMemberID (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

            axios.patch(`/contact/${params.contact_id}/update-member-id`, params).then((resp) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.success('Custom member ID has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    enableDisableDeliveryStatus (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

            axios.patch(`/contact/update-delivery-status`, params).then((resp) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.success(`The ${ params.type } sending ${ params.status ? 'enabled' : 'disabled' } successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateFromLaunchPad (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

            axios.patch(`/contact/${params.contact_id}/update-from-launchpad`, params).then((resp) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                store.dispatch('getAssets', {contact_id: params.contact_id})
                Toastr.success('Contact information has been saved successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getChartData (store, params) {
        store.commit('SET_CONTACT_CHART_LOADER_STATUS', true);

        axios.get(`/contact/chart-data`, { params }).then((resp) => {
            store.commit('SET_CONTACT_CHART_DATA', resp.data);
            store.commit('SET_CONTACT_CHART_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_CONTACT_CHART_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    changeGender (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.patch('/contact/change-gender', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Contacts gender has been updated successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    changeStatusTab (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.patch('/contact/change-status', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Contacts tab has been updated successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    disableConfirmation(store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/contact/disable-confirmation', params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Email confirmation has been disabled successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    validateEmails(store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch('/contact/validate-email', params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Email validation has been successfully added in queue!');
                resolve(true)
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    bulkDelete (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

            axios.post('/contact/delete', params).then((resp) => {
                store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Contacts has been deleted successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    changePhoneCountryCode (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.patch('/contact/update-phone-country-code', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Phone number country code updated successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    mergeContacts (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.post('/contact/merge', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Contacts has been merged successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    moveContacts (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.post('/contact/move', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Contacts has been moved successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    updateBirthday (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/contact/${params.contact_id}/update-birthday`, params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Date of birth has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    copyContactToLocation (store, params) {
        delete params.action;

        store.commit('SET_BULK_ACTION_LOADER_STATUS', true);

        axios.post('/contact/copy-to-location', params).then((resp) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            store.commit('SET_BULK_ACTION_WATCHER');
            Toastr.success('Contacts has been copied successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('SET_BULK_ACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    changeMemberIDEmail (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_EMAIL_LOADER_STATUS', true);

            axios.post('/contact/change-member-id-email', params).then((resp) => {
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Email has been queued successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    mapCSVFields (store, params) {
        return axios.post('/contact/map-csv-field', params);
    },

    importContact (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/contact/import', params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    integrateMBSync (store, params) {
        return axios.post('/integration/connect-mindbody', params);
    },

    syncMBClient (store, params) {
        return axios.get('/integration/sync-mindbody', { params });
    },

    exportContact (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/contact/export', { params, responseType: 'blob' }).then((resp) => {
                const url   = window.URL.createObjectURL(new Blob([resp.data]));
                const link  = document.createElement('a');
                const user  = store.rootState.authModule.user;
                const batch = Math.random().toString(16).slice(2);
                const fileName = `${user.fname}-${user.lname}-${user.id}-contacts-${batch}.${params.export_method}`;

                link.href = url;
                link.setAttribute('download', fileName.toLowerCase());
                document.body.appendChild(link);
                link.click();
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getLifecycle (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`/contact/${params.contact_id}/lifecycles`, { params }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    getLifecycleSubject (store, contactId) {
        return new Promise(function(resolve, reject) {
            axios.get(`/contact/${contactId}/lifecycle-subject`).then((resp) => {
                store.commit('SET_LIFECYCLE_SUBJECT_LIST', resp.data);
                resolve(true);
            }).catch((err) => {
                resolve(false);
                console.log(err);
            });
        });
    },

    getNotes (store, params) {
        store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', true);

        axios.get(`/contact/${params.contact_id}/notes`, { params }).then((resp) => {
            store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', false);
            store.commit('SET_NOTES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    markContactAsStarred (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/contact/${params.contact_id}/update-starred`, params).then((resp) => {
                Toastr.success(`Contact has been ${ params.starred ? 'mark' : 'unmark' } as starred successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getContactStats (store, contactId) {
        axios.get(`/contact/${contactId}/stats`).then((resp) => {
            store.commit('SET_STATS', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    getEmailLogs (store, params) {
        store.commit('SET_EMAIL_LOADER_STATUS', true);
        store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', true);

        axios.get(`/contact/${params.contact_id}/email-logs`, { params }).then((resp) => {
            store.commit('SET_EMAIL_LOADER_STATUS', false);
            store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', false);
            store.commit('SET_EMAILS', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_EMAIL_LOADER_STATUS', false);
        });
    },

    deleteEmailLogs (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_EMAIL_LOADER_STATUS', true);

            axios.delete(`/contact/${params.contact_id}/delete-email-logs`, { params }).then((resp) => {
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_EMAIL_LOADER_STATUS', false);
                resolve(false)
            });
        });
    },

    deleteNotes (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/contact/delete-notes', params).then((resp) => {
                Toastr.success('Notes has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                resolve(false);
                console.log(err);
            });
        });
    },

    updateNote (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/contact/${params.id}/update-note`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
                console.log(err);
            });
        });
    },

    getContactReward (store, params) {
        store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', true);

        axios.get(`/contact/${params.contact_id}/rewards`, { params }).then((resp) => {
            store.commit('SET_CONTACT_PROFILE_LOADER_STATUS', false);
            store.commit('SET_REWARDS', resp.data.rewards);
            store.commit('SET_REWARDS_TOTAL_POINTS', resp.data.total_points);
        }).catch((err) => {
            store.commit('SET_REWARD_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    editReward (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_REWARD_LOADER_STATUS', true);

            axios.post('/contact/edit-rewards', params).then((resp) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                Toastr.success('Reward points has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    redeemPoints (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_REWARD_LOADER_STATUS', true);

            axios.post('/contact/redeemed-reward-points', params).then((resp) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                Toastr.success('Reward points has been redeemed successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_REWARD_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteReward (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/contact/delete-rewards', params).then((resp) => {
                Toastr.success('Selected reward was successfully deleted!');
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    updateTemperature (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/contact/${params.contact_id}/change-temperature`, params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success(`Contact temperature has been successfully updated to ${ params.temperature}!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateContactPhoto (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CONTACT_PHOTO_LOADER_STATUS', true);

            axios.patch(`/contact/${params.contact_id}/update-photo`, params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                store.commit('SET_CONTACT_PHOTO_LOADER_STATUS', false);
                Toastr.success('Contact profile picture has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_CONTACT_PHOTO_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getAllContacts (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get('/contact/all', { params }).then((resp) => {
                store.commit('SET_ALL_CONTACTS', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false)
            });
        });
    },

    getCustomFields (store, params) {
        store.commit('SET_CUSTOM_FIELD_LOADER_STATUS', true);

        axios.get('/contact/custom-field/list', { params }).then((resp) => {
            store.commit('SET_CUSTOM_FIELDS', resp.data);
            store.commit('SET_CUSTOM_FIELD_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CUSTOM_FIELD_LOADER_STATUS', false);
        });
    },

    saveCustomField (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/contact/custom-field/save', params).then((resp) => {
                Toastr.success('Custom field has been saved successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteCustomField (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/contact/custom-field/${id}/delete`).then((resp) => {
                Toastr.success('Custom field has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getSuppressedContact (store, params) {
        store.commit('SET_SUPPRESSED_CONTACT_LOADER_STATUS', true);

        axios.get(`/contact/suppressions/${params.type}`, { params }).then((resp) => {
            store.commit('SET_SUPPRESSED_CONTACTS', resp.data);
            store.commit('SET_SUPPRESSED_CONTACT_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_SUPPRESSED_CONTACT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactRewardPoints (store, contactId) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get(`contact/${contactId}/reward-points`).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_REWARDS_TOTAL_POINTS', resp.data.total_points);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactBroadcastLogs (store, params) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get('contact/broadcast-logs', { params }).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_BROADCAST_LOGS', resp.data);

        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactTags (store, contactId) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);
        store.commit('SET_CONTACT_TAGS_LOADER_STATUS', true);

        axios.get(`contact/${contactId}/tags`).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_TAGS_LOADER_STATUS', false);
            store.commit('SET_SELECTED_TAGS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_TAGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactPages (store, params) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get(`contact/pages`, { params }).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_PAGES', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactSequences (store, params) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get(`contact/sequences`, { params }).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_SEQUENCES', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactPlaybooks (store, params) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get(`contact/playbooks`, { params }).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_PLAYBOOKS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactJourneys (store, params) {
        store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', true);

        axios.get(`contact/journeys`, { params }).then((resp) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            store.commit('SET_CONTACT_JOURNEYS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_DETAILS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    markContactAsFlagged (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/contact/${params.contact_id}/update-flagged`, params).then((resp) => {
                Toastr.success(`Contact has been ${ params.flagged ? 'mark' : 'unmark' } as flagged successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sendMessage (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SEND_MESSAGE_LOADER_STATUS', true);

            axios.post('/contact/send-portal-message', params).then((resp) => {
                store.commit('SET_SEND_MESSAGE_LOADER_STATUS', false);
                Toastr.success('SMS has been queued successfully!');
                store.commit('SET_BULK_ACTION_WATCHER');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEND_MESSAGE_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    },

    scheduleMessage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', true);

            axios.post('/contact/schedule-portal-message', params).then(() => {
                store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', false);
                Toastr.success('SMS has been successfully scheduled!');
                store.commit('SET_BULK_ACTION_WATCHER');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SCHEDULE_MESSAGE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getCSVImportLogs (store) {
        return axios.get('/contact/import-log');
    },

    deleteCSVImportLogs (store) {
        return axios.post('/contact/delete-csv-import-log');
    },

    updateContact (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_CONTACT_LOADER_STATUS', true);

            axios.patch(`/contact/${params.id}/update`, params).then((resp) => {
                store.commit('SET_UPDATE_CONTACT_LOADER_STATUS', false);
                Toastr.success('Contact has been saved successfully!');

                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_UPDATE_CONTACT_LOADER_STATUS', false);
                resolve(false);

                const errors = err.response && err.response.data && err.response.data.errors;

                if (errors && params.setFieldError) {
                    Object.keys(errors).map(field => {
                        let errorString = errors[field].join(', ');
                        params.setFieldError(field+'-'+params.id, errorString);
                    });
                }
            });
        });
    },

    getSendEmailEventLogs (store, params) {
        store.commit('SET_SEND_EMAIL_EVENT_LOGS_LOADER_STATUS', true);

        axios.get('contact/email-sent-event-logs', { params }).then((resp) => {
            store.commit('SET_SEND_EMAIL_EVENT_LOGS_LOADER_STATUS', false);
            store.commit('SET_SEND_EMAIL_EVENT_LOGS_STATUS', resp.data);
        }).catch((err) => {
            store.commit('SET_SEND_EMAIL_EVENT_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });

    },

    updateLastAction (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch('contact/update-last-action', params).then((resp) => {
                store.commit('SET_BULK_ACTION_WATCHER');
                Toastr.success('Contact last action has been successfully updated.');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    savePortalPassword (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PORTAL_PASSWORD_UPDATE_LOADER_STATUS', true);

            axios.post(`contact/${params.contact}/update-portal-password`, params).then((resp) => {
                store.commit('SET_PORTAL_PASSWORD_UPDATE_LOADER_STATUS', false);
                Toastr.success('Contact portal password has been successfully updated.');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_PORTAL_PASSWORD_UPDATE_LOADER_STATUS', false);
                resolve(true);
            });
        });
    },

    portalAccountActiveSuspend (store, params){
        return new Promise(function (resolve, reject) {
            store.commit('SET_CONTACT_SAVE_LOADER_STATUS', true);

            axios.patch(`/contact/portal-account-activate-suspend`, params).then((resp) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.success(`Contact ${params.type} has been updated successfully`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CONTACT_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    resendLogin (store, params) {
        return new Promise((resolve) => {
            axios.post(`/contact/resend-login`, params).then((resp) => {
                Toastr.success(`Login details has been sent successfuly`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    tabsListSort (store, params) {
        return new Promise((resolve) => {
            axios.patch('/contact/tabs/list-sort', params).then((resp) => {
                Toastr.success(`Contact center tabs updated successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getDeletedContact (store, params) {
        store.commit('SET_CONTACTS_DELETED_LOADER_STATUS', true);

        axios.get('/contact/deleted/list', { params }).then((resp) => {
            store.commit('SET_CONTACTS_DELETED_LOADER_STATUS', false);
            store.commit('SET_CONTACTS_DELETED', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACTS_DELETED_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    filterContactsByUnopenedEmails (store, params) {
        store.commit('SET_UNOPENED_EMAILS_FILTER_LOADER_STATUS', true);

        axios.get('/contact/filter-by-unopened-emails', { params }).then((resp) => {
            store.commit('SET_UNOPENED_EMAILS_FILTER_LOADER_STATUS', false);
            store.commit('SET_UNOPENED_EMAILS_CONTACTS', resp.data);
        }).catch((err) => {
            store.commit('SET_UNOPENED_EMAILS_FILTER_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    applyUnopenedTag(store, params) {
        return new Promise((resolve) => {
            axios.post(`/contact/apply-unopened-tag`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
