import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getOptinSettings (store, params) {
        store.commit('SET_OPTIN_SETTINGS_LOADER_STATUS', true);

        axios.get(`/services/${params.id}/opt-in-settings`, { params }).then((resp) => {
            store.commit('SET_OPTIN_SETTINGS', resp.data.data);
            store.commit('SET_OPTIN_SETTINGS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_OPTIN_SETTINGS_LOADER_STATUS', false);
        });
    },

    updateOptinSettings (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_OPTIN_SETTINGS_UPDATE_LOADER_STATUS', true);

            axios.patch(`/opt-in-settings/${params.id}/update`, { setting_value: JSON.stringify(params.setting_value) }).then((resp) => {
                store.commit('SET_OPTIN_SETTINGS_UPDATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_OPTIN_SETTINGS_UPDATE_LOADER_STATUS', false);

                Toastr.handleOptinServerError(err, params.setFieldError, false, params.field)

                resolve(false);
            });
        })
    },
};

export default actions;
