const apps = [
    {
        name: 'AppsIndex',
        component: import(/* webpackChunkName: "js/apps" */ '@/pages/apps/Index'),
        path: '/apps',
        meta: {
            title: 'Apps',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default apps;