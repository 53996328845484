import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getPlans (store) {
        store.commit('SET_BILLING_PLANS_LOADER_STATUS', true);

        axios.get('/billing/plans').then((resp) => {
            store.commit('SET_BILLING_PLANS', resp.data);
            store.commit('SET_BILLING_PLANS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_BILLING_PLANS_LOADER_STATUS', false);
        });
    },

    getSubscription (store) {
        store.commit('SET_BILLING_SUBSCRIPTION_LOADER_STATUS', true);

        axios.get('/billing/subscription').then((resp) => {
            if (resp) {
                store.commit('SET_BILLING_SUBSCRIPTION', resp.data);
            }

            store.commit('SET_BILLING_SUBSCRIPTION_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_BILLING_SUBSCRIPTION_LOADER_STATUS', false);
        });
    },

    getBillingProfile (store) {
        store.commit('SET_BILLING_PROFILE_LOADER_STATUS', true);

        axios.get('/billing/profile').then((resp) => {
            store.commit('SET_BILLING_PROFILE', resp.data);
            store.commit('SET_BILLING_PROFILE_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_BILLING_PROFILE_LOADER_STATUS', false);
        });
    },

    updateBillingProfile (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_BILLING_PROFILE_SAVE_LOADER_STATUS', true);

            axios.patch(`/billing/profile/${params.id}/update`, params).then((resp) => {
                store.commit('SET_BILLING_PROFILE', resp.data);
                store.commit('SET_BILLING_PROFILE_SAVE_LOADER_STATUS', false);
                Toastr.success('Billing profile has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_BILLING_PROFILE_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateSubscriptionInfo (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/billing/profile/${params.id}/update-subscription`, params).then((resp) => {
                store.commit('SET_BILLING_PROFILE', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    generateTwoFACode (store, params) {
        store.commit('SET_TWOFA_CODE_LOADER_STATUS', true);

        axios.get(`/billing/generate-twofa-codes`, params).then((resp) => {
            store.commit('SET_TWOFA_CODE', resp.data);
            store.commit('SET_TWOFA_CODE_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_TWOFA_CODE_LOADER_STATUS', false);
        });
    },

    enableTwoFA (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/billing/enable-twofa', params).then((result) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    disableTwoFA (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/billing/disable-twofa', params).then((result) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    changeBillingEmail (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/billing/change-email', params).then((result) => {
                Toastr.success('Billing email has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    activateSuspendedAccount (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/billing/activate-suspended-account', params).then((result) => {
                Toastr.success('Account has been activated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getOldSubscriptions (store, params) {
        store.commit('SET_OLD_SUBSCRIPTION_LOADER_STATUS', true);

        axios.get('/billing/old-subscriptions', { params }).then((resp) => {
            store.commit('SET_OLD_SUBSCRIPTION', resp.data);
            store.commit('SET_OLD_SUBSCRIPTION_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_OLD_SUBSCRIPTION_LOADER_STATUS', false);
        });
    },

    getAllAssets (store) {
        store.commit('SET_ALL_ASSETS_LOADER_STATUS', true);

        axios.get('/billing/all-assets').then((resp) => {
            store.commit('SET_ALL_ASSETS', resp.data);
            store.commit('SET_ALL_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ALL_ASSETS_LOADER_STATUS', false);
        });
    },

    archiveUnarchiveAsset (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/billing/archive-unarchive-asset', params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    }
};

export default actions;