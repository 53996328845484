const state = {
    allHabitTrackings: [],
    habitTrackingLoader: false,
    contactHabitTrackingLoader: false,
    contactCurrentProgressLoader: false,
    contactHabitTrackings: [],
    habitCategories: [],
    contactCurrentProgress: {},
    habitTrackings: {},
    habitTrackingComponentLoader: false,
    activeContacts: {},
    deletedContacts: {},
    habitTrackingUpdateLoader: false,
    selectedHabitTracking: {},
    submittedProofs: {},
    leaderboard: {},
    habitTrackingLeaderboardLoader: false,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'order',
        order: 'desc',
    },
    habitTrackingDefaultSettings: {
        public_settings: {
            header_bgcolor: '#FFF',
            header_textcolor: '#000',
            headline: 'Time To Submit Your Habits',
            subheadline: '',
            overlay_color: '#2c3e50',
            overlay_opacity: 50,
            element_color: '#D4D4D4',
            element_text_color: '#2C3E50',
            submit_btn_text:  'Submit',
            submit_btn_textcolor: '#fff',
            submit_btn_bgcolor: '#2c3e50',
            thankyou_bgcolor: '#FFFFFF',
            thankyou_textcolor: '#2C3E50',
            footer_bgcolor: '#D4D4D4',
            footer_textcolor:'#2C3E50',
            cover_color:'#1976d2',
        },
        headline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 50,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.25)',
        },
        subheadline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 25,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.68)',
        },
    },
    refreshScoreLoader: false,
};

export default state;
