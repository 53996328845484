const page = [
    {
        name: 'PageIndex',
        component: () => import(/* webpackChunkName: "js/page/index" */ '@/pages/page/Index'),
        path: '/pages',
        meta: {
            title: 'Pages',
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
        },
    },
    {
        name: 'PageEdit',
        component: () => import(/* webpackChunkName: "js/page/edit" */ '@/pages/page/PageEdit'),
        path: '/page/:page/edit',
        meta: {
            title: 'Page Edit',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            group: 'Coaching',
            parent: {
                title: 'Pages',
                name: 'PageIndex',
            }
        },
    },
];

export default page;