const contact = [
    {
        name: 'ContactIndex',
        component: () => import(/* webpackChunkName: "js/contact" */ '@/pages/contact/Index'),
        path: '/contacts',
        meta: {
            title: 'Contacts',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'ContactProfile',
        component: () => import(/* webpackChunkName: "js/contact" */ '@/pages/contact/Profile'),
        path: '/contact/:id/profile',
        meta: {
            title: 'Profile',
            requiresAuth: true,
            accountActive: true,
            hasParent: true,
            parent: {
                title: 'Contacts',
                name: 'ContactIndex',
            }
        },
    },
    {
        name: 'SmsCenterIndex',
        component: () => import(/* webpackChunkName: "js/sms-center" */ '@/pages/sms-center/Index'),
        path: '/message-center',
        meta: {
            title: 'Message Center',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'SmsCenterIndexV2',
        component: () => import(/* webpackChunkName: "js/sms-center" */ '@/pages/sms-center/Index'),
        path: '/sms-center',
        meta: {
            title: 'Message Center',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default contact;