import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getClientPortal (store, loader = true) {
        return new Promise(function(resolve, reject) {
            if (loader) {
                store.commit('SET_CLIENT_PORTAL_LOADER_STATUS', true);
            }

            axios.get('/client-portal').then((resp) => {
                store.commit('SET_CLIENT_PORTAL', resp.data);
                store.commit('SET_CLIENT_PORTAL_LOADER_STATUS', false);
                store.commit('SET_LAST_REFRESHED_AT');
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_CLIENT_PORTAL_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    updatePortalSetting (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_LOADER_STATUS', true);

            axios.post('/client-portal/update-settings', params).then((resp) => {
                store.commit('SET_CLIENT_PORTAL', resp.data);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Client portal settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getPlaybooks (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/playbooks', { params }).then((resp) => {
            store.commit('SET_PLAYBOOKS', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getPinnedPlaybooks (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/pinned-playbooks').then((resp) => {
            store.commit('SET_PINNED_PLAYBOOKS', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getPlaybookCategories (store, params) {
        // store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/playbook-categories', { params }).then((resp) => {
            store.commit('SET_PLAYBOOK_CATEGORIES', resp.data);
            // store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            // store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getPages (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/pages', { params }).then((resp) => {
            store.commit('SET_PAGES', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getWorkouts (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/workouts', { params }).then((resp) => {
            store.commit('SET_WORKOUTS', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getWorkoutPlans (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/workout-plans', { params }).then((resp) => {
            store.commit('SET_WORKOUT_PLANS', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getForms (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/forms', { params }).then((resp) => {
            store.commit('SET_FORMS', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    getCalendarEvents (store, params) {
        store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', true);

        axios.get('/calendar/events', { params }).then((resp) => {
            store.commit('SET_CALENDAR_EVENTS', resp.data);
            store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', false);
        });
    },

    getCalendarEventsByDate (store, params) {
        store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', true);

        axios.get('/calendar/events-by-date', { params }).then((resp) => {
            store.commit('SET_CALENDAR_EVENTS_BY_DATE', resp.data);
            store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_CALENDAR_EVENTS_LOADER_STATUS', false);
        });
    },

    fetchFiles (store, params) {
        store.commit('SET_ASSETS_LOADER_STATUS', true);

        axios.get('/client-portal/files', { params }).then((resp) => {
            store.commit('SET_FILES', resp.data);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ASSETS_LOADER_STATUS', false);
        });
    },

    renameTab (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_TAB_RENAME_LOADER_STATUS', { field: params.field, status: true });

            axios.post('/client-portal/rename-tab', params).then((resp) => {
                store.commit('SET_CLIENT_PORTAL', resp.data);
                Toastr.success('Client portal tab name has been updated successfully!');
                store.commit('SET_TAB_RENAME_LOADER_STATUS', { field: params.field, status: false });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_TAB_RENAME_LOADER_STATUS', { field: params.field, status: false });
                resolve(false);
            });
        });
    },

    updateCalendarTitle (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_CALENDAR_TITLE_LOADER_STATUS', { field: params.field, status: true });

            axios.post('/client-portal/update-calendar-title', params).then((resp) => {
                store.commit('SET_CLIENT_PORTAL', resp.data);
                Toastr.success('Calendar drawer title has been updated successfully!');
                store.commit('SET_CALENDAR_TITLE_LOADER_STATUS', { field: params.field, status: false });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_CALENDAR_TITLE_LOADER_STATUS', { field: params.field, status: false });
                resolve(false);
            });
        });
    },

    updateWelcomeSetting (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_LOADER_STATUS', true);

            axios.post('/client-portal/update-welcome-settings', params).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('SET_UPDATE_LOADER_STATUS', false);
                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Client portal settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    addAnnouncement (store, portalId) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ANNOUNCEMENT_SETTINGS_ADD_LOADER_STATUS', true);

            axios.post(`/client-portal/${portalId}/add-new-announcement`).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('SET_ANNOUNCEMENT_SETTINGS_ADD_LOADER_STATUS', false);
                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('New announcement has been added successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ANNOUNCEMENT_SETTINGS_ADD_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    updateAnnouncementSetting (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ANNOUNCEMENT_SETTINGS_UPDATE_LOADER_STATUS', true);

            axios.post('/client-portal/update-announcement-settings', params).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('SET_ANNOUNCEMENT_SETTINGS_UPDATE_LOADER_STATUS', false);
                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Client portal settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ANNOUNCEMENT_SETTINGS_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteAnnouncementSetting (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ANNOUNCEMENT_SETTINGS_DELETE_LOADER_STATUS', true);

            axios.delete(`/client-portal/delete-announcement-settings/${params.id}`).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('SET_ANNOUNCEMENT_SETTINGS_DELETE_LOADER_STATUS', false);
                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Announcement has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_ANNOUNCEMENT_SETTINGS_DELETE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortAnnouncementSetting (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/client-portal/sort-announcement-settings`, params).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Announcement has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveNavigationMenu (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/client-portal/update-navigation-menu`, params).then((resp) => {
                store.dispatch('getClientPortal', false);

                store.commit('RESET_LAST_REFRESHED_AT');
                Toastr.success('Menu has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
