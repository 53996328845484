import store from '@/store'

const mutations = {
    SET_ALL_FORMS (state, pages) {
        state.allForms = pages;
    },

    SET_FORM_LOADER_STATUS (state, value) {
        state.formLoader = value;
    },

    SET_CONTACT_FORMS (state, forms) {
        state.contactForms = forms;
    },

    SET_FORMS (state, forms) {
        state.forms = forms;
    },

    SET_FORM_CREATE_LOADER_STATUS (state, status) {
        state.formCreateLoader = status;
    },

    SET_FORM_DETAIL_LOADER_STATUS (state, status) {
        state.formDetailLoader = status;
    },

    SET_SELECTED_FORM (state, form) {
        state.selectedForm = form;
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'form_name', value: form.survey_name }, { root: true });
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'form_url', value: form.url }, { root: true });
        store.commit('commonModule/ADD_VARIABLE_TO_RESERVED_WORDS', { name: 'form_url_button', value: form.url }, { root: true });
    },

    SET_FORM_COMPONENT_LOADER_STATUS (state, value) {
        state.formComponentLoader = value;
    },

    SET_ANALYTICS (state, value) {
        state.analytics = value;
    },

    SET_FORM_RESPONDENTS (state, value) {
        state.formRespondents = value;
    },

    SET_FORM_UPDATE_LOADER_STATUS (state, status) {
        state.formUpdateLoader = status;
    },

    SET_ASSET_LIST (state, assets) {
        state.assetList = assets;
    },

    SET_QUESTIONS (state, questions) {
        state.questions = questions;
    },

    SET_FORM_QUESTION_LOADER_STATUS (state, status) {
        state.questionLoader = status;
    },

    SET_FORM_QUESTION_SAVE_LOADER_STATUS (state, status) {
        state.questionSaveLoader = status;
    },

    SET_FORM_QUESTION_DULPICATE_LOADER_STATUS (state, status) {
        state.questionDuplicateLoader = status;
    },

    SET_DOWNLOAD_ANALYTICS_LOADER_STATUS (state, status) {
        state.downloadAnalyticsLoader = status;
    },

    SET_DOWNLOAD_ANALYTICS_CSV_LOADER_STATUS (state, status) {
        state.downloadAnalyticsCsvLoader = status;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                          per_page: 6,
                          page: 1,
                          search: '',
                          order_by: 'ordering',
                          order: 'desc',
                          folder_id: null,
                      };
    },
};

export default mutations;