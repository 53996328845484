const mutations = {
    SET_LIBRARY_IMAGES (state, images) {
        state.libraryImages = images;
    },

    SET_PIXEL_IMAGES (state, images) {
        state.pexelsImages = images;
    },

    SET_LIBRARY_IMAGE_LOADER_STATUS (state, status) {
        state.imageLibraryLoader = status;
    },

    SET_IMAGE_UPLOAD_LOADER_STATUS (state, status) {
        state.imageUploadLoader = status;
    },

    RESET_IMAGE_LIBRARY (state, status) {
        state.libraryImages = [];
        state.pexelsImages  = [];
    },

    SET_IMAGE_TYPES (state, types) {
        state.imageTypes = types;
    },

    SET_IMAGE_BLOB_LOADER_STATUS (state, status) {
        state.imageBlobLoader = status;
    },
};

export default mutations;