const checkout = [
    {
        name: 'CheckoutIndex',
        component: () => import(/* webpackChunkName: "js/checkout/index" */ '@/pages/checkout/Index'),
        path: '/checkout',
        meta: {
            title: 'Checkout',
            requiresAuth: true,
            accountActive: true,
        },
    },
    {
        name: 'CheckoutClients',
        component: () => import(/* webpackChunkName: "js/checkout/clients" */ '@/pages/checkout/Clients'),
        path: '/checkout/clients',
        meta: {
            title: 'Clients',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            parent: {
                title: 'Checkout',
                name: 'CheckoutIndex',
            }
        },
    },
    {
        name: 'CheckoutProducts',
        component: () => import(/* webpackChunkName: "js/checkout/products" */ '@/pages/checkout/Products'),
        path: '/checkout/products',
        meta: {
            title: 'Products',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            parent: {
                title: 'Checkout',
                name: 'CheckoutIndex',
            }
        },
    },
    {
        name: 'CheckoutOrders',
        component: () => import(/* webpackChunkName: "js/checkout/orders" */ '@/pages/checkout/Orders'),
        path: '/checkout/orders',
        meta: {
            title: 'Orders',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            parent: {
                title: 'Checkout',
                name: 'CheckoutIndex',
            }
        },
    },
    {
        name: 'CheckoutSubscriptions',
        component: () => import(/* webpackChunkName: "js/checkout/subscriptions" */ '@/pages/checkout/Subscriptions'),
        path: '/checkout/subscriptions',
        meta: {
            title: 'Subscriptions',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            parent: {
                title: 'Checkout',
                name: 'CheckoutIndex',
            }
        },
    },
    {
        name: 'CheckoutLogs',
        component: () => import(/* webpackChunkName: "js/checkout/logs" */ '@/pages/checkout/Logs'),
        path: '/checkout/logs',
        meta: {
            title: 'Logs',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            parent: {
                title: 'Checkout',
                name: 'CheckoutIndex',
            }
        },
    },
];

export default checkout;
