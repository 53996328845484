import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'

import VueClipboard from 'vue3-clipboard'
import Datepicker from '@vuepic/vue-datepicker'
import VueEasyLightbox from 'vue-easy-lightbox'
import VCalendar from 'v-calendar'
import Pagination from '@hennge/vue3-pagination'
import GoogleLogin from 'vue3-google-login'
import VueCookies from 'vue3-cookies'
import Multiselect from '@vueform/multiselect'
import Toastr from '@/utils/Toastr'

const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
const ConditionComponent = defineAsyncComponent(() => import('@/pages/journey/components/Condition'))
const QuoteLoader = defineAsyncComponent(() => import('@/components/QuoteLoader'))
const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
const Redactor = defineAsyncComponent(() => import('@/components/Redactor'))
const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))
const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))

import '@vueform/multiselect/themes/default.css'
import '@/assets/style/global.css'
import '@/assets/style/component.css'
import '@/assets/style/pages.css'
import '@/assets/style/media.css'
import '@/assets/style/newsletter.css'
import '@/assets/style/checkout.css'
import '@/assets/style/tooltip.css'
import '@/assets/css/app.css'
import 'toastr/build/toastr.css'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import 'vue-color-kit/dist/vue-color-kit.css'
import 'vue3-emoji-picker/dist/style.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'v3-infinite-loading/lib/style.css'
import 'v-calendar/dist/style.css'

import clickOutside from '@/directives/click-outside'
import tooltip from '@/directives/tooltip'

import store from '@/store'
import router from '@/routes'

const app = createApp(App);
      app.use(router);
      app.use(VueClipboard, {
          autoSetContainer: true,
          appendToBody: true,
      })
      app.component('Datepicker', Datepicker);
      app.component('InnerSidebar', InnerSidebar);
      app.use(store);
      app.directive('clickOutside', clickOutside);
      app.directive('tooltip', tooltip);
      app.use(VCalendar);
      app.use(VueEasyLightbox);
      app.use(GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID });
      app.component('condition-component', ConditionComponent);
      app.component('quote-loader', QuoteLoader);
      app.component('pagination', Pagination);
      app.component('page-filter', PageFilter);
      app.component('image-library', ImageLibrary);
      app.component('redactor', Redactor);
      app.component('color-picker', ColorPicker);
      app.component('sending-method', SendingMethod);
      app.component('email-component', EmailComponent);
      app.component('sms-component', SmsComponent);
      app.component('line-loader', LineLoader);
      app.component('multiselect', Multiselect);
      app.component('video-tutorial', VideoTutorial);
      app.use(VueCookies, { expireTimes: '15d', secure: true });
      app.mixin({
          methods: {
              handleFormInvalidSubmit (form) {
                  if (form && Object.keys(form.errors).length) {
                      Toastr.error(Object.values(form.errors)[0]);
                  }

                  const elements = document.getElementsByClassName('has-error');

                  if (elements.length) {
                      elements[0].scrollIntoView(true);
                  }
              },

              toggleGistChatBox () {
                  const element = document.getElementById('gist-app');

                  if (element) {
                      if (element.style.display == 'none') {
                          element.style.display = 'block';
                      } else {
                          element.style.display = 'none';
                      }
                  }
              },

              toggleScrollBar (value) {
                  if (value) {
                      document.body.classList.add('modal-open');
                   } else {
                      document.body.classList.remove('modal-open');
                  }
              },

              checkIfParentHasClass (element, className) {
                  let el = element;
                  let result = false;

                  for (let i = 0; i < 10; i++) {
                      if (el && el.classList && Array.from(el.classList).includes(className)) {
                          result = true;

                          break;
                      } else if (el) {
                          el = el.parentNode;
                      }
                  }

                  return result;
              },

              enforceMinMax (el) {
                  if (el && el.target && el.target.value != "") {
                      if (parseInt(el.target.value) < parseInt(el.target.min)) {
                          el.target.value = el.target.min;
                      }

                      if (parseInt(el.target.value) > parseInt(el.target.max)) {
                          el.target.value = el.target.max;
                      }
                  }
              },
            }
      });
      app.mount("#app");

      const favicon = document.getElementById('favicon');
      favicon.href = process.env.VUE_APP_NAME ? require('@/assets/images/thrivecoach/logo.svg') : 'favicon.ico';
