<template>
    <section class="leftsidebar-main-sec">
        <!-- <button class="close_btn" @click="toggleSidebar"><img src="@/assets/images/bar.svg"></button> -->
        <div class="leftsidebar-links-area">
            <nav>
                <div class="logo_wpr">
                    <router-link to="/contacts"><img :src="env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png')"></router-link>
                </div>
                <ul>
                    <li @click="closeToolsMarketplace()">
                        <router-link :to="{ name: 'HomeIndex' }">
                            <img src="@/assets/images/home.svg">
                        </router-link>
                    </li>
                    <li @click="closeToolsMarketplace()" v-tooltip="`Open your contact center`" position="bottom-right">
                        <router-link :to="{ name: 'ContactIndex' }">
                            <img src="@/assets/images/contacts.svg">
                        </router-link>
                    </li>
                    <li @click="closeToolsMarketplace()" v-tooltip="`Open Thrive Checkouts and view your financials`" position="bottom-right">
                        <router-link :to="{ name: 'CheckoutIndex' }" :class="{'router-link-active router-link-exact-active': $route.meta && $route.meta.parent && $route.meta.parent.name === 'CheckoutIndex'}">
                            <img src="@/assets/images/cart2.svg">
                        </router-link>
                    </li>
                </ul>
            </nav>
            <inner-sidebar />
            <div class="area_title">
                <h4>Tooltips</h4>
                <label for="has-tooltip" class="switch_option capsule_btn p-0">
                    <input type="checkbox" name="has_tooltip" v-model="hasTooltip" :true-value="1" :false-value="0" id="has-tooltip" @change="handleTooltipChange" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div class="leftsidebar-other-link">
                <ul>
                    <!-- <li>
                        <a>
                            <img src="@/assets/images/dashboard.svg">
                            <span>Dashboards</span>
                        </a>
                    </li> -->
                    <li>
                        <router-link :to="{ name: 'AdminSettings' }">
                            <img src="@/assets/images/gear.svg">
                            <span>Settings</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <span @click="toggleSidebar" id="toggle_sidebar"><i class="fas fa-chevron-right"></i></span>
	</section>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'SideBar',

        data () {
            return {
                env: {},
                hasTooltip: 1,
                isSidebarExpanded: localStorage.getItem('sidebar_expanded') ? parseInt(localStorage.getItem('sidebar_expanded')) : 0,
            };
        },

        props:{
            toggleTools: Function,
            closeToolsMarketplace: Function,
        },

        computed: mapState({
            user: state => state.authModule.user,
            lastToolRouteName: state => state.commonModule.lastToolRouteName,
        }),

        watch:{
            hasTooltip (val) {
                const vm = this;

                vm.$emit('tooltip-status', val);
            },

            user (user) {
                const vm = this;

                setTimeout(function () {
                    if (user.id) {
                        vm.hasTooltip = user.has_tooltip;
                    }
                }, 100)
            }
        },

        mounted () {
            const vm = this;

            vm.env = process.env;
            vm.$emit('tooltip-status', vm.hasTooltip);

            if (vm.isSidebarExpanded) {
                const sidebar = document.querySelector('.leftsidebar-main-sec').closest('#app');

                if (sidebar) {
                    sidebar.classList.add('expanded');
                }
            }
        },

        methods:{
            ...mapActions({
                updateContactPreferences: 'authModule/updateContactPreferences',
            }),

            toggleSidebar () {
                const sidebar = document.querySelector('.leftsidebar-main-sec').closest('#app');

                if (sidebar.classList.contains('expanded')) {
                    sidebar.classList.remove('expanded');

                    localStorage.setItem('sidebar_expanded', 0);
                } else {
                    sidebar.classList.add('expanded');

                    localStorage.setItem('sidebar_expanded', 1);
                }
            },

            handleTooltipChange (e) {
                const vm = this;

                vm.updateContactPreferences({ id: vm.user.id, has_tooltip: e.target._modelValue });
            }
        }
    }
</script>

<style scoped>
    .logo_wpr img {
        width: 30px;
        transition: all 0.3s ease-in-out;
    }
    #app.expanded .logo_wpr img{
        width: 50px;
    }
    :deep(.sidebar .nav_list){
        border: 0;
        padding: 10px 0;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu){
        position: absolute;
        left: 100%;
        top: 0;
        width: 160px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 20px rgb(0,0,0,0.15);
        margin-top: -10px;
        margin-left: 15px;
        z-index: 1;
        display: none;
        opacity: 0;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu ul){
        flex-direction: column;
        align-items: flex-start;
        max-height: 300px;
        overflow-y: auto;
        padding: 10px 0;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu ul::-webkit-scrollbar){
        width: 4px;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu ul::-webkit-scrollbar-thumb){
        background: #dbdbdb;
        border-radius: 2px;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu.sm ul){
        max-height: 200px;
    }
    :deep(.sidebar .nav_list .menu_wpr .extend_menu:before){
        content: '';
        position: absolute;
        left: -6px;
        top: 23px;
        width: 10px;
        height: 10px;
        border-left: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        background: #fff;
        transform: rotate(45deg);
    }
    :deep(.sidebar .nav_list .menu_wpr.show .extend_menu){
        animation: dropMove 0.5s ease-in-out;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: block;
        opacity: 1;
    }
    @keyframes dropMove {
        from {margin-top: 0; opacity: 0;}
        to {margin-top: -10px; opacity: 1;}
    }
    :deep(.sidebar ul li){
        margin: 0;
    }
    :deep(.sidebar ul li a){
        width: auto;
        height: auto;
        border: 0;
        padding: 9px 15px;
        background: transparent;
    }
    :deep(.sidebar h5){
        font-size: 13px;
        line-height: 16px;
        background: transparent !important;
    }
    /* :deep(#toggle_sidebar){
        display: none !important;
    } */
    :deep(* [data-v-tooltip]:after){
        max-width: 150px;
    }
    .leftsidebar-links-area .switch_option{
        justify-content: center;
    }
    .leftsidebar-links-area .switch_option h5{
        display: none;
    }
    #app.expanded .leftsidebar-links-area .switch_option{
        justify-content: space-between;
    }
    #app.expanded .leftsidebar-links-area .switch_option h5{
        display: block;
    }
    .leftsidebar-other-link ul{
        padding: 15px 0;
        flex-direction: column;
        position: relative;
    }
    /* .leftsidebar-other-link ul::after{
        content: '';
        position: absolute;
        left: -20px;
        right: -20px;
        top: 0;
        border-top: 1px solid #e9e9e9;
    } */
    #app.expanded .leftsidebar-other-link ul{
        flex-direction: column;
    }
    .leftsidebar-other-link ul li{
        padding: 7px 0;
    }
    #app.expanded .leftsidebar-other-link ul li{
        padding: 4px 0;
    }
    .leftsidebar-other-link ul li a{
        height: 30px;
        width: 30px;
        text-decoration: none;
        padding: 0;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
    }
    #app.expanded .leftsidebar-other-link ul li a{
        border: 1px solid transparent;
        height: 36px;
        width: 130px;
        justify-content: flex-start;
        cursor: pointer;
    }
    .leftsidebar-other-link ul li a span{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #2f7eed;
        margin-left: 10px;
        overflow: hidden;
        display: none;
    }
    #app.expanded .leftsidebar-other-link ul li a span{
        display: block;
    }
    .leftsidebar-other-link ul li a.router-link-active{
        background: #e1eeff;
        border-color: #e1eeff;
    }
    #app.expanded .leftsidebar-other-link ul li a.router-link-active{
        background: transparent;
        border-color: transparent;
    }
    #toggle_sidebar {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #e9e9e9;
        font-size: 10px;
        color: #5a5a5a;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -11px;
        top: 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        z-index: 12;
    }

    #app.expanded #toggle_sidebar {
        transform: rotate(-180deg);
    }
    @media(max-width: 991px){
        #app.expanded .logo_wpr img{
            width: 30px;
        }
        :deep(.sidebar .nav_list .menu_wpr .extend_menu){
            width: auto;
            position: static;
            box-shadow: none;
            background: transparent;
            /* margin-left: 0; */
        }
        :deep(.sidebar .nav_list .menu_wpr .extend_menu ul),
        :deep(.sidebar .nav_list .menu_wpr .extend_menu.sm ul){
            max-height: none;
        }
        :deep(.sidebar .nav_list .menu_wpr .extend_menu:before){
            display: none;
        }
        :deep(.sidebar .nav_list .menu_wpr.show h5 i){
            transform: rotate(90deg);
        }
        #toggle_sidebar {
            left: calc(100% - 25px);
        }
        #app.expanded #toggle_sidebar {
            left: calc(100% - 13px);
        }
        /* :deep(.sidebar ul li a){
            padding: 0 3px;
        } */
    }
</style>
