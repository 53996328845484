const mutations = {
    SET_BILLING_PLANS (state, plans) {
        state.plans = plans;
    },

    SET_BILLING_SUBSCRIPTION (state, subscription) {
        state.subscription = subscription;
    },

    SET_BILLING_PLANS_LOADER_STATUS (state, status) {
        state.plansLoader = status;
    },

    SET_BILLING_SUBSCRIPTION_LOADER_STATUS (state, status) {
        state.subscriptionLoader = status;
    },

    SET_BILLING_PROFILE (state, profile) {
        state.billingProfile = profile;
    },

    SET_BILLING_PROFILE_LOADER_STATUS (state, status) {
        state.billingProfileLoader = status;
    },

    SET_BILLING_PROFILE_SAVE_LOADER_STATUS (state, status) {
        state.billingProfileSaveLoader = status;
    },

    SET_TWOFA_CODE_LOADER_STATUS (state, status) {
        state.twoFACodeLoader = status;
    },

    SET_OLD_SUBSCRIPTION_LOADER_STATUS (state, status) {
        state.oldSubscriptionLoader = status;
    },

    SET_TWOFA_CODE (state, codes) {
        state.twoFACode = codes;
    },

    SET_OLD_SUBSCRIPTION (state, subscription) {
        state.oldSubscription = subscription;
    },

    SET_ALL_ASSETS_LOADER_STATUS (state, status) {
        state.allAssetsLoader = status;
    },

    SET_ALL_ASSETS (state, assets) {
        state.allAssets = assets;
    },
};

export default mutations;