const getters = {
    FILTER_CATEGORY_TYPE: (state) => (type) => {
        let categoryType = [];

        if (type == 'all') {
            return state.categories;
        } else if (type == 'email') {
            categoryType = [1];
        } else if (type == 'sms') {
            categoryType = [3];
        } else if (type == 'voice') {
            categoryType = [4];
        } else if (type == 'content') {
            categoryType = [2];
        } else if (type == 'multi_channel' || type == 'multi') {
            categoryType = [1, 3];
        }

        let categoryList = [];

        state.categories.forEach((category) => {
            if (category.is_content && categoryType.includes(category.is_content)) {
                categoryList.push(category);
            }
        });

        return categoryList;
    }

};

export default getters;
