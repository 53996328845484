import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    storeMemberIdSetting (store, params) {
        store.commit('SET_MEMBER_ID_LOADER_STATUS', true);

        axios.post('/member-id/store-member-id-setting', params).then((resp) => {
            store.commit('SET_MEMBER_ID_LOADER_STATUS', false);
            store.dispatch('getMemberIdSetting');
            Toastr.success('Member ID Dasboard settings was successfully updated.');
        }).catch((err) => {
            store.commit('SET_MEMBER_ID_LOADER_STATUS', false);
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    getMemberIdSetting (store, params) {
        axios.get('/member-id/get-member-id-setting', { params }).then((resp) => {
            store.commit('SET_MEMBER_ID_SETTING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getMemberSettingPublic (store, params) {
        axios.get(`/member-id/${params.id}/public-setting`, { params }).then((resp) => {
            store.commit('SET_PUBLIC_MEMBER_SETTING', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    sendMemberId (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/member-id/${params.id}/send`, params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },
};

export default actions;