const state = {
    videos: [],
    questions: [],
    progress: { categories: [], answers: [], score: 0 },
    currentProgress: { categories: [], answers: [], score: 0 },
    histories: [],
    progressLoader: false,
};

export default state;
