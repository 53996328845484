const state = {
    authToken: localStorage.hasOwnProperty('authToken') && isJson(localStorage.getItem('authToken')) ? JSON.parse(localStorage.getItem('authToken')) : {},
    user: {},
    membership: localStorage.hasOwnProperty('membership') && isJson(localStorage.getItem('membership'))? JSON.parse(localStorage.getItem('membership')) : {},
    companyUserPermissions: [],
    companyLocations: [],
    accessed: false,
    fileStorage: {},
    integrations: {},
    forgotPasswordMessage: '',
    formLoader: false,
    isUserLoggedIn: localStorage.getItem('isUserLoggedIn'),
    organizationLocations: [],
    locationTabs: [],
    organizationUsers: [],
    organizationSuperUsers: [],
    userLoader: false,
    locationLoader: false,
    locationSaveLoader: false,
    whiteLabelLoader: false,
    permissions: [],
    userSaveLoader: false,
    fitFunnelWebhooks: [],
    customEmailDomains: {},
    emailDomainLoader: false,
    domainDNSDetails: {},
    domainDNSLoader: false,
    customEmailDomainSenderLoader: false,
    customEmailDomainSenders: {},
    organizationUserLoader: false,
    is2FAEnabled: false,
    is2FAVerified: false,
    isAccountAcitve: true,
    isAccountSuspended: false,
    contactProfileSetting: {},
    whiteLabel: {},
    loggedOutAt: 0,
    developerLoginLoader: false,
    isAccountHibernated: false,
    autoLoginLoader: false,
    hasTrialPeriod: false,
};

function isJson (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export default state;
