const mutations = {
    SET_SIGNATURES (state, signatures) {
        state.signatures = signatures;
    },

    SET_DEFAULT_SIGNATURE (state, defaultSignature) {
        state.defaultSignature = defaultSignature;
    },

    SET_APPLY_TO_ALL (state, applyToAll) {
        state.applyToAll = applyToAll;
    },

    SET_ALL_SIGNATURES (state, signatures) {
        state.allSignatures = signatures;
    },

    SET_SIGNATURE_LOADER_STATUS (state, value) {
        state.signatureLoader = value;
    }
};

export default mutations;