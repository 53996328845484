const tag = [
    {
        name: 'TagIndex',
        component: () => import(/* webpackChunkName: "js/tag" */ '@/pages/tag/Index'),
        path: '/tags',
        meta: {
            title: 'Tags',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
];

export default tag;