import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllPipelines (store, params) {
        axios.get('/pipeline/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_PIPELINES', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    getPipelineStages (store, id) {
        axios.get(`/pipeline/${id}/stages`).then((resp) => {
            store.commit('SET_PIPELINE_STAGES', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
        });
    },

    addContact (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('pipeline/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                Toastr.success('Contacts have been successfully added to pipelines!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    removeContact (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('pipeline/remove-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                Toastr.success('Contacts have been successfully removed from pipelines!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },
};

export default actions;