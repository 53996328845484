const mutations = {
    SET_FOLDERS (state, folders) {
        state.folders = folders;
    },

    SET_FOLDER_LOADER_STATUS (state, folder) {
        state.folderLoader = status
    },

    SET_FOLDER_UPDATE_LOADER_STATUS (state, folder) {
        state.folderUpdateLoader = status
    },

    SET_ADD_ASSET_LOADER_STATUS (state, status) {
        state.addAssetLoader = status
    },

    SET_MAIN_FOLDERS (state, folders) {
        state.mainFolders = folders;
    },

    SET_MAIN_FOLDER_LOADER_STATUS (state, status) {
        state.mainFolderLoader = status;
    },

    SET_IS_ARCHIVED_STATUS (state, value) {
        localStorage.setItem('isArchivedFolder', value);

        state.isArchived = value;
    },

    SET_LAST_FOLDER_BREADCRUMB (state, breadcrumb) {
        localStorage.setItem('lastFolderBreadcrumb', JSON.stringify(breadcrumb));

        state.lastFolderBreadcrumb = breadcrumb;
    },

    SET_IS_ASSET_FOLDER_STATUS (state, status) {
        localStorage.setItem('isAssetFolder', status);

        state.isAssetFolder = status;
    },

    SET_IS_PREVIOUS_FOLDER_STATUS (state, status) {
        localStorage.setItem('isPreviousFolder', status);

        state.isPreviousFolder = status;
    },

    SET_FOLDER_ID (state, id) {
        localStorage.setItem('folderId', (id ? id : null));

        state.folderId = id;
    },

    SET_FOLDER_BREADCRUMB (state, obj) {
        state.folderBreadcrumb[obj.key] =  obj.value;
    },

    SET_IS_REFRESH_STATUS (state, status) {
        state.isRefresh = status;
    },
};

export default mutations;
