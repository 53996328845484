import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'
import Helper from '@/utils/Helper'

const checkoutApi = process.env.VUE_APP_CHECKOUT_API_URL+'/api'

const actions = {
    createCheckoutToken (store, params) {
        axios.post(`${checkoutApi}/user/create-token`, params).then((resp) => {
            store.commit('SET_CHECKOUT_AUTH_TOKEN', resp.data.token);
        }).catch((err) => {
            console.log(err);
        });
    },

    getUserLocations (store) {
        axios.get(`${checkoutApi}/user/locations`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_USER_LOCATIONS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getTagGroupByLocation (store) {
        axios.get(`${checkoutApi}/tag/group-by-location`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_LOCATION_TAGS_GROUP', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getOrderBumps (store) {
        axios.get(`${checkoutApi}/bump/list`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ORDER_BUMPS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    deleteBump (store, id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${checkoutApi}/bump/${id}/delete`, {
                params: {},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Bump has been deleted successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    updateBump (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_BUMP_UPDATE_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/bump/${params.id}/update`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_BUMP_UPDATE_LOADER_STATUS', false);

                Toastr.success('Bump has been updated successfully!');

                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_BUMP_UPDATE_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    getCoupons (store, product) {
        store.commit('SET_COUPON_LIST_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/product/${product}/coupons`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_COUPONS', resp.data);
            store.commit('SET_COUPON_LIST_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_COUPON_LIST_LOADER_STATUS', false);
        });
    },

    createCoupon (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_COUPON_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/stripe/create-coupon`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_COUPON_LOADER_STATUS', false);

                Toastr.success('Coupon has been created successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_COUPON_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    deleteCoupon (store, id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${checkoutApi}/stripe/${id}/delete-coupon`, {
                params: {},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Coupon has been deleted successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    getProcessors (store) {
        axios.get(`${checkoutApi}/stripe/processors`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_PROCESSORS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    createProcessor (store) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PROCESSOR_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/stripe/create-processor`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PROCESSOR_LOADER_STATUS', false);
                store.dispatch('getProcessors');

                Toastr.success('Processor has been created successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PROCESSOR_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    deleteProcessor (store, id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${checkoutApi}/stripe/${id}/delete-processor`, {
                params: {},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.dispatch('getProcessors');

                Toastr.success('Processor has been deleted successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    getDefaultTemplates (store) {
        axios.get(`${checkoutApi}/product/default-templates`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_DEFAULT_TEMPLATES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getProduct (store, product) {
        return new Promise(function(resolve, reject) {
            axios.get(`${checkoutApi}/product/${product}/details`, {
                params: {},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    createProduct (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/product/create`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', false);

                Toastr.success('Product has been created successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getProducts (store, params) {
        store.commit('SET_PRODUCT_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/product/list`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_PRODUCT_LOADER_STATUS', false);
            store.commit('SET_PRODUCTS', resp.data);
        }).catch((err) => {
            store.commit('SET_PRODUCT_LOADER_STATUS', false);

            Toastr.handleServerError(err);
        });
    },

    updateProduct (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/product/${params.id}/update`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', false);

                Toastr.success('Product has been updated successfully!');

                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', false);

                if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
                    if (params.setFieldError) {
                        const errors = err.response.data.errors;
                        const vm     = this;

                        Object.keys(errors).map(field => {
                            let errorString = errors[field].join(', ');

                            if (field == 'bump.name') {
                                params.setFieldError('product_bump_name', errorString.replace('bump.name', 'product name'));
                            } else {
                                params.setFieldError(field, errorString);
                            }
                        });

                        const elements = document.getElementsByClassName('has-error');

                        if (elements.length) {
                            elements[0].scrollIntoView(true);
                        }

                        Toastr.error('Unable to submit form, Please correct all the errors!');
                    }
                }

                resolve(false);
            });
        });
    },

    saveProductTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/product/${params.product_id}/update-template`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', false);

                Toastr.success('Template has been updated successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PRODUCT_UPDATE_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    getClients (store, params) {
        store.commit('SET_CLIENT_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/client/list`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_CLIENTS', resp.data);
            store.commit('SET_CLIENT_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_CLIENT_LOADER_STATUS', false);
        });
    },

    deleteProduct (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`${checkoutApi}/product/delete`, {
                params,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                if (resp.data.message) {
                    Toastr.success(resp.data.message);
                }

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    duplicateProduct (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`${checkoutApi}/product/duplicate`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Product has been duplicated successfully!');

                resolve(true);
            }).catch((err) => {
                Helper.handleDuplicateError(err);

                resolve(false);
            });
        });
    },

    getCompanySettings (store) {
        store.commit('SET_COMPANY_SETTING_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/setting/company-settings`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_COMPANY_SETTING_LOADER_STATUS', false);
            store.commit('SET_COMPANY_SETTINGS', resp.data);
        }).catch((err) => {
            store.commit('SET_COMPANY_SETTING_LOADER_STATUS', false);

            Toastr.handleServerError(err);
        });
    },

    updateCompanySettings (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/setting/update-company-settings`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', false);
                store.dispatch('getCompanySettings');

                Toastr.success('Company settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateCompanyCurrencySettings (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/setting/update-company-currency-settings`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', false);
                store.dispatch('getCompanySettings');

                Toastr.success('Company currency settings has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_UPDATE_COMPANY_SETTING_LOADER_STATUS', false);

                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getEmailSettings (store, params) {
        store.commit('SET_EMAIL_SETTING_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/setting/email-settings`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_EMAIL_SETTING_LOADER_STATUS', false);

            if (params.type == 1) {
                store.commit('SET_ABANDONED_EMAIL_SETTINGS', resp.data);
            } else if (params.type == 2) {
                store.commit('SET_TRANSACTION_EMAIL_SETTINGS', resp.data);
            } else if (params.type == 3) {
                store.commit('SET_DUNNING_EMAIL_SETTINGS', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_EMAIL_SETTING_LOADER_STATUS', false);

            Toastr.handleServerError(err);
        });
    },

    updateEmailSettings (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_UPDATE_EMAIL_SETTING_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/setting/update-email-settings`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_UPDATE_EMAIL_SETTING_LOADER_STATUS', false);

                Toastr.success('Email settings has been updated successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_UPDATE_EMAIL_SETTING_LOADER_STATUS', false);

                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    updateClientProfile (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_UPDATE_CLIENT_PROFILE_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/client/${params.id}/update-profile`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_UPDATE_CLIENT_PROFILE_LOADER_STATUS', false);
                store.commit('SET_CLIENT_WATCHER');
                Toastr.success('Profile has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_UPDATE_CLIENT_PROFILE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getSinglePurchaseProducts (store, params) {
        store.commit('SET_SINGLE_PURCHASE_PRODUCTS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/client/single-purchase-products`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_SINGLE_PURCHASE_PRODUCTS', resp.data);
            store.commit('SET_SINGLE_PURCHASE_PRODUCTS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_SINGLE_PURCHASE_PRODUCTS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getOrderTransactions (store, params) {
        store.commit('SET_ORDER_TRANSACTION_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/transactions`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ORDER_TRANSACTION', resp.data);
            store.commit('SET_ORDER_TRANSACTION_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ORDER_TRANSACTION_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getClientAnalytics (store, client) {
        store.commit('SET_CLIENT_ANALYTICS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/client/${client}/analytics`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_CLIENT_ANALYTICS', resp.data);
            store.commit('SET_CLIENT_ANALYTICS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_CLIENT_ANALYTICS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getClientOrderSubscriptions (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CLIENT_ORDER_SUBSCRIPTIONS_LOADER_STATUS', true);

            axios.get(`${checkoutApi}/client/order-subscriptions`, {
                params,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_CLIENT_ORDER_SUBSCRIPTIONS', resp.data);
                store.commit('SET_CLIENT_ORDER_SUBSCRIPTIONS_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CLIENT_ORDER_SUBSCRIPTIONS_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getTrackingIds (store) {
        axios.get(`${checkoutApi}/product/tracking-ids`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_TRACKING_IDS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getAllSubscriptions (store, params) {
        store.commit('SET_ALL_SUBSCRIPTIONS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/all-subscriptions`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ALL_SUBSCRIPTIONS', resp.data.subscriptions);
            store.commit('SET_SUBSCRIPTION_STATS', resp.data.stats);
            store.commit('SET_ALL_SUBSCRIPTIONS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ALL_SUBSCRIPTIONS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getSubscriptionProducts (store) {
        axios.get(`${checkoutApi}/product/subscription-products`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_SUBSCRIPTION_PRODUCTS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getCheckoutLogs (store, params) {
        store.commit('SET_CHECKOUT_LOGS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/checkout-logs`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_CHECKOUT_LOGS', resp.data);
            store.commit('SET_CHECKOUT_LOGS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_CHECKOUT_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getOrdersAnalytics (store, params) {
        store.commit('SET_ORDERS_ANALYTICS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/analytics`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ORDERS_ANALYTICS', resp.data);
            store.commit('SET_ORDERS_ANALYTICS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ORDERS_ANALYTICS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    refundPayment (store, params) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_REFUND_PAYMENT_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/order/refund-payment`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_REFUND_PAYMENT_LOADER_STATUS', false);
                Toastr.success('Amount has been refunded successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_REFUND_PAYMENT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sendSubscriptionInvoice (store, subscription) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_SEND_INVOICE_LOADER_STATUS', subscription);

            axios.put(`${checkoutApi}/subscription-invoice/${subscription}/send`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                Toastr.success('Subscription invoice has been send successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sendOrderInvoice (store, order) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_SEND_INVOICE_LOADER_STATUS', order);

            axios.put(`${checkoutApi}/order-invoice/${order}/send`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                Toastr.success('Order invoice has been send successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    downloadOrderInvoice (store, order) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_DOWNLOAD_INVOICE_LOADER_STATUS', order);

            axios.get(`${checkoutApi}/order-invoice/${order}/download`, { params: {}, responseType: 'blob',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_DOWNLOAD_INVOICE_LOADER_STATUS', 0);

                const url   = window.URL.createObjectURL(new Blob([resp.data]));
                const link  = document.createElement('a');
                const batch = Math.random().toString(16).slice(2);
                const fileName = `invoice-${batch}.pdf`;

                link.href = url;
                link.setAttribute('download', fileName.toLowerCase());
                document.body.appendChild(link);
                link.click();

                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOWNLOAD_INVOICE_LOADER_STATUS', 0);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getPaidSubscriptionLogs (store, params) {
        store.commit('SET_PAID_SUBSCRIPTION_LOGS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/subscription-logs`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_PAID_SUBSCRIPTION_LOGS', resp.data);
            store.commit('SET_PAID_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_PAID_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getFailedSubscriptionLogs (store, params) {
        store.commit('SET_FAILED_SUBSCRIPTION_LOGS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/subscription-logs`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_FAILED_SUBSCRIPTION_LOGS', resp.data);
            store.commit('SET_FAILED_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_FAILED_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getTrialSubscriptionLogs (store, params) {
        store.commit('SET_TRIAL_SUBSCRIPTION_LOGS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/order/subscription-logs`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_TRIAL_SUBSCRIPTION_LOGS', resp.data);
            store.commit('SET_TRIAL_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_TRIAL_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    updateOrderTrialStatus (store, params) {
        return new Promise ((resolve, reject) => {
            axios.patch(`${checkoutApi}/order/${params.id}/update-subscription-details`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Trial status has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateOrderBillingStatus (store, params) {
        return new Promise ((resolve, reject) => {
            axios.patch(`${checkoutApi}/order/${params.id}/update-subscription-details`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Next billing status has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateTrialEndDate (store, params) {
        return new Promise ((resolve, reject) => {
            axios.patch(`${checkoutApi}/order/${params.id}/update-subscription-details`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Trial end date has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateNextBillingDate (store, params) {
        return new Promise ((resolve, reject) => {
            axios.patch(`${checkoutApi}/order/${params.id}/update-subscription-details`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Next billing date has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    cancelSubscription (store, id) {
        return new Promise ((resolve, reject) => {
            axios.post(`${checkoutApi}/order/${id}/cancel-subscription`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Subscription has been cancelled successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameProduct (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', true);

            axios.patch(`${checkoutApi}/product/${params.id}/rename`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', false);

                Toastr.success('Product has been renamed successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PRODUCT_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    archiveUnarchiverProduct (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${checkoutApi}/product/archive-unarchive`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success(`Product has been ${params.archive ? 'archive' : 'unarchive' } successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    applyCouponCode (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${checkoutApi}/${params.product_id}/apply-coupon`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Coupon has been applied!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAssignedTagList (store) {
        axios.get(`${checkoutApi}/tag/client-assign-tags`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ASSIGNED_TAG_LIST', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getRecentActivity (store, filter) {
        store.commit('SET_RECENT_ACTIVITIES_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/dashboard/recent-activities`, {
            params: { filter },
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_RECENT_ACTIVITIES_LOADER_STATUS', false);
            store.commit('SET_RECENT_ACTIVITIES', resp.data);
        }).catch((err) => {
            store.commit('SET_RECENT_ACTIVITIES_LOADER_STATUS', false);
            console.log(err);
        });
    },

    getChartData (store, params) {
        store.commit('SET_CHART_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/dashboard/chart-stats`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_CHART_LOADER_STATUS', false);
            store.commit('SET_CHART_DATA', resp.data.chart);
            store.commit('SET_CHART_STATS', resp.data.stats);
            store.commit('SET_GROSS_SALE', resp.data.gross_sale);
        }).catch((err) => {
            store.commit('SET_CHART_LOADER_STATUS', false);
            console.log(err);
        });
    },

    getAllProducts (store) {
        store.commit('SET_ALL_PRODUCT_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/product/list-all`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ALL_PRODUCT_LOADER_STATUS', false);
            store.commit('SET_ALL_PRODUCTS', resp.data);
        }).catch((err) => {
            store.commit('SET_ALL_PRODUCT_LOADER_STATUS', false);
            console.log(err);
        });
    },

    sendSubscriptionChargeInvoice (store, subscription) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_SEND_INVOICE_LOADER_STATUS', subscription);

            axios.put(`${checkoutApi}/subscription-charge-invoice/${subscription}/send`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEND_INVOICE_LOADER_STATUS', 0);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getClientAbandonedOrders (store, params) {
        store.commit('SET_CLIENT_ABANDONED_ORDERS_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/client/abandoned-orders`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_CLIENT_ABANDONED_ORDERS', resp.data);
            store.commit('SET_CLIENT_ABANDONED_ORDERS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_CLIENT_ABANDONED_ORDERS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getAllOrderTransactions (store, params) {
        axios.get(`${checkoutApi}/order/all-order-transactions`, {
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ALL_ORDER_TRANSACTIONS', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    createSetupIntent (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SETUP_INTENT_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/stripe/${params.processor}/create-setup-intent`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_SETUP_INTENT_LOADER_STATUS', false);

                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_SETUP_INTENT_LOADER_STATUS', false);

                resolve(false);
            });
        });
    },

    updateClientCard (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ADD_CARD_LOADER_STATUS', true);

            axios.post(`${checkoutApi}/stripe/${params.client_id}/update-card`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_ADD_CARD_LOADER_STATUS', false);

                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_ADD_CARD_LOADER_STATUS', false);

                resolve(false);
            });
        });
    },


    sendCardUpdateEmail (store, subscription) {
        return new Promise ((resolve, reject) => {
            store.commit('SET_CARD_UPDATE_EMAIL_LOADER_STATUS', true);

            axios.put(`${checkoutApi}/order/${subscription}/update-card-email`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_CARD_UPDATE_EMAIL_LOADER_STATUS', false);
                Toastr.success('Card information has been sent successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CARD_UPDATE_EMAIL_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getProductTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PRODUCT_TEMPLATE_LOADER_STATUS', true);

            axios.get(`${checkoutApi}/product/${params.product_id}/product-template`, {
                params,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                store.commit('SET_PRODUCT_TEMPLATE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_PRODUCT_TEMPLATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    removeCoupon (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${checkoutApi}/${params}/remove-coupon`, {}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteProof (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`${checkoutApi}/${params.template}/${params.proof}/delete-proof`, {
                params: {},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Proof has been deleted successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    shareProductWithAccounts (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`${checkoutApi}/product/${params.id}/share-with-accounts`, params, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                    'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                    'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
                },
            }).then((resp) => {
                Toastr.success('Product has been shared successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    getAllSubscriptionLogs ( store, order){
        store.commit('SET_ALL_SUBSCRIPTION_LOGS_LOADER_STATUS', true);
        axios.get(`${checkoutApi}/order/${order}/all-subscription-logs`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_ALL_SUBSCRIPTION_LOGS', resp.data);
            store.commit('SET_ALL_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_ALL_SUBSCRIPTION_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getLiveProducts (store) {
        store.commit('SET_LIVE_PRODUCT_LOADER_STATUS', true);

        axios.get(`${checkoutApi}/product/live-products`, {
            params: {},
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${store.state.checkoutAuthToken}`,
                'accessed-location-id': store.rootState.authModule.authToken.accessed_location_id,
                'company-admin-id': store.rootState.authModule.authToken.company_admin_id,
            },
        }).then((resp) => {
            store.commit('SET_LIVE_PRODUCTS', resp.data);
            store.commit('SET_LIVE_PRODUCT_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_LIVE_PRODUCT_LOADER_STATUS', false);
        });
    },
};

export default actions;
