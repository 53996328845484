const mutations = {
    SET_ACCESS_KEY_LOADER_STATUS (state, status) {
        state.accessKeyLoader = status;
    },

    SET_CONFIRMED_OPTIN_LOADER_STATUS (state, status) {
        state.confirmedOptinLoader = status;
    },

    SET_SETUP_WIZARD_LOADER_STATUS (state, status) {
        state.setupWizardLoader = status;
    },
};

export default mutations;
