const mutations = {
    SET_ACTIVITY_LOG_LOADER_STATUS (state, value) {
        state.activityLogLoader = value;
    },

    SET_MODULES (state, data) {
        state.modules = data;
    },
};

export default mutations;