import router from '@/routes'

const mutations = {
    SET_ACCOUNT_CREATE_LOADER_STATUS (store, status) {
        store.accountCreateLoader = status;
    },

    SET_ACCOUNT_RESEND_CODE_LOADER_STATUS (store, status) {
        store.accountResendCodeLoader = status;
    },

    SET_ACCOUNT_DETAILS (store, details) {
        store.acountDetails = details;
    },

    SET_SUBSCRIPTION_PLANS (store, plans) {
        store.plans = plans;
    },

    SET_ACCOUNT_ACTIVATED (store, status) {
        store.isAccountActivated = status;
    },
};

export default mutations;
