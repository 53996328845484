<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Content Club And Social Media Club</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="section_wpr">
                    <h3>Would You Like High-Quality Done-For-You Preformatted And Thrive Coach Ready Content Every Month?</h3>
                    <img src="@/assets/images/content-club-final.png" class="mt-4 mb-4">
                    <ul class="facility_list">
                        <li>8 Done-For-You High-Quality <span>Email Newsletters</span></li>
                        <li>Client Portal Where Everything Is Delivered <span>To Your Clients</span></li>
                        <li>Thrive Coach Ready Professionally Designed Playbook <span>Designed to Wow Clients</span></li>
                        <li>Thrive Coach Ready Email Nurture Sequence <span>Designed to Convert Leads into Clients</span></li>
                        <li>A Full Month of <span>Engaging Social Media Posts</span></li>
                    </ul>
                    <h3>Plus These Thrive Only Bonuses</h3>
                    <ul class="facility_list">
                        <li>A Done-For-You Newsletter Signup Form</li>
                        <li>A Done-For-You High Converting Landing Page</li>
                        <li>A Done-For-You Automated Journey</li>
                        <li>A Done-For-You Canva Template Library</li>
                    </ul>
                </div>
                <a target="_blank" href="https://contentandsocialmediaclub.com/~access/a1c1147f/" class="learn_more mt-2">Learn More</a>
                <div class="text_info">
                    Use Coupon Code CLUB49 To Get 50% Off Your First Month
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Content Club',

        data () {
            return  {

            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    };
</script>