const getters = {
    FILTER_TAGS_TYPE: (state) => (tags) => {
        const tagsGroup = state.tagsGroup;
        let systemTags  = [];
        let idTags      = [];
        let triggerTags = [];
        const tagsArray = JSON.parse(JSON.stringify(tags));

        tagsGroup.forEach((group, key) => {
            if (group.type == 'System Tags') {
                systemTags = group.tags.map(tag => tag.id);
            } else if (group.type == 'ID Tags') {
                idTags = group.tags.map(tag => tag.id);
            } else if (group.type == 'Trigger Tags') {
                triggerTags = group.tags.map(tag => tag.id);
            }
        });

        tagsArray.forEach((tag, key) => {
            if (systemTags.includes(tag.id)) {
                tagsArray[key].tag_type = 1;
            } else if (idTags.includes(tag.id)) {
                tagsArray[key].tag_type = 2;
            } else if (triggerTags.includes(tag.id)) {
                tagsArray[key].tag_type = 3;
            }
        });

        return tagsArray;
    }
};

export default getters;