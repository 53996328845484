const mutations = {
    SET_MEMBER_ID_LOADER_STATUS (state, value) {
        state.memberIdLoader = value;
    },

    SET_MEMBER_ID_SETTING (state, setting) {
        state.memberIdSetting = setting;
    },

    SET_PUBLIC_MEMBER_SETTING (state, setting) {
        state.publicMemberSetting = setting;
    },
};

export default mutations;