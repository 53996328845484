const state = {
    folders: [],
    folderLoader: false,
    folderUpdateLoader: false,
    addAssetLoader: false,
    mainFolders: [],
    isArchived: localStorage.hasOwnProperty('isArchivedFolder') ? (JSON.parse(localStorage.getItem('isArchivedFolder')) ? true : false ) : false,
    lastFolderBreadcrumb: localStorage.hasOwnProperty('lastFolderBreadcrumb') ?  (JSON.parse(localStorage.getItem('lastFolderBreadcrumb')) ?  JSON.parse(localStorage.getItem('lastFolderBreadcrumb')) : []): [],
    isAssetFolder: localStorage.hasOwnProperty('isAssetFolder') ? (JSON.parse(localStorage.getItem('isAssetFolder')) ? JSON.parse(localStorage.getItem('isAssetFolder')) : false) : false,
    folderId: localStorage.hasOwnProperty('folderId') ? JSON.parse(localStorage.getItem('folderId')) : null,
    isPreviousFolder: localStorage.hasOwnProperty('isPreviousFolder') ? (JSON.parse(localStorage.getItem('isPreviousFolder')) ? JSON.parse(localStorage.getItem('isPreviousFolder')) : false) : false,
    folderBreadcrumb: [],
    isRefresh: true,
};

export default state;
