const ui = [
                {
                    name: 'WorkoutUIIndex',
                    component: () => import(/* webpackChunkName: "js/ui" */ '@/pages/workout-ui/Index'),
                    path: '/workouts-ui',
                    meta: {
                        title: 'Workouts',
                        requiresAuth: true,
                        accountActive: true,
                    },
                },
                {
                    name: 'ClientPortalUIIndex',
                    component: () => import(/* webpackChunkName: "js/ui" */ '@/pages/client-portal-ui/Index'),
                    path: '/client-portal-ui',
                    meta: {
                        title: 'Client Portal UI',
                        requiresAuth: true,
                        accountActive: true,
                    },
                },
                {
                    name: 'CancellationIndex',
                    component: () => import(/* webpackChunkName: "js/ui" */ '@/pages/cancellation/Index'),
                    path: '/cancellation',
                    meta: {
                        title: 'Cancellation',
                        requiresAuth: true,
                        accountActive: true,
                    },
                },
            ];

export default ui;
