<template>
    <div class="loader_area" v-show="loader"><quote-loader /></div>
        <div v-show="!loader" :class="{'no-tooltip' : !hasTooltip, 'no_headerSidebar': $route.name == 'SmsCenterIndex'}">
        <div v-if="isAuthRoute && (hasBillingNotificationBar || hasEmailNotificationBar)" class="header-main-sec notification-bar">
            <div class="content" v-if="billingNotification.card_expire_notification && !billingNotification.is_payment_failed && !billingNotification.has_trial">
                Your card on file expires in {{ billingNotification.card_expire_in_days }} days. Please update your payment method on file to avoid any service interruptions.

                <button class="action-button" @click="toggleCardAdd()">Update Card</button>
            </div>
            <div class="content" v-else-if="billingNotification.is_payment_failed && !billingNotification.has_trial">
                <!-- Your card payment is failed. Please update your payment method on file to avoid any service interruptions. -->
                Looks like your payment method on file declined. Please update your payment method to maintain account access.

                <button class="action-button" @click="toggleBillingUpdate()">Update Billing</button>
            </div>
            <!-- <div class="content" v-else-if="billingNotification.has_trial">
                Your trial expires in {{ billingNotification.trial_remaining_days }} days. Set up your &nbsp; <router-link :to="{ name: 'AdminSettings' }">account here</router-link>.

                <button class="action-button" @click="togglePaidSubscription()">Start Billing Early!</button>
            </div> -->
            <div class="content" v-else-if="hasEmailNotificationBar">
                Your email status Switch is OFF. Please turn your email status Switch ON in your admin settings to send emails.

                <button class="action-button" @click="handleEnableEmailNotification()">Enable</button>
            </div>

            <div class="close-button pointer" @click="hasBillingNotificationBar = false" v-if="hasBillingNotificationBar">
                <i class="fa fa-times"></i>
            </div>
            <div class="close-button pointer" @click="hasEmailNotificationBar = false" v-else>
                <i class="fa fa-times"></i>
            </div>
            <!-- <div v-if="isAuthRoute && hasEmailNotificationBar" class="header-main-sec notification-bar">
                <div class="content">
                    Your email status Switch is OFF. Please turn your email status Switch ON in your admin settings to send emails.

                    <button class="action-button" @click="handleEnableEmailNotification()">Enable</button>
                </div>
                <div class="close-button pointer" @click="hasEmailNotificationBar = false">
                    <i class="fa fa-times"></i>
                </div>
            </div> -->
        </div>
        <header-component :class="{'has-notification-bar' : (hasBillingNotificationBar || hasEmailNotificationBar)}" v-if="isAuthRoute" :toggle-tools="toggleTools" :toggle-marketplace="toggleMarketplace" :close-tools-marketplace="closeToolsMarketplace" />
        <sidebar-component v-if="isAuthRoute" :toggle-tools="toggleTools" :close-tools-marketplace="closeToolsMarketplace" @tooltip-status="handleTooltipStatus"/>

        <div class="main_body" :class="{ def : publicRoutes.includes($route.name), 'plans': [ 'SelectPlan', 'FormPublicResponse'].includes($route.name), 'has-notification-bar' : hasBillingNotificationBar, 'body-scroll': $route.name == 'BroadcastPublicView', 'setup': $route.name == 'AccountSetup' }">
            <div class="default-loader" v-if="developerLoginLoader">
                <quote-loader />
            </div>
            <template v-else>
                <router-view></router-view>
                <tools-component v-if="isAuthRoute" v-model="toolsMenu"></tools-component>
                <marketplace-component v-if="isAuthRoute" v-model="marketplace"></marketplace-component>
                <add-new-card v-model="addNewCard" :refresh-billing="getBillingNotification" />
                <update-billing v-model="updateBilling" :refresh-billing="getBillingNotification" />
                <start-paid-subscription v-model="paidSubscription" :refresh-billing="getBillingNotification" />
            </template>
        </div>

        <dns-modal v-model="dnsWarning" />
        <div class="toastr" id="toastr_element"></div> 
    </div>
</template>

<script>
    import moment from 'moment'
    import axios from '@/services/axios'
    import HeaderComponent from '@/components/Header'
    import SidebarComponent from '@/components/Sidebar'
    import ToolsComponent from '@/components/Tools'
    import MarketplaceComponent from '@/components/Marketplace'
    import * as AllRules from '@vee-validate/rules'
    import AddNewCard from '@/pages/admin-setting/components/account/billing/AddNewCard'
    import UpdateBilling from '@/pages/admin-setting/components/account/billing/UpdateBilling'
    import StartPaidSubscription from '@/pages/admin-setting/components/account/billing/StartPaidSubscription'
    import DnsModal from '@/components/DNSModal'

    import { localize } from '@vee-validate/i18n'
    import { defineRule, configure } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    Object.keys(AllRules).forEach(rule => {
      if (typeof AllRules[rule] === 'function') {
        defineRule(rule, AllRules[rule]);
      } else {
        console.log(`Skipping rule '${rule}' because it's not a function`);
      }
    });

    defineRule('required_editor', (value, [target], ctx) => {
        const val = value ? value.replace(/<(.|\n)*?>/g, '') : value;

        if (!val || !val.length) {
            return `The ${ctx.field} field is required`;
        }

        return true;
    });

    defineRule('required_int', (value, [target], ctx) => {
        const val = value > 0;

        if (!val || !val.length) {
            return `The ${ctx.field} field is required`;
        }

        return true;
    });

    defineRule('validate_url', (value, [target], ctx) => {

        if (value && value.length && !value.includes(target)) {
            return `The ${ctx.field} URL is not a valid ${ctx.field} URL`;
        }

        return true;
    });

    defineRule('url_bar', (value, [target], ctx) => {
        if (value && value.length) {
            const val = value.slice(0, 8);

            return (val.includes('https://') || val.includes('http://')) ? true : `The ${ctx.field} URL is not a valid URL`;
        }

        return true;
    });

    defineRule('lowercase', (value, [target], ctx) => {
        if (value && value.length) {
            if (/[A-Z]/.test(value)) {
                return `The ${ctx.field} must be in lowercase`;
            }
        }

        return true;
    });

    defineRule('fb_pixel', (value, [target], ctx) => {
        if (value && value.length) {
            if (value.includes('<script>') && value.includes('.facebook.')) {
                return true;
            } else {
                return `You entry does not reflect the character structure of a facebook pixel code. Please make sure you are putting your pixel code in the correct input area.`;
            }
        }

        return true;
    });

    configure({
        generateMessage: localize('en', {
            messages: {
                required: 'The {field} field is required',
                min: 'The {field} must be at least 0:{min} characters',
                email: 'The {field} field must be a valid email',
                url: 'The {field} field must be a valid URL',
                numeric: 'The {field} field must be a numeric value',
                between: 'The {field} is not valid',
                min_value: 'The {field} must be greater than or equal to 0:{min}',
                max_value: 'The {field} must be less than or equal to 0:{max}',
            },
        }),
    });

    export default {
        name: 'Layout',

        data () {
            return {
                isAuthRoute: false,
                toolsMenu: false,
                marketplace: false,
                addNewCard: false,
                updateBilling: false,
                paidSubscription: false,
                hasBillingNotificationBar: false,
                billingNotification: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                publicRoutes: [
                                  'Login',
                                  'Forgot',
                                  'Index',
                                  'Register',
                                  'VerifyAccount',
                                  'VerifyBusiness',
                                  'RegisterNewEmail',
                                  'RegisterNewPhone',
                                  'VerifyPhone',
                                  'SelectPlan',
                                  'RegistrationFinish',
                                  'MemberIDDashboard',
                                  'RecoveryPageDashboard',
                                  'GetStarted',
                                  'PaymentConfirmation',
                                  'ActivateAccount',
                                  'BroadcastPublicView',
                                  'Suspended',
                                  'FormPublicResponse'
                              ],
                hasEmailNotificationBar: false,
                loader: false,
                dnsWarning: false,
                hasTooltip: 0,
            };
        },

        components: {
            HeaderComponent,
            SidebarComponent,
            ToolsComponent,
            MarketplaceComponent,
            AddNewCard,
            UpdateBilling,
            StartPaidSubscription,
            DnsModal,
        },

        watch: {
            $route (route) {
                const vm = this;

                if (route.name == 'TwoFactorAuthentication') {
                    vm.isAuthRoute = false;
                } else if (route.name == 'ActivateAccount') {
                    vm.isAuthRoute = false;
                } else {
                    vm.isAuthRoute = route.meta.requiresAuth;
                }

                vm.checkRoutePermission(route.name, vm.isAuthRoute);

                if (route.name == 'Referral') {
                    vm.initReferral(route.params.token);
                }

                if (route.name == 'DeveloperLogin') {
                    vm.developerLogin({user: route.params.user, token: route.params.token});
                }

                if (route.name == 'SmsCenterIndex' && route.query.auto_login == 'true') {
                    vm.developerLogin({user: route.query.user, token: route.query.access_token, redirect: 'SmsCenterIndex', params: route.query });
                } else if (route.redirectedFrom && route.redirectedFrom.name && route.redirectedFrom.query.auto_login == 'true') {
                    vm.developerLogin({user: route.redirectedFrom.query.user, token: route.redirectedFrom.query.access_token, redirect: 'SmsCenterIndex', params: route.redirectedFrom.query });
                }

                if (route.name && route.name.includes('Checkout')) {
                    if (vm.user && vm.user.id && !vm.user.checkout_enabled) {
                        vm.$router.push({ name: 'Error-404' });
                    }
                }

                if (route.name == 'PlaybookAnswerQuestion') {
                    vm.loader = true;

                    if (vm.isUserLoggedIn) {
                        vm.loader = false;
                        vm.$router.push({ name: 'PlaybookIndex', query: { action: 'answer-question', playbook_id: route.query.playbook_id }});
                    } else {
                        vm.loginWithPlaybookToken({ token: route.query.token }).then((result) => {
                            vm.loader = false;

                            if (result) {
                                vm.$router.push({ name: 'PlaybookIndex', query: { action: 'answer-question', playbook_id: route.query.playbook_id }});
                            } else {
                                vm.$router.push({ name: 'Error-404' });
                            }
                        });
                    }
                }

                if (route.name == 'AutoLogin') {
                    vm.loader = true;

                    let query = route.query.query ? route.query.query : false;
                    query = query ? Object.fromEntries(new URLSearchParams(query.replaceAll('|', '&').replaceAll(':', '='))): {};

                    if (vm.isUserLoggedIn) {
                        vm.loader = false;
                        vm.$router.push({ path: route.query.redirect, query });
                    } else {
                        vm.autoLogin(route.params.token).then((result) => {
                            vm.loader = false;

                            if (result) {
                                vm.$router.push({ path: route.query.redirect, query });
                            } else {
                                vm.$router.push({ name: 'Error-404' });
                            }
                        });
                    }
                }
            },

            isAuthRoute (val) {
                const vm = this;

                if (val) {
                    vm.refreshAuth();
                }
            },

            user (user) {
                const vm = this;

                setTimeout(function () {
                    if (user.id && user.is_account_suspended == 1) {
                        vm.$router.push({ name: 'Suspended' });
                    } else if (user.id) {
                        vm.getBillingNotification();
                        vm.getSubscription();

                        if (!vm.checkoutAuthToken && user.id) {
                            vm.createCheckoutToken({ email: user.email, account_id: user.id, access_token: user.access_token });
                        }

                        if (vm.$route.name && vm.$route.name.includes('Checkout')) {
                            if (vm.user && vm.user.id && !vm.user.checkout_enabled) {
                                vm.$router.push({ name: 'Error-404' });
                            }
                        }

                        if (user.email_dns_missing) {
                            vm.dnsWarning = true;
                        }

                        vm.checkRoutePermission(vm.$route.name, vm.isAuthRoute);
                    }

                    if (user.id) {
                        vm.hasTooltip = user.has_tooltip;
                    }
                }, 100);
            },

            subscription (subscription) {
                const vm = this;

                if ((process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'staging') && (vm.isAuthRoute || vm.isUserLoggedIn)) {
                    if (subscription && subscription.interval == 'month' && subscription.subsctiption_status !== 'trialing') {
                        // tap('conversion', vm.user.id, subscription.amount, { customer_id: vm.user.id });
                        // tap('conversion', subscription.id, subscription.amount, {customer_id: subscription.customer_id});
                        tap('conversion', (`${vm.user.full_name}_${vm.user.email}`).replaceAll(' ', '_'), subscription.amount, {customer_id: vm.user.id});
                    } else if (subscription && subscription.interval == 'month') {
                        tap('trial', vm.user.id);
                    }
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                isUserLoggedIn: state => state.authModule.isUserLoggedIn,
                subscription: state => state.billingModule.subscription,
                developerLoginLoader: state => state.authModule.developerLoginLoader,
                checkoutAuthToken: state => state.checkoutModule.checkoutAuthToken,
                authToken: state => state.authModule.authToken,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.$route.name == 'TwoFactorAuthentication') {
                vm.isAuthRoute = false;
            } else if (vm.$route.name == 'ActivateAccount') {
                vm.isAuthRoute = false;
            } else {
                vm.isAuthRoute = vm.$route.meta.requiresAuth;
            }

            setTimeout(function () {
                if (vm.$route.name && vm.$route.name.includes('Checkout')) {
                    if (vm.user && vm.user.id && !vm.user.checkout_enabled) {
                        vm.$router.push({ name: 'Error-404' });
                    }
                }
            }, 10);

            if (process.env.VUE_APP_ENV == 'production' && (vm.isAuthRoute || vm.isUserLoggedIn)) {
                const script = document.createElement('script');
                script.innerHTML = "(function(d,h,w){var gist=w.gist=w.gist||[];gist.methods=['trackPageView','identify','track','setAppId'];gist.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;}};for(var i=0;i<gist.methods.length;i++){var c=gist.methods[i];gist[c]=gist.factory(c)}s=d.createElement('script'),s.src='https://widget.getgist.com',s.async=!0,e=d.getElementsByTagName(h)[0],e.appendChild(s),s.addEventListener('load',function(e){},!1),gist.setAppId('x7poc7zo'),gist.trackPageView()})(document,'head',window);";

                document.head.appendChild(script);
            }

            if (process.env.VUE_APP_ENV == 'production' && (vm.isAuthRoute || vm.isUserLoggedIn)) {
                const script = document.createElement('script');
                script.innerHTML = "(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');";

                document.head.appendChild(script);

                setTimeout(function () {
                    tap('create', process.env.VUE_APP_TAP_ACCOUNT_ID, { integration: "stripe" });
                    tap('detect');
                }, 1000);
            }
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                logout: 'authModule/logout',
                updateReferralsStats: 'referralModule/updateReferralsStats',
                developerLogin: 'authModule/developerLogin',
                updateKillStatus: 'authModule/updateKillStatus',
                getSubscription: 'billingModule/getSubscription',
                createCheckoutToken: 'checkoutModule/createCheckoutToken',
                loginWithPlaybookToken: 'authModule/loginWithPlaybookToken',
                autoLogin: 'authModule/autoLogin',
            }),

            handleTooltipStatus (value) {
                const vm = this;
                vm.hasTooltip = value;
            },

            toggleTools () {
                const vm = this;

                vm.toolsMenu = true;
            },

            toggleMarketplace () {
                const vm = this;

                vm.marketplace = true;
            },

            closeToolsMarketplace () {
                const vm = this;

                vm.toolsMenu = false;
                vm.marketplace = false;

                const closeButtons = document.getElementsByClassName('modal-window-close-button');

                if (closeButtons.length) {
                    closeButtons.forEach((closeButton) => {
                        closeButton.click();
                    });
                }
            },

            initReferral (token) {
                const vm = this;
                let isReclicked = false;
                const lastClicked = vm.$cookies.get('referral_clicked_at');

                if (lastClicked) {
                    isReclicked = moment().diff(lastClicked, 'minutes') > 3 ? false : true;
                }

                if (!isReclicked) {
                    vm.$cookies.set('referral_token', token);
                    vm.$cookies.set('referral_clicked_at', moment());
                    vm.updateReferralsStats(token);
                }

                if (vm.isUserLoggedIn) {
                    vm.logout().then((result) => {
                        if (result) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                } else {
                    vm.$router.push({ name: 'Register' });
                }
            },

            getBillingNotification () {
                const vm = this;

                axios.get(`${vm.billingApi}/stripe/billing-notifications`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.billingNotification = resp.data;

                    if (resp.data.has_stripe_subscription && (resp.data.card_expire_notification || resp.data.is_payment_failed)) {
                        vm.hasBillingNotificationBar = true;
                    } else {
                        vm.hasBillingNotificationBar = false;
                    }

                    if (vm.user.email_status == 0) {
                        vm.hasEmailNotificationBar = true;
                    } else {
                        vm.hasEmailNotificationBar = false;
                    }
                }).catch((err) => {
                    if (vm.user.email_status == 0) {
                        vm.hasEmailNotificationBar = true;
                    } else {
                        vm.hasEmailNotificationBar = false;
                    }
                });
            },

            toggleCardAdd () {
                const vm = this;

                vm.addNewCard = true;
            },

            toggleBillingUpdate () {
                const vm = this;

                vm.updateBilling = true;
            },

            togglePaidSubscription () {
                const vm = this;

                vm.paidSubscription = true;
            },

            goToAdminSetting () {
                window.location = '/admin-settings';
            },

            handleEnableEmailNotification () {
                const vm = this;

                vm.updateKillStatus({ type: 'email', status: 1 }).then((resp) => {
                    if (resp) {
                        vm.refreshAuth();
                    }
                });
            },

            checkRoutePermission (route, auth) {
                const vm = this;

                const modules = {
                                    AdminSettings: 'admin-settings',
                                    AccountBilling: 'admin-settings',
                                    AccountDowngrade: 'admin-settings',
                                    BroadcastIndex: 'broadcasts',
                                    CheckoutIndex: 'checkouts',
                                    CheckoutClients: 'checkouts',
                                    CheckoutProducts: 'checkouts',
                                    CheckoutOrders: 'checkouts',
                                    CheckoutSubscriptions: 'checkouts',
                                    CheckoutLogs: 'checkouts',
                                    ClientPortalIndex: 'client-portal',
                                    ContactIndex: 'contacts-center',
                                    SmsCenterIndex: 'sms-center',
                                    FileIndex: 'files',
                                    FormIndex: 'forms',
                                    FormEdit: 'forms',
                                    GroupIndex: 'groups',
                                    HabitTrackingIndex: 'habit-tracking',
                                    JourneyIndex: 'journeys',
                                    OptinIndex: 'opt-ins',
                                    PageIndex: 'pages',
                                    PageEdit: 'pages',
                                    PlaybookIndex: 'playbooks',
                                    PlaybookEdit: 'playbooks',
                                    ProgressTrackingIndex: 'progress-tracking',
                                    SequenceIndex: 'sequences',
                                    TagIndex: 'tags',
                                    TemplatesIndex: 'templates',
                                    WorkoutIndex: 'workouts',
                                };

                if (auth && modules[route]) {
                    if (vm.companyUserCan('view', modules[route]) === false) {
                        vm.$router.push({ name: 'AccessDenied' });
                    }
                }

                if (route == 'AccessDenied' && (vm.user.is_organization_owner || vm.user.is_company_location)) {
                    vm.$router.push({ name: 'ContactIndex' });
                }
            }
        },
    }
</script>
<style>
    @font-face {
        font-family: "Shadows";
        src: local("Shadows"),
        url(./assets/font/shadows-into-light-two.regular.ttf) format("truetype");
    }

    .main_body.def.plans {
        background-image: url('~@/assets/images/gradient-bg.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
    }

    .default-loader {
        height: 80vh;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .notification-bar {
        background: #F2A31D;
        min-height: 40px;
        color: #000;
        position: fixed;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        padding: 10px;
    }

    .header-main-sec.notification-bar {
        z-index: 13;
        justify-content: space-between;
    }

    .header-main-sec.notification-bar .close-button {
        right: 10px;
        color: white;
        position: inherit;
    }

    .header-main-sec.notification-bar .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-main-sec.notification-bar .content .action-button {
        background: #2F7FED;
        color: white;
        font-weight: 500;
        min-width: 100px;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        padding: 3px 10px;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        box-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
    }

    .header-main-sec.has-notification-bar {
        top: 40px;
    }

    .main_body.has-notification-bar {
        padding-top: 150px;
    }


    .main_body.def.body-scroll {
        height: auto;
    }

    .notification-bar a {
        text-decoration: none;
        color: #000;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
    }

    @media(max-width: 991px){
        .header-main-sec.notification-bar {
            justify-content: center;
        }
        .notification-bar {
            font-size: 11px;
            line-height: 14px;
            font-weight: 500;
            padding: 5px 10px;
        }
    }
</style>
