import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllSequences (store, params) {
        axios.get('/sequence/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_SEQUENCES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getSequences (store, params) {
        store.commit('SET_SEQUENCE_LOADER_STATUS', true);

        axios.get('/sequence/lists', { params }).then((resp) => {
            store.commit('SET_SEQUENCES', resp.data);
            store.commit('SET_SEQUENCE_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SEQUENCE_LOADER_STATUS', false);
        });
    },

    getEmailCount (store, id) {
        axios.post(`/sequence/${id}/get-email-count`).then((resp) => {
            console.log(resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    startSequence (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/sequence/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                Toastr.success('Contacts have been successfully added to sequences!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);

                resolve(false);
            });
        });
    },

    stopSequence (store, params) {
        delete params.action;

        store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

        axios.post('/sequence/remove-contact', params).then((resp) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
            store.commit('contactModule/SET_UPDATED_ACTION', 'Sequences', { root: true });
            Toastr.success('Contacts have been successfully removed from sequences!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    getSequenceMessage (store, sequenceId) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_EMAIL_LOADER_STATUS', true);

            axios.get(`/sequence/${sequenceId}/messages`).then((resp) => {
                store.commit('SET_SEQUENCE_EMAILS', resp.data);
                store.commit('SET_SEQUENCE_EMAIL_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_EMAIL_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getAnalytics (store, params) {
        store.commit('SET_SEQUENCE_ANALYTIC_LOADER_STATUS', true);

        axios.get(`/sequence/${params.sequence_id}/analytics`, { params }).then((resp) => {
            if (params.type == 'email') {
                store.commit('SET_SEQUENCE_EMAIL_ANALYTICS', resp.data);
            } else {
                store.commit('SET_SEQUENCE_SMS_ANALYTICS', resp.data);
            }

            store.commit('SET_SEQUENCE_ANALYTIC_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_SEQUENCE_ANALYTIC_LOADER_STATUS', false);
        });
    },

    createSequence (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_CREATE_LOADER_STATUS', true);

            axios.post('/sequence/create', params).then((resp) => {
                store.dispatch('getAllSequences');
                store.commit('SET_SEQUENCE_CREATE_LOADER_STATUS', false);
                Toastr.success(`${params.title} sequence has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_CREATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateSequence (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_UPDATE_LOADER_STATUS', true);

            axios.patch(`/sequence/${params.sequence_id}/update`, params).then((resp) => {
                store.commit('SET_SELECTED_SEQUENCE', resp.data);
                store.commit('SET_SEQUENCE_UPDATE_LOADER_STATUS', false);
                Toastr.success('Sequence has been updated successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    renameSequence (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', true);

            axios.patch('/sequence/rename', params).then((resp) => {
                store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Sequence name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    assignContacts (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', true);

            axios.post('/sequence/add-contact', params).then((resp) => {
                store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Sequence has been assigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sortSequenceMessages (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/sequence/${params.sequence_id}/sort-messages`, params).then((resp) => {
                // store.dispatch('getSequenceMessage', params.sequence_id);
                Toastr.success('Sequence messages has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicateSequence (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/sequence/duplicate', params).then((resp) => {
                store.dispatch('getAllSequences');
                Toastr.success('Sequence has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteSequence (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/sequence/delete', { params }).then((resp) => {
                store.dispatch('getAllSequences');
                Toastr.success('Sequence has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getSequenceUser (store, params) {
        store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', true);

        axios.get(`/sequence/${params.sequence_id}/users`, { params }).then((resp) => {
            store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
            store.commit('SET_SEQUENCE_USERS', resp.data);
        }).catch((err) => {
            store.commit('SET_SEQUENCE_COMPONENT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    deleteSequenceUser (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/sequence/delete-user', { params }).then((resp) => {
                Toastr.success(`Selected sequence ${params.ids.length > 1 ? 'users' : 'user'} has been successfully remove as sequence user`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateSequenceMessage (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_MESSAGE_UPDATE_LOADER_STATUS', true);

            axios.patch(`/sequence/${params.id}/update-message`, params).then((resp) => {
                store.commit('SET_SEQUENCE_MESSAGE_UPDATE_LOADER_STATUS', false);

                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_SEQUENCE_MESSAGE_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    scheduleSequence (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/sequence/${params.sequence_id}/schedule-sequence`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sendSequenceNow (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/sequence/${params.sequence_id}/send-now`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteSequenceMessage (store, messageId) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/sequence/${messageId}/delete-message`).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getSequenceMessageSchedule (store, params) {
        store.commit('SET_SEQUENCE_SCHEDULE_LOADER_STATUS', true);

        axios.get(`/sequence/${params.message_id}/schedules`, { params }).then((resp) => {
            store.commit('SET_SEQUENCE_SCHEDULE_LOADER_STATUS', false);
            store.commit('SET_SEQUENCE_SCHEDULE', resp.data);
        }).catch((err) => {
            store.commit('SET_SEQUENCE_SCHEDULE_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    deleteSequenceSchedule (store, scheduleId) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/sequence/${scheduleId}/delete-schedule`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    addNewMessage (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/sequence/${params.id}/add-message`, params).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getMessageAnalytics (store, id) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_DETAIL_ANALYTICS_LOADER_STATUS', true);

            axios.get(`/sequence/${id}/detail-analytics`).then((resp) => {
                store.commit('SET_SEQUENCE_DETAIL_ANALYTICS_LOADER_STATUS', false);
                store.commit('SET_MESSAGES_ANALYTICS', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_SEQUENCE_DETAIL_ANALYTICS_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getMessageSubscribers (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SEQUENCE_ANALYTICS_CONTACTS_LOADER_STATUS', true);

            axios.get(`/sequence/${params.sequence_id}/message/${params.message_id}/subscribers`, { params }).then((resp) => {
                store.commit('SET_SEQUENCE_ANALYTICS_CONTACTS_LOADER_STATUS', false);
                store.commit('SET_MESSAGES_SUBSCRIBERS', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_SEQUENCE_ANALYTICS_CONTACTS_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    resendSequenceMessage (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/sequence/${params.sequence_id}/message/${params.message_id}/resend`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
