import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllJourneys (store, params) {
        axios.get('/journey/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_JOURNEYS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    startJourney (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_LOADER_STATUS', true);

            axios.post('/journey/start', params).then((resp) => {
                store.commit('SET_JOURNEY_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Journeys', { root: true });
                Toastr.success('Journey has been queued successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_JOURNEY_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    terminateJourney (store, params) {
        return new Promise(function(resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/journey/terminate', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Journeys', { root: true });
                Toastr.success('Journey has been terminated successfully!');
                resolve(true);

                if (params.closeModal) {
                    params.closeModal();
                }
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getJourneys (store, params) {
        store.commit('SET_JOURNEY_LOADER_STATUS', true);

        axios.get('/journey/lists', { params }).then((resp) => {
            store.commit('SET_JOURNEYS', resp.data);
            store.commit('SET_JOURNEY_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_JOURNEY_LOADER_STATUS', false);
        });
    },

    duplicateJourney (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/journey/duplicate', params).then((resp) => {
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                Toastr.success('Journey has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteJourney (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/journey/delete', { params }).then((resp) => {
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                Toastr.success('Journey has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    changeJourneyStatus (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', true);

            axios.patch(`/journey/${params.id}/change-status`, params).then((resp) => {
                Toastr.success(`Journey has been ${ params.status ? 'enabled' : 'disabled' } successfully!`);
                store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    renameJourney (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', true);

            axios.patch(`/journey/${params.id}/rename`, params).then((resp) => {
                Toastr.success(`Journey has been renamed successfully!`);
                store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', false);
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_JOURNEY_UPDATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getJourneyUsers (store, params) {
        store.commit('SET_JOURNEY_USERS_LOADER_STATUS', true);

        axios.get(`/journey/${params.journey_id}/users`, { params }).then((resp) => {
            store.commit('SET_JOURNEY_USERS', resp.data);
            store.commit('SET_JOURNEY_USERS_LOADER_STATUS', false);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_JOURNEY_USERS_LOADER_STATUS', false);
        });
    },

    createJourney (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_CREATE_LOADER_STATUS', true);

            axios.post(`/journey/create`, params).then((resp) => {
                store.commit('SET_JOURNEY_CREATE_LOADER_STATUS', false);
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_JOURNEY_CREATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getJourneyLinks (store, params) {
        axios.get('/journey/links', { params }).then((resp) => {
            store.commit('SET_JOURNEY_LINKS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    createTrigger (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', true);

            axios.post(`/journey/${params.automation_id}/create-trigger`, params).then((resp) => {
                store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', false);
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', false);
                resolve(false);
            });
        });
    },

    createAction (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', true);

            axios.post(`/journey/${params.automation_id}/create-action`, params).then((resp) => {
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', false);
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER', false);
                resolve(false);
            });
        });
    },

    journeyDetails (store, journeyId) {
        return new Promise(function(resolve, reject) {
            axios.get(`/journey/${journeyId}/details`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    getConditionActions (store, actionId) {
        return new Promise(function(resolve, reject) {
            axios.get(`/journey/condition/${actionId}/actions`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    saveTrigger (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_TRIGGER_SAVE_LOADER', true);

            axios.patch(`/journey/trigger/${params.id}/update`, params).then((resp) => {
                store.commit('SET_JOURNEY_TRIGGER_SAVE_LOADER', false);
                Toastr.success('Journey trigger has been saved successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_JOURNEY_TRIGGER_SAVE_LOADER', false);
                resolve(false);
            });
        });
    },

    saveAction (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_JOURNEY_ACTION_SAVE_LOADER', true);

            axios.patch(`/journey/action/${params.id}/update`, params).then((resp) => {
                store.commit('SET_JOURNEY_ACTION_SAVE_LOADER', false);
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                Toastr.success(`Journey ${params.action_type} has been saved successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_JOURNEY_ACTION_SAVE_LOADER', false);
                resolve(false);
            });
        });
    },

    deleteTrigger (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/journey/trigger/${id}/delete`).then((resp) => {
                Toastr.success('Journey trigger has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteAction (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/journey/action/${params.id}/delete`, { params }).then((resp) => {
                Toastr.success('Journey action has been deleted successfully!');
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getJourneyHistory (store, params) {
        store.commit('SET_JOURNEY_HISTORY_LOADER', true);

        axios.get(`/journey/${params.journey_id}/histories`, { params }).then((resp) => {
            store.commit('SET_JOURNEY_HISTORIES', resp.data);
            store.commit('SET_JOURNEY_HISTORY_LOADER', false);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_JOURNEY_HISTORY_LOADER', false);
        });
    },

    refreshAction (store, action) {
        return new Promise(function(resolve, reject) {
            axios.get(`/journey/action/${action}/refresh`).then((resp) => {
                store.dispatch('getAllJourneys', { select: 'id,name,action,action_title,action_type' });
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },
};

export default actions;
