const state = {
    categories: [],
    templates: [],
    templateLoader: false,
    templateAjaxLoader: false,
    allTemplates: [],
    templateList: {},
    templateListLoader: false,
    templateComponentLoader: false,
    templateLogLoader: false,
    templateLogs: {},
    categoryLoadedAt: null,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        category: '',
        type: 'all'
    },
};

export default state;
