const publicRoute = [
    {
        name: 'MemberIDDashboard',
        component: () => import(/* webpackChunkName: "js/public/member-id-dashboard" */ '@/pages/admin-setting/components/member-id/PublicDashboard'),
        path: '/member-id/:id/:access_code',
        meta: {
            title: 'Member ID Dashboard',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'RecoveryPageDashboard',
        component: () => import(/* webpackChunkName: "js/public/recovery-page-dashboard" */ '@/pages/admin-setting/components/email/RecoveryPublicDashboard'),
        path: '/resubscribes/:id',
        meta: {
            title: 'Recovery Page',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'BroadcastPublicView',
        component: () => import(/* webpackChunkName: "js/public/broadcast-public-view" */ '@/pages/broadcast/BroadcastPublicView'),
        path: '/broadcast/:id/public-view',
        meta: {
            title: 'Broadcast View',
            requiresAuth: false,
            accountActive: true,
        },
    },
    {
        name: 'Suspended',
        component: () => import(/* webpackChunkName: "js/public/broadcast-public-view" */ '@/pages/auth/AccountSuspended'),
        path: '/account-suspended',
        meta: {
            title: 'Account Suspended',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default publicRoute;