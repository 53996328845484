// const FormEdit = () => import(/* webpackChunkName: "js/form" */ '@/pages/form/FormEdit')

const form = [
    {
        name: 'FormIndex',
        component: () => import(/* webpackChunkName: "js/form/index" */ '@/pages/form/Index'),
        path: '/forms',
        meta: {
            title: 'Forms',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
    {
        name: 'FormEdit',
        component: () => import(/* webpackChunkName: "js/form/setting" */ '@/pages/form/components/Setting'),
        path: '/form/:form/edit',
        meta: {
            title: 'Form Edit',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            group: 'Admin',
            parent: {
                title: 'Forms',
                name: 'FormIndex',
            }
        },
    },
    {
        name: 'FormResponse',
        component: () => import(/* webpackChunkName: "js/form/setting" */ '@/pages/form/components/Response'),
        path: '/form/:form/response/:contact/:respondent',
        meta: {
            title: 'Form Response',
            requiresAuth: true,
            hasParent: true,
            accountActive: true,
            group: 'Admin',
            parent: {
                title: 'Forms',
                name: 'FormIndex',
            }
        },
    },
    {
        name: 'FormPublicResponse',
        component: () => import(/* webpackChunkName: "js/form/setting" */ '@/pages/form/components/PublicResponse'),
        path: '/form/:form/view-response/:contact/:respondent',
        meta: {
            title: 'Form Response',
            requiresAuth: false,
            accountActive: false,
            group: 'Admin',
            parent: {
                title: 'Forms',
                name: 'FormIndex',
            }
        },
    },
];

export default form;
