import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getWorkouts (store, params) {
        store.commit('SET_WORKOUT_LOADER_STATUS', true);

        axios.get(`/workout/list`, { params }).then((resp) => {
            store.commit('SET_WORKOUTS', resp.data);
            store.commit('SET_WORKOUT_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_WORKOUT_LOADER_STATUS', false);
        });
    },

    getWorkoutAttributeTypes (store) {
        store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', true);

        axios.get(`/workout/attribute-types`).then((resp) => {
            store.commit('SET_WORKOUT_ATTRIBUTE_TYPES', resp.data);
            store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', false);
        });
    },

    getWorkoutCustomAttributeTypes (store) {
        store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', true);

        axios.get(`/workout/custom-attribute-types`).then((resp) => {
            store.commit('SET_WORKOUT_CUSTOM_ATTRIBUTE_TYPES', resp.data);
            store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS', false);
        });
    },

    getWorkoutExercises (store, params) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_WORKOUT_EXERCISES_LOADER_STATUS', true);

            axios.get(`/workout/exercises`, { params }).then((resp) => {
                store.commit('SET_WORKOUT_EXERCISES', resp.data);
                store.commit('SET_WORKOUT_EXERCISES_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_WORKOUT_EXERCISES_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    createWorkout (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/workout/create`, params).then((resp) => {
                store.dispatch('getAllWorkouts');
                Toastr.success('Workout has been created successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateWorkout (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_UPDATE_LOADER_STATUS', true);

            axios.patch(`/workout/${params.id}/update`, params).then((resp) => {
                Toastr.success('Workout has been updated successfully!');
                store.commit('SET_WORKOUT_UPDATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_WORKOUT_UPDATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    createNewBlock (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_CREATE_BLOCK_LOADER_STATUS', true);

            axios.post(`/workout/${params.workout_id}/create-block`, params).then((resp) => {
                Toastr.success('Workout block has been saved successfully!');
                store.commit('SET_CREATE_BLOCK_LOADER_STATUS', false);
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_CREATE_BLOCK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getWorkoutBlocks (store, workoutId) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_BLOCK_LOADER', true);

            axios.get(`/workout/${workoutId}/blocks`).then((resp) => {
                store.commit('SET_WORKOUT_BLOCK_LOADER', false);
                store.commit('SET_WORKOUT_BLOCKS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_BLOCK_LOADER', false);
                resolve(false);
            });
        });
    },

    addBlockExercise (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', true);

            axios.post('/workout/block/add-exercise', params).then((resp) => {
                store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', false);
                resolve(false);
            })
        });
    },

    deleteWorkoutBlock (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/block/${params.id}/delete`, { data: params }).then((resp) => {
                Toastr.success('Workout block has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicateWorkoutBlock (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_BLOCK_DUPLICATE_LOADER_STATUS', true);

            axios.post(`/workout/block/${params.id}/duplicate`, params).then((resp) => {
                Toastr.success('Workout block has been duplicated successfully!');
                store.commit('SET_WORKOUT_BLOCK_DUPLICATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_BLOCK_DUPLICATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateWorkoutBlock (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_UPDATE_BLOCK_LOADER_STATUS', true);

            axios.patch(`/workout/block/${params.id}/update`, params).then((resp) => {
                Toastr.success('Workout block has been updated successfully!');
                store.commit('SET_UPDATE_BLOCK_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_UPDATE_BLOCK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getWorkoutSmartBlocks (store) {
        store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', true);

        axios.get(`/workout/smart-blocks`).then((resp) => {
            store.commit('SET_WORKOUT_SMART_BLOCKS', resp.data);
            store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', false);
        });
    },

    createSmartBlock (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', true);

            axios.post(`/workout/block/${params.id}/create-smart-block`, params).then((resp) => {
                Toastr.success('Workout smart block has been created successfully!');
                store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_WORKOUT_SMART_BLOCK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    deleteSmartBlock (store, blockId) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/block/${blockId}/delete-smart-block`).then((resp) => {
                Toastr.success('Workout smart block has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteBlockExercise (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/block/${params.id}/delete-exercise`, { data: params }).then((resp) => {
                Toastr.success('Workout block exercise has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortWorkoutBlocks (store, params) {
        axios.patch(`/workout/${params.workout_id}/sort-block`, params).then((resp) => {
            store.dispatch('getWorkoutBlocks', params.workout_id);
            Toastr.success('Workout blocks has been sorted successfully!');
        }).catch((err) => {
            console.log(err);
            Toastr.handleServerError(err);
        });
    },

    sortBlocksExercise (store, params) {
        axios.patch(`/workout/block/${params.workout_block_id}/sort-block-exercise`, params).then((resp) => {
            store.dispatch('getWorkoutBlocks', params.workout_id);
            Toastr.success('Workout blocks exercise has been sorted successfully!');
        }).catch((err) => {
            console.log(err);
            Toastr.handleServerError(err);
        });
    },

    updateBlockExercise (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', true);

            axios.patch(`/workout/block/${params.workout_block_id}/update-exercise`, params).then((resp) => {
                Toastr.success('Workout block exercise has been updated successfully!');
                store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', false);
                store.dispatch('getWorkoutBlocks', params.workout_id);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_WORKOUT_BLOCK_EXERCISE_LOADER', false);
                resolve(false);
            });
        });
    },

    createWorkoutAttribute (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/workout/create-attribute`, params).then((resp) => {
                Toastr.success('Workout attribute has been created successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteWorkoutAttribute (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/delete-attribute`, { data: params }).then((resp) => {
                Toastr.success('Workout attribute has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameWorkout (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`workout/${params.id}/rename`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteWorkout (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('/workout/delete', { params }).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    duplicateWorkout (store, params) {
        return new Promise((resolve, reject) => {
            axios.post('/workout/duplicate', params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateFavouriteExercise (store, params) {
        return new Promise(function (resolve, reject) {
            axios.patch(`/workout/update-favourite-exercise`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    createWorkoutExercise (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/workout/create-exercise`, params).then((resp) => {
                Toastr.success('Workout exercise has been created successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAllWorkouts (store, params) {
        store.commit('SET_ALL_WORKOUT_LOADER_STATUS', true);

        axios.get(`/workout/all`, params).then((resp) => {
            store.commit('SET_ALL_WORKOUT_LOADER_STATUS', false);
            store.commit('SET_ALL_WORKOUTS', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_ALL_WORKOUT_LOADER_STATUS', false);
        });
    },

    deleteWorkoutExercise (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/delete-exercise`, { params }).then((resp) => {
                Toastr.success(`${params.ids.length > 1 ? 'Exercises' : 'Exercise'} has been deleted successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    assignContacts (store, params) {
        return new Promise((resolve, reject) => {
            params = JSON.parse(JSON.stringify(params));
            const path = params.assetType == 'plan' ? 'workout-plan' : 'workout';
            delete params.assetType;

            store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', true);

            axios.post(`/${path}/add-contact`, params).then((resp) => {
                store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Workout plan has been assigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    unassignContacts (store, params) {
        return new Promise((resolve, reject) => {
            params = JSON.parse(JSON.stringify(params));
            const path = params.assetType == 'plan' ? 'workout-plan' : 'workout';
            delete params.assetType;

            store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', true);

            axios.post(`/${path}/remove-contact`, params).then((resp) => {
                store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Workout plan has been unassigned successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAssignContacts (store, params) {
        params = JSON.parse(JSON.stringify(params));
        const path = params.assetType == 'plan' ? 'workout-plan' : 'workout';
        delete params.assetType;

        store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', true);

        axios.get(`/${path}/${params.id}/contacts`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);

            if (params.analytic) {
                store.commit('SET_ACTIVE_CONTACTS', resp.data);
            } else if (params.status == 'active') {
                store.commit('SET_ACTIVE_CONTACTS', resp.data);
            } else if (params.status == 'deleted') {
                store.commit('SET_DELETED_CONTACTS', resp.data);
            } else if (params.status == 'optins') {
                store.commit('SET_OPTIN_CONTACTS', resp.data);
            } else if (params.status == 'completed') {
                store.commit('SET_COMPLETED_CONTACTS', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },



    assignedWorkoutPlanList (store, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/workout/${id}/assigned-plans`).then((resp) => {
                store.commit('SET_ASSIGNED_WORKOUT_PLANS', resp.data);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updatePinAttributeType (store, params) {
        return new Promise(function (resolve, reject) {
            axios.patch(`/workout/pin-attribute-type`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    resendReminder (store, params) {
        params = JSON.parse(JSON.stringify(params));
        const path = params.assetType == 'plan' ? 'workout-plan' : 'workout';
        delete params.assetType;

        return new Promise((resolve, reject) => {
            axios.patch(`/${path}/${params.id}/resend-reminder`, params).then((resp) => {
                Toastr.success(`${params.type == 1 ? 'Email' : 'SMS'} reminder has been sent successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    startWorkout (store, params) {
        return new Promise(function (resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/workout/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Workout has been started successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    stopWorkout (store, params) {
        return new Promise(function (resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/workout/remove-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Workout has been stopped successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getWorkoutAnalytics (store, params) {
        store.commit('SET_WORKOUT_ANALYTICS_LOADER_STATUS', true);

        axios.get(`/workout/${params.workout}/analytics`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_ANALYTICS', resp.data);
            store.commit('SET_WORKOUT_ANALYTICS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_WORKOUT_ANALYTICS_LOADER_STATUS', false);
        });
    },

    getContactWorkoutActivity (store, params) {
        store.commit('SET_WORKOUT_CONTACT_ACTIVITY_LOADER_STATUS', true);

        axios.get(`/workout/${params.workout}/activity`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_CONTACT_ACTIVITY', resp.data);
            store.commit('SET_WORKOUT_CONTACT_ACTIVITY_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_WORKOUT_CONTACT_ACTIVITY_LOADER_STATUS', false);
        });
    },

    getWorkoutNotes (store, params) {
        store.commit('SET_WORKOUT_NOTES_LOADER_STATUS', true);

        axios.get(`/workout/${params.workout_id}/notes`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_NOTES', resp.data);
            store.commit('SET_WORKOUT_NOTES_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_WORKOUT_NOTES_LOADER_STATUS', false);
        });
    },

    getWorkoutBlockStats (store, params) {
        return new Promise((resolve) => {
            axios.get(`/workout/${params.workout_block_id}/block-stats`, { params }).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    // Plans Action Goes below

    getWorkoutPlans (store, params) {
        return new Promise((resolve) => {
            store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', true);

            axios.get(`/workout-plan/list`, { params }).then((resp) => {
                store.commit('SET_WORKOUT_PLANS', resp.data);
                store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getWorkoutPlanDetails (store, plan) {
        store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', true);

        axios.get(`/workout-plan/${plan}/details`).then((resp) => {
            store.commit('SET_SELECTED_WORKOUT_PLAN', resp.data);
            store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_WORKOUT_PLAN_LOADER_STATUS', false);
        });
    },

    createWorkoutPlan (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/workout-plan/create`, params).then((resp) => {
                Toastr.success('Workout plan has been created successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    saveWorkoutPlan (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_PLAN_UPDATE_LOADER_STATUS', true);

            axios.post(`/workout-plan/store`, params).then((resp) => {
                Toastr.success('Workout plan has been saved successfully!');
                store.commit('SET_WORKOUT_PLAN_UPDATE_LOADER_STATUS', false);
                store.commit('SET_SELECTED_WORKOUT_PLAN', resp.data);
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_WORKOUT_PLAN_UPDATE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getPlanAnalytics (store, params) {
        store.commit('SET_WORKOUT_PLAN_ANALYTICS_LOADER_STATUS', true);

        axios.get(`/workout-plan/${params.plan}/analytics`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_PLAN_ANALYTICS', resp.data);
            store.commit('SET_WORKOUT_PLAN_ANALYTICS_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_WORKOUT_PLAN_ANALYTICS_LOADER_STATUS', false);
        });
    },

    getContactPlanActivity (store, params) {
        store.commit('SET_WORKOUT_PLAN_CONTACT_ACTIVITY_LOADER_STATUS', true);

        axios.get(`/workout-plan/${params.plan}/activity`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_PLAN_CONTACT_ACTIVITY', resp.data);
            store.commit('SET_WORKOUT_PLAN_CONTACT_ACTIVITY_LOADER_STATUS', false);
        }).catch((err) => {
            store.commit('SET_WORKOUT_PLAN_CONTACT_ACTIVITY_LOADER_STATUS', false);
        });
    },

    getAllWorkoutPlans (store, params) {
        store.commit('SET_ALL_WORKOUT_PLAN_LOADER_STATUS', true);

        axios.get(`/workout-plan/all`, params).then((resp) => {
            store.commit('SET_ALL_WORKOUT_PLAN_LOADER_STATUS', false);
            store.commit('SET_ALL_WORKOUT_PLANS', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
            store.commit('SET_ALL_WORKOUT_PLAN_LOADER_STATUS', false);
        });
    },

    createPlanBlankWeek (store, plan) {
        return new Promise((resolve, reject) => {
            store.commit('SET_CREATE_PLAN_WEEK_LOADER', true);

            axios.post(`/workout-plan/${plan}/create-week`).then((resp) => {
                store.commit('SET_CREATE_PLAN_WEEK_LOADER', false);
                Toastr.success('Workout plan week has been created successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_CREATE_PLAN_WEEK_LOADER', false);
                resolve(false);
            });
        });
    },

    renameWorkoutPlan (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`workout-plan/${params.id}/rename`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },


    duplicateWorkoutPlan (store, params) {
        return new Promise((resolve, reject) => {
            axios.post('workout-plan/duplicate', params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteWorkoutPlan (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('/workout-plan/delete', { params }).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    getWorkoutPlanWeeks (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_PLAN_WEEK_LOADER', true);

            axios.get(`/workout-plan/${params.workout_plan_id}/weeks`, { params }).then((resp) => {
                store.commit('SET_WORKOUT_PLAN_WEEK_LOADER', false);
                store.commit('SET_WORKOUT_PLAN_WEEKS', resp.data);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_WORKOUT_PLAN_WEEK_LOADER', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateWorkoutPlanWeek (store, params) {
        const isNotification = typeof params.is_notification === "undefined" || params.is_notification ? true : false;
        delete params.is_notification;

        return new Promise((resolve, reject) => {
            store.commit('SET_UPDATE_WORKOUT_PLAN_WEEK_LOADER', true);

            axios.patch(`/workout-plan/week/${params.id}/update`, params).then((resp) => {
                store.commit('SET_UPDATE_WORKOUT_PLAN_WEEK_LOADER', false);

                if (isNotification) {
                    Toastr.success('Workout plan week has been updated successfully!');
                }

                resolve(true);
            }).catch((err) => {
                store.commit('SET_UPDATE_WORKOUT_PLAN_WEEK_LOADER', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },


    createSmartWeek (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_WORKOUT_PLAN_SMART_WEEK_LOADER_STATUS', true);

            axios.post(`/workout-plan/week/${params.id}/create-smart-week`, params).then((resp) => {
                store.commit('SET_WORKOUT_PLAN_SMART_WEEK_LOADER_STATUS', false);
                store.dispatch('getPlanSmartWeeks');
                Toastr.success('Workout plan smart week has been created successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_WORKOUT_PLAN_SMART_WEEK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    savePlanWeekWorkout (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', true);

            axios.post(`/workout-plan/week/${params.workout_plan_week_id}/save-plan-week-workout`, params).then((resp) => {
                Toastr.success('Workout plan week workouts has been saved successfully!');
                store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    storePlanWeekWorkouts (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', true);

            axios.post(`/workout-plan/week/${params.workout_plan_week_id}/store-week-workouts`, params).then((resp) => {
                Toastr.success('Workout plan week workouts has been saved successfully!');
                store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_SAVE_PLAN_WEEK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    deletePlanWeek (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout-plan/week/${params.id}/delete`, { data: params }).then((resp) => {
                Toastr.success('Workout plan week has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicatePlanWeek (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PLAN_WEEK_DUPLICATE_LOADER_STATUS', true);

            axios.post(`/workout-plan/week/${params.id}/duplicate`, params).then((resp) => {
                Toastr.success('Workout plan week has been duplicated successfully!');
                store.commit('SET_PLAN_WEEK_DUPLICATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAN_WEEK_DUPLICATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortWeekWorkouts (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/workout-plan/week/${params.workout_plan_week_id}/sort-week-workout`, params).then((resp) => {
                Toastr.success('Workout plan week workout has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortPlanWeeks (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/workout-plan/${params.workout_plan_id}/sort-week`, params).then((resp) => {
                Toastr.success('Workout plan week has been sorted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getPlanSmartWeeks (store) {
        store.commit('SET_Plan_SMART_WEEK_LOADER_STATUS', true);

        axios.get(`/workout-plan/smart-weeks`).then((resp) => {
            store.commit('SET_Plan_SMART_WEEKS', resp.data);
            store.commit('SET_Plan_SMART_WEEK_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_Plan_SMART_WEEK_LOADER_STATUS', false);
        });
    },

    addPlanSmartWeek (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_CREATE_PLAN_WEEK_LOADER', true);

            axios.post(`/workout-plan/${params.workout_plan_id}/add-smart-week`, params).then((resp) => {
                store.commit('SET_CREATE_PLAN_WEEK_LOADER', false);
                Toastr.success('Workout plan week has been created successfully!');
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CREATE_PLAN_WEEK_LOADER', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteSmartWeek (store, weekId) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout-plan/week/${weekId}/delete-smart-week`).then((resp) => {
                store.dispatch('getPlanSmartWeeks');
                Toastr.success('Workout plan smart week has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getWorkoutPlanDayDetail (store, params) {
        axios.get(`/workout-plan/${params.workout_plan_id}/day-detail`, { params }).then((resp) => {
            store.commit('SET_WORKOUT_PLAN_DAY', resp.data);
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    startWorkoutPlan (store, params) {
        return new Promise(function (resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/workout-plan/add-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Workout has been started successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    stopWorkoutPlan (store, params) {
        return new Promise(function (resolve, reject) {
            delete params.action;

            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

            axios.post('/workout-plan/remove-contact', params).then((resp) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Habit Scenarios', { root: true });
                Toastr.success('Workout has been stopped successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }

                resolve(true);
            }).catch((err) => {
                store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateWorkoutExercise (store, params) {
        return new Promise((resolve, reject) => {
            axios.post(`/workout/update-exercise`, params).then((resp) => {
                Toastr.success('Workout exercise has been update successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getContactWorkouts (store, params) {
        store.commit('SET_CONTACT_WORKOUT_LOADER_STATUS', true);

        axios.get(`/workout/${params.contact_id}/contact-workouts`, { params }).then((resp) => {
            store.commit('SET_CONTACT_WORKOUT_LOADER_STATUS', false);
            store.commit('SET_CONTACT_WORKOUTS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_WORKOUT_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getContactWorkoutPlans (store, params) {
        store.commit('SET_CONTACT_WORKOUT_PLAN_LOADER_STATUS', true);

        axios.get(`/workout-plan/${params.contact_id}/contact-workout-plans`, { params }).then((resp) => {
            store.commit('SET_CONTACT_WORKOUT_PLAN_LOADER_STATUS', false);
            store.commit('SET_CONTACT_WORKOUT_PLANS', resp.data);
        }).catch((err) => {
            store.commit('SET_CONTACT_WORKOUT_PLAN_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getWorkoutPlanReport (store, params) {
        return new Promise((resolve) => {
            store.commit('SET_CONTACT_WORKOUT_PLAN_REPORT_LOADER_STATUS', true);

            axios.get(`/workout-plan/${params.plan_id}/contact-report`, { params: params.params }).then((resp) => {
                store.commit('SET_CONTACT_WORKOUT_PLAN_REPORT_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CONTACT_WORKOUT_PLAN_REPORT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    getWorkoutPlanExerciseHistory (store, params) {
        return new Promise((resolve) => {
            store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', true);

            axios.get(`/workout-plan/${params.plan_id}/contact-exercise-history`, { params: params.params }).then((resp) => {
                store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    getWorkoutReport (store, params) {
        return new Promise((resolve) => {
            store.commit('SET_CONTACT_WORKOUT_REPORT_LOADER_STATUS', true);

            axios.get(`/workout/${params.workout_id}/contact-report`, { params: params.params }).then((resp) => {
                store.commit('SET_CONTACT_WORKOUT_REPORT_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CONTACT_WORKOUT_REPORT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    getWorkoutExerciseHistory (store, params) {
        return new Promise((resolve) => {
            store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', true);

            axios.get(`/workout/${params.workout_id}/contact-exercise-history`, { params: params.params }).then((resp) => {
                store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        })
    },

    updateWorkoutBlockExercise (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/workout/block-exercise/${params.id}/update`, params).then((resp) => {
                Toastr.success('Workout block exercise has been updated successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    duplicateWorkoutBlockExercise (store, exerciseId) {
        return new Promise((resolve, reject) => {
            axios.patch(`/workout/block-exercise/${exerciseId}/duplicate`).then((resp) => {
                Toastr.success('Workout block exercise has been duplicate successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getWorkoutCategories (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_CONTACT_WORKOUT_CATEGORY_LOADER_STATUS', true);

            axios.get(`/workout/category/lists`, { params }).then((resp) => {
                store.commit('SET_CONTACT_WORKOUT_CATEGORIES', resp.data);
                store.commit('SET_CONTACT_WORKOUT_CATEGORY_LOADER_STATUS', false);
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_CONTACT_WORKOUT_CATEGORY_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    createWorkoutCategory (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_CONTACT_WORKOUT_CATEGORY_SAVE_LOADER_STATUS', true);

            axios.post(`/workout/category/create`, params).then((resp) => {
                Toastr.success('Workout category has been created successfully!');
                store.commit('SET_CONTACT_WORKOUT_CATEGORY_SAVE_LOADER_STATUS', false);
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_CONTACT_WORKOUT_CATEGORY_SAVE_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    deleteWorkoutCategory (store, categoty) {
        return new Promise((resolve, reject) => {
            axios.delete(`/workout/category/${categoty}/delete`).then((resp) => {
                Toastr.success('Workout category has been deleted successfully!');
                resolve(resp);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },
};

export default actions;
