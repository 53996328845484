<template>
    <div class="modal secondary security card-form" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header">
                <h1 class="sub_header m-0" v-if="paymentIntent">
                    <template v-if="paymentIntent.requiresPaymentMethod">Confirm your {{ paymentIntent.amount }} payment</template>
                    <template v-if="paymentIntent.isSucceeded">Payment Successful</template>
                    <template v-if="paymentIntent.isCancelled">Payment Cancelled</template>
                    <template v-if="paymentIntent.requiresAction || paymentIntent.requiresConfirmation">Payment Confirm</template>
                </h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="setting_wpr mt-3">
                <div v-if="paymentIntentLoader" class="card-loader">
                    <quote-loader />
                </div>
                <div v-else>
                    <template v-if="paymentIntent && paymentIntent.requiresPaymentMethod">
                        <Form @submit="confirmCardPayment" v-slot="{ errors }" class="verify_form">
                            <label>Extra confirmation is needed to process your payment. Please confirm your payment by filling out your payment details below.</label>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Name on Card</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                        <Field type="text" :autocomplete="'nofill'" name="name" v-model="cardForm.name" rules="required" placeholder="Enter Cardholder Name" />
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Card Number</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.card_number }">
                                        <Field autocomplete="off" type="text" name="card_number" v-model="cardForm.card_number">
                                            <div id="card-element"></div>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="card_number" class="text-danger" />
                                </div>
                            </div>
                            <div class="create_btn mt-3">
                                <button :disabled="verifyPaymentLoader" class="primary_btn pointer" v-if="paymentIntent.requiresPaymentMethod">
                                    <i class="fa fa-spinner fa-spin" v-if="verifyPaymentLoader"></i>&nbsp;
                                    {{ verifyPaymentLoader ? 'Paying' : `Pay ${paymentIntent.amount}` }}
                                </button>
                            </div>
                        </Form>
                    </template>
                    <template v-if="paymentIntent && paymentIntent.isSucceeded">
                        <Form class="verify_form">
                            <label class="input_label">This payment was already successfully confirmed.</label>
                        </Form>
                    </template>
                    <template v-if="paymentIntent && paymentIntent.isCancelled">
                        <Form class="verify_form">
                            <label class="input_label">This payment was cancelled.</label>
                        </Form>
                    </template>
                    <template v-if="paymentIntent && (paymentIntent.requiresAction || paymentIntent.requiresConfirmation)">
                        <Form class="verify_form">
                            <label class="input_label" v-if="paymentConfirmError">{{ paymentConfirmError }}</label>
                            <button :disabled="verifyPaymentLoader" :class="{ 'mt-2': paymentConfirmError }" type="button" class="primary_btn pointer" @click="confirmPaymentMethod()">
                                <i class="fa fa-spinner fa-spin" v-if="verifyPaymentLoader"></i>&nbsp;
                                {{ verifyPaymentLoader ? 'Confirming' : `Confirm your ${ paymentIntent.amount } payment` }}
                            </button>
                        </Form>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    export default {
        name: 'Payment Action Required',

        data () {
            return {
                verifyPaymentLoader: false,
                cardForm: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                paymentIntent: null,
                paymentIntentLoader: false,
            }
        },

        props: {
            modelValue: Boolean,
            refreshBilling: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.createPaymentIntent();
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
            }),

            stripeElements () {
                return stripeInstance.elements();
            },
        },

        beforeUnmount () {
            const vm = this;

            if (vm.card && !vm.card._destroyed) {
                vm.card.destroy();
            }
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            async confirmCardPayment (form, { setFieldError }) {
                const vm = this;
                vm.verifyPaymentLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: {
                            card: vm.card,
                            billing_details: { name: form.name }
                        }
                    }
                );

                if (error) {
                    vm.verifyPaymentLoader = false;
                    setFieldError('card_number', error.message);
                } else {
                    vm.verifyPaymentLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.closeModal();

                        if (vm.refreshBilling) {
                            vm.refreshBilling();
                        }

                        vm.verifyPaymentLoader = false;
                        vm.paymentIntent = null;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.verifyPaymentLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },

            createPaymentIntent () {
                const vm = this;

                vm.paymentIntentLoader = true;

                axios.post(`${vm.billingApi}/stripe/create-payment-intent`, {}, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {

                    if (resp.data.object == 'checkout.session') {
                        vm.closeModal();
                        vm.paymentIntentLoader  = false;
                        window.location.href    = resp.data.url;
                    } else {
                        vm.paymentIntent        = resp.data;
                        vm.paymentIntentLoader  = false;

                        setTimeout(function () {
                            vm.initializeStripe();
                        }, 10);
                    }
                }).catch((err) => {
                    vm.paymentIntentLoader = false;
                    vm.closeModal();

                    const options = Helper.swalWarningOptions('Oops!', 'Looks like something went wrong, please try after some time.');

                    Swal.fire(options);
                });
            },

            initializeStripe () {
                const vm = this;

                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.card = vm.stripeElements.create('card', { hidePostalCode: true, style });
                vm.card.mount('#card-element');
            },

            async confirmPaymentMethod () {
                const vm = this;

                vm.verifyPaymentLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: vm.paymentIntent.payment_method,
                    }
                );

                if (error) {
                    vm.verifyPaymentLoader = false;

                    if (error.code == 'payment_intent_authentication_failure') {
                        vm.paymentIntent.requiresPaymentMethod = true;
                        vm.paymentIntent.requiresAction = false;
                        vm.initializeStripe();
                    }
                } else {
                    vm.verifyPaymentLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.closeModal();

                        if (vm.refreshBilling) {
                            vm.refreshBilling();
                        }

                        vm.verifyPaymentLoader = false;
                        vm.paymentIntent = null;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.verifyPaymentLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .card-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .setting_wpr {
        width: 100%;
    }
</style>
