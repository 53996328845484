const mutations = {
    SET_ALL_JOURNEYS (state, journeys) {
        state.allJourneys = journeys;
    },

    SET_JOURNEYS (state, journeys) {
        state.journeys = journeys;
    },

    SET_JOURNEY_LOADER_STATUS (state, status) {
        state.journeyLoader = status;
    },

    SET_JOURNEY_UPDATE_LOADER_STATUS (state, status) {
        state.journeyUpdateLoader = status;
    },

    SET_SELECTED_JOURNEY (state, journey) {
        state.selectedJourney = journey;
    },

    SET_JOURNEY_USERS_LOADER_STATUS (state, status) {
        state.journeyUsersLoader = status;
    },

    SET_JOURNEY_CREATE_LOADER_STATUS (state, status) {
        state.journeyCreateLoader = status;
    },

    SET_JOURNEY_USERS (state, users) {
        state.journeyUsers = users;
    },

    SET_JOURNEY_LINKS (state, links) {
        state.journeyLinks = links;
    },

    SET_JOURNEY_ADD_TRIGGER_ACTION_LOADER (state, status) {
        state.journeyAddTriggerActionLoader = status;
    },

    SET_JOURNEY_TRIGGER_SAVE_LOADER (state, status) {
        state.triggerSaveLoader = status;
    },

    SET_JOURNEY_ACTION_SAVE_LOADER (state, status) {
        state.actionSaveLoader = status;
    },

    SET_JOURNEY_HISTORY_LOADER (state, status) {
        state.journeyHistoryLoader = status;
    },

    SET_JOURNEY_HISTORIES (state, links) {
        state.journeyHistories = links;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'ordering',
                            order: 'desc',
                            folder_id: null,
                        };
    },
};

export default mutations;
