import router from '@/routes'
import Swal from 'sweetalert2'
import Helper from '@/utils/Helper'
import store from '@/store'
import moment from 'moment'

const axios = require('axios')
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
      axios.defaults.headers.common['Accept'] = 'application/json'
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      axios.defaults.baseURL = process.env.VUE_APP_API_URL+'/api/v3/'

setTimeout(() => {
    let auth = store.state.authModule.authToken;

    if (!auth) {
        auth = localStorage.hasOwnProperty('authToken') && isJson(localStorage.getItem('authToken')) ? JSON.parse(localStorage.getItem('authToken')) : {};
    }

    if (auth) {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+auth.access_token;
        axios.defaults.headers.common['accessed-location-id'] = auth.accessed_location_id;
        axios.defaults.headers.common['company-admin-id'] = auth.company_admin_id;
    }
}, 1);

const modules = [
    'contact/add',
    'contact/import-contacts'
];

axios.interceptors.response.use(function (response) {
    if (['post', 'delete'].includes(response.config.method) && modules.includes(response.config.url.replace(response.config.baseURL, ''))) {
        axios.get('membership/plan-limit').then((resp) => {
            store.commit('authModule/SET_USER_MEMBERSHIP', resp.data.membership);
        }).catch((err) => {
            console.log('Unable to get membership plan limit', err);
        });
    }

    return response;
}, function (error) {
    if (!error.response.request.responseURL.includes(process.env.VUE_APP_BILLING_API_URL) && !error.response.request.responseURL.includes(process.env.VUE_APP_CHECKOUT_API_URL)) {
        if (401 === error.response.status) {
            const loggedOutAt = store.state.authModule.loggedOutAt;

            store.commit('authModule/LOGOUT_USER', false, { root: true });
            store.commit('RESET_STATE', false, { root: true });

            if (loggedOutAt && moment().diff(loggedOutAt, 'minutes') > 2) {
                const options = Helper.swalWarningOptions('Session Expired', 'Your session has expired. Please refresh page to continue.');

                Swal.fire(options).then(() => {
                    window.location.reload();
                });
            }

            if (loggedOutAt == 0) {
                store.commit('authModule/SET_LOGGED_OUT_AT', false, { root: true });
            }
        } else if (403 === error.response.status) {
            if (error.response.data && error.response.data.email_exists) {
                const options = Helper.swalConfirmOptions('', `Looks like you're already registered with <b>${error.response.data.email}</b>, <a href="/login">click here<a> to login`, 'Login');
                options.preConfirm  = function () {
                                          return router.push({ name: 'Login' });
                                      }

                Swal.fire(options);
            } else {
                router.push({ name: 'Error-403' });
            }
        } else {
            return Promise.reject(error);
        }
    } else if (store.state.authModule.authToken && error.response.request.responseURL.includes(process.env.VUE_APP_CHECKOUT_API_URL)) {
        const lastLoggedOutAt = localStorage.getItem('checkout_loggedout_at');

        if (!lastLoggedOutAt || (lastLoggedOutAt && moment().diff(lastLoggedOutAt, 'minutes') > 2)) {
            localStorage.setItem('checkout_loggedout_at', moment());

            const user = store.state.authModule.user;
            // const options = Helper.swalWarningOptions('Session Expired', 'Your session has expired. Please refresh page to continue.');
            // 
            // Swal.fire(options);
            store.dispatch('checkoutModule/createCheckoutToken', { email: user.email, account_id: user.id, access_token: user.access_token }, { root: true });
        }

        return Promise.reject(error);
    } else if (error.response.request.responseURL.includes(process.env.VUE_APP_BILLING_API_URL)) {
        return Promise.reject(error);
    }
});

function isJson (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export default axios;