import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getCategories (store) {
        return new Promise(function(resolve, reject) {
            axios.get('/template/categories').then((resp) => {
                store.commit('SET_CATEGORIES', resp.data);
                store.commit('SET_TEMPLATE_CATEGORY_LOADED_AT');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    getTemplates (store, params) {
        const category = params.category;
        delete params.category;
        store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', true);

        axios.get(`template/${category}/templates`, { params }).then((resp) => {
            store.commit('SET_TEMPLATES', resp.data);
            store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', false);
        });
    },

    getAllTemplates (store, params) {
        store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', true);

        axios.get(`template/lists`, { params }).then((resp) => {
            store.commit('SET_ALL_TEMPLATES', resp.data);
            store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_TEMPLATE_AJAX_LOADER_STATUS', false);
        });
    },

    sendTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEMPLATE_LOADER_STATUS', true);

            axios.post('/template/send-to-contact', params).then((resp) => {
                store.commit('SET_TEMPLATE_LOADER_STATUS', false);

                if (resp.data.status) {
                    store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                    Toastr.success('Template has been queued successfully!');
                    resolve(true);
                } else {
                    Toastr.error(resp.data.response);
                    resolve(false);
                }
            }).catch((err) => {
                store.commit('SET_TEMPLATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false)
            });
        });
    },

    getTemplateList (store, params) {
        store.commit('SET_TEMPLATE_LIST_LOADER_STATUS', true);

        axios.get(`template/template-list`, { params }).then((resp) => {
            store.commit('SET_TEMPLATE_LIST', resp.data);
            store.commit('SET_TEMPLATE_LIST_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_TEMPLATE_LIST_LOADER_STATUS', false);
        });
    },

    changeCategory (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`template/change-category`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    duplicateTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/template/duplicate', params).then((resp) => {
                Toastr.success(`${params.length > 1 ? 'templates' : 'template'} has been duplicated successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/template/delete', { params }).then((resp) => {
                store.dispatch('getAllTemplates');
                Toastr.success(`${params.length > 1 ? 'templates' : 'template'} has been deleted successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                console.log(err);
                resolve(false);
            });
        });
    },

    addBroadcastsDraft (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/template/add-broadcasts-draft', params).then((resp) => {
                Toastr.success(`${params.length > 1 ? 'templates' : 'template'} has been successfully add to broadcast draft.`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    createCategory (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', true);

            axios.post('/template/create-category', params).then((resp) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.success(`Template category has been successfully created.`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    editCategory (store, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/template/${params.id}/edit-category`, params).then((resp) => {
                Toastr.success(`Template category has been successfully updated.`);
                resolve(true);
            }).catch((err) => {
                resolve(err);
            });
        });
    },

    deleteCategory (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete(`/template/${params.id}/delete-category`, { params }).then((resp) => {
                Toastr.success(`Template category has been deleted successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameTemplate (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', true);

            axios.patch('template/rename', params).then((resp) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Template name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    createTemplate (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', true);

            axios.post('template/create', params).then((resp) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Template has been created successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError, true);
                resolve(false);
            });
        });
    },

    editTemplate (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', true);

            axios.patch('template/edit', params).then((resp) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Template has been created successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEMPLATE_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError, true);
                resolve(false);
            });
        });
    },

    getTemplateLogs (store, params) {
        store.commit('SET_TEMPLATE_LOG_LOADER_STATUS', true);

        axios.get(`template/template-logs`, { params }).then((resp) => {
            store.commit('SET_TEMPLATE_LOGS', resp.data);
            store.commit('SET_TEMPLATE_LOG_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_TEMPLATE_LOG_LOADER_STATUS', false);
        });
    }
};

export default actions;
