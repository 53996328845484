import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getDomains (store, params) {
        store.commit('SET_DOMAIN_LOADER', true);

        axios.get('/domain/list', { params }).then((resp) => {
            store.commit('SET_DOMAINS', resp.data);
            store.commit('SET_DOMAIN_LOADER', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_DOMAIN_LOADER', false);
        });
    },

    verifyDomain (store, ids) {
        return new Promise(function(resolve, reject) {
            axios.patch('/domain/verify', { ids }).then((resp) => {
                if (resp.data.status == 1) {
                    Toastr.success(`${ ids.length > 1 ? 'Domains' : 'Domain' } has been verified successfully!`);
                    resolve(true);
                } else {
                    Toastr.error(resp.data.message);
                    resolve(false);
                }
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteDomain (store, ids) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_LOADER', true);

            axios.delete('/domain/delete', { params: { ids } }).then((resp) => {
                Toastr.success(`${ ids.length > 1 ? 'Domains' : 'Domain' } has been deleted successfully!`);
                store.commit('SET_DOMAIN_LOADER', false);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_DOMAIN_LOADER', false);
                resolve(false);
            });
        });
    },

    activateDomain (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch('/domain/activate', { id }).then((resp) => {
                Toastr.success(`Domain has been activated successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveDomain (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_DOMAIN_SAVE_LOADER', true);

            axios.post('/domain/save', params).then((resp) => {
                store.commit('SET_DOMAIN_SAVE_LOADER', false);
                Toastr.success(resp.data.message);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_DOMAIN_SAVE_LOADER', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    enableDisableDomain (store, status) {
        return new Promise(function(resolve, reject) {
            axios.post('/domain/enable-disable', { status }).then((resp) => {
                store.dispatch('authModule/refreshAuth', {}, { root: true });
                Toastr.success(`Custom domain has been ${status ? 'enabled' : 'disabled'} successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

};

export default actions;