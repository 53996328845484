import moment from 'moment'

const mutations = {
    SET_CLIENT_PORTAL (state, portal) {
        state.clientPortal = portal;
    },

    SET_CLIENT_PORTAL_LOADER_STATUS (state, status) {
        state.clientPortalLoader = status;
    },

    SET_UPDATE_LOADER_STATUS (state, status) {
        state.updateLoader = status;
    },

    SET_ASSETS_LOADER_STATUS (state, status) {
        state.assetsLoader = status;
    },

    SET_PLAYBOOKS (state, playbooks) {
        state.playbooks = playbooks;
    },

    SET_PINNED_PLAYBOOKS (state, playbooks) {
        state.pinnedPlaybooks = playbooks;
    },

    SET_PLAYBOOK_CATEGORIES (state, categories) {
        state.playbookCategories = categories;
    },

    SET_PAGES (state, pages) {
        state.pages = pages;
    },

    SET_FORMS (state, forms) {
        state.forms = forms;
    },

    SET_LAST_REFRESHED_AT (state) {
        state.lastRefreshedAt = moment();
    },

    RESET_LAST_REFRESHED_AT (state) {
        state.lastRefreshedAt = null;
    },

    SET_WORKOUTS (state, workouts) {
        state.workouts = workouts;
    },

    SET_WORKOUT_PLANS (state, plans) {
        state.workoutPlans = plans;
    },

    SET_CALENDAR_EVENTS (state, events) {
        state.calendarEvents = events;
    },

    SET_CALENDAR_EVENTS_BY_DATE (state, events) {
        state.calendarEventsByDate = events;
    },

    SET_CALENDAR_EVENTS_LOADER_STATUS (state, status) {
        state.calendarEventLoader = status;
    },

    SET_FILES (state, files) {
        state.files = files;
    },

    SET_TAB_RENAME_LOADER_STATUS (state, field) {
        state.tabRenameLoader[field.field] = field.status;
    },

    SET_CALENDAR_TITLE_LOADER_STATUS (state, field) {
        state.calendarTitleLoader[field.field] = field.status;
    },

    SET_ANNOUNCEMENT_SETTINGS_ADD_LOADER_STATUS (state, status) {
        state.announcementSettingAddLoader = status;
    },

    SET_ANNOUNCEMENT_SETTINGS_UPDATE_LOADER_STATUS (state, status) {
        state.announcementSettingUpdateLoader = status;
    },

    SET_ANNOUNCEMENT_SETTINGS_DELETE_LOADER_STATUS (state, status) {
        state.announcementSettingDeleteLoader = status;
    },
};

export default mutations;