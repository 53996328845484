import axios from '@/services/axios'

const actions = {
    getActivityModule (store) {
        store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', true);
        
        axios.get('/activity-feed/get-activity-module').then((resp) => {
            store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', false);
            store.commit('SET_MODULES', resp.data);
        }).catch((err) => {
            store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', false);
            console.log(err);
        });
    },

    getNotification (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', true);

            axios.get('/activity-feed/get-notification', { params }).then((resp) => {
                store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_ACTIVITY_LOG_LOADER_STATUS', false);
                console.log(err);
                resolve(false)
            });
        });
    },

};

export default actions;