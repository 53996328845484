const mutations = {
    SET_EMAIL_REPORT_LOADER_STATUS (state, value) {
        state.emailReportLoader = value;
    },

    SET_EMAIL_REPORTS (state, data) {
        state.emailReports = data;
    },

    SET_DELIVERY_LOG (state, data) {
        state.deliveryLog = data;
    },

    SET_DELIVERY_LOG_LOADER_STATUS (state, value) {
        state.deliveryLogLoader = value;
    },
};

export default mutations;