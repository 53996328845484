const mutations = {
    SET_OPTIN_SETTINGS (state, tags) {
        state.optinSettings = tags;
    },

    SET_OPTIN_SETTINGS_LOADER_STATUS (state, status) {
        state.optinSettingsLoaderSatatus = status;
    },

    SET_OPTIN_SETTINGS_UPDATE_LOADER_STATUS (state, status) {
        state.optinSettingsUpdateLoaderSatatus = status;
    },
};

export default mutations;
