const mutations = {
    SET_ALL_TASKS (state, tasks) {
        state.allTasks = tasks;
    },

    SET_ALL_USER_TASKS (state, tasks) {
        state.allUserTasks = tasks;
    },

    SET_TASK_LOADER_STATUS (state, value) {
        state.taskLoader = value;
    },
};

export default mutations;