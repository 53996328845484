const mutations = {
    SET_ALL_PAGES (state, pages) {
        state.allPages = pages;
    },

    SET_PAGE_LOADER_STATUS (state, value) {
        state.pageLoader = value;
    },

    SET_PAGE_DETAIL_LOADER_STATUS (state, value) {
        state.pageDetailLoader = value;
    },

    SET_PAGES_STATUS (state, value) {
        state.pages = value;
    },

    SET_SELECTED_PAGE (state, page) {
        let headline_setting = page.headline_setting ? JSON.parse(JSON.stringify(page.headline_setting)) : {};
        let subheadline_setting = page.subheadline_setting ? JSON.parse(JSON.stringify(page.subheadline_setting)) : {};

        if (!headline_setting.font_size) {
            headline_setting = {
                font_family: 'Inter',
                font_weight: 600,
                font_size: 50,
                font_color: '#2C3E50',
                has_shadow: headline_setting.has_shadow ? headline_setting.has_shadow :  0,
                shadow_x: headline_setting.shadow_x ? headline_setting.shadow_x : 0,
                shadow_y: headline_setting.shadow_y ? headline_setting.shadow_y : 4,
                shadow_blur: headline_setting.shadow_blur ? headline_setting.shadow_blur : 4,
                shadow_color: headline_setting.shadow_color ? headline_setting.shadow_color : '#2F7FED',
            };
        }

        if (!subheadline_setting.font_size) {
            subheadline_setting = {
                font_family: 'Inter',
                font_weight: 500,
                font_size: 25,
                font_color: '#2C3E50',
                has_shadow: subheadline_setting.has_shadow ? subheadline_setting.has_shadow :  0,
                shadow_x: subheadline_setting.shadow_x ? subheadline_setting.shadow_x : 0,
                shadow_y: subheadline_setting.shadow_y ? subheadline_setting.shadow_y : 4,
                shadow_blur: subheadline_setting.shadow_blur ? subheadline_setting.shadow_blur : 4,
                shadow_color: subheadline_setting.shadow_color ? subheadline_setting.shadow_color : '#2F7FED',
            };
        }

        page.headline_setting     = headline_setting;
        page.subheadline_setting  = subheadline_setting;

        state.selectedPage = page;
    },

    SET_PAGE_UPDATE_LOADER_STATUS (state, value) {
        state.pageUpdateLoader = value;
    },

    SET_PAGE_COMPONENT_LOADER_STATUS (state, value) {
        state.pageComponentLoader = value;
    },

    SET_ASSIGNED_MEMBERS_STATUS (state, contacts) {
        state.assignedMembers = contacts;
    },

    SET_PAGE_DOWNLOADS (state, downloads) {
        state.downloads = downloads;
    },

    SET_PAGE_DOWNLOAD_LOADER_STATUS (state, status) {
        state.downloadLoader = false;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                          per_page: 6,
                          page: 1,
                          search: '',
                          mode: 'all',
                          order_by: 'ordering',
                          order: 'desc',
                          folder_id: null,
                      };
    },
};

export default mutations;
