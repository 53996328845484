import router from '@/routes'

const mutations = {
    SET_NEWSLETTER_TEMPLATES (store, templates) {
        store.newsletterTemplates = templates;
    },

    SET_NEWSLETTER_TEMPLATES_LOADER_STATUS (store, status) {
        store.newsletterTemplatesLoader = status;
    },

    SET_NEWSLETTER_LOADER_STATUS (store, status) {
        store.newsletterLoader = status;
    },

    SET_NEWSLETTER_UPDATE_LOADER_STATUS (store, status) {
        store.newsletterUpdateLoader = status;
    },

    SET_NEWSLETTER_DOWNLOAD_LOADER_STATUS (store, status) {
        store.newsletterDownloadLoader = status;
    },

    SET_NEWSLETTERS (store, newsletters) {
        store.newsletters = newsletters;
    },

    SET_NEWSLETTER_DOWNLOADS (store, downloads) {
        store.newsletterDownloads = downloads;
    },

    SET_NEWSLETTER_COMPONENT_LOADER_STATUS (store, status) {
        store.newsletterComponentLoader = status;
    },

    SET_RESPONDENTS (store, respondents) {
        store.respondents = respondents;
    },

    SET_NEWSLETTER_TEMPLATE (store, template) {
        store.newsletterTemplate = template;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            order_by: 'order',
                            order: 'desc',
                            folder_id: null,
                        };
    },
};

export default mutations;
