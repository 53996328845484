const template = [
    {
        name: 'TemplatesIndex',
        component: () => import(/* webpackChunkName: "js/template" */ '@/pages/templates/Index'),
        path: '/template',
        meta: {
            title: 'Templates',
            requiresAuth: true,
            accountActive: true,
            group: 'Admin',
        },
    },
];

export default template;
