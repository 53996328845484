const mutations = {
    SET_WORKOUT_DASHBOARD_PARAMS (state, obj) {
        state.workoutParams[obj.key] = obj.value;
    },

    RESET_WORKOUT_DASHBOARD_PARAMS (state) {
        state.workoutParams = {
                                    per_page: 6,
                                    page: 1,
                                    search: '',
                                    order_by: 'order',
                                    order: 'desc',
                              };
    },

    SET_PLAN_DASHBOARD_PARAMS (state, obj) {
        state.planParams[obj.key] = obj.value;
    },

    RESET_PLAN_DASHBOARD_PARAMS (state) {
        state.planParams = {
                                per_page: 6,
                                page: 1,
                                search: '',
                                filter: 'date_created',
                            };
    },

    SET_WORKOUTS (state, workouts) {
        state.workouts = workouts;
    },

    SET_WORKOUT_LOADER_STATUS (state, value) {
        state.workoutLoader = value;
    },

    SET_WORKOUT_ATTRIBUTE_TYPES (state, types) {
        state.workoutAttributeTypes = types.filter(({type}) => ['Exercise Type', 'Body Part', 'Muscle Target', 'Equipment'].includes(type));

        let exerciseAttributes = [];

        if (types && types.length) {
            types.forEach(({attributes}) => {
                attributes.forEach((attribute) => {
                    exerciseAttributes.push({
                        id: attribute.id,
                        name: attribute.name,
                        type: getAttributeFilterType(attribute.workout_attribute_type_id),
                    });
                })
            });
        }

        state.exerciseAttributes = exerciseAttributes;
    },

    SET_WORKOUT_CUSTOM_ATTRIBUTE_TYPES (state, types) {
        state.workoutCustomAttributeTypes = types;
    },

    SET_WORKOUT_ATTRIBUTE_TYPES_LOADER_STATUS (state, value) {
        state.workoutAttributeTypesLoader = value;
    },

    SET_WORKOUT_EXERCISES (state, exercises) {
        state.workoutExercises = exercises;
    },

    SET_WORKOUT_EXERCISES_LOADER_STATUS (state, value) {
        state.workoutExercisesLoader = value;
    },

    SET_WORKOUT_UPDATE_LOADER_STATUS (state, value) {
        state.workoutUpdateLoader = value;
    },

    SET_CREATE_BLOCK_LOADER_STATUS (state, value) {
        state.createBlockLoader = value;
    },

    SET_UPDATE_BLOCK_LOADER_STATUS (state, value) {
        state.updateBlockLoader = value;
    },

    SET_WORKOUT_BLOCKS (state, blocks) {
        state.workoutBlocks = blocks;
    },

    SET_WORKOUT_BLOCK_LOADER (state, value) {
        state.workoutBlockLoader = value;
    },

    SET_SELECTED_WORKOUT (state, workout) {
        state.selectedWorkout = workout;
    },

    SET_WORKOUT_BLOCK_EXERCISE_LOADER (state, value) {
        state.workoutBlockExerciseLoader = value;
    },

    SET_WORKOUT_BLOCK_DUPLICATE_LOADER_STATUS (state, value) {
        state.workoutBlockDuplicateLoader = value;
    },

    SET_WORKOUT_SMART_BLOCKS (state, blocks) {
        state.workoutSmartBlocks = blocks;
    },

    SET_WORKOUT_SMART_BLOCK_LOADER_STATUS (state, value) {
        state.workoutSmartBlockLoader = value;
    },

    SET_WORKOUT_PLAN_LOADER_STATUS (state, status) {
        state.workoutPlanLoader = status;
    },

    SET_WORKOUT_PLANS (state, plans) {
        state.workoutPlans = plans;
    },

    SET_SELECTED_WORKOUT_PLAN (state, plans) {
        state.selectedWorkoutPlan = plans;
    },

    SET_WORKOUT_PLAN_UPDATE_LOADER_STATUS (state, status) {
        state.workoutPlanUpdateLoader = status;
    },

    SET_WORKOUT_PLAN_WEEKS (state, weeks) {
        state.workoutPlanWeeks = weeks;
    },

    SET_WORKOUT_PLAN_WEEK_LOADER (state, status) {
        state.workoutPlanWeekLoader = status;
    },

    SET_ALL_WORKOUTS (state, workouts) {
        state.allWorkouts = workouts;
    },

    SET_ALL_WORKOUT_LOADER_STATUS (state, status) {
        state.allWorkoutLoader = status;
    },

    SET_ALL_WORKOUT_PLANS (state, plans) {
        state.allWorkoutPlans = plans;
    },

    SET_ALL_WORKOUT_PLAN_LOADER_STATUS (state, status) {
        state.allWorkoutPlanLoader = status;
    },

    SET_WORKOUT_PLAN_COMPONENT_LOADER_STATUS (state, status) {
        state.workoutPlanComponentLoader = status;
    },

    SET_ACTIVE_CONTACTS (state, contacts) {
        state.activeContacts = contacts;
    },

    SET_DELETED_CONTACTS (state, contacts) {
        state.deletedContacts = contacts;
    },

    SET_OPTIN_CONTACTS (state, contacts) {
        state.optinContacts = contacts;
    },

    SET_COMPLETED_CONTACTS (state, contacts) {
        state.completedContacts = contacts;
    },

    SET_UPDATE_WORKOUT_PLAN_WEEK_LOADER (state, status) {
        state.updateWorkoutPlanLoader = status;
    },

    SET_WORKOUT_PLAN_SMART_WEEK_LOADER_STATUS (state, status) {
        state.workoutPlanSmartWeekLoader = status;
    },

    SET_SAVE_PLAN_WEEK_LOADER_STATUS (state, status) {
        state.savePlanWeekLoader = status;
    },

    SET_PLAN_WEEK_DUPLICATE_LOADER_STATUS (state, status) {
        state.planWeekDuplicateLoader = status;
    },

    SET_Plan_SMART_WEEKS (state, weeks) {
        state.planSmartWeeks = weeks;
    },

    SET_Plan_SMART_WEEK_LOADER_STATUS (state, value) {
        state.planSmartWeekLoader = value;
    },

    SET_ASSIGNED_WORKOUT_PLANS (state, plans) {
        state.assignedWorkoutPlans = plans;
    },

    SET_CREATE_PLAN_WEEK_LOADER (state, status) {
        state.createPlanWeekLoader = status;
    },

    SET_WORKOUT_PLAN_DAY (state, data) {
        state.workoutplanDay = data;
    },

    SET_WORKOUT_PLAN_ANALYTICS (state, analytics) {
        state.planAnalytics = analytics;
    },

    SET_WORKOUT_PLAN_ANALYTICS_LOADER_STATUS (state, status) {
        state.planAnalyticsLoader = status;
    },

    SET_WORKOUT_PLAN_CONTACT_ACTIVITY (state, activity) {
        state.contactPlanActivity = activity;
    },

    SET_WORKOUT_PLAN_CONTACT_ACTIVITY_LOADER_STATUS (state, status) {
        state.contactPlanActivityLoader = status;
    },

    SET_WORKOUT_ANALYTICS (state, analytics) {
        state.workoutAnalytics = analytics;
    },

    SET_WORKOUT_ANALYTICS_LOADER_STATUS (state, status) {
        state.workoutAnalyticsLoader = status;
    },

    SET_WORKOUT_CONTACT_ACTIVITY (state, activity) {
        state.contactWorkoutActivity = activity;
    },

    SET_WORKOUT_CONTACT_ACTIVITY_LOADER_STATUS (state, status) {
        state.contactWorkoutActivityLoader = status;
    },

    SET_WORKOUT_NOTES (state, notes) {
        state.workoutNotes = notes;
    },

    SET_WORKOUT_NOTES_LOADER_STATUS (state, status) {
        state.workoutNotesLoader = status;
    },

    SET_CONTACT_WORKOUTS (state, workouts) {
        state.contactWorkouts = workouts
    },

    SET_CONTACT_WORKOUT_LOADER_STATUS (state, status) {
        state.contactWorkoutLoader = status;
    },

    SET_CONTACT_WORKOUT_PLANS (state, plans) {
        state.contactWorkoutPlans = plans
    },

    SET_CONTACT_WORKOUT_PLAN_LOADER_STATUS (state, status) {
        state.contactWorkoutPlanLoader = status;
    },

    SET_CONTACT_WORKOUT_PLAN_REPORT_LOADER_STATUS (state, status) {
        state.workoutPlanReportLoader = status;
    },

    SET_CONTACT_WORKOUT_REPORT_LOADER_STATUS (state, status) {
        state.workoutReportLoader = status;
    },

    SET_CONTACT_WORKOUT_HISTORY_CHART_LOADER_STATUS (state, status) {
        state.historyChartLoader = status;
    },

    SET_CONTACT_WORKOUT_CATEGORY_LOADER_STATUS (state, status) {
        state.workoutCategoryLoader = status;
    },

    SET_CONTACT_WORKOUT_CATEGORIES (state, categories) {
        state.workoutCategories = categories;
    },

    SET_CONTACT_WORKOUT_CATEGORY_SAVE_LOADER_STATUS (state, status) {
        state.categorySaveLoader = status;
    },
};

    function getAttributeFilterType (id) {
        let type = '';

        if (id == 1) {
            type = 'exercise'
        } else if (id == 2) {
            type = 'part'
        } else if (id == 3 || id == 4) {
            type = 'muscle'
        } else if (id == 5) {
            type = 'equipment'
        } else if (id == 6) {
            type = 'skeletal'
        } else if (id == 7) {
            type = 'movement'
        } else if (id == 8) {
            type = 'position'
        } else if (id == 9) {
            type = 'contraction'
        } else if (id == 10) {
            type = 'difficulty'
        } else if (id == 11) {
            type = 'avatar'
        }

        return type;
    }

export default mutations;
