const mutations = {
    SET_CATEGORIES (state, categories) {
        state.categories = categories;
    },

    SET_TEMPLATES (state, templates) {
        state.templates = templates;
    },

    SET_ALL_TEMPLATES (state, templates) {
        state.allTemplates = templates;
    },

    SET_TEMPLATE_LOADER_STATUS (state, value) {
        state.templateLoader = value;
    },

    SET_TEMPLATE_AJAX_LOADER_STATUS (state, value) {
        state.templateAjaxLoader = value;
    },

    SET_TEMPLATE_LIST (state, templates) {
        state.templateList = templates;
    },

    SET_TEMPLATE_LIST_LOADER_STATUS (state, value) {
        state.templateListLoader = value;
    },

    SET_TEMPLATE_COMPONENT_LOADER_STATUS (state, value) {
        state.templateComponentLoader = value;
    },

    SET_TEMPLATE_LOG_LOADER_STATUS (state, value) {
        state.templateLogLoader = value;
    },

    SET_TEMPLATE_LOGS (state, logs) {
        state.templateLogs = logs;
    },

    SET_TEMPLATE_CATEGORY_LOADED_AT (state) {
        state.categoryLoadedAt = Date.now();
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                            per_page: 6,
                            page: 1,
                            search: '',
                            category: '',
                            type: 'all'
                        };
    },
};

export default mutations;
