import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getGroups (store, params) {
        store.commit('SET_ALL_GROUP_LOADER_STATUS', true);

        axios.get('/group/lists', params).then((resp) => {
            store.commit('SET_GROUPS', resp.data);
            store.commit('SET_ALL_GROUP_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ALL_GROUP_LOADER_STATUS', false);
        });
    },

    getGroupContacts (store, params) {
        store.commit('SET_GROUP_LOADER_STATUS', true);

        axios.get('/group/contact-list', { params }).then((resp) => {
            store.commit('SET_GROUP_LOADER_STATUS', false);
            store.commit('SET_CONTACTS', resp.data);
        }).catch((err) => {
            store.commit('SET_GROUP_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getUserGroups (store, params) {
        store.commit('SET_ALL_GROUP_LOADER_STATUS', true);

        axios.get('/group/user-groups', params).then((resp) => {
            store.commit('SET_USER_GROUPS', resp.data);
            store.commit('SET_ALL_GROUP_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ALL_GROUP_LOADER_STATUS', false);
        });
    },

    createGroup (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', true);

            axios.post('/group/create-group', params).then((resp) => {
                store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', false);
                store.dispatch('getGroups');
                Toastr.success('Group has been created successfully!');

                resolve(true);
            }).catch((err) => {
                store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    editGroup (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', true);

            axios.post(`/group/${params.id}/edit-group`, params).then((resp) => {
                store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', false);
                store.dispatch('getGroups');
                Toastr.success('Group has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_GROUP_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteGroup (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/group/${params.id}/delete`, { params }).then((resp) => {
                store.dispatch('getGroups');
                Toastr.success('Group has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            })
        });
    }
};

export default actions;
