const state = {
    newsletterTemplates: [],
    newsletterTemplatesLoader: false,
    newsletterLoader: false,
    newsletterUpdateLoader: false,
    newsletterDownloadLoader: false,
    newsletters: {},
    newsletterComponentLoader: false,
    newsletterDownloads: [],
    respondents: {},
    newsletterTemplate: {},
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'order',
        order: 'desc',
        folder_id: null,
    },
};

export default state;