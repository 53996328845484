import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getBroadcasts (store, params) {
        store.commit('SET_BROADCAST_LOADER_STATUS', true);

        axios.get('/broadcast/list', { params }).then((resp) => {
            store.commit('SET_BROADCASTS', resp.data);
            store.commit('SET_BROADCAST_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_BROADCAST_LOADER_STATUS', false);
        });
    },

    deleteBroadcast (store, params) {
        return new Promise((resolve, reject) => {
            axios.delete('/broadcast/delete', { params }).then((resp) => {
                Toastr.success('Broadcast has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicateBroadcast (store, params) {
        return new Promise((resolve, reject) => {
            axios.post('/broadcast/duplicate', params).then((resp) => {
                Toastr.success('Broadcast has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renameBroadcast (store, params) {
        return new Promise( (resolve, reject) => {
            store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', true);

            axios.patch('/broadcast/rename', params).then((resp) => {
                store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', false);
                Toastr.success('Broadcast name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    resendUnopens (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', true);

            axios.post('/broadcast/resend-unopens', params).then((resp) => {
                store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', false);
                Toastr.success('New broadcast has been created successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_BROADCAST_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    saveBroadcast (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_BROADCAST_SAVE_LOADER_STATUS', true);

            axios.post('/broadcast/save', params).then((resp) => {
                store.commit('SET_BROADCAST_SAVE_LOADER_STATUS', false);
                Toastr.success(`Broadcast has been ${params.id ? 'updated' : 'created'} successfully!`);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_BROADCAST_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getBroadcastReciept (store, broadcast) {
        return new Promise(function(resolve, reject) {
            axios.get(`/broadcast/${broadcast}/recipient`).then((resp) => {
                store.commit('SET_BROADCAST_RECIPIENT', resp.data);
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    getBroadcastLogs(store, params) {
        store.commit('SET_BROADCAST_LOGS_LOADER_STATUS', true);

        axios.get('broadcast/broadcast-logs', { params }).then((resp) => {
            store.commit('SET_BROADCAST_LOGS_LOADER_STATUS', false);
            store.commit('SET_BROADCAST_LOGS', resp.data);
        }).catch((err) => {
            store.commit('SET_BROADCAST_LOGS_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    getSupperessionList(store, params) {
        store.commit('SET_SUPPRESSION_LOADER_STATUS', true);

        axios.get('broadcast/suppressed-contacts', { params }).then((resp) => {
            store.commit('SET_SUPPRESSION_LOADER_STATUS', false);

            if (params.type == 'bounces') {
                store.commit('SET_BOUNCES', resp.data);
            } else if (params.type == 'complaints') {
                store.commit('SET_COMPLAINTS', resp.data);
            } else if (params.type == 'unsubscribes') {
                store.commit('SET_UNSUBSCRIBES', resp.data);
            }
        }).catch((err) => {
            store.commit('SET_SUPPRESSION_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    sendBroadcast (store, broadcastId) {
        return new Promise(function(resolve, reject) {
            axios.post(`/broadcast/${broadcastId}/send-broadcast`).then((resp) => {
                Toastr.success(`Broadcast has been queued successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                if (err.response.status == 404 && err.response.data && err.response.data.error == 'limit-error') {
                    resolve('limit-error');
                } else {
                    resolve(false);
                }
            });
        });
    },

    getBroadcastDetails(store, id) {
        store.commit('SET_BROADCAST_DETAIL_LOADER_STATUS', true);

        axios.get(`broadcast/${id}/public-view`).then((resp) => {
            store.commit('SET_BROADCAST_DETAIL_LOADER_STATUS', false);
            store.commit('SET_BROADCAST_DETAIL', resp.data.broadcast);
            store.commit('signatureModule/SET_SIGNATURES', resp.data.signatures, { root: true });
        }).catch((err) => {
            store.commit('SET_BROADCAST_DETAIL_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },
};

export default actions;
