const mutations = {
    SET_CONTENT_CSV_LOADER (state, status) {
        state.contentCsvLoader = status;
    },

    SET_CONTENT_HISTORIES (state, histories) {
        state.contentImportHistories = histories;
    },

    SET_CONTENT_BATCHES (state, batches) {
        state.contentImportBatches = batches;
    },

    SET_LOCATIONS (state, locations) {
        state.locations = locations;
    },

    SET_LOCATION_LOADER_STATUS (state, status) {
        state.locationLoader = status;
    },

    SET_IMPORT_HISTORY_LOADER_STATUS (state, status) {
        state.importHistoryLoader = status;
    },

    SET_CONTENT_CSV_UPLOAD_STATUS (state, status) {
        state.contentCSVUploadStatus = status;
    },

    SET_VERIFIED_FILE_DATA (state, data) {
        state.verifiedFileData = data;
    },
};

export default mutations;