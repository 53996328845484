import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    uploadImage (store, params) {
        return axios.post('/image-library/upload', params);
    },

    getLibraryImages (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', true);

            axios.get('/image-library/images', { params }).then((resp) => {
                store.commit('SET_LIBRARY_IMAGES', resp.data);
                store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
                store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', false);
            });
        });
    },

    getPixelsImages (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', true);

            axios.get('/image-library/pexels-image', { params }).then((resp) => {
                store.commit('SET_PIXEL_IMAGES', resp.data);
                store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                resolve(false);
                store.commit('SET_LIBRARY_IMAGE_LOADER_STATUS', false);
            });
        });
    },

    bulkImageUpload (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_IMAGE_UPLOAD_LOADER_STATUS', true);

            axios.post('/image-library/bulk-upload', params).then((resp) => {
                store.commit('SET_IMAGE_UPLOAD_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_IMAGE_UPLOAD_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getImageTypes (store) {
        axios.get('/image-library/types').then((resp) => {
            store.commit('SET_IMAGE_TYPES', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getAllLibraryImages (store, params) {
        return axios.get('/image-library/all-images', { params });
    },

    deleteImage (store, imageId) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/image-library/${imageId}/delete`).then((resp) => {
                Toastr.success('Image has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getImageBlobFromUrl (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_IMAGE_BLOB_LOADER_STATUS', true);

            axios.get(`/image-library/url-to-blob`, { params }).then((resp) => {
                setTimeout(function () {
                    store.commit('SET_IMAGE_BLOB_LOADER_STATUS', false);
                }, 100);
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_IMAGE_BLOB_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },
};

export default actions;
