const state = {
    allJourneys: [],
    journeyLoader: false,
    journeys: {},
    selectedJourney: {},
    journeyUpdateLoader: false,
    journeyUsersLoader: false,
    journeyUsers: {},
    journeyCreateLoader: false,
    journeyLinks: [],
    journeyAddTriggerActionLoader: false,
    triggerSaveLoader: false,
    actionSaveLoader: false,
    journeyHistoryLoader: false,
    journeyHistories: {},
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'ordering',
        order: 'desc',
        folder_id: null,
    },
};

export default state;
