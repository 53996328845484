const mutations = {
    SET_ALL_FILES (state, files) {
        state.allFiles = files;
    },

    SET_CONTACT_FILES (state, files) {
        state.contactFiles = files;
    },

    SET_FILE_LOADER_STATUS (state, value) {
        state.fileLoader = value;
    },

    SET_FILE_REFRESH_LOADER_STATUS (state, value) {
        state.fileRefreshLoader = value;
    },

    UPDATE_CONTACT_FILES (state, file) {
        state.contactFiles.push(file);
    },

    REMOVE_CONTACT_FILE (state, index) {
        delete state.contactFiles[index];
    },

    SET_CONTACT_FILE_LOADER_STATUS (state, value) {
        state.contactFileLoader = value;
    },

    SET_FILES (state, files) {
        state.files = files;
    },

    SET_FILE_USERS (state, users) {
        state.fileUsers = users;
    },
};

export default mutations;