const mutations = {
    SET_TAGS (state, tags) {
        state.tags = tags;
    },

    SET_TAGS_GROUP (state, tags) {
        state.tagsGroup = tags;
    },

    SET_TAG_LOADER_STATUS (state, status) {
        state.tagLoader = status;
    },

    SET_ALL_TAG_LOADER_STATUS (state, status) {
        state.allTagLoader = status;
    },

    SET_CONTACTS (state, contacts) {
        state.contacts = contacts;
    },
};

export default mutations;
