const clientPortal = [
    {
        name: 'ClientPortalIndex',
        component: () => import(/* webpackChunkName: "js/client-portal" */ '@/pages/client-portal/Index'),
        path: '/client-portal',
        meta: {
            title: 'Client Portal',
            requiresAuth: true,
            accountActive: true,
        },
    },
];

export default clientPortal;