import axios from '@/services/axios'

const actions = {
    getVideos (store) {
        axios.get('/setup-guide/videos').then((resp) => {
            store.commit('SET_SETUP_GUIDE_VIDEOS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getQuestions (store) {
        axios.get('/setup-guide/questions').then((resp) => {
            store.commit('SET_SETUP_GUIDE_QUESTIONS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getProgress (store, batch) {
        store.commit('SET_SETUP_GUIDE_PROGRESS_LOADER_STATUS', true);

        axios.get(`/setup-guide/${batch}/progress`).then((resp) => {
            store.commit('SET_SETUP_GUIDE_PROGRESS', resp.data);
            store.commit('SET_SETUP_GUIDE_PROGRESS_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SETUP_GUIDE_PROGRESS_LOADER_STATUS', false);
        });
    },

    getCurrentProgress (store) {
        axios.get('/setup-guide/current-progress').then((resp) => {
            store.commit('SET_SETUP_GUIDE_CURRENT_PROGRESS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    saveQuestionAnswer (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/setup-guide/save-question-answer', params).then((resp) => {
                store.commit('SET_SETUP_GUIDE_PROGRESS', resp.data);

                resolve(true);
            }).catch((err) => {
                resolve(false)
            });
        });
    },

    markVideoComplete (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/setup-guide/mark-video-complete', params).then((resp) => {
                store.commit('SET_SETUP_GUIDE_VIDEOS', resp.data);

                resolve(true);
            }).catch((err) => {
                resolve(false)
            });
        });
    },

    getHistories (store) {
        return new Promise(function(resolve, reject) {
            axios.get('/setup-guide/histories').then((resp) => {
                store.commit('SET_SETUP_GUIDE_HISTORIES', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    finishQuestions (store) {
        return new Promise(function(resolve, reject) {
            axios.post('/setup-guide/finish-questions').then((resp) => {
                store.commit('SET_SETUP_GUIDE_CURRENT_PROGRESS', resp.data);
                store.dispatch('getHistories');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    retakeAssesment (store) {
        return new Promise(function(resolve, reject) {
            axios.post('/setup-guide/retake-assesment').then((resp) => {
                store.commit('SET_SETUP_GUIDE_CURRENT_PROGRESS', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },
};

export default actions;
