const state = {
    allPages: [],
    pageLoader: false,
    pageDetailLoader: false,
    pages: {},
    selectedPage: {},
    pageUpdateLoader: false,
    pageComponentLoader: false,
    assignedMembers: {},
    downloads: [],
    downloadLoader: false,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        mode: 'all',
        order_by: 'ordering',
        order: 'desc',
        folder_id: null,
    },
};

export default state;
