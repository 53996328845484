import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getSignature (store, params) {
        store.commit('SET_SIGNATURE_LOADER_STATUS', true);

        axios.get('/signature/list', params).then((resp) => {
            store.commit('SET_SIGNATURE_LOADER_STATUS', false);
            store.commit('SET_SIGNATURES', resp.data.signatures);
            store.commit('SET_DEFAULT_SIGNATURE', resp.data.default);
            store.commit('SET_APPLY_TO_ALL', resp.data.apply_to_all);
        }).catch((err) => {
            store.commit('SET_SIGNATURE_LOADER_STATUS', false);
            console.log(err);
        });
    },

    storeSignature (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SIGNATURE_LOADER_STATUS', true);

            axios.post('/signature/store', params).then((resp) => {
                store.commit('SET_SIGNATURE_LOADER_STATUS', false);
                store.dispatch('getSignature', {});
                Toastr.success(`Email signature was successfully ${params.id ? 'created' : 'updated'}!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SIGNATURE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError, true);
                resolve(false);
            });
        });
    },

    getSignatureList (store, params) {
        store.commit('SET_SIGNATURE_LOADER_STATUS', true);

        axios.get('/signature/get-signature', { params }).then((resp) => {
            store.commit('SET_ALL_SIGNATURES', resp.data);
            store.commit('SET_SIGNATURE_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_SIGNATURE_LOADER_STATUS', false);
        });
    },

    deleteSignature (store, id) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_SIGNATURE_LOADER_STATUS', true);

            axios.delete(`/signature/${id}/delete`).then((resp) => {
                store.dispatch('getSignature', {});
                store.commit('SET_SIGNATURE_LOADER_STATUS', false);
                Toastr.success('Email signature was successfully deleted');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_SIGNATURE_LOADER_STATUS', false);
                console.log(err);
                resolve(false);
            });
        });
    }
};

export default actions;
