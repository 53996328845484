const state = {
    broadcasts: {},
    broadcastLoader: false,
    broadcastComponentLoader: false,
    broadcastSaveLoader: false,
    recipient: {},
    broadcastLogs: {},
    broadcastLogsLoader: false,
    suppressionLoader: false,
    bounces: {},
    complaints: {},
    unsubscribes: {},
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'id',
        order: 'desc',
        type: 'all',
    },
    broadcastDetailLoader: false,
    broadcastDetail: {},
    totalBroadcastEmailsSent: 0,
}

export default state;
