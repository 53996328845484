import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    verifyContentCsv (store, params) {
        store.commit('SET_CONTENT_CSV_LOADER', true);

        axios.post('/content-upload/verify-csv', params).then((resp) => {
            store.commit('SET_CONTENT_CSV_LOADER', false);

            if (resp.data) {
                store.commit('SET_VERIFIED_FILE_DATA', resp.data);
            }
        }).catch((err) => {
            Toastr.handleServerError(err, params.setFieldError);
            store.commit('SET_CONTENT_CSV_LOADER', false);
        });
    },

    getLocations (store) {
        store.commit('SET_LOCATION_LOADER_STATUS', true);

        axios.get('/content-upload/locations').then((resp) => {
            store.commit('SET_LOCATION_LOADER_STATUS', false);
            store.commit('SET_LOCATIONS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            store.commit('SET_LOCATION_LOADER_STATUS', false);
        });
    },

    importContentCsv (store, params) {
        store.commit('SET_CONTENT_CSV_LOADER', true);
        store.commit('SET_CONTENT_CSV_UPLOAD_STATUS', 0);

        axios.post('/content-upload/import-contents', params).then((resp) => {
            store.commit('SET_CONTENT_CSV_LOADER', false);

            if (resp.data.status) {
                store.commit('SET_CONTENT_CSV_UPLOAD_STATUS', 1);

                store.dispatch('formModule/getAllForms', {}, { root: true });
                store.dispatch('habitTrackingModule/getAllHabitTrackings', {}, { root: true });
                store.dispatch('journeyModule/getAllJourneys', {}, { root: true });
                store.dispatch('pageModule/getAllPages', {}, { root: true });
                store.dispatch('pipelineModule/getAllPipelines', {}, { root: true });
                store.dispatch('playbookModule/getAllPlaybooks', {}, { root: true });
                store.dispatch('progressTrackingModule/getAllProgressTrackings', {}, { root: true });
                store.dispatch('sequenceModule/getAllSequences', {}, { root: true });
                store.dispatch('taskModule/getAllTasks', {}, { root: true });
                store.dispatch('templateModule/getAllTemplates', {}, { root: true });
            }
        }).catch((err) => {
            Toastr.handleServerError(err, params.setFieldError);
            store.commit('SET_CONTENT_CSV_LOADER', false);
        });
    },

    getContentImportHistories (store) {
        store.commit('SET_IMPORT_HISTORY_LOADER_STATUS', true);

        axios.get('/content-upload/histories').then((resp) => {
            store.commit('SET_IMPORT_HISTORY_LOADER_STATUS', false);
            store.commit('SET_CONTENT_HISTORIES', resp.data.histories);
            store.commit('SET_CONTENT_BATCHES', resp.data.batches);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            store.commit('SET_IMPORT_HISTORY_LOADER_STATUS', false);
        });
    },
};

export default actions;