const mutations = {
    SET_DOMAINS (state, domains) {
        state.domains = domains;
    },

    SET_DOMAIN_LOADER (state, status) {
        state.domainLoader = status;
    },

    SET_DOMAIN_SAVE_LOADER (state, status) {
        state.domainSaveLoader = status;
    },
};

export default mutations;