import axios from '@/services/axios'
import router from '@/routes'
import Toastr from '@/utils/Toastr'


const actions = {
    getNewsletterTemplates (store, params) {
        store.commit('SET_NEWSLETTER_TEMPLATES_LOADER_STATUS', true);

        axios.get(`/newsletter/templates`, { params }).then((resp) => {
            store.commit('SET_NEWSLETTER_TEMPLATES', resp.data);
            store.commit('SET_NEWSLETTER_TEMPLATES_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_NEWSLETTER_TEMPLATES_LOADER_STATUS', false);
        });
    },

    getNewsletters (store, params) {
        store.commit('SET_NEWSLETTER_LOADER_STATUS', true);

        axios.get(`/newsletter/lists`, { params }).then((resp) => {
            store.commit('SET_NEWSLETTERS', resp.data);
            store.commit('SET_NEWSLETTER_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_NEWSLETTER_LOADER_STATUS', false);
        });
    },

    updateNewsletter (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_NEWSLETTER_UPDATE_LOADER_STATUS', true);

            axios.post(`/newsletter/store`, params).then((resp) => {
                store.commit('SET_NEWSLETTER_UPDATE_LOADER_STATUS', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_NEWSLETTER_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getNewsletterDownloads (store, id) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_NEWSLETTER_DOWNLOAD_LOADER_STATUS', true);

            axios.get(`/newsletter/${id}/downloads`).then((resp) => {
                store.commit('SET_NEWSLETTER_DOWNLOADS', resp.data);
                store.commit('SET_NEWSLETTER_DOWNLOAD_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_NEWSLETTER_DOWNLOAD_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    renameNewsletter (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', true);

            axios.patch('newsletter/rename', params).then((resp) => {
                store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
            });
        });
    },

    deleteDownload (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/newsletter/delete-download`, { params }).then((resp) => {
                store.dispatch('getNewsletterDownloads', params.newsletter_id);
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    renameDownloadFile (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/newsletter/download/${params.id}/rename`, params).then((resp) => {
                store.dispatch('getNewsletterDownloads', params.newsletter_id);
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    deleteNewsletter (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/newsletter/delete', { params }).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    duplicateNewsletter (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/newsletter/duplicate', params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    deleteStep (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/newsletter/${params.id}/delete-step`, { params }).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    updateFavourite (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/newsletter/update-favourite`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    getRespondents (store, params) {
        store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', true);

        axios.get('/newsletter/respondents', { params }).then((resp) => {
            store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', false);
            store.commit('SET_RESPONDENTS', resp.data);
        }).catch(() => {
            store.commit('SET_NEWSLETTER_COMPONENT_LOADER_STATUS', false);
        });
    },

    getNewsletterTemplate(store, id) {
        axios.get(`/newsletter/${id}/template`).then((resp) => {
            store.commit('SET_NEWSLETTER_TEMPLATE', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },
};

export default actions;
