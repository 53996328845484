import { createRouter, createWebHistory } from 'vue-router'

import ui from './ui'
import auth from './auth'
import error from './error'
import contact from './contact'
import apps from './apps'
import adminSetting from './admin-setting'
import playbook from './playbook'
import clientPortal from './client-portal'
import form from './form'
import page from './page'
import habitTracking from './habit-tracking'
import progressTracking from './progress-tracking'
import file from './file'
import broadcast from './broadcast'
import sequence from './sequence'
import journey from './journey'
import publicRoute from './public'
import template from './template'
import tag from './tag'
import group from './group'
import setupGuide from './setup-guide'
import optin from './optin'
import checkout from './checkout'
import workout from './workout'

import store from '@/store'


const axios = require('axios')
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
      axios.defaults.headers.common['Accept'] = 'application/json'
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
      axios.defaults.baseURL = process.env.VUE_APP_API_URL+'/api/v3/'

    let authToken = store.state.authModule.authToken;

    if (!authToken) {
        authToken = localStorage.hasOwnProperty('authToken') && isJson(localStorage.getItem('authToken')) ? JSON.parse(localStorage.getItem('authToken')) : {};
    }

    if (authToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+authToken.access_token;
        axios.defaults.headers.common['accessed-location-id'] = authToken.accessed_location_id;
        axios.defaults.headers.common['company-admin-id'] = authToken.company_admin_id;
    }

let routes = [];

routes = routes.concat(ui, auth, error, contact, apps, adminSetting, playbook, clientPortal, form, page, habitTracking, progressTracking, file, broadcast, sequence, journey, publicRoute, template, tag, group, setupGuide, optin, checkout, workout);

const router = createRouter({
    history:createWebHistory(),
    routes
});

const publicRoutes = [
    'Login',
    'Forgot',
    'Index',
    'Register',
    'VerifyAccount',
    'VerifyBusiness',
    'RegisterNewEmail',
    'RegisterNewPhone',
    'VerifyPhone',
    'SelectPlan',
    'RegistrationFinish',
    'MemberIDDashboard',
    'RecoveryPageDashboard',
    // 'BroadcastPublicView',
];

router.beforeEach((to, from, next) => {
    const nextRoute = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    if (nextRoute) {
        document.title = nextRoute.meta.title;
    }

    const isAuthorised        = store.state.authModule.isUserLoggedIn;
    const is2FAEnabled        = store.state.authModule.is2FAEnabled;
    const is2FAVerified       = store.state.authModule.is2FAVerified;
    const isAccountAcitve     = store.state.authModule.isAccountAcitve;
    const isAccountHibernated = store.state.authModule.isAccountHibernated;
    const isAccountSuspended  = store.state.authModule.isAccountSuspended;

    handleAssetFolder(to, from);

    if (((isAuthorised && !is2FAEnabled) || (isAuthorised && is2FAEnabled && is2FAVerified)) && nextRoute && publicRoutes.includes(nextRoute.name)) {
        if (nextRoute.meta.accountActive && isAccountAcitve) {
            if (isAccountHibernated) {
                next({ name: 'ActivateAccount' });
            } else {
                next({ name: 'ContactIndex' });
            }
        } else {
            next({ name: 'PaymentActionRequired' });
        }
    } else if (isAuthorised && nextRoute && nextRoute.meta.requiresAuth && is2FAEnabled && !is2FAVerified && nextRoute && publicRoutes.includes(nextRoute.name)) {
        next({ name: 'TwoFactorAuthentication' });
    } else if (!isAuthorised && nextRoute && nextRoute.meta.requiresAuth) {
        next({ name: 'Login' });
    }

    if (!to.matched.length) {
        next({ name: 'Error-404' });
    } else {
        if (nextRoute && nextRoute.meta.requiresAuth && nextRoute.meta.accountActive && !isAccountAcitve && !isAccountSuspended) {
            next({ name: 'PaymentActionRequired' });
        } else if (nextRoute && nextRoute.meta.requiresAuth && nextRoute.meta.accountActive && !isAccountAcitve && isAccountSuspended && to.name != 'Suspended') {
            next({ name: 'Suspended' });
        } else if (isAccountAcitve && to.name == 'PaymentActionRequired') {
            next({ name: 'ContactIndex' });
        } else if (nextRoute && nextRoute.meta.requiresAuth && nextRoute.meta.accountActive && isAccountHibernated) {
            next({ name: 'ActivateAccount' });
        } else {
            next();
        }
    }
});


function handleAssetFolder (to, from) {
    const checkRoutes = ['PlaybookIndex', 'PlaybookEdit', 'PageIndex', 'PageEdit', 'FormIndex', 'FormEdit', 'OptinIndex', 'SequenceIndex', 'TemplatesIndex', 'JourneyIndex', 'FileIndex'];

    if (checkRoutes.includes(to.name) && (from.name == undefined || checkRoutes.includes(from.name))) {
        if (!localStorage.getItem('routeModule') || (localStorage.getItem('routeModule') !=  getRouteModueName(to.name))) {
            store.commit('folderModule/SET_IS_PREVIOUS_FOLDER_STATUS', false, { root: true });
            handleRemoveLocalStorageKeys();
        } else {
            store.commit('folderModule/SET_IS_PREVIOUS_FOLDER_STATUS', true, { root: true });
        }
    } else {
        store.commit('folderModule/SET_IS_PREVIOUS_FOLDER_STATUS', false, { root: true });
        handleRemoveLocalStorageKeys();
    }

    setTimeout(function () {
        store.commit('commonModule/SET_LAST_ROUTE_NAME', to.name, { root: true });

        const routeModule = getRouteModueName(to.name);

        if (routeModule != 'other') {
            localStorage.setItem('routeModule', routeModule)
        } else {
            localStorage.removeItem('routeModule');
        }
    }, 100);

    if (from.name == undefined) {
        store.commit('folderModule/SET_IS_REFRESH_STATUS', true, { root: true });
    } else {
        store.commit('folderModule/SET_IS_REFRESH_STATUS', false, { root: true });
    }
}

function getRouteModueName (routeName) {
    const checkRoutes = ['OptinIndex', 'SequenceIndex', 'TemplatesIndex', 'JourneyIndex', 'FileIndex'];

    if (routeName == 'PlaybookIndex' || routeName == 'PlaybookEdit') {
        return 'playbook';
    } else if (routeName == 'PageIndex' || routeName == 'PageEdit') {
        return 'page';
    } else if (routeName == 'FormIndex' || routeName == 'FormEdit') {
        return 'form';
    } else {
        return checkRoutes.includes(routeName) ? routeName : 'other';
    }
}

function handleRemoveLocalStorageKeys () {
    const keys = ['isArchived','lastFolderBreadcrumb', 'isAssetFolder', 'folderId', 'isPreviousFolder', ];

    keys.forEach((key) => {
        if (localStorage.hasOwnProperty(key)) {
            localStorage.removeItem(key);
        }
    });
}

function isJson (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export default router;
