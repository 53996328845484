const workout = [
    {
        name: 'WorkoutIndex',
        component: () => import(/* webpackChunkName: "js/workouts" */ '@/pages/workout/Index'),
        path: '/workouts',
        meta: {
            title: 'Workouts',
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
        },
    },
];

export default workout;