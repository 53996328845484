<template>
    <div v-if="modelValue" class="edit_modal" @click.self="closeModal()">
        <div class="edit_section setting_wpr p-0">
            <div class="form_body">
                <h2 class="title_header m-0">Add Files<button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button></h2>
                <form @submit.prevent="handleFileAddToFolder">
                    <div class="form_content">
                        <ul class="card_listing infinite-wrapper">
                            <li v-for="(image, i) of libraryImages" :key="i">
                                <img :src="image.img_src" alt="" />
                                <label :for="`image-${i}`" class="checkbox">
                                    <input type="checkbox" :id="`image-${i}`" :value="image.id" v-model="selected" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </li>
                            <infinite-loading class="infinite-loading" @infinite="getImages" target=".infinite-wrapper" />
                        </ul>
                        <div class="action_wpr px-5 py-4">
                            <button type="button" :disabled="folderAddAssetLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="folderAddAssetLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="folderAddAssetLoader"></i> {{ folderAddAssetLoader ? 'Adding' : 'Add' }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from 'v3-infinite-loading'
    import Toastr from '@/utils/Toastr'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Image Move',

        data () {
            return {
                libraryImages: [],
                params: {
                    page: 1,
                    per_page: 20,
                    type: 'all',
                    last_page: 1,
                },
                state: undefined,
                selected: [],
            };
        },

        props: {
            modelValue: Boolean,
            folder: Object,
            type: String,
        },

        emits: ['update:modelValue'],

        components: {
            InfiniteLoading,
        },

        watch: {

        },

        computed: mapState({
            folderAddAssetLoader: state => state.folderModule.folderAddAssetLoader,
        }),

        mounted () {
            const vm = this;

            vm.libraryImages = [];
        },

        methods: {
            ...mapActions({
                getAllLibraryImages: 'imageLibraryModule/getAllLibraryImages',
                addFileToFolder: 'folderModule/addAssets',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            getImages ($state) {
                const vm = this;

                vm.state = $state;
                vm.params.type = vm.type;

                if (vm.params.page <= vm.params.last_page) {
                    vm.getAllLibraryImages(vm.params).then((resp) => {
                        if (resp.data.data.length) {
                            vm.params.last_page = resp.data.last_page;
                            vm.params.page = resp.data.current_page + 1;

                            vm.libraryImages.push(...resp.data.data);

                            setTimeout(function () {
                                if (vm.state != undefined) {
                                    vm.state.loaded();
                                }
                            }, 1000);
                        } else {
                            if (vm.state != undefined) {
                                vm.state.complete();
                            }
                        }
                    });
                }
            },

            handleFileAddToFolder () {
                const vm = this;

                if (vm.selected.length) {
                    const params = { asset_type: 'images', assets_id: vm.selected, folder_id: vm.folder.id };
                    vm.addFileToFolder(params).then((result) => {
                        if (result) {
                            vm.closeModal();
                            vm.$parent.getLibraryImages(vm.$parent.libraryParams);
                        }
                    });
                } else {
                    Toastr.error('Please select at least one file to add to this folder.');
                }
            },
        },
    }
</script>

<style scoped>
    .card_listing {
        height: 325px;
        overflow: hidden;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .card_listing li {
        position: relative;
        width: 25%;
        height: 200px;
        padding: 15px;
    }
    .card_listing li .checkbox {
        position: absolute;
        top: 25px;
        right: 25px;
        padding: 0;
        border: 0;
    }
    .card_listing li img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .close_btn{
        position: static !important;
        background: transparent !important;
        color: #121525 !important;
    }

</style>