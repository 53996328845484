const state = {
    allForms: [],
    formLoader: false,
    contactForms: [],
    forms: [],
    formCreateLoader: false,
    formComponentLoader: false,
    analytics: [],
    formDetailLoader: false,
    formUpdateLoader: false,
    selectedForm: {},
    formRespondents: {},
    assetList: [],
    questions: [],
    questionLoader: false,
    questionSaveLoader: false,
    questionDuplicateLoader: false,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'ordering',
        order: 'desc',
        folder_id: null,
    },
    formDefaultSettings: {
        instruction_settings: {
            label: 'Get Started',
            btntextcolor: '#ffffff',
            btncolor: '#2C3E50',
        },
        headline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 50,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.25)',
        },
        subheadline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 25,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.68)',
        },
        submit_label: {
            'btncolor': '#D4D4D4',
            'btntextcolor': '#2C3E50',
            'label': 'SUBMIT',
            'position': 'center'
        },
        accent_color: '#2C3E50',
        ip_bgcolor: '#E2E2E2',
        ip_textcolor: '#2C3E50',
        header_bgcolor: '#ffffff',
        header_textcolor: '#67757c',
        other_header_info: 'Please Complete This Form',
        cover_textcolor: '#2C3E50',
        description: '',
        overlay_color: '#2c3e50',
        overlay_opacity: 50,
        cover_background_color: '#FFFFFF',
        progressbar_color: '#D4D4D4',
        coach_title: 'CPT, CSCS',
        coach_headline: 'Meet Your Coach',
        coach_name: 'Joe Trainer',
        coach_bio: '<p>Joe Trainer has been changing lives since dinosaurs roamed the earth. He is certified to coach clients in almost anything because he is the world\'s most awesome coach. Rumor has it he even taught Joe Biden how to ride a bicycle. Clearly, Joe knows training!&nbsp;</p>',
        footer_bgcolor: '#D4D4D4',
        footer_textcolor: '#2C3E50',
        optin_headline: 'Please enter your details below',
        add_ons: ['fname', 'lname'],
        thankyou_message: 'Thank you for your submission! We will be in touch soon',
        thankyou_bgcolor: '#FFF',
        thankyou_textcolor: '#121525',
        header: '',
        custom_headline_setting: {
            font_color: '#2C3E50',
        },
        sub_header: '',
        custom_subheadline_setting: {
            font_color: '#2C3E50',
        },
        downloadAnalyticsLoader: false,
        downloadAnalyticsCsvLoader: false,
    }
};

export default state;
