<template>
    <div class="preview_area" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn" @click="closeModal"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="left">
                <button type="button" class="history_btn" @click="importHistory = true;">History</button>
                <div class="container">
                    <div v-if="step == 0" class="upload_csv">
                        <h2>Import Your CSV Content File</h2>
                        <h4>Drag and drop your file here or click to select a file</h4>
                        <div class="upload_image mb-1">
                            <label for="show_email" v-bind="getRootProps()">
                                <input type="file" v-bind="getInputProps({ ref: 'content-import-file', multiple: false })" @change="fileSelected($event)" ref="content-import-file" id="show_email" hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                <img src="@/assets/images/upload.svg" >
                                <h4>Click to Import CSV</h4>
                            </label>
                        </div>
                        <h5>Please wait for your CSV to be verified and then proceed with the on-screen instructions.</h5>
                        <button type="button" class="ok_btn" disabled @click="nextStep(2)">
                            {{ contentCsvLoader ? 'Validating CSV' : 'Begin Import' }}
                        </button>
                    </div>
                    <div v-if="step == 1" class="upload_csv">
                        <h2>Success!</h2>
                        <h5>CSV file successfully loaded. Press import.</h5>
                        <button type="button" class="ok_btn" @click="nextStep(2)">Import</button>
                    </div>
                    <div v-if="step == 2" class="imported_area">
                        <h2>Import To</h2>
                        <div class="profile_list">
                            <ul>
                                <li>
                                    <label for="organization" class="checkbox">
                                        <input type="checkbox" id="organization" :value="user.id" v-model="selectedUser" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <p>Primary Account</p>
                                    </label>
                                </li>
                                <li v-for="(location, l) of locations" :key="l">
                                    <label :for="`location-${l}`" class="checkbox">
                                        <input type="checkbox" :id="`location-${l}`" :value="location.id" v-model="selectedUser" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <p v-tooltip="`Sub account ${l+1}`" position="bottom-right">{{ location.businessname }}</p>
                                    </label>
                                </li>
                                <p class="text-danger p-15" v-show="locationError">Please select a account to proceed</p>
                            </ul>
                        </div>
                        <div class="capsule_elm mt-4">
                            <h5 class="large">Use Saved Styling?</h5>
                            <label for="saved_styling" class="switch_option capsule_btn">
                                <input type="checkbox" id="saved_styling" v-model="useDefaultStyling" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <button type="button" class="ok_btn" @click="nextStep(3)">Continue</button>
                    </div>
                    <div v-if="step == 3" class="imported_area">
                        <h2>Great !</h2>
                        <h5>Your content is being imported</h5>
                        <div class="status_box">
                            <div class="status">
                                <h6>{{ importProgress.progress }}%</h6>
                                <div class="bar"><span :style="`width: ${importProgress.progress}%`"></span></div>
                                <small class="pull-right">{{ importProgress.index }}/{{ contentCount }} Items</small>
                            </div>
                            <div class="secondary_box">
                                <img class="img-responsive" :src="importProgress.icon" />
                                <h5 class="capitalize text-center">{{ importProgress.type ? importProgress.type+'s' : '' }}</h5>
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 4" class="imported_area">
                        <h2>Success!</h2>
                        <h5>Your content has been imported</h5>
                        <div class="status_box">
                            <div class="status">
                                <h6>100%</h6>
                                <div class="bar"><span style="width: 100%;"></span></div>
                                <small class="text-center">{{ contentCount }}/{{ contentCount }} Items Imported</small>
                            </div>
                        </div>
                        <button type="button" class="ok_btn" @click="showHistory()">Show Me</button>
                    </div>
                    <div v-if="step == 5" class="imported_area">
                        <h2>Oops!</h2>
                        <h5>Incorrectly formatted. please try again.</h5>
                        <button type="button" class="ok_btn" @click="closeModal()">Close</button>
                    </div>
                </div>
            </div>
            <div class="right" :class="{'show' : importHistory}">
                <button class="close_btn" @click="importHistory = false;"><i class="fas fa-times"></i></button>
                <content-import-history v-model="contentImportHistory" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { useDropzone } from 'vue3-dropzone'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Pusher from 'pusher-js'

    import { defineAsyncComponent } from 'vue'

    const ContentImportHistory = defineAsyncComponent(() => import('@/pages/apps/components/ContentImportHistory'))

    export default {
        name: 'Content Import Wizard',

        props: {
            modelValue: Boolean,
            toggleHistory: Function
        },

        emits: ['update:modelValue'],

        data () {
            return  {
                step: 0,
                file: '',
                selectedUser: [],
                importProgress: {
                    index: 0,
                    type: '',
                    progress: 0,
                    icon: '',
                },
                getRootProps: null,
                getInputProps: null,
                contentImportHistory: true,
                importHistory: false,
                locationError: false,
                useDefaultStyling: 0,
            };
        },

        components:{
            ContentImportHistory
        },

        watch: {
            verifiedFileData (data) {
                const vm = this;

                if (data.status == 1) {
                    vm.step = 1;
                    vm.contentCount = data.response;
                    vm.importProgress.type = data.type.type;
                    vm.importProgress.icon = data.type.icon;

                    if (data.limit_reached_message) {
                        const options = Helper.swalWarningOptions('Limit reached!', data.limit_reached_message);

                        Swal.fire(options);
                    }
                } else {
                    vm.step = 5;
                }
            },

            contentCSVUploadStatus (status) {
                if (status) {
                    const vm = this;

                    vm.step = 4;
                }
            },

            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.useDefaultStyling = 0;
                    vm.getContentImportHistories();
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            locations: state => state.appsModule.locations,
            verifiedFileData: state => state.appsModule.verifiedFileData,
            contentCsvLoader: state => state.appsModule.contentCsvLoader,
            locationLoader: state => state.appsModule.locationLoader,
            contentCSVUploadStatus: state => state.appsModule.contentCSVUploadStatus,
        }),

        mounted () {
            const vm = this;

            setTimeout(function () {
                const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                    broadcaster: 'pusher',
                    forceTLS: true
                });

                const channel = pusher.subscribe('content-upload-channel-' + vm.user.id);
                channel.bind('content-upload-event', function (message) {
                    if (message.employer === vm.user.id) {
                        vm.importProgress = message;
                    }
                }.bind(vm));
            }, 1000);

            vm.getLocations();
            vm.getContentImportHistories();

            const { getRootProps, getInputProps  } = useDropzone({ onDrop: vm.dropzoneDrop });

            vm.getRootProps = getRootProps;
            vm.getInputProps = getInputProps;
        },

        methods: {
            ...mapActions({
                getLocations: 'appsModule/getLocations',
                verifyContentCsv: 'appsModule/verifyContentCsv',
                importContentCsv: 'appsModule/importContentCsv',
                getContentImportHistories: 'appsModule/getContentImportHistories'
            }),

            closeModal () {
                const vm = this;

                vm.step = 0;
                vm.$emit('update:modelValue', false);
            },

            fileSelected (event) {
                const vm = this;
                vm.file = event.target.files[0];

                let formData = new FormData();
                formData.append('file', vm.file);

                vm.verifyContentCsv(formData);
            },

            nextStep (step) {
                const vm = this;

                vm.locationError = false;

                if (step == 3 && vm.selectedUser.length > 0) {
                    let formData = new FormData();
                    formData.append('file', vm.file);
                    formData.append('user_ids', vm.selectedUser);
                    formData.append('use_default_styling', vm.useDefaultStyling);

                    vm.importContentCsv(formData);

                    vm.step = 3;
                    vm.locationError = false;
                } else if (step == 3 && vm.selectedUser.length == 0) {
                    vm.step = 2;
                    vm.locationError = true;
                } else {
                    vm.step = step;
                }
            },

            showHistory () {
                const vm = this;

                vm.step = 0;

                setTimeout(function () {
                    vm.getContentImportHistories();
                }, 200);
            },

            dropzoneDrop (file) {
                const vm = this;

                vm.file = file[0];

                let formData = new FormData();
                formData.append('file', vm.file);

                vm.verifyContentCsv(formData);
            },
        },
    };
</script>

<style scoped>
.left{
    padding: 30px 40px 30px 40px;
    height: 100vh;
    overflow-y: auto;
    position: relative;
    z-index: 2;
    background: #fafafb;
    flex: 1;
}
.left::-webkit-scrollbar{
    width: 8px;
}
.left::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 4px;
}
.right{
    width: 530px;
    background: #fff;
    border-left: 1px solid #eee;
}
.right .close_btn{
    width: 22px;
    height: 22px;
    font-size: 12px;
    border-radius: 50%;
    background: #eaeaea;
    display: none;
    align-items: center;
    justify-content: center;
    color: #5a5a5a;
    position: absolute;
    left: auto;
    right: 20px;
    top: 22px;
    z-index: 6;
}
.history_btn{
    padding: 4px 10px;
    border-radius: 3px;
    color: #5a5a5a;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    position: absolute;
    right: 15px;
    top: 15px;
    letter-spacing: 0.5px;
    cursor: pointer;
    display: none;
}
.container{
    max-width: 800px;
    margin: 0 auto;
}
h2{
    font-size: 26px;
    line-height: 38px;
    color: #121525;
    font-weight: 500;
    margin: 10px 0;
    text-align: center;
}
h4{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
    color: #5a5a5a;
    font-weight: 400;
    text-align: center;
}
h5{
    font-size: 15px;
    line-height: 22px;
    margin-top: 15px;
    color: #5a5a5a;
    font-weight: 400;
    text-align: center;
}
:deep(.upload_image label){
    background: #fff;
    border-radius: 10px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
:deep(.upload_image label img){
    height: 50px;
    width: auto;
    opacity: 0.5;
    transform: none;
}
.ok_btn {
    display: block;
    padding: 10px 20px;
    background: #2f7fed;
    margin: 30px auto 0 auto;
    border-radius: 5px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}
.profile_list{
    background: #fff;
    border-radius: 10px;
    padding: 30px 15px;
    margin-top: 30px;
}
.profile_list ul{
    display: flex;
    flex-wrap: wrap;
}
.profile_list ul li{
    width: 33.333%;
    padding: 15px;
}
.profile_list ul li label.checkbox{
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 5px;
    background: #eef5ff;
}
.profile_list ul li:first-child label.checkbox{
    background: #fff7e7;
}
.profile_list ul li label.checkbox span{
    margin-top: 2px;
    background: #fff;
}
.profile_list ul li label.checkbox p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    padding-left: 8px;
    color: #121525;
}

.status_box{
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 30px;  
}
.secondary_box{
    max-width: 300px;
    margin: 30px auto 0 auto;
}

.p-15 {
    padding: 15px;
}

@media(max-width: 1199px){
    .right{
        width: 450px;
    }
}
@media(max-width: 991px){
    .history_btn{
        display: block;
    }
    .right{
        width: 350px;
        position: fixed;
        right: -350px;
        top: 0;
        z-index: 6;
        transition: all 0.3s ease-in-out;
    }
    .right.show{
        right: 0;
    }
    .right .close_btn{
        display: flex;
    }
}
@media(max-width: 767px){
    h2{
        font-size: 22px;
        line-height: 30px;
        margin-top: 30px;
    }
    h4{
        font-size: 15px;
        line-height: 22px;
    }
    h5{
        font-size: 13px;
        line-height: 20px;
    }
    .profile_list ul li{
        width: 50%;
    }
}
@media(max-width: 599px){
    .profile_list ul li label.checkbox p{
        font-size: 13px;
        line-height: 18px;
    }
}
@media(max-width: 499px){
    .left{
        padding: 30px 20px;
    }
    .profile_list ul li{
        padding: 10px;
    }
}
@media(max-width: 499px){
    .profile_list{
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
    .profile_list ul li{
        width: 100%;
    }
}
</style>
