const state = {
    allPlaybooks: [],
    playbookLoader: false,
    playbookStepLoader: false,
    playbookUserLoader: false,
    playbooks: {},
    playbookUsers: {},
    analyics: {},
    playbookQuestion: {},
    playbookQuestionLoader: false,
    playbookDetailLoader: false,
    selectedPlaybook: {},
    playbookConfiguration: {},
    playbookUpdateLoader: false,
    playbookCategories: {},
    categorySaveLoader: false,
    playbookStepTabSaveLoader: false,
    playbookStepSaveLoader: false,
    playbookSteps: [],
    stepDownloads: [],
    smartSteps: [],
    playbookSendLoader: false,
    playbookStepDuplicateLoader: false,
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'ordering',
        order: 'desc',
        folder_id: null,
    },
    canvaZipFiles: [],
    canvaZipFileLoaderStatus: false,
    canvaZipFileUploadLoaderStatus: false,
    canvaBuildLoaderStatus: false,
    canvaArchivedSteps: [],
    canvaArchivedStepsLoaderStatus: false,
};

export default state;
