import initialState from './state';

const mutations = {
    SET_SCHEDULED_SMS (state, data) {
        state.scheduledSMS = data;
    },

    SET_SCHEDULES (state, data) {
        state.schedules = data;
    },

    SET_SCHEDULE_LOADER_STATUS (state, value) {
        state.scheduleLoader = value;
    },

};

export default mutations;
