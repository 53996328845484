import alertImage from '@/assets/images/alert.svg'
import axios from '@/services/axios'
import { copyText } from 'vue3-clipboard'
import moment from 'moment'

import Toastr from '@/utils/Toastr'
import Swal from 'sweetalert2'

class Helper {
    constructor () {
        if (!Helper.instance) {
            Helper.instance = this;
        }

        return Helper.instance;
    }

    swalWarningOptions (title, html = '', confirmButtonText = 'Ok') {
        return {
            title,
            html,
            confirmButtonText,
            // iconHtml: `<img src="${alertImage}" alt="" class="alert_img">`,
            confirmButtonColor: '#2f80ed',
            allowOutsideClick: false,
        };
    }

    swalConfirmOptions (title, html = '', confirmButtonText = 'Yes', showLoaderOnConfirm = true) {
        return {
            title,
            html,
            confirmButtonText,
            // iconHtml: `<img src="${alertImage}" alt="" class="alert_img">`,
            confirmButtonColor: '#2f80ed',
            showCancelButton: true,
            allowOutsideClick: false,
            showLoaderOnConfirm,
        };
    }

    swalConfirmOptionsWithDenyButton (title, html = '', confirmButtonText = 'Yes', denyButtonText = 'No', confirmButtonColor = '#eb1414', denyButtonColor = '#2f80ed') {
        return {
            title,
            html,
            confirmButtonText,
            denyButtonText,
            confirmButtonColor,
            denyButtonColor,
            // iconHtml: `<img src="${alertImage}" alt="" class="alert_img">`,
            showDenyButton: true,
            showCancelButton: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            showLoaderOnDeny: true,
        };
    }

    fileCropWarning() {
        return {
            title: 'Would you like to crop the image?',
            html: 'Once cropped the animation of the image would not work.',
            // iconHtml: `<img src="${alertImage}" alt="" class="alert_img">`,
            confirmButtonColor: '#2f80ed',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        };
    }

    getFileExtentions () {
        return {
            imagesExt: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'image/jpeg', 'image/gif', 'image/png', 'image/bmp'],
            videoExt:  ['mpeg', 'mp4', 'quicktime', 'webm', '3gpp', '3gp', '3gpp2', '3gpp-tt', 'H261', 'H263', 'H263-1998', 'H263-2000', 'H264', 'video/mpeg', 'video/mp4', 'video/quicktime', 'video/webm', 'video/3gpp', 'video/3gpp2', 'video/3gpp-tt', 'video/H261', 'video/H263', 'video/H263-1998', 'video/H263-2000', 'video/H264'],
            audioExt:  ['basic', 'L24', 'mp4', 'mpeg', 'ogg', 'vorbis', 'vnd.rn-realaudio', 'vnd.wave', '3gpp', '3gpp2', 'ac3', 'webm', 'amr-nb', 'amr', 'mp3', 'audio/basic', 'audio/L24', 'audio/mp4', 'audio/mpeg', 'audio/ogg', 'audio/vorbis', 'audio/vnd.rn-realaudio', 'audio/vnd.wave', 'audio/3gpp', 'audio/3gpp2', 'audio/ac3', 'audio/vnd.wave', 'audio/webm', 'audio/amr-nb', 'audio/amr'],
            docExt:    ['doc', 'docx'],
            excelExt:  ['xls', 'xlsx', 'csv'],
            filesExt:  ['vcard', 'csv', 'rtf', 'richtext', 'calendar', 'directory'],
        };
    }

    getFileIconByUrl(url, uniqueId = '', contentType = '') {
        let icon        = '';
        const ext       =  url && url.split('.').pop();
        const extObj    = instance.getFileExtentions();
        const imagesExt = extObj.imagesExt;
        const videoExt  = extObj.videoExt;
        const docExt    = extObj.docExt;
        const excelExt  = extObj.excelExt;
        const audioExt  = extObj.audioExt;

        if (ext) {
            if (imagesExt.includes(ext) || (contentType && imagesExt.includes(contentType))) {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${url}" alt="img" /></a>`;
            } else if (videoExt.includes(ext) || (contentType && videoExt.includes(contentType))) {
                icon = `<video controls style="max-width: 100%;">
                            <source src="${url}" type="video/mp4">
                            <source src="${url}" type="video/ogg">
                            Your browser does not support the video tag.
                        </video>`;
            } else if (docExt.includes(ext) || (contentType && docExt.includes(contentType))) {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/doc.png')}" alt="img" /></a>`;
            } else if (excelExt.includes(ext) || (contentType && excelExt.includes(contentType))) {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/excel.png')}" alt="img" /></a>`;
            } else if (ext == 'pdf' || (contentType && contentType == 'application/pdf')) {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/pdf2.png')}" alt="img" /></a>`;
            } else if (ext == 'zip') {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/zip.png')}" alt="img" /></a>`;
            } else if (audioExt.includes(ext) || (contentType && audioExt.includes(contentType))) {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/audio.png')}" alt="img" /></a>`;
            } else {
                icon = `<a href="${url}" target="blank"><img class="img-thumbnail" data-lightbox="sms-lightbox-${uniqueId}" src="${require('@/assets/images/file.png')}" alt="img" /></a>`;
            }
        }

        return icon;
    }

    getFileThumbByUrl(url, contentType = '') {
        let icon        = '';
        const ext       =  url && url.split('.').pop();
        const extObj    = instance.getFileExtentions();
        const imagesExt = extObj.imagesExt;
        const videoExt  = extObj.videoExt;
        const docExt    = extObj.docExt;
        const excelExt  = extObj.excelExt;
        const audioExt  = extObj.audioExt;

        if (ext) {
            if (imagesExt.includes(ext) || (contentType && imagesExt.includes(contentType))) {
                icon = url;
            } else if (videoExt.includes(ext) || (contentType && videoExt.includes(contentType))) {
                icon = require('@/assets/images/file.png');
            } else if (docExt.includes(ext) || (contentType && docExt.includes(contentType))) {
                icon = require('@/assets/images/doc.png');
            } else if (excelExt.includes(ext) || (contentType && excelExt.includes(contentType))) {
                icon = require('@/assets/images/excel.png');
            } else if (ext == 'pdf' || (contentType && contentType == 'application/pdf')) {
                icon = require('@/assets/images/pdf2.png');
            } else if (ext == 'zip') {
                icon = require('@/assets/images/zip.png');
            } else if (audioExt.includes(ext) || (contentType && audioExt.includes(contentType))) {
                icon = require('@/assets/images/audio.png');
            } else {
                icon = require('@/assets/images/file.png');
            }
        }

        return icon;
    }

    downloadFile (url, fileName, type) {
        axios.get(url, { responseType: 'blob' }).then((resp) => {
            const blob = new Blob([resp.data], { type });
            const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;
                  link.click();

            URL.revokeObjectURL(link.href);
        }).catch((err) => {
            console.log(err);
        });
    }

    strSlug (string) {
        if (!string) {
            return string;
        }

        return string.toLowerCase()
                     .replace(/ /g, '-')
                     .replace(/[^\w-]+/g, '');
    }

    statusName (status, statuses) {
        let name = status;
        const tab = statuses.filter((t) => { return t.value == name })[0]

        if (tab) {
            name = tab.title;
        } else if (status == 'Leads') {
            name = statuses['Prospect'];
        } else if (status == 'Trial') {
            name = statuses['ActiveTrial'];
        } else if (status == 'Bucket') {
            name = statuses['Lost'];
        }

        return name;
    }

    ordinalSuffix (i) {
        const j = i % 10,
              k = i % 100;

        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }

        return i + "th";
    }

    twoDigitNumber (number) {
        return (number < 10) ? '0' + number.toString() : number.toString();
    }

    copyContent (content, text = 'Link') {
        return new Promise(function(resolve, reject) {
            copyText(content, undefined, (error, event) => {
                if (error) {
                    Toastr.error(`Failed to copy ${text.toLowerCase()}!`);
                    resolve(true);
                } else {
                    Toastr.success(`${text} copied successfully!`);
                    resolve(false);
                }
            });
        });
    }

    base64toBlob(imageData, ext) {
        const byteString = atob(imageData.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: 'image/'+ ext });
    }

    handleDuplicateError (err, fieldName = 'name') {
        if (err.response && err.response.status == 422 && err.response.data && err.response.data.errors) {
            const errors = err.response.data.errors;
            const vm     = this;

            Object.keys(errors).map(field => {
                if (field == fieldName) {
                    let errorString = errors[field].join(', ');
                    Swal.showValidationMessage( errorString );
                }
            });
        }
    }

    filterDates (duration = 'Today') {
        let filter = {
                        start_date: '',
                        end_date: '',
                    };

        if (duration == 'Today') {
            filter.start_date  = moment.utc().format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'Yesterday') {
            filter.start_date  = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
        } else if (duration == 'Last 7 Days') {
            filter.start_date  = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'Last 14 Days') {
            filter.start_date  = moment.utc().subtract(14, 'days').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'Last 30 Days') {
            filter.start_date  = moment.utc().subtract(30, 'days').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'This Month') {
            filter.start_date  = moment.utc().startOf('month').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'Last Month') {
            filter.start_date  = moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        } else if (duration == 'This Year') {
            filter.start_date  = moment.utc().startOf('year').format('YYYY-MM-DD');
            filter.end_date    = moment.utc().format('YYYY-MM-DD');
        } else if (duration == 'All Time') {
            filter.start_date  = '';
            filter.end_date    = '';
        }

        return filter;
    }

    capitalizeFirst (string) {
        return string ? string[0].toUpperCase() + string.slice(1) : '';
    }

    singularize (word) {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: 'ze',
            ses: 's',
            es: 'e',
            s: ''
        };

        if (word == 'progress') {
            return word;
        }

        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`), r => endings[r]
        );
    }

    lowerFirst (string) {
        return string ? string[0].toLowerCase() + string.slice(1) : '';
    }

    ellipsis (string, length = 100) {
        if (string && string.length > length) {
            return string.substring(0, length) + '...';
        }

        return string;
    }

    capitalizeFirstLetter (string) {
        const words = string.split(' ');

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(' ');
    }
}

const instance = new Helper();
Object.freeze(instance);

export default instance;
