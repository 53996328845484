const mutations = {
    SET_SCHEDULE_LOADER_STATUS (state, status) {
        state.scheduleLoader = status;
    },

    SET_LATEST_SMS (state, sms) {
        state.latestSMS = sms;
    },

    SET_STATS (state, data) {
        state.stats = data;
    },

    SET_SCHEDULED_SMS (state, data) {
        state.scheduledSms = data;
    },

    SET_SCHEDULED_SMS_LOADER_STATUS (state, status) {
        state.scheduledSmsLoader = status;
    }
};

export default mutations;