import moment from 'moment'
import Constant from '@/utils/Constant'

const mutations = {
    SET_CHECKOUT_AUTH_TOKEN (state, token) {
        state.checkoutAuthToken = token;

        localStorage.setItem('checkoutAuthToken', JSON.stringify(token));
    },

    RESET_CHECKOUT_AUTH_TOKEN (state, token) {
        state.checkoutAuthToken = null;

        localStorage.removeItem('checkoutAuthToken');
    },

    SET_PRODUCT_COMPONENT_LOADER_STATUS (state, status) {
        state.productComponentLoader = status;
    },

    SET_PRODUCT_LOADER_STATUS (state, status) {
        state.productLoader = status;
    },

    SET_PRODUCT_UPDATE_LOADER_STATUS (state, status) {
        state.productUpdateLoader = status;
    },

    SET_PRODUCTS (state, products) {
        state.products = products;
    },

    SET_CLIENT_LOADER_STATUS (state, status) {
        state.clientLoader = status;
    },

    SET_CLIENT_PARAMS (state, obj) {
        state.clientParams[obj.key] = obj.value;
    },

    SET_CLIENTS (state, clients) {
        state.clients = clients;
    },

    SET_USER_LOCATIONS (state, locations) {
        state.locations = locations;
    },

    SET_LOCATION_TAGS_GROUP (state, tags) {
        state.tagsGroupByLocation = tags;
    },

    SET_COMPANY_SETTINGS (state, settings) {
        state.companySettings = settings;

        if (settings && settings.currency) {
            const currency =  Constant.currency.find(({code}) => code == settings.currency);

            state.companyCurrencySymbol = currency && currency.symbolNative ? currency.symbolNative : '$';
        } else {
            state.companyCurrencySymbol = '$';
        }
    },

    SET_COMPANY_SETTING_LOADER_STATUS (state, status) {
        state.companySettingLoader = status;
    },

    SET_ORDER_BUMPS (state, bumps) {
        state.orderBumps = bumps;
    },

    SET_COUPONS (state, coupons) {
        state.coupons = coupons;
    },

    SET_DEFAULT_TEMPLATES (state, templates) {
        state.defaultTemplates = templates;
    },

    SET_UPDATE_COMPANY_SETTING_LOADER_STATUS (state, status) {
        state.updateCompanySettingLoader = status;
    },

    SET_EMAIL_SETTING_LOADER_STATUS (state, status) {
        state.emailSettingLoader = status;
    },

    SET_ABANDONED_EMAIL_SETTINGS (state, setting) {
        state.abandonedEmailSettings = setting;
    },

    SET_TRANSACTION_EMAIL_SETTINGS (state, setting) {
        state.transactionEmailSettings = setting;
    },

    SET_DUNNING_EMAIL_SETTINGS (state, setting) {
        state.dunningEmailSettings = setting;
    },

    SET_UPDATE_EMAIL_SETTING_LOADER_STATUS (state, status) {
        state.updateEmailSettingLoader = status;
    },

    SET_COUPON_LOADER_STATUS (state, status) {
        state.couponLoader = status;
    },

    SET_PROCESSOR_LOADER_STATUS (state, status) {
        state.processorLoader = status;
    },

    SET_PROCESSORS (state, processors) {
        state.processors = processors;
    },

    SET_BUMP_UPDATE_LOADER_STATUS (state, status) {
        state.bumpUpdateLoader = status;
    },

    SET_UPDATE_CLIENT_PROFILE_LOADER_STATUS (state, status) {
        state.updateClientProfileLoader = status;
    },

    SET_CLIENT_WATCHER (state) {
        state.clientWatcher++;
    },

    SET_SINGLE_PURCHASE_PRODUCTS_LOADER_STATUS (state, status) {
        state.singlePurchaseProductsLoader = status;
    },

    SET_SINGLE_PURCHASE_PRODUCTS (state, orders) {
        state.singlePurchaseProducts = orders;
    },

    SET_ORDER_TRANSACTION (state, orders) {
        state.orderTransactions = orders;
    },

    SET_ORDER_TRANSACTION_LOADER_STATUS (state, status) {
        state.orderTransactionsLoader = status;
    },

    SET_COUPON_LIST_LOADER_STATUS (state, status) {
        state.couponListLoader = status;
    },

    SET_CLIENT_ANALYTICS (state, analytics) {
        state.clientAnalytics = analytics;
    },

    SET_CLIENT_ANALYTICS_LOADER_STATUS (state, status) {
        state.clientAnalyticsLoader = status;
    },

    SET_CLIENT_ORDER_SUBSCRIPTIONS_LOADER_STATUS (state, status) {
        state.clientOrderSubscriptionsLoader = status;
    },

    SET_CLIENT_ORDER_SUBSCRIPTIONS (state, subscriptions) {
        state.clientOrderSubscriptions = subscriptions;
    },

    SET_TRACKING_IDS (state, ids) {
        state.trackingIds = ids;
    },

    SET_ALL_SUBSCRIPTIONS_LOADER_STATUS (state, status) {
        state.allSubscriptionsLoader = status;
    },

    SET_ALL_SUBSCRIPTIONS (state, subscriptions) {
        state.allSubscriptions = subscriptions;
    },

    SET_SUBSCRIPTION_PRODUCTS (state, ids) {
        state.subscriptionProducts = ids;
    },

    SET_CHECKOUT_LOGS (state, logs) {
        state.checkoutLogs = logs;
    },

    SET_CHECKOUT_LOGS_LOADER_STATUS (state, status) {
        state.checkoutLogsLoader = status;
    },


    SET_ORDERS_ANALYTICS (state, logs) {
        state.OrdersAnalytics = logs;
    },

    SET_ORDERS_ANALYTICS_LOADER_STATUS (state, status) {
        state.OrdersAnalyticsLoader = status;
    },

    SET_IS_SIDEBAR_MOUNTED (state, val) {
        state.isSidebarMounted = val;
    },

    SET_SEND_INVOICE_LOADER_STATUS (state, status) {
        state.sendInvoiceLoader = status;
    },

    SET_REFUND_PAYMENT_LOADER_STATUS (state, status) {
        state.refundPaymentLoader = status;
    },

    SET_FAILED_SUBSCRIPTION_LOGS (state, logs) {
        state.failedSubscriptionLogs = logs;
    },

    SET_FAILED_SUBSCRIPTION_LOGS_LOADER_STATUS (state, status) {
        state.failedSubscriptionLogsLoader = status;
    },

    SET_TRIAL_SUBSCRIPTION_LOGS (state, logs) {
        state.trialSubscriptionLogs = logs;
    },

    SET_TRIAL_SUBSCRIPTION_LOGS_LOADER_STATUS (state, status) {
        state.trialSubscriptionLogsLoader = status;
    },

    SET_PAID_SUBSCRIPTION_LOGS (state, logs) {
        state.paidSubscriptionLogs = logs;
    },

    SET_PAID_SUBSCRIPTION_LOGS_LOADER_STATUS (state, status) {
        state.paidSubscriptionLogsLoader = status;
    },

    SET_DASHBOARD_PARAMS (state, obj) {
        state.params[obj.key] = obj.value;
    },

    RESET_DASHBOARD_PARAMS (state) {
        state.params = {
                          per_page: 6,
                          page: 1,
                          search: '',
                          order_by: 'ordering',
                          order: 'desc',
                          folder_id: null,
                      };
    },

    SET_ASSIGNED_TAG_LIST (state, tags) {
        state.assignedTagList = tags;
    },

    SET_RECENT_ACTIVITIES_LOADER_STATUS (state, status) {
        state.recentActivitiesLoader = status;
    },

    SET_RECENT_ACTIVITIES (state, activities) {
        state.recentActivities = activities;
    },

    SET_CHART_LOADER_STATUS (state, status) {
        state.chartLoader = status;
    },

    SET_CHART_DATA (state, chartData) {
        state.chartData = chartData;
    },

    SET_ALL_PRODUCT_LOADER_STATUS (state, status) {
        state.allProductLoader = status;
    },

    SET_ALL_PRODUCTS (state, products) {
        state.allProducts = products;
    },

    SET_CHART_STATS (state, chartStats) {
        state.chartStats = chartStats;
    },

    SET_GROSS_SALE (state, grossSale) {
        state.grossSale = grossSale;
    },

    SET_CLIENT_ABANDONED_ORDERS_LOADER_STATUS (state, status) {
        state.clientAbandonedOrdersLoader = status;
    },

    SET_CLIENT_ABANDONED_ORDERS (state, orders) {
        state.clientAbandonedOrders = orders;
    },

    SET_ALL_ORDER_TRANSACTIONS (state, orders) {
        state.allOrderTransactions = orders;
    },

    SET_SUBSCRIPTION_STATS (state, stats) {
        state.subscriptionStats = stats;
    },

    SET_ADD_CARD_LOADER_STATUS (state, status) {
        state.addCardLoader = status;
    },

    SET_SETUP_INTENT_LOADER_STATUS (state, status) {
        state.setupIntentLoader = status;
    },

    SET_CARD_UPDATE_EMAIL_LOADER_STATUS (state, status) {
        state.cardUpdateEmailLoader = status;
    },

    SET_PRODUCT_TEMPLATE_LOADER_STATUS (state, status) {
        state.productTemplateLoader = status;
    },

    SET_DOWNLOAD_INVOICE_LOADER_STATUS (state, status) {
        state.downloadInvoiceLoader = status;
    },

    SET_ALL_SUBSCRIPTION_LOGS (state, status) {
        state.allSubscriptionLogs = status;
    },

    SET_LIVE_PRODUCTS (state, status) {
        state.liveProducts = status;
    },

    SET_LIVE_PRODUCT_LOADER_STATUS (state, status) {
        state.liveProductLoader = status;
    },

};

export default mutations;
