import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    updateTease (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', true);

            axios.patch(`/tease-settings/${params.id}/update`, { setting_value: JSON.stringify(params.setting_value), enabled: params.enabled }).then((resp) => {
                store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', false);

                Toastr.success('Tease settings has been updated successfully.');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', false);

                Toastr.handleOptinServerError(err, params.setFieldError, false, params.field)

                resolve(false);
            });
        })
    },
    enableDisableTease (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', true);

            axios.patch(`/tease-settings/${params.id}/enable-disable`, {  enabled: params.enabled }).then((resp) => {
                store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', false);

                Toastr.success(`Tease settings has been ${params.enabled ? 'enabled' : 'disabled'} successfully.`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TEASE_SETTINGS_UPDATE_LOADER_STATUS', false);

                Toastr.handleOptinServerError(err, params.setFieldError, false, params.field)

                resolve(false);
            });
        })
    },
};

export default actions;
