const progressTracking = [
    {
        name: 'ProgressTrackingIndex',
        component: () => import(/* webpackChunkName: "js/progress" */ '@/pages/progress-tracking/Index'),
        path: '/progress-tracking',
        meta: {
            title: 'Progress Tracking',
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
        },
    },
    {
        name: 'ProgressPublicResponse',
        component: () => import(/* webpackChunkName: "js/progress" */ '@/pages/progress-tracking/PublicResponse'),
        path: '/progress-tracking/:progress/view-response/:contact',
        meta: {
            title: 'Tracking Response',
            hasParent: true,
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
            parent: {
                title: 'Progress Tracking',
                name: 'ProgressTrackingIndex',
            }
        },
    },
];

export default progressTracking;
