const sequence = [
    {
        name: 'SequenceIndex',
        component: () => import(/* webpackChunkName: "js/sequence" */ '@/pages/sequence/Index'),
        path: '/sequences',
        meta: {
            title: 'Sequences',
            requiresAuth: true,
            accountActive: true,
            group: 'Marketing',
        },
    },
];

export default sequence;
