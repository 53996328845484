import { createStore, createLogger } from 'vuex'
import authModule from '@/store/modules/auth'
import appsModule from '@/store/modules/apps'
import commonModule from '@/store/modules/common'
import contactModule from '@/store/modules/contact'
import tagModule from '@/store/modules/tag'
import templateModule from '@/store/modules/template'
import signatureModule from '@/store/modules/signature'
import imageLibraryModule from '@/store/modules/image-library'
import smsCenterModule from '@/store/modules/sms-center'
import playbookModule from '@/store/modules/playbook'
import pageModule from '@/store/modules/page'
import formModule from '@/store/modules/form'
import fileModule from '@/store/modules/file'
import journeyModule from '@/store/modules/journey'
import pipelineModule from '@/store/modules/pipeline'
import sequenceModule from '@/store/modules/sequence'
import progressTrackingModule from '@/store/modules/progress-tracking'
import habitTrackingModule from '@/store/modules/habit-tracking'
import taskModule from '@/store/modules/task'
import folderModule from '@/store/modules/folder'
import memberIdModule from '@/store/modules/member-id'
import emailLogModule from '@/store/modules/email-log'
import domainModule from '@/store/modules/domain'
import activityLogModule from '@/store/modules/activity-log'
import scheduleModule from '@/store/modules/schedule'
import rewardModule from '@/store/modules/reward'
import contentSettingModule from '@/store/modules/content-setting'
import clientPortalModule from '@/store/modules/client-portal'
import broadcastModule from '@/store/modules/broadcast'
import groupModule from '@/store/modules/group'
import portalChatModule from '@/store/modules/portal-chat'
import registerModule from '@/store/modules/register'
import billingModule from '@/store/modules/billing'
import adminSettingModule from '@/store/modules/admin-setting'
import setupGuideModule from '@/store/modules/setup-guide'
import referralModule from '@/store/modules/referral'
import newsletterModule from '@/store/modules/newsletter'
import checkoutModule from '@/store/modules/checkout'
import workoutModule from '@/store/modules/workout'
import optinsModule from '@/store/modules/optins'
import teaseModule from '@/store/modules/tease'

const debug = false;//process.env.NODE_ENV !== 'production'
const _ = require('lodash');

export const initialStoreModules = {
    authModule,
    appsModule,
    commonModule,
    contactModule,
    tagModule,
    templateModule,
    signatureModule,
    imageLibraryModule,
    smsCenterModule,
    playbookModule,
    pageModule,
    formModule,
    fileModule,
    journeyModule,
    pipelineModule,
    sequenceModule,
    progressTrackingModule,
    habitTrackingModule,
    taskModule,
    folderModule,
    memberIdModule,
    emailLogModule,
    domainModule,
    activityLogModule,
    scheduleModule,
    rewardModule,
    contentSettingModule,
    clientPortalModule,
    broadcastModule,
    groupModule,
    portalChatModule,
    registerModule,
    billingModule,
    adminSettingModule,
    setupGuideModule,
    referralModule,
    newsletterModule,
    checkoutModule,
    workoutModule,
    optinsModule,
    teaseModule,
};

const store = createStore({
    modules: _.cloneDeep(initialStoreModules),
    mutations: {
        RESET_STATE (state) {
            _.forOwn(initialStoreModules, (value, key) => {
                if (key !== 'authModule') {
                    state[key] = _.cloneDeep(value.state);
                }
            });
        },
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});

export default store;
