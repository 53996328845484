<template>
    <div class="content_history">
        <div class="header">
            <div class="section_header m-0">
                <h2>Latest Import</h2>
                <small>{{ activeBatchDate }}</small>
            </div>
        </div>
        <div class="history_body">
            <div class="listing_loader" v-show="historyLoader"><quote-loader /></div>
            <div v-show="!historyLoader">
                <ul class="import_list mb-5" v-if="activeBatchHistories.length">
                    <li v-for="(history, h) of activeBatchHistories" :key="h">
                        <div class="category">
                            <div class="list_body">
                                <button class="external_link pointer" type="button" @click="handleRediret(history)">
                                    <i class="fas fa-external-link-alt"></i>
                                </button>
                                <h4 class="capitalize">{{ history.type.replace('-', ' ') }}{{ (history.type == 'progress-tracking' || history.type == 'habit-tracking') ? '' : 's' }}</h4>
                                <ol>
                                    <li v-for="(name, n) of history.names" :key="n"><span>{{ name }}</span></li>
                                </ol>
                            </div>
                        </div>
                    </li>
                </ul>
                <h3 v-else>
                    <span class="text-center" v-if="!historyLoader">No import history found!</span>
                </h3>
                <label for="content-history-switch" class="switch_option capsule_btn" v-if="activeBatchHistories.length">
                    <h5>Import History<span>Previous import history</span></h5>
                    <input type="checkbox" id="content-history-switch" v-model="importHistory" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="importHistory" class="impcard">
                    <ul>
                        <li v-for="(batch, b) of batches" :key="b">
                            <div class="impcard_details">
                                <h5 class="capitalize">{{ batch.type }}</h5>
                                <p>{{ batch.date }}</p>
                            </div>
                            <span class="view" @click="setActiveBatch(batch)"><i class="fas fa-search-plus"></i></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Content Import History',

        data () {
            return  {
                importHistory: false,
                activeBatch: '',
                activeBatchDate: '',
                activeBatchHistories: [],
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.getContentImportHistories();
                }
            },

            batches (batches) {
                if (batches[0]) {
                    const vm = this;

                    vm.activeBatch      = batches[0].batch;
                    vm.activeBatchDate  = batches[0].date;
                }
            },

            activeBatch (batch) {
                if (batch) {
                    const vm = this;

                    vm.activeBatchHistories = vm.histories[batch];
                }
            },
        },

        computed: mapState({
            histories: state => state.appsModule.contentImportHistories,
            batches: state => state.appsModule.contentImportBatches,
            historyLoader: state => state.appsModule.importHistoryLoader,
        }),

        methods: {
            ...mapActions({
                getContentImportHistories: 'appsModule/getContentImportHistories'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            setActiveBatch (batch) {
                const vm = this;

                vm.activeBatch      = batch.batch;
                vm.activeBatchDate  = batch.date;
            },

            handleRediret (history) {
                const vm = this;

                const routeData = vm.$router.resolve({ path: history.link == '/templates-library' ? '/template' : history.link });
                window.open(routeData.href, '_blank');
            }
        },
    };
</script>

<style scoped>
    .category_img {
        position: relative;
    }

    .category_img .external_link {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 10;
    }
    .impcard ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 10px 0;
    }
    .impcard ul li{
        padding: 10px 15px;
        border: 1px solid #f3f3f3;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }
    .impcard .impcard_details h5 {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
    }
    .impcard .impcard_details p {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #999;
    }
    .impcard .view {
        margin-left: auto;
        padding: 0 5px 0 10px;
        font-size: 13px;
        cursor: pointer;
    }

    /*----- Content history -----*/
    .content_history{
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .header{
        padding: 25px 40px;
        border-bottom: 1px solid #e9e9e9;
    }
    .history_body{
        padding: 30px 40px;
        flex: 1 1 auto;
    }
    .history_body h3{
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
    }
    @media(max-width: 991px){
        .header{
            padding: 20px 30px;
        }
        .global_setting .header .section_header h2{
            font-size: 18px;
            line-height: 25px;
        }
    }
</style>
