const habitTracking = [
    {
        name: 'HabitTrackingIndex',
        component: () => import(/* webpackChunkName: "js/habit" */ '@/pages/habit-tracking/Index'),
        path: '/habit-tracking',
        meta: {
            title: 'Habit Tracking',
            requiresAuth: true,
            accountActive: true,
            group: 'Coaching',
        },
    },
];

export default habitTracking;
