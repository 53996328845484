<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="$emit('update:modelValue', false)"><i class="fas fa-long-arrow-alt-left"></i></button>
            <!-- <div class="toolbar">
                <img src="@/assets/images/marketplace.svg" alt="" class="mk_logo">
                <div class="mk_menu show">
                    <h4 @click="toggleSubmenu($event)"><span><i class="fas fa-tags"></i></span>Thrive Content <i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul class="mk_sub_menu">
                        <li :class="tab === 'branding' ? 'active' : ''" @click="tab = 'branding'">Branding</li>
                        <li :class="tab === 'marketing' ? 'active' : ''" @click="tab = 'marketing'">Marketing</li>
                        <li :class="tab === 'nurture' ? 'active' : ''" @click="tab = 'nurture'">Nurture</li>
                        <li :class="tab === 'onboarding' ? 'active' : ''" @click="tab = 'onboarding'">Onboarding</li>
                        <li :class="tab === 'programs' ? 'active' : ''" @click="tab = 'programs'">Client Programs</li>
                    </ul>
                </div>
                <div class="mk_menu">
                    <h4 @click="toggleSubmenu($event)"><span><i class="fas fa-tags"></i></span>Thrive Courses <i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul class="mk_sub_menu">
                        <li :class="tab === 'training-brand' ? 'active' : ''" @click="tab = 'training-brand'">Branding</li>
                        <li :class="tab === 'training-market' ? 'active' : ''" @click="tab = 'training-market'">Marketing</li>
                        <li :class="tab === 'sales' ? 'active' : ''" @click="tab = 'sales'">Sales</li>
                        <li :class="tab === 'training-onboard' ? 'active' : ''" @click="tab = 'training-onboard'">Onboarding</li>
                        <li :class="tab === 'delivery' ? 'active' : ''" @click="tab = 'delivery'">Program Delivery</li>
                        <li :class="tab === 'retention' ? 'active' : ''" @click="tab = 'retention'">Retention</li>
                        <li :class="tab === 'duplication' ? 'active' : ''" @click="tab = 'duplication'">Duplication</li>
                    </ul>
                </div>
                <div class="mk_menu">
                    <h4 @click="toggleSubmenu($event)"><span><i class="fas fa-tags"></i></span>Thrive Services <i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul class="mk_sub_menu">
                        <li :class="tab === 'coaching' ? 'active' : ''" @click="tab = 'coaching'">Success Coaching</li>
                        <li :class="tab === 'setup' ? 'active' : ''" @click="tab = 'setup'">Account Setup</li>
                        <li :class="tab === 'assistants' ? 'active' : ''" @click="tab = 'assistants'">Virtual Assistants</li>
                        <li :class="tab === 'web-design' ? 'active' : ''" @click="tab = 'web-design'">Website Design</li>
                        <li :class="tab === 'tutoring' ? 'active' : ''" @click="tab = 'tutoring'">Tutoring</li>
                    </ul>
                </div>
                <div class="mk_menu">
                    <h4 @click="toggleSubmenu($event)"><span><i class="fas fa-tags"></i></span>Thrive Workshops <i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul class="mk_sub_menu">
                        <li :class="tab === 'thrive' ? 'active' : ''" @click="tab = 'thrive'">Thrive Live 2023</li>
                    </ul>
                </div>
                <div class="mk_menu">
                    <h4 @click="toggleSubmenu($event)"><span><i class="fas fa-tags"></i></span>Thrive Gear <i class="fas fa-angle-down ml-auto"></i></h4>
                    <ul class="mk_sub_menu">
                        <li :class="tab === 'hats' ? 'active' : ''" @click="tab = 'hats'">Hats</li>
                        <li :class="tab === 'shirts' ? 'active' : ''" @click="tab = 'shirts'">Shirts</li>
                        <li :class="tab === 'hoodies' ? 'active' : ''" @click="tab = 'hoodies'">Hoodies</li>
                        <li :class="tab === 'jackets' ? 'active' : ''" @click="tab = 'jackets'">Jackets</li>
                        <li :class="tab === 'swag' ? 'active' : ''" @click="tab = 'swag'">Swag</li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <div class="content_area">
                    <div class="section_content w-100">
                        <div class="tag_line">Purchase curated coaching and services or done-for-you business systems that import directly into your Thrive Coach account!</div>
                        <div class="comming-soon">
                            <h2>Coming In 2023!</h2>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="marketplace">
                <div class="top_bar">
                    <ul>
                        <li>Alicia Streger, CSCS</li>
                    </ul>
                    <img src="@/assets/images/marketplace.svg" alt="Marketplace" @click="$emit('update:modelValue', false)">
                </div>
                <div class="content_body">
                    <div class="hero_section">
                        <img src="@/assets/images/streger.jpg" alt="" class="hero_img">
                        <div class="hero_content">
                            <h2>Challenges & Transformation Programs That Install With <span>One-Click</span> Into Your Thrive Coach Account!</h2>
                            <h6>Each product below is a complete, rebrandable, plug-and-play program. Even better… they’re all 100% dietician approved!</h6>
                            <img src="@/assets/images/streger-sign.png" alt="Alicia Streger, CSCS">
                            <div class="cta_btn" @click="tutorial = true;"><span><i class="fas fa-play"></i></span>Watch how easy 1-click install can be!</div>
                        </div>
                    </div>
                    <div class="category_section">
                        <ul class="categories">
                            <li @click="category = 'programs'" :class="{'active' : category === 'programs'}">Challenges & Transformation Programs</li>
                            <li @click="category = 'marketing'" :class="{'active' : category === 'marketing'}">Marketing Bundles</li>
                            <li @click="category = 'content'" :class="{'active' : category === 'content'}">Content Club</li>
                        </ul>
                        <div class="selected_section" v-if="category === 'programs'">
                            <h2>Challenges & Transformation Programs</h2>
                            <p>Each product below is a complete, rebrandable, plug-and-play program. Even better… they're all 100% dietician approved!</p>
                            <ul class="product_listing">
                                <li v-for="(program, p) in programs" :key="p">
                                    <div class="product_card">
                                        <a :href="program.link" target="_blank"><img :src="require(`@/assets/images/${program.src}`)" alt=""></a>
                                        <div class="product_info">
                                            <h4>{{ program.title }}</h4>
                                            <p v-html="program.desc"></p>
                                            <a :href="program.link" target="_blank" class="more">Learn more</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="selected_section" v-if="category === 'marketing'">
                            <h2>Marketing Bundles</h2>
                            <p>Go from zero to LAUNCH in under 2 hours with all of the ads, marketing emails, scripts & funnels you need to generate revenue from our DFY programs ASAP!</p>
                            <ul class="product_listing">
                                <li v-for="(market, m) in markets" :key="m">
                                    <div class="product_card">
                                        <a :href="market.link" target="_blank"><img :src="require(`@/assets/images/${market.src}`)" alt=""></a>
                                        <div class="product_info">
                                            <h4>{{ market.title }}</h4>
                                            <p v-html="market.desc"></p>
                                            <a :href="market.link" target="_blank" class="more">Learn more</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="content_wpr" v-if="category === 'content'">
                            <div class="content_club">
                                <div class="bar">FITNESS PROS, HEALTH COACHES & ONLINE TRAINERS:</div>
                                <h2>Want to spend less time on social media, and more time growing your health or fitness business?</h2>
                                <!-- <h4>Writing New Content Over & Over Becomes a HUGE Chore…</h4> -->
                                <h3>Access monthly copy-&-paste templates, trainings, and live coaching <span>just for health & fitness pros</span> to help you shortcut the marketing process and sell your programs!</h3>
                                <div class="club_content">
                                    <div class="club_thumb">
                                        <img src="@/assets/images/fitpro-club.png" alt="" class="thumb_img">
                                        <!-- <div class="canva_info">
                                            <label>100% Rebrandable & Customizable in</label>
                                            <img src="@/assets/images/canva-logo.png" alt="">
                                        </div> -->
                                    </div>
                                    <div class="subscription_content">
                                        <div class="title">Here's what Content Club helps you do EVERY month…</div>
                                        <ul>
                                            <li><span>Grow your audience FAST</span> - Don't have time to create new freebies like eBooks, guides, checklists, or recipe books to grow your email list or add to your programs? Content Club does it FOR you every month — and all you have to do is put your name, colors, and logo on them!</li>
                                            <li><span>Turn email subscribers into clients</span> - The more your audience knows, loves, and trusts you, the faster they'll sign up for your programs. Get monthly fill-in-the-blank emails that educate, inspire, and CONVERT your subscribers into real, paying clients!</li>
                                            <li><span>Build authority on social media</span> - Yes, people DO check your social media accounts before they ever decide to work with you! Content Club gives you a DAILY posting strategy designed to attract, connect with, educate, and convert new followers — plus, it's all done for you so you don't have to write or create anything from scratch! </li>
                                            <li><span>Feel confident on camera</span> - Reels are the key to boosting your visibility right now, but it's not always easy to come up with fresh, original content. Instead of winging it, you'll get access to brand new scripts and ideas for IG & FB reels EVERY month to amplify your reach!</li>
                                            <li><span>Level up your marketing skills</span> - Access trainings on creating scroll-stopping hooks, fine-tuning sales page headlines, how to sell on Instagram stories and so much more!</li>
                                            <li><span>Get LIVE help and feedback</span> - Once a month, you're invited to bring your stickiest marketing, mindset, and business challenges to a LIVE discussion with Alicia to get answers to your questions in real time!</li>
                                        </ul>
                                        <div class="offer">Start getting your time back for just <span>$27</span>!</div>
                                        <!-- <div class="today_offer">Today Just for <span>$49.50</span></div> -->
                                        <button type="button" class="cta_btn" @click="apply_coupon = true;">I'M READY TO GROW MY BUSINESS!</button>
                                        <label class="notes">14-Day Money-Back Guarantee</label>
                                    </div>
                                </div>
                            </div>
                            <div class="selected_section">
                                <h2>Members Only</h2>
                                <p>Content Club MEMBERS ONLY Can also purchase previous months content at a discount!</p>
                                <ul class="product_listing">
                                    <li v-for="(workshop, w) in workshops" :key="w">
                                        <div class="product_card">
                                            <img :src="require(`@/assets/images/${workshop.src}`)" alt="">
                                            <div class="product_info">
                                                <h4>{{ workshop.title }}</h4>
                                                <p>{{ workshop.desc }}</p>
                                                <a :href="workshop.red_link" target="_blank" class="more">Join The Club</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal secondary coupon_apply" v-if="apply_coupon">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="apply_coupon = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="coupon_details">
                    <h2 class="mb-2">Get The Offer!</h2>
                    <label class="notes">Use this code to get:<span class="off_amt">50% Off</span>On your first month of Content Club!</label>
                    <div class="coupon_wpr">
                        <div class="coupon_code">{{ coupon }}</div>
                        <span class="copy" @click="copyCode"><i class="far fa-copy"></i></span>
                    </div>
                    <a href="https://contentandsocialmediaclub.com/~access/a1c1147f/" target="_blank" class="proceed_btn">Click Here To Get The Deal!</a>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr" v-html="tutorial_video_src"></div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Marketplace',

        data () {
            return {
                tab: 'branding',
                category: 'programs',
                apply_coupon: false,
                coupon: 'CLUB49',
                tutorial: false,
                tutorial_video_src: '<iframe src="//youtube.com/embed/KOM1Wjq2y7Y?autoplay=0" width="100%" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen="true" id="video_player" ></iframe>',
                programs:[
                    {
                        src: '6wcBG.jpg',
                        title: '6-Week Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/h3nVpTLQ/641791',
                        desc: '<p>Save yourself 100+ hours putting together your own transformation challenges and programs.<br/><br/>This transformation program can be used for both online and offline businesses and is great for both MEN & WOMEN.<br/><br/>It’s dietician-approved, and has everything you need to run a successful and profitable challenge.<br/><br/>You’ll get six weeks of sample meal guides, grocery lists, success manuals, recipes, daily emails, client handouts, and a LOT more!  Everything in this program is 100% rebrandable, so you can add your own logo, colors, and branding.</p>'
                    },
                    {
                        src: '28dcBG.jpg',
                        title: '28-Day Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/28-Day-Challenge/641791',
                        desc: '<p>This program is different from the 6-Week Challenge. It has a similar philosophy, but different recipes, emails, & content so your clients have variety throughout the year!<br/><br/>This program that has been PROVEN to get incredible results for both MEN & WOMEN.<br/><br/>It’s dietician approved and has all of the tools you need to run a highly successful challenge.<br/><br/>You’ll get 28-Days worth of delicious recipes, sample meal guides, grocery lists, success manuals, daily emails, client handouts, and more. Everything is rebrandable and has been approved by a dietician. It’s 100% done-for-you.</p>'
                    },
                    {
                        src: 'pbpBG.png',
                        title: 'Plant-Based Program',
                        link: 'https://fitproessentials.samcart.com/referral/kRLiWZYC/641791',
                        desc: '<p>Plant-based diets have experienced a MASSIVE resurgence in popularity …<br/><br/>and now you’ve got a PLUG-&-PLAY resource to help you tap into this underserved market and take your business to another level!<br/><br/>It’s 30-Day program that is dietician-approved and has everything you need to run a successful and profitable transformation program.<br/><br/>You’ll get sample meal guides, printable grocery lists, success manual, recipe book, inspirational client emails, handouts, and a LOT more! Everything is 100% rebrandable, so you can add your own logo & branding.</p>'
                    },
                    {
                        src: 'lbdBG.jpg',
                        title: 'Original LBD Project',
                        link: 'https://fitproessentials.samcart.com/referral/The-Little-Black-Dress-Project/641791',
                        desc: '<p><strong>*TOP SELLER*</strong> This program will change the game for your business! It literally has EVERYTHING you need to run a successful (and highly profitable) transformation challenge.<br/><br/>The Little Black Dress Project is on a MISSION to help women to become more self-confident, lose weight, and celebrate being a strong and sexy woman.<br/><br/>You’ll get 6 weeks of LBD recipes, a sample meal guide, sample grocery lists, success manual, daily emails, client handouts, and a lot more!<br/><br/>Everything is rebrandable and has been approved by a dietician.</p>'
                    },
                    {
                        src: 'lbd2BG.jpg',
                        title: 'Original LBD Project 2.0',
                        link: 'https://fitproessentials.samcart.com/referral/tLIggF6E/641791',
                        desc: '<p>The LBD Project 2.0 is a dietician-approved, <strong>28-Day</strong> transformation program that has everything you need to deliver life-changing results for your clients… all while creating a cash-flow infusion into your business.<br/><br/>It shares the same philosophy as the original LBD Project… but the 2.0 version has <strong>COMPLETELY DIFFERENT RECIPES & CONTENT!</strong><br/><br/>This program makes for the perfect addition to the original LBD Project, but also works great as a stand-alone program!<br/><br/>Like the original, this 2.0 edition is also 100% rebrandable, and plug-and-play!</p>'
                    },
                    {
                        src: '6wmcBG.png',
                        title: '6-Week Men’s Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/gfyJ64GS/641791',
                        desc: '<p>The 6-Week Transformation Program is <strong>BUILT EXCLUSIVELY FOR MEN</strong> will help your male clients embody the BEST version of themselves so they can be better men at home, at work, and in their communities.<br/><br/>We’ll dive into carb cycling, results-driven workouts, optimizing sleep, meal prep, mindset, and SO much more.<br/><br/>This program dives deeper into some of the REAL areas that men can benefit from… like managing stress, finding purpose, developing more confidence, and becoming a true leader!<br/><br/>All you have to do is put your name, colors, and logo on it.</p>'
                    },
                    {
                        src: '10ddBG.jpg',
                        title: '10-Day Detox',
                        link: 'https://fitproessentials.samcart.com/referral/OpTbV85x/641791',
                        desc:'<p>This is the exact 10-Day Detox program I’ve run to MASSIVE success in my fitness program every single time.<br/><br/>It makes for a perfect program in the Spring, or after the holidays.<br/><br/>You’ll have a done-for-you solution that can make your business a LOT of money while getting your clients incredible results.<br/><br/>Complete with a 30-page Client Success Manual, sample meal guide, grocery lists, daily emails, and a recipe manual…<br/><br/>You’ll have everything you need to make this program a huge success! This is also 100% rebrandable.</p>'
                    },
                    {
                        src: '21dhcBG.png',
                        title: '21 Day Habit Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/habit-challenge/641791',
                        desc:'<p>This fully done-for-you, 21-Day Challenge is designed to help you attract new leads and boost client results & retention.<br/><br/>We all know that creating healthy habits is the KEY to success for our clients … and creating and installing new, healthy habits is exactly what this 21-Day Challenge is DESIGNED to do!<br/><br/>Customizable client success guide, DFY coaching emails, printable habit tracker, proven marketing emails, bonus marketing funnel, and more!<br/><br/>Simply brand this program with your own logo, colors, and contact info…. and you’re ready to go!</p>'
                    },
                    {
                        src: '21dkcBG.jpg',
                        title: '21 Day Keto Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/b5RnJ7Qn/641791',
                        desc:'<p>This is a plug-n-play, rebrandable 21-day Keto Transformation Program (dietician approved).<br/><br/>Meal guides, printable grocery lists, inspirational client emails, client success manual, recipe book, swap and subs guide, measurements worksheet, and more!<br/><br/>Simply brand this program with your own logo, colors, and contact info…. and you’re ready to go!<br/><br/>There’s a lot of misinformation about keto out there. Here’s your chance to offer your clients and prospects a COMPLETE PROGRAM and educate them on how to do it PROPERLY.</p>'
                    },
                ],
                markets:[
                    {
                        src: '6wsBG.png',
                        title: '6-Week Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/8lw5na38/641791',
                        desc: '<p>Boost your bottom line with this client favorite! Tailor this plug-and-play funnel to ANY 6-week challenge or program, or run it evergreen for consistent cash flow.<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy Facebook Ads Templates</li><li>Complete Marketing Funnel</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                    {
                        src: 'lbd-pmbBG.png',
                        title: 'The LBD Project',
                        link: 'https://fitproessentials.samcart.com/referral/tUvwnQRn/641791',
                        desc: '<p>Our brand new LBD Project Marketing Bundle HAS ARRIVED! 💃 Promote our most profitable program as soon as TODAY with our easy-to-edit, plug-and-play funnel!<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy Facebook Ads Templates</li><li>Complete Marketing Funnel</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                    {
                        src: 'LBD-2BG.png',
                        title: 'The LBD Project 2.0',
                        link: 'https://fitproessentials.samcart.com/referral/xeorNR5P/641791',
                        desc: '<p>Whether you’re promoting this as a standalone program or continuation to the Original LBD, this high-converting bundle has you covered! Just rebrand and LAUNCH! Get 2 sets of EVERYTHING:<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy FB Ads Templates</li><li>Complete Marketing Funnel</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                    {
                        src: 'hcmbBG.png',
                        title: 'Hero Challenge',
                        link: 'https://fitproessentials.samcart.com/referral/OhR3h62O/641791',
                        desc: '<p>Ready to save time and energy setting up all of your marketing for your 6-Week Men’s Hero Challenge!? We’ve got you covered.<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy Hero Challenge Facebook Ads</li><li>Complete Marketing Funnel!</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                    {
                        src: 'pbmbBG.png',
                        title: 'Plant-Based',
                        link: 'https://fitproessentials.samcart.com/referral/oeKjS6zy/641791',
                        desc: '<p>Start generating revenue faster than you can say “veggie burger” by simply adding your logo, colors, and branding to this plug-and-play funnel!<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy Facebook Ads Templates</li><li>Complete Marketing Funnel</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                    {
                        src: '10ddmbBg.png',
                        title: '10 Day Detox',
                        link: 'https://fitproessentials.samcart.com/referral/r7Bxj3Oa/641791',
                        desc: '<p>Attract high-quality clients with this NEW, beautifully-designed bundle that’s built to CONVERT — no guesswork necessary!<br/><br/><ul><li>Proven-to-Convert Marketing Emails</li><li>Swipe & Deploy Facebook Ads Templates</li><li>Complete Rebrandable Marketing Funnel</li><li>100% Happiness Guarantee</li></ul></p>'
                    },
                ],
                workshops:[
                    {
                        src: 'mk_workshop1.jpg',
                        title: 'May 2023 Content',
                        desc: 'Get 30-Days of FUN and results-driven workout programming to help you deliver an incredible virtual experience to your clients.',
                        red_link: 'https://contentandsocialmediaclub.com/~access/a1c1147f/'
                    },
                    {
                        src: 'mk_workshop2.jpg',
                        title: 'April 2023 Content',
                        desc: 'Get an ENTIRE YEAR’S WORTH of swipe-and-deploy content for your Facebook™ Groups!',
                        red_link: 'https://contentandsocialmediaclub.com/~access/a1c1147f/'
                    },
                    {
                        src: 'mk_workshop3.jpg',
                        title: 'March 2023 Content',
                        desc: 'This Goal Workshop is the EXACT same workshop that brought in $16,281 in new business in ONE hour',
                        red_link: 'https://contentandsocialmediaclub.com/~access/a1c1147f/'
                    },
                ]
            };
        },

        props:{
            modelValue: Boolean,
        },

        watch:{
            modelValue(val){
                if(val){
                    document.body.classList.add('modal-open');
                }else{
                    document.body.classList.remove('modal-open');
                }
            }
        },

        methods: {
            toggleSubmenu(e){
                let el = e.currentTarget.closest('.mk_menu');
                if(el.classList.contains('show')){
                    el.classList.remove('show');
                }else{
                    el.classList.add('show');
                }
            },
            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },
            copyCode(){
                navigator.clipboard.writeText(this.coupon);
            }
        },
    }
</script>

<style scoped>
    .content_club{
        margin: 50px 0 0;
        text-align: center;
        border-radius: 10px;
        background: rgba(0,0,0,0.01);
        /* background: #fbfbfb; */
        position: relative;
        z-index: 1;
    }
    .content_club:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('~@/assets/images/content_bg.jpg');
        opacity: 0.03;
        z-index: -1;
    }
    .content_club .bar{
        padding: 12px 15px;
        background: #f1e5d2;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 40px;
        border-radius: 10px 10px 0 0;
    }
    .content_club h2{
        font-size: 27px;
        line-height: 37px;
        font-weight: 500;
        color: #121525;
        max-width: 800px;
        margin: 0 auto 20px auto;
    }
    .content_club h4{
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        color: #f2a31d;
        margin-bottom: 20px;
    }
    .content_club h3{
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #5a5a5a;
        max-width: 750px;
        margin: 0 auto 15px auto;
    }
    .content_club h3 span{
        font-weight: 500;
        font-style: italic;
        text-decoration: underline;
    }
    .content_club .club_content{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 30px 0 60px 0;
    }
    .club_content .club_thumb{
        width: calc(40% - 30px);
    }
    .club_thumb .thumb_img{
        max-width: 350px;
        width: 100%;
        height: auto;
    }
    .club_thumb .canva_info{
        padding: 30px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .club_thumb .canva_info label{
        width: 150px;
        text-align: right;
        padding-right: 20px;
        margin-right: 15px;
        border-right: 1px solid #d9d9d9;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #a1a1a1;
        font-style: italic;
        display: block;
    }
    .club_thumb .canva_info img{
        max-height: 40px;
        width: auto;
    }
    .club_content .subscription_content{
        width: calc(50% - 30px);
        padding: 30px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        background: #fff;
    }
    .subscription_content .title{
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
    }
    .subscription_content ul{
        padding: 20px 0 30px 0;
    }
    .subscription_content ul li{
        padding: 10px 0 10px 35px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        position: relative;
    }
    .subscription_content ul li span{
        color: #121525;
        font-weight: 500;
        text-decoration: underline;
    }
    .subscription_content ul li:before{
        position: absolute;
        left: 0;
        top: 12px;
        width: 22px;
        height: 22px;
        background: #e2eeff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 10px;
        color: #2f7eed;
    }
    .subscription_content .offer{
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 25px;
    }
    .subscription_content .offer span{
        color: #f2a31d;
        font-weight: 500;
    }
    .subscription_content .today_offer{
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        color: #5a5a5a;
        font-style: italic;
    }
    .subscription_content .today_offer span{
        color: #f2a31d;
        font-weight: 500;
    }
    .subscription_content .cta_btn{
        padding: 15px 20px;
        font-size: 17px;
        line-height: 25px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        margin: 15px auto 0 auto;
        cursor: pointer;
    }
    .subscription_content label.notes{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #929292;
        font-style: italic;
        display: block;
        margin: 12px 0;
    }
    .modal.coupon_apply .modal_container{
        width: 600px;
    }
    .modal.coupon_apply .modal_header .close_btn {
        right: -7px;
        top: -7px;
        border: 1px solid #c7c7c7;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
    }
    .coupon_details{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .coupon_wpr{
        max-width: 300px;
        width: 100%;
        display: flex;
        gap: 10px;
        margin: 20px 0;
    }
    .coupon_wpr .coupon_code{
        text-align: center;
        flex: 1 1 auto;
        padding: 12px;
        border: 2px dashed #f2a31d;
        background: #fff7ea;
        border-radius: 3px;
        font-size: 18px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
    }
    .coupon_wpr .copy{
        width: 50px;
        height: 50px;
        /* border: 1px solid #bdd9ff; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #2f7eed;
        border-radius: 3px;
        cursor: pointer;
        background: #e5f0ff;
    }
    .coupon_apply .proceed_btn{
        padding: 15px 30px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        background: #2f7eed;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        width: 100%;
        margin: 15px auto 20px auto;
        cursor: pointer;
    }
    .coupon_apply .notes{
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        /* font-style: italic; */
        display: block;
        text-align: center;
        margin: 10px 0;
    }
    .coupon_apply .notes .off_amt{
        display: block;
        font-size: 40px;
        line-height: 50px;
        font-weight: 300;
        padding: 10px 0;
        color: #f2a31d;
    }
    .toolbar{
        height: calc(100vh - 50px);
        overflow-y: scroll;
        margin-left: 10px;
        padding: 70px 20px 20px 20px;
        background: #fff;
        border-right: 1px solid #e9e9e9;
        width: 250px;
        border-radius: 5px 5px 0 0;
    }
    .toolbar::-webkit-scrollbar{
        display: none;
    }
    .toolbar ul li{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 10px 15px;
        cursor: pointer;
        position: relative;
    }
    .toolbar ul li:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-left: 2px solid #a1c8ff;
        height: 0;
        transition: all 0.3s ease-in-out;
    }
    .toolbar ul li.active{
        color: #2f7eed;
        background: #fafafa;
    }
    .toolbar ul li.active:before{
        height: 100%;
    }
    .mk_menu:not(:first-of-type){
        position: relative;
        margin-top: -1px;
    }
    .mk_menu h4{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 14px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .mk_menu h4 i{
        color: #5a5a5a;
        transition: all 0.3s ease-in-out;
    }
    .mk_menu h4 span{
        position: relative;
        margin-left: -5px;
    }
    .mk_menu h4 span i{
        transform: scaleX(-1) !important;
        margin-right: 15px;
    }
    .mk_menu .mk_sub_menu{
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        position: relative;
        margin-bottom: 5px;
    }
    .mk_menu .mk_sub_menu:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
    }
    .mk_menu.show .mk_sub_menu{
        max-height: 100vh;
    }
    .mk_menu.show h4 i{
        transform: rotate(180deg);
    }
    .tag_line{
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
        font-style: italic;
        padding: 30px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        margin-bottom: 30px;
    }
    .comming-soon{
        height: calc(100vh - 315px);
        background: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    .comming-soon h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    .mk_logo{
        margin: 0 0 15px -5px;
    }


    /* marketplace */
    .preview_area{
        background: #fafafb;
    }
    .global_setting{
        overflow-y: scroll;
    }
    .marketplace{
        max-width: 1170px;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.05);
    }
    .marketplace .top_bar{
        display: flex;
        padding: 0 30px;
        justify-content: space-between;
        align-items: center;
        background: #fcfcfc;
        border-bottom: 1px solid #ededed;
    }
    .marketplace .top_bar img{
        max-height: 18px;
        width: auto;
        cursor: pointer;
    }
    .marketplace .top_bar ul{
        display: flex;
        gap: 30px;
        justify-content: flex-start;
    }
    .marketplace .top_bar li{
        height: 60px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .marketplace .content_body{
        padding: 60px;
        background: #fff;
    }
    .hero_section{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 60px;
    }
    .hero_section .hero_img{
        width: 40%;
        height: auto;
    }
    .hero_section .hero_content{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .hero_section .hero_content h2{
        font-size: 30px;
        line-height: 45px;
        font-weight: 300;
        color: #121525;
        margin-bottom: 25px;
    }
    .hero_section .hero_content h2 span{
        font-weight: 500;
    }
    .hero_section .hero_content h6{
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        color: #5a5a5a;
        margin-bottom: 45px;
    }
    .hero_section .hero_content img{
        display: block;
        margin: 0 15px 0 auto;
        max-height: 22px;
    }
    .hero_section .hero_content .cta_btn{
        margin: 30px auto 0 0;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .hero_section .hero_content .cta_btn span{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    .hero_section .hero_content .cta_btn span i{
        font-size: 8px;
        color: #121525;
        margin-left: 2px;
    }
    .selected_section{
        padding: 45px 0 0;
    }
    .selected_section h2{
        font-size: 30px;
        line-height: 47px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
    }
    .selected_section p{
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
        color: #5a5a5a;
    }
    .category_section{
        padding: 100px 0 0 0;
    }
    .category_section .categories{
        display: flex;
        align-items: center;
        gap: 5%;
        position: relative;
    }
    .category_section .categories:after{
        position: absolute;
        content: '';
        border-bottom: 1px solid #e9e9e9;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .category_section .categories li{
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
        padding: 12px 0;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .category_section .categories li:after{
        position: absolute;
        content: '';
        border-bottom: 1px solid #2f7eed;
        width: 100%;
        left: -100%;
        bottom: 0;
        transition: all 0.3s ease-in-out;
    }
    .category_section .categories li.active{
        color: #2f7eed;
    }
    .category_section .categories li.active:after{
        left: 0;
    }
    .product_listing{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        padding: 30px 0;
    }
    .product_listing li{
        width: 33.333%;
        padding: 15px;
    }
    .product_listing li .product_card{
        display: flex;
        flex-direction: column;
        height: 100%;
        line-height: 0;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
    }
    .product_listing li .product_card img{
        border-radius: 5px 5px 0 0;
        width: 100%;
        height: auto;
    }
    .product_listing li .product_card .product_info{
        padding: 25px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .product_listing li .product_card h4{
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 14px;
    }
    .product_listing li .product_card p{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        margin-bottom: 25px;
        /* display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden; */
    }
    .product_listing li .product_card p :deep(ul){
        padding-left: 15px;
    }
    .product_listing li .product_card p :deep(ul li){
        list-style-type: disc;
        padding: 3px 0;
    }
    .product_listing li .product_card .more{
        padding: 8px 20px;
        font-size: 13px;
        line-height: 18px;
        color: #fff;
        border-radius: 3px;
        background: #2c3e50;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin: auto auto 5px 0;
        text-decoration: none;
        cursor: pointer;
    }
    /* .global_setting .close_btn{
        top: 48px;
    } */
    @media(max-width: 1199px){
        .marketplace .top_bar img{
            max-height: 14px;
        }
        .marketplace .content_body{
            padding: 50px 40px;
        }
        .hero_section {
            gap: 45px;
        }
        .hero_section .hero_content h2 {
            font-size: 2.4vw;
            line-height: 1.5;
            margin-bottom: 20px;
        }
        .hero_section .hero_content h6 {
            font-size: 15px;
            line-height: 25px;
        }
        .hero_section .hero_content img {
            max-height: 18px;
        }
        .selected_section h2{
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        .selected_section p{
            font-size: 13px;
            line-height: 20px;
        }
        .content_club .club_content{
            gap: 40px;
        }
        .content_club h2{
            font-size: 20px;
            line-height: 28px;
        }
        .content_club h3{
            font-size: 16px;
            line-height: 23px;
        }
        .content_club h4{
            font-size: 16px;
            line-height: 23px;
        }
    }
    @media(max-width: 991px){
        .preview_area{
            z-index: 13;
        }
        .marketplace .content_body{
            padding: 50px 35px;
        }
        .hero_section {
            gap: 40px;
        }
        .hero_section .hero_content h6 {
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 15px;
        }
        .hero_section .hero_content img {
            max-height: 15px;
        }
        .hero_section .hero_content .cta_btn{
            margin: 15px auto 5px 0;
        }
        .product_listing li{
            width: 50%;
        }
        .subscription_content .offer {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 10px;
        }
        .subscription_content .today_offer{
            font-size: 16px;
            line-height: 23px;
        }
        .club_content .subscription_content{
            width: calc(55% - 30px);
            padding: 30px 20px;
        }
        .subscription_content .offer{
            font-size: 17px;
            line-height: 24px;
        }
        .subscription_content .cta_btn{
            font-size: 15px;
            line-height: 22px;
        }
        .subscription_content label.notes{
            font-size: 14px;
            line-height: 19px;
        }
    }
    @media(max-width: 767px){
        .marketplace .top_bar li{
            font-size: 13px;
        }
        .marketplace .content_body{
            padding: 50px 30px;
        }
        .hero_section {
            gap: 30px;
        }
        .hero_section .hero_content h6 {
            font-size: 11px;
            line-height: 17px;
            margin-bottom: 10px;
        }
        .hero_section .hero_content img {
            max-height: 13px;
        }
        .hero_section .hero_content .cta_btn{
            margin: 10px auto 5px 0;
            font-size: 11px;
        }
        .category_section{
            padding-top: 70px;
        }
        .selected_section{
            padding-top: 30px;
        }
        .category_section .categories li{
            font-size: 13px;
            line-height: 18px;
        }
        .content_club .club_content{
            flex-direction: column;
        }
        .club_content .club_thumb{
            flex: 0 0 auto;
            max-width: 350px;
        }
        .club_content .subscription_content{
            flex: 0 0 auto;
            max-width: 550px;
        }
        .modal.coupon_apply .modal_container{
            width: 350px;
        }
        .coupon_apply .proceed_btn{
            font-size: 15px;
            line-height: 22px;
            padding: 12px 25px;
        }
        .coupon_apply .notes{
            font-size: 13px;
            line-height: 20px;
        }
        .coupon_apply .notes .off_amt{
            font-size: 30px;
            line-height: 40px;
        }
        .coupon_wpr .coupon_code{
            padding: 10px;
            font-size: 15px;
            line-height: 22px;
        }
        .coupon_wpr .copy{
            width: 46px;
            height: 46px;
        }
        .content_club h2, .content_club h3{
            padding: 0 30px;
        }
        .club_content .subscription_content{
            width: calc(100% - 60px);
            max-width: 100%;
            padding: 30px;
        }
    }
    @media(max-width: 599px){
        .marketplace .top_bar li{
            font-size: 11px;
        }
        .hero_section{
            flex-wrap: wrap;
        }
        .hero_section .hero_img{
            width: 100%;
            height: 300px;
            object-position: top center;
        }
        .hero_section .hero_content h2 {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 15px;
        }
        .hero_section .hero_content h6 {
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 15px;
        }
        .hero_section .hero_content .cta_btn{
            margin: 20px auto 5px 0;
            font-size: 13px;
        }
        .product_listing li{
            width: 100%;
        }
        .club_content .club_thumb{
            width: 60%;
        }
    }
    @media(max-width: 499px){
        .marketplace .top_bar{
            padding: 0 20px;
        }
        .marketplace .content_body{
            padding: 40px 20px;
        }
        .product_listing li .product_card .product_info{
            padding: 20px;
        }
        .product_listing li .product_card h4{
            font-size: 18px;
            line-height: 26px;
        }
        .content_club h2, .content_club h3{
            padding: 0 20px;
        }
        .club_content .club_thumb{
            width: 80%;
        }
        .club_content .subscription_content{
            width: calc(100% - 30px);
            max-width: 100%;
            padding: 30px 15px;
        }
        .content_club .bar{
            font-size: 11px;
        }
        .subscription_content .offer{
            font-size: 17px;
            line-height: 24px;
        }
        .subscription_content .today_offer{
            font-size: 13px;
            line-height: 18px;
        }
        .subscription_content label.notes{
            font-size: 12px;
            line-height: 18px;
        }
        .subscription_content .cta_btn{
            font-size: 14px;
            padding: 12px 10px;
        }
    }
</style>
