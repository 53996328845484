const getters = {
    GET_CROPPER_ASPECT_RATIO_BY_TYPE: (state) => (type) => {
        let size, ratio;

        if (type === 'favicon') {
            size = '32x32';
            ratio = 32/32;
        } else if (type === 'public-cover') {
            size = '2560x600';
            ratio = 2560/600;
            // size = '1280x300';
            // ratio = 1280/300;
        } else if (type  === 'coach-logo' || type  === 'avatar') {
            size ='360x360';
            ratio = 360/360;
        } else if (type === 'public-logo') {
           size ='180x60';
           ratio = 180/60;
        } else if(type === 'author-image') {
            size ='180x180';
            ratio = 180/180;
        } else if(type === 'content-image') {
            size ='600x350';
            ratio = 600/350;
        } else if(type === 'dashboard-thumb') {
            size = '1024x662';
            ratio = 1024/662;
        } else if(type === 'social-share') {
            size = '1200x630';
            ratio = 1200/630;
        } else if(type === 'offer-popup' || type === 'offer') {
            size = '1200x800';
            ratio = 1200/800;
        } else if(type === 'playbook-step-banner') {
            size = '1200x200';
            ratio = 1200/200;
        } else if(type === 'playbook-step-thumb') {
            size = '1200x700';
            ratio = 1200/700;
        } else if (type === 'profile-pic') {
            size = '160x160';
            ratio = 160/160
        } else if (type === 'all') {
            size = '0x0';
            ratio = 0/0
        } else if (type.toLowerCase() === 'portrait') {
            size = '400x600';
            ratio = 400/600;
        } else if (type.toLowerCase() === 'landscape') {
            size = '600x400';
            ratio = 600/400;
        } else if (type.toLowerCase() === 'public-cover-small') {
            size = '746X480';
            ratio = 746/480;
        } else if (type.toLowerCase() === 'newsletter-cover') {
            size = '1920X970';
            ratio = 1920/970;
        } else if (type.toLowerCase() === 'newsletter-popup') {
            size = '350X530';
            ratio = 350/530;
        } else if (type.toLowerCase() === 'newsletter-thankyou-cover') {
            size = '1920X400';
            ratio = 1920/400;
        } else if(type === 'checkout-cover') {
            size = '1200x675';
            ratio = 1200/675;
        }  else if(type === 'checkout-product') {
            size = '600X360';
            ratio = 600/360;
        }  else if(type === 'exercise-gif') {
            size = '920X518';
            ratio = 920/518;
        }  else if(type === 'exercise-thumb') {
            size = '480X270';
            ratio = 480/270;
        }  else if(type === 'custom-icon') {
            size = '128X128';
            ratio = 128/128;
        }  else if(type === 'cover-medium') {
            size = '768X300';
            ratio = 768/300;
        }

        return { size, ratio, type };
    },
};

export default getters;
