const state = {
    groups: [],
    allGroupLoader: false,
    groupLoader: false,
    contacts: {},
    userGroups: [],
    groupComponentLoader: false,
};

export default state;
