import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getTags (store, params) {
        store.commit('SET_ALL_TAG_LOADER_STATUS', true);

        axios.get('/tag/list', params).then((resp) => {
            store.commit('SET_TAGS_GROUP', resp.data.tags);
            store.commit('SET_TAGS', resp.data.original);
            store.commit('SET_ALL_TAG_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_ALL_TAG_LOADER_STATUS', false);
        });
    },

    createTag (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TAG_LOADER_STATUS', true);

            axios.post('/tag/create', params).then((resp) => {
                store.commit('SET_TAG_LOADER_STATUS', false);
                Toastr.success('Tag has been created successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TAG_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    addTag (store, params) {
        delete params.action;

        store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

        axios.patch('/tag/add-contact', params).then((resp) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
            store.commit('contactModule/SET_UPDATED_ACTION', 'Tags', { root: true });
            Toastr.success('Tag has been added successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    removeTag (store, params) {
        delete params.action;

        store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', true, { root: true });

        axios.patch('/tag/remove-contact', params).then((resp) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
            Toastr.success('Tag has been remove successfully!');

            if (params.closeModal) {
                params.closeModal();
            }
        }).catch((err) => {
            store.commit('contactModule/SET_BULK_ACTION_LOADER_STATUS', false, { root: true });
            Toastr.handleServerError(err, params.setFieldError);
        });
    },

    getTagContacts (store, params) {
        store.commit('SET_TAG_LOADER_STATUS', true);

        axios.get('/tag/contact-list', { params }).then((resp) => {
            store.commit('SET_TAG_LOADER_STATUS', false);
            store.commit('SET_CONTACTS', resp.data);
        }).catch((err) => {
            store.commit('SET_TAG_LOADER_STATUS', false);
            Toastr.handleServerError(err);
        });
    },

    editTag (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_TAG_LOADER_STATUS', true);

            axios.patch('/tag/edit-tag', params).then((resp) => {
                store.commit('SET_TAG_LOADER_STATUS', false);
                Toastr.success('Tag has been stored successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_TAG_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteTag (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/tag/${params.id}/delete`, { params }).then((resp) => {
                Toastr.success('Tag has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },
};

export default actions;
