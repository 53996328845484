const state = {
    contacts: [],
    statuses: [],
    statusList: [],
    statusesGroup: [],
    totalLength: 0,
    contactsLoader: false,
    assetsLoader: false,
    bulkActions: [
        {
            title: 'Contacts',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                {
                  title: 'Change Gender',
                  action: 'genderToggle',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                {
                  title: 'Switch Tabs',
                  action: 'statusToggle',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                { title: 'Update Phone Country Code', action: 'togglePhoneCountryCodePanel' },
                { title: 'Merge Contacts', action: 'toggleMergeContactsPanel' },
                {
                    title: 'Move Contacts',
                    action: 'toggleAssignContactsPanel',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
                { title: 'Celebrate Birthdays', action: 'toggleContactsBirthdayPanel' },
                { title: 'Deactivate Birthdays', action: 'toggleDeactivateBirthdayPanel' },
                {
                    title: 'Copy To Location',
                    action: 'toggleCopyContactsPanel',
                    hasCan: true,
                    can: {
                        action: 'assign',
                        module: 'contacts-center'
                    }
                },
                { title: 'Change member ID', action: 'toggleChangeMemberIdPanel' },
                {
                    title: 'Activate Portal',
                    action: 'activate',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
                {
                    title: 'Suspend Portal',
                    action: 'suspend',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
            ],
        },
        {
            title: 'SMS',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send SMS', action: 'sendSMSToggle' },
                {
                  title: 'Disable SMS',
                  action: 'disableSMSSendingPrompt',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                {
                  title: 'Enable SMS',
                  action: 'enableSMSSendingPrompt',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                { title: 'Send Portal Message', action: 'sendPortalMessage' },
            ],
        },
        {
            title: 'Tags',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                {
                  title: 'Add Tag',
                  action: 'addTagsToggle',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                {
                  title: 'Remove Tag',
                  action: 'removeTagsToggle',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
            ],
        },
        {
            title: 'Email',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send Email', action: 'sendEmailToggle' },
                {
                  title: 'Disable Email',
                  action: 'disableEmailSendingPrompt',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                {
                  title: 'Enable Email',
                  action: 'enableEmailSendingPrompt',
                  hasCan: true,
                  can: {
                      action: 'update',
                      module: 'contacts-center'
                  }
                },
                { title: 'Request Confirmation', action: 'requestConfirmationToggle' },
                { title: 'Disable Confirmation', action: 'disableConfirmationPrompt' },
                { title: 'Validate Email', action: 'validateEmailPrompt' },
                { title: 'Enable Broadcasts', action: 'enableBroadcasts' },
                { title: 'Disable Broadcasts', action: 'disableBroadcasts' },
            ],
        },
        // {
        //     title: 'Pipelines',
        //     action: '',
        //     hasCan: true,
        //     can: {
        //         action: 'update',
        //         module: 'contacts-center'
        //     },
        //     hasSubMenu: true,
        //     submenus: [
        //         { title: 'Add Contacts To Pipeline', action: 'toggleAddPipelineContactsPanel' },
        //         { title: 'Remove Contacts From Pipeline', action: 'toggleRemovePipelineContactsPanel' },
        //     ],
        // },
        {
            title: 'Sequences',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Start Sequence', action: 'sendSequenceToggle' },
                { title: 'Stop Sequence', action: 'stopSequenceToggle' },
            ],
        },
        {
            title: 'Journeys',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Start Journey', action: 'startJourneyToggle' },
                { title: 'Terminate Journey', action: 'terminateJourneyToggle' },
            ],
        },
        {
            title: 'Playbooks',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send Playbook', action: 'assignPlaybookToggle' },
                { title: 'Restrict Playbook', action: 'restrictPlaybookToggle' },
            ],
        },
        {
            title: 'Templates',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send Template', action: 'sendTemplateToggle' },
            ],
        },
        {
            title: 'Forms',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send Form', action: 'sendFormToggle' },
                { title: 'Restrict Form', action: 'restrictFormToggle' },
            ],
        },
        {
            title: 'Pages',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Send Page', action: 'sendPageToggle' },
                { title: 'Restrict Page', action: 'restrictPageToggle' },
            ],
        },
        {
            title: 'Files',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Add A File', action: 'addFileToggle' },
            ],
        },
        {
            title: 'Client Tracking',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Start Client Tracking ', action: 'toggleStartProgressTracking' },
                { title: 'Stop Client Tracking', action: 'toggleStopProgressTracking' },
            ],
        },
        {
            title: 'Habit Tracking',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Start Habit Tracking', action: 'toggleStartHabitTracking' },
                { title: 'Stop Habit Tracking', action: 'toggleStopHabitTracking' },
            ],
        },
        {
            title: 'Workouts',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                { title: 'Start Workout', action: 'toggleStartWorkout' },
                { title: 'Stop Workout', action: 'toggleStopWorkout' },
                { title: 'Enroll in Workout Program', action: 'toggleStartWorkoutPlan' },
                { title: 'Restrict Workout Program', action: 'toggleStopWorkoutPlan' },
            ],
        },
        {
            title: 'Client Portal',
            action: '',
            hasCan: true,
            can: {
                action: 'update',
                module: 'contacts-center'
            },
            hasSubMenu: true,
            submenus: [
                {
                    title: ' Suspend portal ',
                    action: 'suspend',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
                {
                    title: ' Reactivate Portal  ',
                    action: 'activate',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
                {
                    title: ' Resend Login Details ',
                    action: 'resendLoginDetails',
                    hasCan: true,
                    can: {
                        action: 'update',
                        module: 'contacts-center'
                    }
                },
            ]
        },
        // {
        //     title: 'Tasks',
        //     action: '',
        //     hasCan: true,
        //     can: {
        //         action: 'update',
        //         module: 'contacts-center'
        //     },
        //     hasSubMenu: true,
        //     submenus: [
        //         { title: 'Start Task', action: 'toggleStartTask' },
        //         { title: 'Stop Task', action: 'toggleStopTask' },
        //     ],
        // },
        {
            title: 'Bulk Delete',
            action: 'deleteContactsPrompt',
            hasCan: true,
            can: {
                action: 'delete',
                module: 'contacts-center'
            }
        },


    ],
    journeyList: [],
    sequenceList: [],
    playbookList: [],
    selectedSequence: [],
    selectedSequenceDefault: [],
    selectedPipelineList: [],
    selectedPipelineDefault: [],
    selectedFormList: [],
    selectedPageList: [],
    selectedProgressTracking: [],
    selectedProgressTrackingDefault: [],
    selectedTask: [],
    selectedHabitTracking: [],
    selectedHabitTrackingDefault: [],
    selectedWorkout: [],
    selectedWorkoutPlans:[],
    selectedWorkoutDefault: [],
    selectedTags: [],
    smsLoader: false,
    emailLoader: false,
    noteLoader: false,
    bulkActionWatcher: false,
    rewardLoader: false,
    contactSaveLoader: false,
    genderList: [
        {
            title: 'Male',
            value: 'Male',
        },
        {
            title: 'Female',
            value: 'Female',
        },
        {
            title: 'Unassigned',
            value: 'Unassigned',
        },
    ],
    temperatureList: [
        {
            title: 'Select temperature...',
            value: '',
        },
        {
            title: 'Cold',
            value: 'cold',
        },
        {
            title: 'Warm',
            value: 'warm',
        },
        {
            title: 'Hot',
            value: 'hot',
        },
    ],
    sourceList: [
        {
            title: 'Select source...',
            value: '',
        },
        {
            title: 'Phone',
            value: 'Phone',
        },
        {
            title: 'Email',
            value: 'Email',
        },
        {
            title: 'Referral',
            value: 'Referral',
        },
        {
            title: 'Internet',
            value: 'Internet',
        },
        {
            title: 'Event',
            value: 'Event',
        },
        {
            title: 'Daily Deal',
            value: 'Daily Deal',
        },
        {
            title: 'Direct Mail',
            value: 'Direct Mail',
        },
        {
            title: 'Networking Group',
            value: 'Networking Group',
        },
        {
            title: 'JV Partner',
            value: 'JV Partner',
        },
        {
            title: 'OM Offers',
            value: 'OM Offers',
        },
        {
            title: 'Member ID',
            value: 'Member ID',
        },
        {
            title: 'Facebook Ads',
            value: 'Facebook Ads',
        },
        {
            title: 'Other',
            value: 'Other',
        },
    ],
    contactChartLoader: false,
    chartData: {
        axis: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [0, 0, 0, 0, 0, 0, 0],
    },
    bulkActionLoader: false,
    lifecycleSubjectList: [],
    notes: {},
    stats: {},
    emails: [],
    contactRewards: [],
    rewardsTotalPoints: 0,
    contactProfileLoader: false,
    photoUpdateLoader: false,
    allContacts: [],
    customFields: [],
    customFieldsAll: [],
    suppressedContacts: {},
    suppressedContactLoader: false,
    statusTabsLoader: false,
    customFieldLoader: false,
    contactDetailsLoader: false,
    contactTagsLoader: false,
    broadcastLogs: {},
    contactPages: {},
    contactSequences: {},
    contactPlaybooks: {},
    contactJourneys: {},
    sendMessageLoader: false,
    scheduleMessageLoader: false,
    updateContactLoader: false,
    sendEmailEventLogs: {},
    sendEmailEventLogsLoader: false,
    showContactRefreshButton: false,
    contactAssetsWatcher: 0,
    updatePortalPasswordLoader: false,
    selectedContact: {},
    tablePreferences: {
        photo: 1,
        temperature: 1,
        starred: 1,
        first_name: 1,
        last_name: 1,
        date_created: 1,
        email: 1,
        number: 1,
        status: 1,
        last_action: 1,
        tags: 0,
        login_count: 0,
        last_email_activity: 0,
        last_chat: 0,
        table_header: 1,
    },
    detetedContact: {},
    deletedContactLoader: false,
    unopenedEmailsFilterLoaderStatus: false,
    unopenedEmailsContacts: 0,
};

export default state;
