<template>
    <div v-if="modelValue" class="edit_modal" @click.self="closeModal()">
        <div class="edit_section setting_wpr move-image-to-folder p-0">
            <div class="form_body">
                <h2 class="title_header m-0">Move Image<button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button></h2>
                <form @submit.prevent="handleFileMoveToFolder">
                    <div class="form_content">
                        <div class="listing_loader" v-show="mainFolderLoader"><quote-loader /></div>
                        <ul class="card_listing" v-show="!mainFolderLoader">
                            <li>
                                <span>Main Folder</span>
                                <label :for="`image-00`" class="radio">
                                    <input type="radio" :id="`image-00`" name="folder" :value="0" v-model="selected">
                                    <!-- <span><i class="fa fa-circle"></i></span> -->
                                </label>
                            </li>
                            <li v-for="(folder, i) of folders" :key="i">
                                <span>{{ folder.title }}</span>
                                <label :for="`image-${i}`" class="radio">
                                    <input type="radio" :id="`image-${i}`" name="folder" :value="folder.id" v-model="selected">
                                    <!-- <span><i class="fa fa-circle"></i></span> -->
                                </label>
                            </li>
                        </ul>
                        <div class="action_wpr px-5 py-4">
                            <button type="button" :disabled="folderAddAssetLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="folderAddAssetLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="folderAddAssetLoader"></i> {{ folderAddAssetLoader ? 'Moving' : 'Move' }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Toastr from '@/utils/Toastr'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Image Move To Folder',

        data () {
            return {
                selected: 0,
            };
        },

        props: {
            modelValue: Boolean,
            selectedImage: Number,
        },

        emits: ['update:modelValue'],

        computed: mapState({
            folderAddAssetLoader: state => state.folderModule.folderAddAssetLoader,
            folders: state => state.folderModule.mainFolders,
            mainFolderLoader: state => state.folderModule.mainFolderLoader,
        }),

        mounted () {
            const vm = this;

            vm.getMainFolder({ asset_type: 'images' });
        },

        methods: {
            ...mapActions({
                addFileToFolder: 'folderModule/addAssets',
                getMainFolder: 'folderModule/getMainFolder',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleFileMoveToFolder () {
                const vm = this;
                const params = { asset_type: 'images', assets_id: [vm.selectedImage], folder_id: vm.selected };

                vm.addFileToFolder(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                        vm.$parent.getLibraryImages(vm.$parent.libraryParams);
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .card_listing {
        height: 325px;
        overflow: hidden;
        overflow-y: auto;
        display: block;
        padding: 15px;
    }

    .card_listing li {
        position: relative;
        width: 100%;
        padding: 5px 15px;
        height: 30px;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 18px;
    }

    .card_listing li span {
        float: left;
    }

    .card_listing li .radio {
        position: absolute;
        top: 7px;
        right: 10px;
        padding: 0;
        border: 0;
    }

    .close_btn {
        position: static !important;
        background: transparent !important;
        color: #121525 !important;
    }

    .move-image-to-folder {
        max-width: 400px;
        margin: auto;
    }
</style>
