const mutations = {
    SET_STATUS_LIST (state, statuses) {
        let statusList = [],
            groupOrder = ['leads', 'trials', 'clients', 'staff', 'lost'];

        for (let groupIndex in groupOrder) {
            for (let statusIndex in statuses[groupOrder[groupIndex]]) {
                statusList.push({
                    title: statuses[groupOrder[groupIndex]][statusIndex].title,
                    value: statuses[groupOrder[groupIndex]][statusIndex].value
                });
            }
        }

        state.statusList = statusList;
    },

    GROUP_STATUSES(state, statuses) {
        let groupOrder = ['leads', 'trials', 'clients', 'staff', 'lost'];

        let array = [
            {
                type: 'leads',
                items: []
            },
            {
                type: 'trials',
                items: []
            },
            {
                type: 'clients',
                items: []
            },
            {
                type: 'staff',
                items: []
            },
            {
                type: 'lost',
                items: []
            }
        ];

        for (let groupIndex in array) {
            for (let statusIndex in statuses[groupOrder[groupIndex]]) {
                array[groupIndex].items.push(
                    {
                        title: statuses[groupOrder[groupIndex]][statusIndex].title,
                        value: statuses[groupOrder[groupIndex]][statusIndex].value
                    }
                );
            }
        }

        state.statusesGroup = array;
    },

    SET_STATUSES (state, statuses) {
        state.statuses = statuses;
    },

    SET_CONTACTS (state, contacts) {
        state.contacts = contacts;
        state.showContactRefreshButton = false;
    },

    SET_CONTACTS_TOTAL_LENGTH (state, totalLength) {
        state.totalLength = totalLength;
    },

    SET_CONTACTS_LOADER_STATUS (state, value) {
        state.contactsLoader = value;
    },

    SET_ASSETS_LOADER_STATUS (state, value) {
        state.assetsLoader = value;
    },

    SET_CONTACT_ASSETS (state, assets) {
        state.journeyList = assets.journeys;
        state.sequenceList = assets.sequences;
        state.playbookList = assets.playbooks;
        state.selectedSequence = assets.sequences;
        state.selectedSequenceDefault = assets.sequences;
        state.selectedPipelineList = assets.pipelines;
        state.selectedPipelineDefault = assets.pipelines;
        state.selectedFormList = assets.forms;
        state.selectedPageList = assets.pages;
        state.selectedProgressTracking = assets.progresses;
        state.selectedProgressTrackingDefault = assets.progresses;
        state.selectedTask = assets.tasks;
        state.selectedHabitTracking = assets.habits;
        state.selectedHabitTrackingDefault = assets.habits;
        state.selectedWorkout = assets.workouts;
        state.selectedWorkoutPlans = assets.plans ? assets.plans : [];
        state.selectedWorkoutDefault = assets.workouts;
        state.selectedTags = assets.tags;
    },

    SET_SMS_LOADER_STATUS (state, value) {
        state.smsLoader = value;
    },

    SET_EMAIL_LOADER_STATUS (state, value) {
        state.emailLoader = value;
    },

    SET_NOTE_LOADER_STATUS (state, value) {
        state.noteLoader = value;
    },

    SET_REWARD_LOADER_STATUS (state, value) {
        state.rewardLoader = value;
    },

    SET_BULK_ACTION_WATCHER (state) {
        state.bulkActionWatcher += 1;
    },

    SET_CONTACT_SAVE_LOADER_STATUS (state, value) {
        state.contactSaveLoader = value;
    },

    SET_CONTACT_CHART_LOADER_STATUS (state, value) {
        state.contactChartLoader = value;
    },

    SET_CONTACT_CHART_DATA (state, data) {
        state.chartData = data;
    },

    SET_BULK_ACTION_LOADER_STATUS (state, value) {
        state.bulkActionLoader = value;
    },

    SET_LIFECYCLE_SUBJECT_LIST (state, data) {
        state.lifecycleSubjectList = data.map(sub => sub.subject);
    },

    SET_NOTES (state, notes) {
        state.notes = notes;
    },

    SET_STATS (state, stats) {
        state.stats = stats;
    },

    SET_EMAILS (state, emails) {
        state.emails = emails;
    },

    SET_REWARDS (state, rewards) {
        state.contactRewards = rewards;
    },

    SET_REWARDS_TOTAL_POINTS (state, points) {
        state.rewardsTotalPoints = points;
    },

    SET_CONTACT_PROFILE_LOADER_STATUS (state, value) {
        state.contactProfileLoader = value;
    },

    SET_CONTACT_PHOTO_LOADER_STATUS (state, value) {
        state.photoUpdateLoader = value;
    },

    SET_ALL_CONTACTS (state, contacts) {
        let newContacts = contacts.filter(c1 => !state.allContacts.find(c2 => c1.id === c2.id));

        state.allContacts = [...state.allContacts, ...newContacts];
    },

    SET_CUSTOM_FIELDS (state, fields) {
        if (fields.data) {
            state.customFields = fields;
        } else {
            state.customFieldsAll = fields;
        }
    },

    SET_SUPPRESSED_CONTACTS (state, contacts) {
        state.suppressedContacts = contacts;
    },

    SET_SUPPRESSED_CONTACT_LOADER_STATUS (state, status) {
        state.suppressedContactLoader = status;
    },

    SET_STATUS_TABS_LOADER_STATUS (state, contacts) {
        state.statusTabsLoader = contacts;
    },

    SET_CUSTOM_FIELD_LOADER_STATUS (state, value) {
        state.customFieldLoader = value;
    },

    SET_CONTACT_DETAILS_LOADER_STATUS (state, value) {
        state.contactDetailsLoader = value;
    },

    SET_CONTACT_TAGS_LOADER_STATUS (state, value) {
        state.contactTagsLoader = value;
    },

    SET_BROADCAST_LOGS (state, logs) {
        state.broadcastLogs = logs;
    },

    SET_SELECTED_TAGS (state, tags) {
        state.selectedTags = tags;
    },

    SET_CONTACT_PAGES (state, pages) {
        state.contactPages = pages;
    },

    SET_CONTACT_SEQUENCES (state, sequences) {
        state.contactSequences = sequences;
    },

    SET_CONTACT_PLAYBOOKS (state, playbooks) {
        state.contactPlaybooks = playbooks;
    },

    SET_CONTACT_JOURNEYS (state, journeys) {
        state.contactJourneys = journeys;
    },

    SET_UPDATED_ACTION (state, action) {
        state.updatedAction = action;
    },

    SET_SEND_MESSAGE_LOADER_STATUS (state, value) {
        state.sendMessageLoader = value;
    },

    SET_SCHEDULE_MESSAGE_LOADER_STATUS (state, value) {
        state.scheduleMessageLoader = value;
    },

    SET_UPDATE_CONTACT_LOADER_STATUS (state, value) {
        state.updateContactLoader = value;
    },

    SET_SEND_EMAIL_EVENT_LOGS_LOADER_STATUS (state, value) {
        state.sendEmailEventLogsLoader = value;
    },

    SET_SEND_EMAIL_EVENT_LOGS_STATUS (state, logs) {
        state.sendEmailEventLogs = logs;
    },

    SET_CONTACT_REFRESH_BUTTON_STATUS (state, status) {
        state.showContactRefreshButton = status;
    },

    SET_CONTACT_ASSETS_WATCHER_STATUS (state) {
        state.contactAssetsWatcher += 1;
    },

    SET_PORTAL_PASSWORD_UPDATE_LOADER_STATUS (state, status) {
        state.updatePortalPasswordLoader = status;
    },

    SET_SELECTED_CONTACT (state, contact) {
        state.selectedContact = contact;
    },

    SET_CONTACT_TABLE_PREFRENCES (state, preferences) {
        state.tablePreferences = preferences;
    },

    SET_CONTACTS_DELETED (state, contact) {
        state.detetedContact = contact;
    },

    SET_CONTACTS_DELETED_LOADER_STATUS (state, connect) {
        state.deletedContactLoader = connect;
    },

    SET_UNOPENED_EMAILS_FILTER_LOADER_STATUS (state, status) {
        state.unopenedEmailsFilterLoaderStatus = status;
    },

    SET_UNOPENED_EMAILS_CONTACTS (state, contacts) {
        state.unopenedEmailsContacts = contacts;
    },
};

export default mutations;
