import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getEmailReport (store, params) {
        store.commit('SET_EMAIL_REPORT_LOADER_STATUS', true);

        axios.get('/email-log/get-email-report', { params }).then((resp) => {
            store.commit('SET_EMAIL_REPORTS', resp.data);
            store.commit('SET_EMAIL_REPORT_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_EMAIL_REPORT_LOADER_STATUS', false);
        });
    },

    getDeliveryLog (store) {
        store.commit('SET_DELIVERY_LOG_LOADER_STATUS', true);

        axios.get('/email-log/get-delivery-log').then((resp) => {
            store.commit('SET_DELIVERY_LOG', resp.data);
            store.commit('SET_DELIVERY_LOG_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_DELIVERY_LOG_LOADER_STATUS', false);
        });
    },
};

export default actions;