const state = {
    clientPortal: {},
    clientPortalLoader: false,
    updateLoader: false,
    assetsLoader: false,
    playbooks: {},
    playbookCategories: [],
    pages: {},
    forms: {},
    workouts: {},
    workoutPlans: {},
    pinnedPlaybooks: [],
    lastRefreshedAt: null,
    calendarEvents: [],
    calendarEventsByDate: [],
    calendarEventLoader: false,
    files: {},
    tabRenameLoader: {
        classroom_tab_name: false,
        playbook_tab_name: false,
        page_tab_name: false,
        form_tab_name: false,
        workout_tab_name: false,
        file_tab_name: false,
        calendar_tab_name: false,
        progress_tab_name: false,
    },
    calendarTitleLoader: {
        morning_text: false,
        afternoon_text: false,
        evening_text: false,
    },
    announcementSettingAddLoader: false,
    announcementSettingUpdateLoader: false,
    announcementSettingDeleteLoader: false,
};

export default state;