<template>
    <div class="modal secondary security card-form" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header">
                <h1 class="sub_header m-0">
                    Love Thrive Coach?
                </h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="setting_wpr mt-3">
                <Form @submit="startPaidSubscription" v-slot="{ errors }" class="verify_form">
                    <label>Want all the benefits of a paid account? Just skip your trial and start billing today!</label>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Enter your account password</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.password }">
                                <Field type="password" :autocomplete="'nofill'" name="password" v-model="password" rules="required|min:8" placeholder="Enter Password" />
                            </div>
                            <ErrorMessage name="password" class="text-danger" />
                        </div>
                    </div>
                    <div class="create_btn mt-3">
                        <button :disabled="paidSubscriptionLoader" class="primary_btn pointer">
                            <i class="fa fa-spinner fa-spin" v-if="paidSubscriptionLoader"></i>&nbsp;
                            {{ paidSubscriptionLoader ? 'Starting' : 'Start Paid Account' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Account Setup',

        data () {
            return {
                paidSubscriptionLoader: false,
                password: '',
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            }
        },

        props: {
            modelValue: Boolean,
            refreshBilling: Function,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        computed:mapState({
            user: state => state.authModule.user,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.password = '';
                vm.$emit('update:modelValue', false);
            },

            async startPaidSubscription (form, { setFieldError }) {
                const vm = this;

                vm.paidSubscriptionLoader = true;

                axios.post(`${vm.billingApi}/stripe/skip-trial`, { password: vm.password }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.closeModal();

                    if (vm.refreshBilling) {
                        vm.refreshBilling();
                    }

                    vm.paidSubscriptionLoader = false;
                    Toastr.success('Paid subscription has been started successfully!');
                }).catch((err) => {
                    vm.paidSubscriptionLoader = false;
                    Toastr.handleServerError(err, setFieldError, false);
                });
            },
        },
    };
</script>

<style scoped>
    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .card-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .setting_wpr {
        width: 100%;
    }
</style>
