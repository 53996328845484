const state = {
    allProgressTrackings: [],
    progressTrackingLoader: false,
    contactProgressTrackingLoader: false,
    contactCurrentProgressLoader: false,
    contactCurrentProgressLoaderV2: false,
    contactProgressTrackings: [],
    contactCurrentProgress: {},
    progressTrackings: {},
    progressTrackingComponentLoader: false,
    selectedProgressTracking: {},
    activeContacts: {},
    deletedContacts: {},
    leaderboard: {},
    progressTrackingLeaderboardLoader: false,
    questionSections: {},
    progressTrackingQuestionLoader: false,
    progressTrackingUpdateLoader: false,
    questionSaveLoader: false,
    submittedProofs: [],
    params: {
        per_page: 6,
        page: 1,
        search: '',
        order_by: 'order',
        order: 'desc',
    },
    defaultForm: {
        public_settings: {
            company_branding: '',
            header_bgcolor: '#FFF',
            header_textcolor: '#000',
            headline: 'Time To Submit Your Progress',
            subheadline: '',
            overlay_color: '#1976D280',
            cover_color: '#1976d2',
            element_color: '#2c3e50',
            element_text_color: '#2C3E50',
            submit_btn_text: 'Submit Progress',
            submit_btn_textcolor: '#fff',
            submit_btn_bgcolor: '#2c3e50',
            footer_bgcolor: '#D4D4D4',
            footer_textcolor: '#2C3E50',
            thankyou_bgcolor: '#EDEBEB',
            thankyou_textcolor: '#2c3e50',
            general_bg_color : '#FFF4E2',
            general_text_color: '#000000',
            general_element_color: '#ED9393'
        },
        headline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 50,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.25)',
        },
        subheadline_setting: {
            font_family: 'Inter',
            font_weight: 600,
            font_size: 25,
            font_color: '#2C3E50',
            has_shadow: 0,
            shadow_x: 0,
            shadow_y: 4,
            shadow_blur: 4,
            shadow_color: 'rgba(0, 0, 0, 0.68)',
        },
    },
    defaultCategories: [],
    defaultCategoriesLoader: false,
    categoryProgressSaveLoader: false,
    categoryProgressUpdateLoader: false,
    categoryUpdateLoader: false,
    refreshContactGoalLoader: false,
    contactGoals: [],
    updateContactGoalLoader: false,
};

export default state;
