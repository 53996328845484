import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getFolder (store, params) {
        store.commit('SET_FOLDER_LOADER_STATUS', true);
        store.commit('SET_FOLDERS', []);
        store.commit('imageLibraryModule/SET_LIBRARY_IMAGES', [], { root: true });

        axios.get(`/folder/${params.asset_type}/list`, { params }).then((resp) => {
            store.commit('SET_FOLDER_LOADER_STATUS', false);
            store.commit('SET_FOLDERS', resp.data.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_FOLDER_LOADER_STATUS', false);
        });
    },

    createFolder (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', true);

            axios.post('/folder/create', params).then((resp) => {
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    updateFolder (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', true);

            axios.patch('/folder/update', params).then((resp) => {
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                resolve(err)
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);
            });
        });
    },

    archiveFolder (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', true);

            axios.patch('/folder/archive', params).then((resp) => {
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                resolve(false)
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);
            });
        });
    },

    deleteFolder (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', true);

            axios.delete('/folder/delete', { params }).then((resp) => {
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                resolve(false)
                store.commit('SET_FOLDER_UPDATE_LOADER_STATUS', false);
            });
        });
    },

    addAssets (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_ADD_ASSET_LOADER_STATUS', true);

            axios.post('/folder/add-assets', params).then((resp) => {
                store.commit('SET_ADD_ASSET_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                store.commit('SET_ADD_ASSET_LOADER_STATUS', false);
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getMainFolder (store, params) {
        store.commit('SET_MAIN_FOLDER_LOADER_STATUS', true);
        store.commit('SET_MAIN_FOLDERS', []);

        axios.get(`/folder/${params.asset_type}/list`, { params }).then((resp) => {
            store.commit('SET_MAIN_FOLDER_LOADER_STATUS', false);
            store.commit('SET_MAIN_FOLDERS', resp.data.data);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_MAIN_FOLDER_LOADER_STATUS', false);
        });
    },

    sortFolders (store, params) {
        axios.patch('/folder/sort', params).catch((err) => {
            console.log(err);
        });
    }
};

export default actions;
