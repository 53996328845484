import axios from '@/services/axios'
import Toastr from '@/utils/Toastr'

const actions = {
    getAllPlaybooks (store, params) {
        axios.get('/playbook/list-all', { params }).then((resp) => {
            store.commit('SET_ALL_PLAYBOOKS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    sendPlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_SEND_LOADER_STATUS', true);

            axios.post('/playbook/send-to-contact', params).then((resp) => {
                store.commit('SET_PLAYBOOK_SEND_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Playbooks', { root: true });
                Toastr.success('Playbook has been queued successfully!');
                resolve(true);

                if (params.closeModal) {
                    params.closeModal();
                }
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_SEND_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    restrictPlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_LOADER_STATUS', true);

            axios.post('/playbook/restrict-from-contact', params).then((resp) => {
                store.commit('SET_PLAYBOOK_LOADER_STATUS', false);
                store.commit('contactModule/SET_BULK_ACTION_WATCHER', {}, { root: true });
                store.commit('contactModule/SET_UPDATED_ACTION', 'Playbooks', { root: true });
                Toastr.success('Playbook has been restricted successfully!');

                if (params.closeModal) {
                    params.closeModal();
                }
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getPlaybooks (store, params) {
        return new Promise((resolve, reject) => {
            store.commit('SET_PLAYBOOK_LOADER_STATUS', true);

            axios.get('/playbook/list', { params }).then((resp) => {
                store.commit('SET_PLAYBOOKS', resp.data);
                store.commit('SET_PLAYBOOK_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_PLAYBOOK_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getPlaybookUsers (store, params) {
        store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', true);

        axios.get(`/playbook/${params.playbook_id}/users`, { params }).then((resp) => {
            store.commit('SET_PLAYBOOK_USERS', resp.data);
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
        });
    },

    renamePlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', true);

            axios.post('/playbook/rename', params).then((resp) => {
                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
                Toastr.success('Playbook name has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getAnalytics (store, params) {
        store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', true);

        axios.get('/playbook/get-analytics', { params }).then((resp) => {
            store.commit('SET_ANALYTICS', resp.data);
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
        });
    },

    getPlaybookQuestions (store, params) {
        store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', true);

        axios.get('/playbook/get-questions', { params }).then((resp) => {
            store.commit('SET_PLAYBOOK_QUESTIONS', resp.data);
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
        });
    },

    deletePlaybookQuestions (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', true);

            axios.delete('/playbook/delete-questions', { params }).then((resp) => {
                store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
                Toastr.success('Playbook question has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    questionToFAQ (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', true);

            axios.post('/playbook/question-to-faq', params).then((resp) => {
                store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);

                const notification = resp.data.status == 1 ? 'success' : 'error';
                Toastr[`${notification}`](resp.data.message);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    getPlaybookDetails (store, playbook) {
        return new Promise(function (resolve, reject) {
            store.commit('SET_SELECTED_PLAYBOOK', {});
            store.commit('SET_PLAYBOOK_DETAIL_LOADER', true);

            axios.get(`playbook/${playbook}/details`).then((resp) => {
                store.commit('SET_SELECTED_PLAYBOOK', resp.data);
                store.commit('SET_PLAYBOOK_DETAIL_LOADER', false);
                resolve(resp.data);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_PLAYBOOK_DETAIL_LOADER', false);
                resolve(false);
            });
        });
    },

    getCustomButtons (store, playbook) {
        axios.get(`playbook/${playbook}/custom-buttons`).then((resp) => {
            store.commit('SET_CUSTOM_BUTTONS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    getPlaybookConfiguration (store) {
        axios.get('/playbook/configuration').then((resp) => {
            store.commit('SET_PLAYBOOK_CONFIGURATION', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    updatePlaybookSetting (store, params) {
        return new Promise(function(resolve, reject) {
            if (!params.playbook_id) {
                resolve(false);
            }

            store.commit('SET_UPDATE_LOADER_STATUS', true);

            axios.patch(`/playbook/${params.playbook_id}/update-settings`, params).then((resp) => {
                store.commit('SET_SELECTED_PLAYBOOK', resp.data);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.success('Playbook has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    savePlaybookCustomButton (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_LOADER_STATUS', true);

            axios.post(`/playbook/save-custom-button`, params).then((resp) => {
                store.dispatch('getCustomButtons', params.playbook_id);
                store.commit('SET_UPDATE_LOADER_STATUS', false);

                if (params.setFieldError) {
                    Toastr.success('Playbook custom button has been saved successfully!');
                }

                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sortActionButton (store, params) {
        axios.patch(`playbook/${params.playbook_id}/sort-action-button`, params).then((resp) => {
            store.commit('SET_SELECTED_PLAYBOOK', resp.data);
            Toastr.success('Playbook action button has been sorted successfully!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    sortCustomButton (store, params) {
        axios.patch(`playbook/${params.playbook_id}/sort-custom-button`, params).then((resp) => {
            store.commit('SET_CUSTOM_BUTTONS', resp.data);
            Toastr.success('Playbook custom button has been sorted successfully!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    savePlaybookFAQ (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_UPDATE_LOADER_STATUS', true);

            axios.post(`/playbook/${params.playbook_id}/save-faq`, params).then((resp) => {
                store.commit('SET_SELECTED_PLAYBOOK', resp.data);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.success('Playbook FAQ has been saved successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                store.commit('SET_UPDATE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sortPlaybookStep (store, params) {
        axios.patch(`/playbook/${params.playbook_id}/sort-steps`, params).then((resp) => {
            store.dispatch('getPlaybookStep', params.playbook_id);
            Toastr.success('Playbook steps has been sorted successfully!');
        }).catch((err) => {
            console.log(err);
            Toastr.handleServerError(err);
        });
    },

    duplicatePlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post('/playbook/duplicate', params).then((resp) => {
                Toastr.success('Playbook has been duplicated successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deletePlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/playbook/delete', { params }).then((resp) => {
                store.dispatch('getAllPlaybooks');
                Toastr.success('Playbook has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    pinUnpinPlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/playbook/${params.id}/pin-unpin`, params).then((resp) => {
                Toastr.success(`Playbook has been ${ params.is_pinned ? 'pinned' : 'unpinned' } successfully!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    enableDisableAllClients (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/playbook/${params.id}/enable-disable-all-clients`, params).then((resp) => {
                Toastr.success(`Playbook has been successfully ${ params.all_clients ? 'enabled' : 'disabled' } for all clients!`);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    createPlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', true);

            axios.post('/playbook/create', params).then((resp) => {
                store.dispatch('getAllPlaybooks');
                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
                Toastr.success(`${params.title} playbook has been created successfully!`);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    createPlaybookCategory (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_CATEGORY_SAVE_LOADER_STATUS', true);

            axios.post('/playbook/create-category', params).then((resp) => {
                store.commit('SET_CATEGORY_SAVE_LOADER_STATUS', false);
                Toastr.success('Playbook category has been created successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_CATEGORY_SAVE_LOADER_STATUS', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteCategory (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete('/playbook/delete-category', { params }).then((resp) => {
                Toastr.success('Playbook category has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    getPlaybookCategories (store, params) {
        store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', true);

        axios.get('/playbook/playbook-categories', { params }).then((resp) => {
            store.commit('SET_PLAYBOOK_CATEGORIES', resp.data);
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
        }).catch((err) => {
            console.log(err);
            store.commit('SET_PLAYBOOK_QUESTION_LOADER_STATUS', false);
        });
    },

    addNewTab (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', true);

            axios.post(`/playbook/step/${params.step_id}/add-tab`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    updateStepTab (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', true);

            axios.post(`/playbook/step-tab/${params.id}/update`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', false);
                resolve(resp.data);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_TAB_LOADER', false);
                resolve(false);
            });
        });
    },

    getPlaybookStep (store, playbookId) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_STEP_LOADER', true);

            axios.get(`/playbook/${playbookId}/steps`).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_LOADER', false);
                store.commit('SET_PLAYBOOK_STEPS', resp.data);

                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_STEP_LOADER', false);
                resolve(false);
            });
        });
    },

    getPlaybookStepContent (store, params) {
        return new Promise(function(resolve, reject) {
            axios.get(`/playbook/${params.playbook_id}/step/${params.step_id}`).then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                resolve(false);
            });
        });
    },

    updatePlaybookStep (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_STEP_SAVE_LOADER', true);

            axios.post(`/playbook/step/${params.step_id}/update-step`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_LOADER', false);

                Toastr.success('Playbook step has been updated successfully!');
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_STEP_SAVE_LOADER', false);
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    sortPlaybookStepTabs (store, params) {
        axios.post(`/playbook/step/${params.step_id}/sort-tabs`, params).then((resp) => {
            store.dispatch('getPlaybookStep', params.playbook_id);
            Toastr.success('Playbook step tabs has been sorted successfully!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    getStepDownloads (store, stepId) {
        return new Promise(function(resolve, reject) {
            axios.get(`/playbook/step/${stepId}/download`).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_DOWNLOADS', resp.data);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            });
        });
    },

    deleteStepDownload (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/playbook/step/delete-download`, { params }).then((resp) => {
                store.dispatch('getStepDownloads', params.step_id);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    getSmartSteps (store) {
        axios.get('/playbook/smart-steps').then((resp) => {
            store.commit('SET_PLAYBOOK_SMART_STEPS', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    createNewStep (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/playbook/${params.playbook_id}/create-step`, params).then((resp) => {
                // Toastr.success('Playbook step has been created successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);
                resolve(false);
            });
        });
    },

    deleteTab (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/playbook/tab/${id}/delete`).then((resp) => {
                Toastr.success('Playbook step tab has been deleted successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteStep (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/playbook/step/${id}/delete`).then((resp) => {
                Toastr.success('Playbook step has been deleted successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    resendPlaybook (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`/playbook/${params.playbook_id}/resend`, params).then((resp) => {
                Toastr.success('Playbook credentials has been sent successfully!');
                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    renamePlaybookDownloadFile (store, params) {
        return new Promise(function(resolve, reject) {
            axios.patch(`/playbook/step-download/${params.id}/rename`, params).then((resp) => {
                Toastr.success('Playbook download file has been renamed successfully!');
                store.dispatch('getStepDownloads', params.step_id);
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteCustomButton (store, buttonId) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/playbook/custom-button/${buttonId}/delete`).then((resp) => {
                Toastr.success('Playbook custom button has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                console.log(err);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    duplicatePlaybookStep (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_STEP_DUPLICATE_LOADER_STATUS', true);

            axios.post(`/playbook/${params.id}/duplicate-step`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_STEP_DUPLICATE_LOADER_STATUS', false);
                resolve(true);
            }).catch((err) => {
                store.commit('SET_PLAYBOOK_STEP_DUPLICATE_LOADER_STATUS', false);
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    deleteFaq (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/playbook/delete-faq`, { params }).then((resp) => {
                Toastr.success('Playbook question has been deleted successfully!');
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                resolve(false);
            });
        });
    },

    sortActionSidebar(store, params) {
        axios.patch(`playbook/${params.playbook_id}/sort-action-sidebar`, params).then((resp) => {
            store.commit('SET_SELECTED_PLAYBOOK', resp.data);
            Toastr.success('Playbook action sidebar has been sorted successfully!');
        }).catch((err) => {
            Toastr.handleServerError(err);
        });
    },

    uploadPlaybookCanvaZip (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_UPLOAD_LOADER_STATUS', true);

            axios.post(`playbook/upload-canva-zip`, params.formData).then((resp) => {
                store.commit('SET_PLAYBOOK_CANVA_ZIP_FILES', resp.data);
                store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_UPLOAD_LOADER_STATUS', false);

                Toastr.success('Awesome! Your Canva files were imported successfully.');

                resolve(true);
            }).catch((err) => {
                console.log(params, 'sd')
                Toastr.handleServerError(err, params.setFieldError);
                store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_UPLOAD_LOADER_STATUS', false);

                resolve(false);
            });
        });
    },

    createPlaybookFromCanva (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', true);

            axios.post(`playbook/create-from-canva`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);

                // Toastr.success('Playbook has been created successfully!');

                resolve(resp.data);
            }).catch((err) => {
                Toastr.handleServerError(err, params.setFieldError);

                store.commit('SET_PLAYBOOK_USER_LOADER_STATUS', false);

                resolve(false);
            });
        });
    },

    getPlaybookCanvaSteps (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS', true);

            if (typeof params == 'object') {
                axios.get(`playbook/${params.batch}/canva-steps-by-batch`).then((resp) => {
                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILES', resp.data);
                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS', false);

                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err);

                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS', false);

                    resolve(false);
                });
            } else {
                axios.get(`playbook/${params}/canva-steps`).then((resp) => {
                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILES', resp.data);
                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS', false);

                    resolve(true);
                }).catch((err) => {
                    Toastr.handleServerError(err);

                    store.commit('SET_PLAYBOOK_CANVA_ZIP_FILE_LOADER_STATUS', false);

                    resolve(false);
                });
            }

        });
    },

    buildPlaybookFromCanva (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_CANVA_BUILD_LOADER_STATUS', true);

            axios.post(`playbook/build-from-canva`, params).then((resp) => {
                store.commit('SET_PLAYBOOK_CANVA_BUILD_LOADER_STATUS', false);
                Toastr.success('Playbook has been updated successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                store.commit('SET_PLAYBOOK_CANVA_BUILD_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    sortCanvaPlaybookSteps (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`playbook/sort-canva-steps`, params).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    archivePlaybookCanvaSteps (store, params) {
        return new Promise(function(resolve, reject) {
            axios.delete(`playbook/canva-step/${params.id}/archive`, { params }).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    deletePlaybookCanvaStep (store, id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`playbook/canva-step/${id}/delete`).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    getArchivedPlaybookCanvaSteps (store, params) {
        return new Promise(function(resolve, reject) {
            store.commit('SET_PLAYBOOK_CANVA_ARCHIVED_STEPS_LOADER_STATUS', true);

            axios.get(`playbook/${params.batch}/archived-canva-steps`, { params }).then((resp) => {
                store.commit('SET_PLAYBOOK_CANVA_ARCHIVED_STEPS', resp.data);
                store.commit('SET_PLAYBOOK_CANVA_ARCHIVED_STEPS_LOADER_STATUS', false);

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);
                store.commit('SET_PLAYBOOK_CANVA_ARCHIVED_STEPS_LOADER_STATUS', false);
                resolve(false);
            });
        });
    },

    degroupPlaybookCanvaStep (store, id) {
        return new Promise(function(resolve, reject) {
            axios.patch(`playbook/canva-step/${id}/degroup`).then((resp) => {
                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    updatePlaybookCanvaStepTitle (store, step) {
        return new Promise(function(resolve, reject) {
            axios.patch(`playbook/canva-step/${step.id}/update-title`, step).then((resp) => {
                Toastr.success('Canva step title has been updated successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    renamePlaybookCanvaStep (store, step) {
        return new Promise(function(resolve, reject) {
            axios.patch(`playbook/canva-step/${step.id}/rename`, step).then((resp) => {
                Toastr.success('Canva step name has been updated successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    createPlaybookCanvaBlankStep (store, params) {
        return new Promise(function(resolve, reject) {
            axios.post(`playbook/canva-blank-step`, params).then((resp) => {
                Toastr.success('New slot has been added successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },

    restorePlaybookCanvaStep (store, step) {
        return new Promise(function(resolve, reject) {
            axios.patch(`playbook/canva-step/${step}/restore`).then((resp) => {
                Toastr.success('Canva step has been restored successfully!');

                resolve(true);
            }).catch((err) => {
                Toastr.handleServerError(err);

                resolve(false);
            });
        });
    },
};


export default actions;
